<template>
  <default-layout
    route-name="Cadastro de Beneficiários"
    :loading="loading"
    @returnToPreviousPage="returnToList"
    custom-function
  >
    <template #tabs>
      <v-tabs v-model="tab" color="secondary">
        <v-tab
          href="#info"
          class="font-weight-bold"
          id="info"
          cy-data="tab_info"
        >
          Informações
        </v-tab>
        <v-tab
          href="#account"
          class="font-weight-bold"
          id="account"
          cy-data="tab_account"
        >
          Dados Bancários
        </v-tab>
      </v-tabs>
    </template>

    <template #header-actions>
      <v-spacer />

      <default-cancel-button @click="returnToList" custom-function />

      <authorization-gate
        auth-route="/company"
        auth-type="write"
        class="d-flex"
      >
        <default-save-button
          class="flex-item d-block"
          @click="save"
          :disabled="!valid"
          :loading="saving"
        />
      </authorization-gate>
    </template>

    <template #content>
      <v-container class="mt-n12" fluid>
        <v-tabs-items v-model="tab">
          <v-tab-item value="info">
            <company-save-form
              v-model="company"
              @valid="changeValidation"
              ref="company_save_form"
            />
          </v-tab-item>

          <v-tab-item value="account">
            <bank-account-management
              v-model="accounts"
              :company="company"
              ref="bank_account_management"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </template>
  </default-layout>
</template>

<script>
import CompanyService from "@/app/Services/CompanyService";
import CompanySaveForm from "./components/CompanySaveForm.vue";
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import DefaultCancelButton from "@/components/layout/DefaultCancelButton.vue";
import AuthorizationGate from "@/components/Authorization/AuthorizationGate.vue";
import DefaultSaveButton from "@/components/layout/DefaultSaveButton.vue";
import BankAccountManagement from "@/components/Company/BankAccountManagement.vue";
import CompanyState from "@/components/Company/State";
import BankAccountService from "@/app/Services/BankAccountService";
import BankAccountConfigService from "@/app/Services/BankAccountConfigService";

export default {
  name: "CompanySave",
  components: {
    CompanySaveForm,
    DefaultLayout,
    DefaultCancelButton,
    AuthorizationGate,
    DefaultSaveButton,
    BankAccountManagement
  },
  data() {
    return {
      loading: false,
      valid: false,
      saving: false,
      tab: "info"
    };
  },
  created() {
    const alreadyEditing = CompanyState().state.editingAccount;

    if (this.isToEdit() && !alreadyEditing) {
      this.loadData();
    }

    if (alreadyEditing) {
      this.changeTab();
    }
  },
  computed: {
    company: {
      get() {
        return CompanyState().state.company;
      },
      set(value) {
        CompanyState().dispatch("updateCompany", value);
      }
    },
    accounts: {
      get() {
        return CompanyState().state.accounts;
      },
      set(value) {
        CompanyState().dispatch("updateAccounts", value);
      }
    }
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;

        const id = this.$route.params.id;

        const response = await CompanyService.build().read({
          id
        });

        this.company = response.data;
        this.accounts = this.company.accounts;

        this.accounts = this.accounts.map(account => {
          if (!account.accounts_config) {
            account.config = {
              extra: []
            };

            return account;
          }

          account.config = account.accounts_config;
          account.config.extra = account.accounts_config
            ? account.accounts_config.accounts_config_extra
            : [];

          return account;
        });
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    validateCompanyForm() {
      return this.$refs.company_save_form.validate();
    },
    validateBankManagementForm() {
      const accounts = CompanyState().state.accounts;
      const haveAccounts = accounts.length >= 1;

      if (!haveAccounts) {
        this.showNeedToHaveAccountNotification();

        return false;
      }

      const haveAccountActive = accounts.some(account => account.status);

      if (!haveAccountActive) {
        this.showNeedToHaveAccountNotification();

        return false;
      }

      const haveSameAccountRegistered = accounts.some(a => {
        const found = accounts.find(
          b =>
            b.account == a.account &&
            b.agency == a.agency &&
            a.id != b.id &&
            !b.removed &&
            !a.removed
        );

        if (found) {
          return true;
        }

        return false;
      });

      if (haveSameAccountRegistered) {
        this.showCannotBeEqualAccountNotification();

        return false;
      }

      return true;
    },
    showCannotBeEqualAccountNotification() {
      this.showToast({
        message: "Contas duplicadas, verifique os registros e tente novamente",
        status: "error"
      });
    },
    showNeedToHaveAccountNotification() {
      this.showToast({
        message: "É necessário possuir uma conta cadastrada e ativa",
        status: "error"
      });
    },
    async save() {
      try {
        this.saving = true;

        if (!this.validateCompanyForm() || !this.valid) {
          return;
        }

        if (!this.validateBankManagementForm()) {
          return;
        }

        let response = {};

        this.company.accounts = CompanyState().state.accounts;
        this.company.phone = String(this.company.phone).replace(/[()-\s]/g, "");

        if (this.isToEdit()) {
          response = await CompanyService.build().update(this.company);
        } else {
          delete this.company.id;

          response = await CompanyService.build().create(this.company);
        }

        await this.upsertAccounts(response.data);

        this.showToast({ message: "Companhia atualizada com sucesso" });

        await CompanyState().dispatch("updateAccountEditingStatus", false);
        await CompanyState().dispatch("clearHandle");

        this.returnToList();
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.saving = false;
      }
    },
    async insertAccount(account) {
      delete account.id;

      const response = await BankAccountService.build().create(account);

      const insertedAccount = response.data;

      this.upsertAccountsConfig(account.config, insertedAccount);

      return response.data;
    },
    async updateAccount(account) {
      const response = await BankAccountService.build().update(account);

      const updatedAccount = response.data;

      this.upsertAccountsConfig(account.config, updatedAccount);

      return response.data;
    },
    async removeAccount(account) {
      const response = await BankAccountService.build().destroy(account);

      return response.data;
    },
    async upsertAccounts(company) {
      const accounts = CompanyState().state.accounts;
      const serializedAccounts = this.orderToUpdateAccountsFirst(accounts);

      const orders = [];

      serializedAccounts.forEach(account => {
        const serializedAccount = this.serializeAccount(account, company.id);

        if (serializedAccount.inserted) {
          orders.push(this.insertAccount(serializedAccount));

          return;
        }

        if (serializedAccount.edited) {
          orders.push(this.updateAccount(serializedAccount));

          return;
        }

        if (serializedAccount.removed) {
          orders.push(this.removeAccount(serializedAccount));

          return;
        }
      });

      const result = await Promise.all(orders);

      return result;
    },
    async insertAccountConfig(account) {
      delete account.id;

      const response = await BankAccountConfigService.build().create(account);

      return response.data;
    },
    async updateAccountConfig(account) {
      const response = await BankAccountConfigService.build().update(account);

      return response.data;
    },
    async upsertAccountsConfig(accountConfig, account) {
      const orders = [];

      accountConfig.bank_account_id = account.id;
      accountConfig.extra = accountConfig.extra.map(extra => {
        delete extra.id;

        return extra;
      });

      accountConfig.extra = accountConfig.extra.filter(extra => !extra.removed);

      if (!accountConfig.id) {
        orders.push(this.insertAccountConfig(accountConfig));

        return;
      }

      if (accountConfig.edited) {
        orders.push(this.updateAccountConfig(accountConfig));

        return;
      }

      const result = await Promise.all(orders);

      return result;
    },
    orderToUpdateAccountsFirst(accounts) {
      return accounts.sort(account => {
        if (account.edited) {
          return 1;
        }
      });
    },
    serializeAccount(register, company_id) {
      register.company_id = company_id;
      register.bank_id = register.bank.id;

      return register;
    },
    isToEdit() {
      const id = this.$route.params.id;

      return id && id != "new";
    },
    isToCreate() {
      const id = this.$route.params.id;

      return id || id == "new";
    },
    changeValidation(value) {
      this.valid = value;
    },
    changeTab() {
      setTimeout(() => {
        this.tab = "account";
      }, 1200);
    },
    async returnToList() {
      await CompanyState().dispatch("updateAccountEditingStatus", false);

      this.$router.push({ name: "Company" });
    }
  }
};
</script>
