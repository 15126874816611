<template>
  <default-layout
    route-name="Beneficiários"
    @search="search"
    :loading="loading"
    :instructions="instructions"
    has-filters
  >
    <template #header-actions>
      <authorization-gate
        auth-route="/company"
        auth-type="write"
        class="flex-item d-flex flex-row"
      >
        <v-btn
          class="ml-auto text-normal my-auto mr-6"
          color="primary"
          @click="add()"
          id="add"
        >
          Adicionar
        </v-btn>
      </authorization-gate>
    </template>

    <template #content>
      <v-data-table
        :headers="headers"
        :items="items"
        :hide-default-footer="true"
        loading-text="Aguarde...carregando"
        class="elevation-0"
        :items-per-page="pagination.itemsPerPage"
        :header-props="{ sortIcon: null }"
      >
        <template v-slot:item.cnpj="item">
          <span>{{ item.item.cnpj | VMask("##.###.###/####-##") }}</span>
        </template>

        <template v-slot:item.status="{ item }">
          <v-switch
            inset
            class="flex-item"
            color="secondary"
            v-model="item.status"
            :readonly="!authorization.canEdit"
            @change="changeStatus(item.id, $event)"
          />
        </template>
        <template v-slot:item.action="{ item }">
          <v-icon @click="edit(item)" medium class="mr-2 my-auto flex-item">
            {{ authorization.canEdit ? "mdi-pencil" : "mdi-eye" }}
          </v-icon>
        </template>
      </v-data-table>
    </template>

    <template #footer>
      <pagination v-model="pagination" @change="loadData" :loading="loading" />
    </template>
  </default-layout>
</template>

<script>
import Vue from "vue";
import CompanyService from "@/app/Services/CompanyService";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import Pagination from "@/components/pagination/Pagination.vue";
import AuthorizationGate from "@/components/Authorization/AuthorizationGate.vue";
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";
import FilterMixin from "@/components/Filters/FilterMixin";
import CompanyState from "@/components/Company/State";
import DefaultLayout from "@/components/layout/DefaultLayout.vue";

const companyService = CompanyService.build();

export default Vue.extend({
  name: "Company",
  components: {
    Pagination,
    AuthorizationGate,
    DefaultLayout
  },
  data() {
    return {
      loading: false,
      instructions: {
        search_for: [
          { text: "Razão Social", field: "name" },
          { text: "CNPJ", field: "cnpj", mask: "##.###.###/####-##" }
        ],
        filter_for: [
          { text: "Ativos", field: "status", value: 1 },
          { text: "Inativos", field: "status", value: 0 }
        ]
      },
      headers: [
        { text: "Razão social", value: "name" },
        { text: "CNPJ", value: "cnpj" },
        { text: "Status", value: "status", align: "start", width: "100" },
        { text: "Ações", value: "action", align: "center", width: "100" }
      ],
      authorization: {
        route: "/company"
      },
      items: []
    };
  },
  mixins: [PaginationMixin, AuthorizationMixin, FilterMixin],
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;

      try {
        const query = {
          params: {
            ...this.pagination,
            filter: this.filter
          }
        };

        const response = await companyService.index({ query });

        this.items = response.data;

        this.items = this.items.map(item => {
          item.status = item.status == 1;

          return item;
        });

        this.paginate(response);

        this.$forceUpdate();
      } catch (httpError) {
        this.defaultCatchError(httpError);
      } finally {
        this.loading = false;
      }
    },
    async changeStatus(item_id, item) {
      try {
        await companyService.update({ id: item_id, status: item });
      } catch (httpError) {
        this.defaultCatchError(httpError);
      }
    },
    async add() {
      await CompanyState().dispatch("clearHandle");

      this.$router.push("company/new");
    },
    async edit(item) {
      await CompanyState().dispatch("clearHandle");

      this.$router.push("company/" + item.id);
    },
    handlePageChange(page) {
      this.configTable.page = page;
      this.loadData();
    }
  }
});
</script>

<style lang="css" scoped>
.v-subheader {
  height: 57px;
  padding: 0;
}
.elevation-1 {
  padding: 10px 37px;
}
.lblUser {
  font-size: 18px;
}
.alignButtons {
  width: 230px;
  margin: 0;
  margin-left: auto;
  padding: 23px 0;
}
.btnAdd {
  width: 177px;
  font-size: 11px;
  font-family: "Helvetica Neue" !important;
  text-transform: none;
  position: absolute;
  right: 20px;
  margin: 10px;
}
.linkRouter {
  list-style: none;
  display: inline-block;
}
.spaceL {
  margin-left: 10px;
}
.btnHome {
  height: 27px;
}
.contentMarginButtons {
  margin-top: 0;
}
</style>
