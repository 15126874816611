<template>
  <v-container id="container" v-if="canViewLoading">
    <v-row>
      <v-col cols="12" class="vh-100 vw-100 d-flex ">
        <main-loading />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RouterMiddleware from "@/router/router.middleware";
import MainLoading from "./MainLoading.vue";

export default {
  name: "RouterLoading",
  components: { MainLoading },
  methods: {
    close() {
      this.$destroy();
    },
    isInAPublicPath() {
      return RouterMiddleware.isInAPublicPath(this.$route.path);
    }
  },
  computed: {
    canViewLoading() {
      if (this.isInAPublicPath()) {
        return false;
      }

      if (this.hasPermissions) {
        return false;
      }

      return true;
    },
    hasPermissions() {
      return this.$store.getters.hasPermissions;
    }
  }
};
</script>

<style scoped>
.d-flex {
  display: flex;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
</style>
