<template>
  <v-select
    label="Tipo de Fatura"
    :items="invoicesTypes"
    v-model="selected"
    clearable
    multiple
  />
</template>

<script>
export default {
  name: "InvoiceTypeSelect",
  data() {
    return {
      invoicesTypes: [
        {
          text: "Frete",
          value: "invoice"
        },
        {
          text: "Demurrage",
          value: "demurrage"
        },
        {
          text: "Nota de Crédito",
          value: "credit_note"
        },
        {
          text: "Unificado",
          value: "unified"
        }
      ],
      selected: this.value
    };
  },
  props: {
    value: Array
  },
  watch: {
    value() {
      this.selected = this.value;
    },
    selected() {
      this.$emit("input", this.selected);
    }
  }
};
</script>

<style></style>
