<template>
  <v-select
    v-model="model"
    clearable
    label="Banco"
    :items="banks"
    :loading="loading"
    :rules="rules != 'undefined' ? rules : []"
    :item-value="itemValue || 'id'"
    item-text="name"
    :return-object="typeof returnObject != 'undefined' ? returnObject : true"
  />
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";

import BankService from "@/app/Services/BankService";

export default {
  name: "BankSelect",
  mixins: [ModelManagementMixin],
  props: {
    rules: Object,
    returnObject: Boolean,
    itemValue: String
  },
  data() {
    return {
      banks: [],
      loading: false
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;

        const response = await BankService.build().index();

        this.banks = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
