import Rest from "../Base/Rest";

/**
 * @typedef {ArchiveImportLogService}
 */
export default class ArchiveImportLogService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/audit/import";
}
