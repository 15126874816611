import RefundService from "@/app/Services/RefundService";

export default {
  data() {
    return {
      refund: {
        created_at: "",
        deleted_at: "",
        files: [],
        financial_motive: "",
        id: "",
        justification: "",
        payment_date: "",
        payment_value: "",
        company_name: "",
        reference: "",
        refund_value: "",
        status_id: 0,
        updated_at: "",
        user: "",
        user_id: ""
      }
    };
  },
  async created() {
    if (this.isToEdit()) {
      await this.loadData();
    }
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;

        const id = this.$route.params.id;

        const response = await RefundService.build().show(id);

        const serializedToFillRefund = this.serializeToFillRefund(
          response.data
        );

        this.refund = serializedToFillRefund;
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    serializeToFillRefund(refund) {
      const mysqlDate = "yyyy-LL-dd";

      const luxonDate = this.toLuxonDate(refund.payment_date, {
        withHour: true
      });

      refund.payer = refund.payer || refund.user.payer;

      refund.payment_date = luxonDate.toFormat(mysqlDate);

      refund.files = refund.files.map(file => {
        file.name = file.file_name;

        return file;
      });

      return refund;
    },
    isToEdit() {
      const id = this.$route.params.id;

      return id && id != "new";
    }
  }
};
