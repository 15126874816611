import SettingsSave from "@/pages/SettingsSave/SettingsSave.vue";

const routes = [
  {
    description: "Configurações",
    path: "/settings",
    name: "SettingsRoutes",
    group_type: "",
    icon: "mdi-cog-outline",
    component: SettingsSave
  }
];

export default routes;
