var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.pagination.itemsPerPage,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.file_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getType(item))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateHour")(item.created_at))+" ")]}},{key:"item.file_size",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatBytesSize")(item.file_size))+" ")]}},{key:"item.operation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getOperation(item))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","loading":item.loading},on:{"click":function($event){return _vm.download(item)}}},[_c('v-icon',[_vm._v(" mdi-download ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }