var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('file-dialog-contract',{attrs:{"file-add-button-message":"Enviar Arquivo","file-upload-button-message":"Clique aqui para adicionar as procurações","accepted-formats":_vm.FileHandle.acceptedFormats,"can-manipulate":"","can-manipulate-due-date":_vm.canEditDateContract},on:{"change":_vm.changeFiles,"download":_vm.download},model:{value:(_vm.model.files),callback:function ($$v) {_vm.$set(_vm.model, "files", $$v)},expression:"model.files"}})],1),_c('v-col',{attrs:{"md":"6","xs":"12"}},[_c('payer-select',{attrs:{"field":"name_or_cnpj_cpf","label":"Nome do Pagador","return-object":""},model:{value:(_vm.payer),callback:function ($$v) {_vm.payer=$$v},expression:"payer"}})],1),_c('v-col',{attrs:{"md":"2","xs":"2"}},[_c('v-btn',{attrs:{"small":"","loading":_vm.registering},on:{"click":_vm.addPayer}},[_vm._v(" Adicionar ")])],1),_c('v-col',{attrs:{"md":"12","xs":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.model.payerGroup,"loading-text":"Aguarde...carregando","hide-default-footer":true,"header-props":{ sortIcon: null },"page":_vm.page,"items-per-page":_vm.itemsPerPage},on:{"update:options":_vm.sortRequest,"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.payer_status",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","color":"secondary"},on:{"change":_vm.save},model:{value:(item.payer_status),callback:function ($$v) {_vm.$set(item, "payer_status", $$v)},expression:"item.payer_status"}})]}},{key:"item.cnpj_cpf",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.maskCNPJ(item.cnpj_cpf))+" ")]}},{key:"item.contract_id",fn:function(ref){
var item = ref.item;
return [_c('contract-select',{attrs:{"user":_vm.model},on:{"change":function($event){return _vm.saveContractByUser(item, $event)}},model:{value:(item.contract_id),callback:function ($$v) {_vm.$set(item, "contract_id", $$v)},expression:"item.contract_id"}})]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [(_vm.canEditDateContract)?_c('v-btn',{staticClass:"ma-2",attrs:{"icon":""},on:{"click":function($event){return _vm.remove(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1):_vm._e()]}}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }