const publicPaths = [
  "/login",
  "/change-password",
  "/recover",
  "/forget-password",
  "/pre-register",
  "/discard-user",
  "/allow-user"
];

const authPath = {
  "/discount-reason": "/invoice/discount",
  "/refund-reason": "/invoice/refund",
  "/pix-generated": "/home",
  "/user": ["/user/internal", "/user/external"],
  "/user/profile": "/home"
};

export default class RouterMiddleware {
  // eslint-disable-next-line
  static async trait({ router, to, from, next }) {
    const { path } = to;

    const noRequired = RouterMiddleware.isInAPublicPath(path);

    if (noRequired) {
      next();

      return;
    }

    await RouterMiddleware.verifyPermission({ router, to, next });
  }

  static isInAPublicPath(path) {
    const noRequired = publicPaths.some(publicPath =>
      RouterMiddleware.like(path, publicPath)
    );

    return noRequired;
  }

  static like(compare, value) {
    const upperCasedCompare = `${compare}`.toLocaleUpperCase();
    const upperCasedValue = `${value}`.toLocaleUpperCase();

    const result = upperCasedCompare.indexOf(upperCasedValue) != -1;

    return result;
  }

  static async verifyPermission({ router, to, next }) {
    const instance = router.app;

    const permissions = await instance.$store.dispatch("getRoles");

    if (!permissions.length) {
      RouterMiddleware.returnToLogin({ instance });
    }

    const hasPermission = RouterMiddleware.goThroughRolesToMatchRoute(
      permissions,
      to
    );

    if (!hasPermission) {
      RouterMiddleware.returnToLogin({ instance });
    }

    next();
  }

  static returnToLogin({ instance }) {
    localStorage.removeItem("user");

    instance.$router.push({ name: "Login", params: { cleanCache: true } });
  }

  static goThroughRolesToMatchRoute(accesses, to) {
    if (!to || !to.path) {
      return false;
    }

    const path = RouterMiddleware.getPath(to);

    const result = accesses.some(access =>
      RouterMiddleware.matchRoute(access, path)
    );

    return result;
  }

  static getPath(to) {
    let path = "";

    Object.entries(authPath).forEach(([key, value]) => {
      const includes = to.path.includes(key);

      if (includes) {
        path = value;
      }
    });

    return path || to.path;
  }

  static matchRoute(access, to) {
    if (access.children && access.children.length) {
      const result = RouterMiddleware.goThroughRolesToMatchRoute(
        access.children,
        to
      );

      return result;
    }

    const routeMatch = to.indexOf(access.route) != -1;
    const result = routeMatch;

    return result;
  }
}
