<template>
  <v-card class="flex-item w-100 pt-4 pb-0" v-click-outside="toggleFilters">
    <v-container>
      <v-row no-gutters>
        <!-- search for -->
        <v-col class="d-flex flex-column px-2 mr-12">
          <span class="d-flex text-caption text--secondary">
            Pesquisar por
          </span>

          <v-radio-group v-model="options.search_for" mandatory>
            <v-radio
              v-for="(search_for, index) in instructions
                ? instructions.search_for
                : []"
              :key="index"
              :label="search_for.text"
              :value="search_for.field"
              @click="handleAnotherOptions(search_for)"
              class="default-filter-select py-2"
              color="secondary"
            />
          </v-radio-group>
        </v-col>

        <!-- filter by -->
        <v-col class="d-flex flex-column px-2" v-if="!uniqueFilter">
          <span
            class="d-flex text-caption text--secondary mb-5"
            v-if="instructions.filter_for.length > 0"
          >
            Filtrar por
          </span>

          <v-checkbox
            v-for="(filter_for, index) in instructions
              ? instructions.filter_for
              : []"
            :key="index"
            v-model="options.filter_for"
            @change="changeFilter"
            :label="filter_for.text"
            :value="filter_for"
            class="default-filter-select ma-0 mb-2 py-2"
            color="secondary"
            hide-details
          />
        </v-col>

        <v-col class="d-flex flex-column px-2" v-if="uniqueFilter">
          <span
            class="d-flex text-caption text--secondary mb-5"
            v-if="instructions.filter_for.length > 0"
          >
            Filtrar por
          </span>

          <v-radio-group
            mandatory
            v-model="options.filter_for"
            @change="changeFilter"
            class="mt-0"
          >
            <v-radio
              v-for="(filter_for, index) in instructions
                ? instructions.filter_for
                : []"
              :key="index"
              :label="filter_for.text"
              :value="filter_for"
              class="default-filter-select ma-0 mb-2 py-2"
              color="secondary"
              hide-details
            />
          </v-radio-group>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "DefaultFilterSelect",
  props: {
    instructions: Object,
    uniqueFilter: Boolean,
    value: Array
  },
  data() {
    return {
      opened: false,
      options: this.value
    };
  },
  watch: {
    value() {
      this.options = this.value;
    },
    options() {
      this.$emit("input", this.options);
    }
  },
  methods: {
    handleAnotherOptions(item) {
      this.options.mask = null;
      this.options.items = null;

      if (item.mask) {
        this.options.mask = item.mask;
      }

      if (item.items) {
        this.options.items = item.items;
      }

      if (item.type) {
        this.options.type = item.type;
      } else {
        this.options.type = "text";
      }
    },
    toggleFilters() {
      this.$emit("toggleFilters");
    },
    changeFilter() {
      this.$emit("changeFilter");
    }
  }
};
</script>

<style>
.default-filter-select {
  min-width: 180px;
}
</style>
