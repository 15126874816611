<template>
  <v-container class="w-100 d-flex flex-row bg-primary-100 rounded pa-3" fluid>
    <input
      ref="fileInput"
      multiple
      v-show="false"
      :value="selectedFiles"
      :accept="acceptedFormats || defaultAcceptedFormats"
      @change="onFileChange"
      type="file"
      data-cy="file_upload"
    />

    <file-upload-button
      ref="file-upload-btn"
      :file-upload-button-message="
        fileUploadButtonMessage ||
          'Clique aqui para adicionar um arquivo (máximo 5mb)'
      "
      v-show="doesntHaveFiles"
      @click="uploadArchives"
    />

    <v-row v-if="!doesntHaveFiles && renderList">
      <v-col cols="2" v-for="file in getFilesWithoutRemoved()" :key="file.id">
        <file-chip
          :file="file"
          :message="getFileName(file)"
          :loading="file.loading || file.downloading"
          icon="mdi-paperclip"
          @updateDateValidate="updateDateValidate(file, $event)"
          @click="download(file)"
          @removeFile="removeFile(file)"
          :canManipulateDueDate="canManipulateDueDate"
          :canManipulate="canManipulate"
        />
      </v-col>
      <v-col cols="2">
        <file-add-button
          @addFiles="addFiles"
          :file-add-button-message="fileAddButtonMessage"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FileUploadButton from "./FileUploadButton.vue";
import FileAddButton from "./FileAddButton.vue";
import FileChip from "./FileChip.vue";
import { v1 as uuid } from "uuid";

export default {
  name: "FileDialogContract",
  components: {
    FileUploadButton,
    FileAddButton,
    FileChip
  },
  props: {
    fileUploadButtonMessage: String,
    value: [File, Object, Array],
    fileAddButtonMessage: Boolean,
    noDivider: Boolean,
    canManipulate: Boolean,
    canManipulateDueDate: Boolean,
    acceptedFormats: String
  },
  data() {
    return {
      defaultAcceptedFormats: [".pdf", ".doc", ".docx"],
      renderList: true,
      files: this.value,
      selectedFiles: []
    };
  },
  computed: {
    doesntHaveFiles() {
      const notSelectedFiles = !this.selectedFile || !this.selectedFile.length;

      const notDefinedFiles = !this.files || !this.files.length;

      if (notSelectedFiles && notDefinedFiles) {
        return true;
      }

      const haveSavedFiles = this.files.some(file => !file.removed);

      return !haveSavedFiles;
    }
  },
  watch: {
    value() {
      this.files = this.value;
    },
    files() {
      this.$emit("input", this.files);
      this.$emit("change", this.files);
    }
  },
  methods: {
    updateDateValidate(file, $event) {
      file.validation_date = $event;
      file.updated = true;

      this.$emit("updateDateValidate", this.files);

      this.reloadList();
    },
    uploadArchives() {
      this.$refs.fileInput.click();
    },
    onFileChange(e) {
      const files = e.target.files;
      const archives = Array.from(files);
      const validArchives = this.returnValidFiles(archives);

      this.loadAndPrepareFiles(validArchives);
    },
    hasValidType(file) {
      const formats = this.acceptedFormats || this.defaultAcceptedFormats;

      const file_name = String(file.name).toLocaleLowerCase();

      return formats.some(format => `${file_name}`.includes(format));
    },
    returnValidFiles(archives) {
      const validateArchives = archives.filter(file => {
        const twentyfivemegabytes = 1024 * 1024 * 25;
        const maximumFileSize = twentyfivemegabytes;

        const sizeBiggerThanMaximumSupported = file.size > maximumFileSize;

        const hasValidType = this.hasValidType(file);

        if (!hasValidType) {
          this.showArchiveFormatInvalidMessage(file);

          return false;
        }

        if (sizeBiggerThanMaximumSupported) {
          this.showArchiveLengthInvalidMessage(file);

          return false;
        }

        return true;
      });

      const mappedArchives = validateArchives.map(file => {
        file.created = true;

        return file;
      });

      return mappedArchives;
    },
    showArchiveFormatInvalidMessage(file) {
      this.showToast({
        message: `Tipo do arquivo ${file.name} não suportado pelo sistema`,
        status: "error"
      });
    },
    showArchiveLengthInvalidMessage(file) {
      this.showToast({
        message: `Arquivo ${file.name} é maior que o tamanho máximo do sistema (5mb)`,
        status: "error"
      });
    },
    getFileName(item) {
      return item ? item.name || item.file_name : "";
    },
    getFilesWithoutRemoved() {
      const result = this.files.filter(file => !file.removed);

      return result;
    },
    addFiles() {
      this.selectedFile = [];

      this.$nextTick(() => {
        this.uploadArchives();
      });
    },
    async download(file) {
      this.$emit("download", file);
    },
    removeFile(file) {
      file.removed = true;

      this.$emit("removeFile", file);

      this.reloadList();
    },
    loadAndPrepareFiles(toLoadFiles) {
      toLoadFiles.forEach(file => {
        const alreadyLoaded = this.files.find(
          loadedFile => loadedFile.name == file.name
        );

        if (!alreadyLoaded) {
          this.loadFile(file);
        } else {
          alreadyLoaded.removed = false;
        }
      });

      this.reloadList();
    },
    async loadFile(file) {
      const preparedFile = {
        id: uuid(),
        created: true,
        name: file.name,
        content: file,
        file
      };

      this.files.push(preparedFile);

      this.reloadList();

      this.$fileManager.toBase64(file).then(content => {
        this.files = this.files.map(selected => {
          if (selected.id == preparedFile.id) {
            selected.loading = false;
            selected.content = content;
          }

          return selected;
        });

        this.reloadList();
      });
    },
    reloadList() {
      this.renderList = false;

      this.$nextTick(() => {
        this.renderList = true;
      });
    }
  }
};
</script>

<style>
.file-preview-item {
  max-width: 200px;
  height: 100%;
}

.remove-file {
  position: relative;
  float: right;
  margin-top: -7%;
}
</style>
