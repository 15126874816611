import Rest from "../Base/Rest";

/**
 * @typedef {UserProfileService}
 */
export default class UserProfileService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/profile";

  static getUserImage(user) {
    if (!user || !user.image) {
      return "@/assets/login/avatar_user.png";
    }

    const route = UserProfileService.build().prepareRoute(
      `files/${user.image}`
    );

    return route;
  }
}
