import AuditImport from "@/pages/AuditImport/AuditImport.vue";
import Audit from "@/pages/Audit/Audit.vue";

import Discount from "@/pages/Discount/Discount.vue";
import Billet from "@/pages/Billet/Billet.vue";

import BankReturn from "@/pages/BankReturn/BankReturn.vue";

const routes = [
  {
    description: "Desconto",
    path: "/reports/discount",
    name: "Discount",
    group_type: "reports",
    component: Discount
  },
  {
    description: "Boletos",
    path: "/reports/billet",
    name: "Billet",
    group_type: "reports",
    component: Billet
  },
  {
    description: "Auditoria",
    path: "/audit/system",
    name: "reportsSystem",
    group_type: "reports",
    component: Audit
  },
  {
    description: "Importação de Arquivos",
    path: "/audit/import",
    name: "reportsImport",
    component: AuditImport,
    group_type: "reports"
  },
  {
    description: "Retornos Bancários",
    path: "/audit/return-files",
    name: "BankReturn",
    group_type: "reports",
    component: BankReturn
  }
];

export default routes;
