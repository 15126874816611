export default {
  data() {
    return {
      verifyAuthentication: {
        accessSave: false
      }
    };
  },
  async created() {
    this.verifyAuth();

    await this.loadPermissions();
  },
  methods: {
    async accessHandle() {
      const canAccessHandle = await this.canAccess({
        route: "/invoice/refund/handle",
        type: "exec"
      });

      return canAccessHandle;
    },
    async verifyAuth() {
      const canAccessRefund = await this.canAccess({
        route: "/invoice/refund",
        type: "write"
      });

      this.verifyAuthentication.accessHandle = await this.accessHandle();

      this.verifyAuthentication.accessSave = canAccessRefund;
    }
  }
};
