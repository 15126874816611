<template>
  <v-hover v-slot="{ hover }">
    <v-container fluid class="w-100">
      <v-row>
        <v-col
          xs="12"
          @click="onClick"
          class="
            text-center
            d-flex
            flex-column
            pa-12
            w-100
            cursor-pointer"
          :class="hover ? 'bg-primary-300' : 'bg-primary-100'"
        >
          <span
            class="flex-item text-subtitle font-weight-bold my-4"
            :class="hover ? 'white--text' : 'text--secondary'"
          >
            Clique aqui para adicionar uma imagem (Máximo 2mb)
          </span>

          <v-icon
            class="flex-item mx-auto"
            :class="hover ? 'white--text' : 'text--secondary'"
            x-large
          >
            mdi-paperclip
          </v-icon>
        </v-col>
      </v-row>
    </v-container>
  </v-hover>
</template>

<script>
export default {
  name: "ImageUploadButtonNew",
  methods: {
    onClick() {
      this.$emit("click");
    }
  }
};
</script>

<style></style>
