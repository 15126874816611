import Vuex from "vuex";
import Vue from "vue";

// no caso do vue não carregar essa lib anteriormente a necessidade de controle de estado
Vue.use(Vuex);

const userTypeAccessState = new Vuex.Store({
  state: {
    permissions: []
  },
  mutations: {
    permissions(state, loadedPermissions) {
      state.permissions = loadedPermissions;
    },
    addPermission(state, toAddPermission) {
      state.permissions.push({ ...toAddPermission });
    },
    removePermission(state, index) {
      state.permissions.splice(index, 1);
    },
    updatePermission(state, permission) {
      state.permissions = state.permissions.map(item => {
        if (item.route == permission.route) {
          return permission;
        }

        return item;
      });
    }
  },
  getters: {
    permissions(state) {
      return state.permissions;
    }
  },
  actions: {
    loadPermissions(context, loadedPermissions) {
      context.commit("permissions", loadedPermissions);
    },

    enable(context, permission) {
      context.commit("addPermission", permission);

      return context.state.permissions;
    },

    disable(context, index) {
      context.commit("removePermission", index);

      return context.state.permissions;
    },

    update(context, permission) {
      context.commit("updatePermission", permission);

      return context.state.permissions;
    }
  }
});

// singleton
const secret = () => userTypeAccessState;

export default secret;
