<template>
  <v-dialog v-model="showDialog" width="60%">
    <v-card :loading="loading">
      <v-card-title class="text-h5">
        Lista de Faturas
      </v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols="12" v-if="loading">
              <main-loading primary />
            </v-col>

            <v-col cols="12" v-else-if="error" class="mx-auto text-center">
              <span class="text-h6 text--secondary font-weight-regular my-12">
                {{ error }}
              </span>
            </v-col>

            <v-container class="mx-n3 pa-0" v-else>
              <v-row no-gutters>
                <invoice-search-dialog-list v-model="data" />

                <v-col cols="12" class="my-5"></v-col>

                <invoice-search-dialog-not-found-list v-model="data" />
              </v-row>
            </v-container>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <default-cancel-button
          text
          @click="showDialog = false"
          custom-function
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import InvoiceSearch from "../../domain/InvoiceSearch";
import MainLoading from "@/components/misc/MainLoading.vue";
import InvoiceSearchDialogList from "./InvoiceSearchDialogList.vue";
import DefaultCancelButton from "@/components/layout/DefaultCancelButton.vue";
import InvoiceSearchDialogNotFoundList from "./InvoiceSearchDialogNotFoundList.vue";

export default {
  components: {
    MainLoading,
    InvoiceSearchDialogList,
    DefaultCancelButton,
    InvoiceSearchDialogNotFoundList
  },
  name: "InvoiceSearch",
  data() {
    return {
      data: [],
      searchedInvoices: [],
      showDialog: false,
      loading: false,
      error: "",
      InvoiceSearch
    };
  },
  mixins: [ModelManagementMixin],
  methods: {
    search(search) {
      this.showDialog = true;

      this.data = search;
    }
  }
};
</script>

<style></style>
