import RefundService from "@/app/Services/RefundService";

export default {
  removeRequestedFiles() {
    const toRemove = this.getFilesRemoved();
    const orders = [];

    toRemove.forEach(file => {
      orders.push(this.removeFile(file));
    });

    return Promise.all(orders);
  },
  async removeFile(file) {
    try {
      if (file.created) {
        return;
      }

      const deleteParams = {
        id: file.id,
        refund_id: this.refund.id
      };

      await RefundService.build().send(deleteParams, "/delete-file");
    } catch (error) {
      throw new Error("Ocorreu um erro ao atualizar os arquivos");
    }
  },
  getFilesRemoved() {
    return this.refund.files.filter(
      file => file.removed && typeof file.removed != "undefined"
    );
  }
};
