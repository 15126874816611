<template>
  <v-dialog v-model="dialog" width="60%">
    <v-card fluid>
      <v-container>
        <v-row>
          <v-col cols="12">
            <span class="text-h5">
              Faturas não encontradas
            </span>
          </v-col>

          <v-col cols="12">
            <div v-for="(invoice, index) in notFoundInvoices" :key="index">
              <v-list-item>
                <v-list-item-title>{{ invoice }}</v-list-item-title>
              </v-list-item>

              <v-divider />
            </div>
          </v-col>

          <v-col sm="12" class="mt-6 d-flex flex-row">
            <v-spacer />

            <v-btn class="font-weight-bold" @click="cancel" text color="text">
              Confirmar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "NotFoundInvoicesDialog",
  props: {
    value: Boolean,
    found: Object,
    search: Object
  },
  data() {
    return {
      dialog: this.value
    };
  },
  computed: {
    notFoundInvoices() {
      const foundInvoices = this.found.map(
        foundInvoice => foundInvoice.invoice
      );

      const invoices = this.search.filter(
        searched => !foundInvoices.includes(searched)
      );

      return invoices;
    }
  },
  watch: {
    value() {
      this.dialog = this.value;
    },
    dialog() {
      this.$emit("input", this.dialog);
    }
  },
  methods: {
    cancel() {
      this.dialog = false;
    }
  }
};
</script>

<style></style>
