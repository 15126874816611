<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="pagination.itemsPerPage"
    hide-default-footer
  >
    <template v-slot:item.file_type="{ item }">
      {{ getType(item) }}
    </template>

    <template v-slot:item.created_at="{ item }">
      {{ item.created_at | formatDateHour }}
    </template>

    <template v-slot:item.file_size="{ item }">
      {{ item.file_size | formatBytesSize }}
    </template>

    <template v-slot:item.operation="{ item }">
      {{ getOperation(item) }}
    </template>

    <template v-slot:item.action="{ item }">
      <v-btn icon @click="download(item)" :loading="item.loading">
        <v-icon>
          mdi-download
        </v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import AuditImportService from "@/app/Services/AuditImportService";
import FileSaver from "file-saver";

export default {
  name: "AuditImportTable",
  props: {
    items: Array,
    itemsPerPage: Number,
    pagination: Object
  },
  data() {
    return {
      downloading: {},
      filter: {
        type: "system"
      },
      headers: [
        {
          text: "Tipo",
          value: "file_type",
          width: 140
        },
        {
          text: "Nome do Arquivo",
          value: "file_name"
        },
        {
          text: "Número da Fatura",
          value: "invoice"
        },
        {
          text: "Data/Hora da Importação",
          value: "created_at"
        },
        {
          text: "Tamanho do Arquivo",
          value: "file_size"
        },
        {
          text: "Status",
          value: "operation"
        },
        {
          text: "Mensagem",
          value: "message"
        },
        {
          text: "Ações",
          value: "action",
          width: 75
        }
      ]
    };
  },
  methods: {
    isDownloading(item) {
      return this.downloading[item.audit_bank_return_file_id];
    },
    async download(item) {
      try {
        item.loading = true;

        const response = await AuditImportService.build().request(
          `download/${item.id}`
        );

        const blob = new Blob([response.data], { type: "application/pdf;" });

        FileSaver.saveAs(blob, item.file_name);
      } catch (error) {
        console.error(error);
        this.defaultCatchError(error);
      } finally {
        item.loading = false;
      }
    },
    downloadFile(filename, text) {
      const element = document.createElement("a");

      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(text)
      );
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    },
    getType(item) {
      switch (item.file_type) {
        case "demurrage":
          return "Demurrage";

        case "credit_note":
          return "Nota de Crédito";

        case "invoice":
          return "Frete";
      }
    },

    getOperation(item) {
      switch (item.operation) {
        case "success":
          return "Sucesso";

        case "error":
          return "Erro";

        case "ignored":
          return "Ignorado";
      }
    }
  }
};
</script>

<style></style>
