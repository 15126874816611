<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col>
        <span class="text-caption font-weight-bold text--disabled">
          Progresso
        </span>

        <v-progress-linear
          background-color="blue lighten-3"
          color="primary"
          :value="realized.percentage"
        />

        <span
          class="text-right text-caption font-weight-bold text--disabled ml-auto"
        >
          {{ realized.items.length }}/{{ data.length }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ArrivalResponseState from "../states/ArrivalResponseState";

export default {
  name: "ProgressIndicator",
  data() {
    return {
      stateManagement: ArrivalResponseState()
    };
  },
  computed: {
    data() {
      const responses = this.stateManagement.state.data;

      return responses;
    },
    realized() {
      const items = this.stateManagement.getters.realizedItems;
      const percentage = this.stateManagement.getters.realizedPercentage;

      return {
        percentage,
        items
      };
    }
  },
  watch: {
    realized() {
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    }
  }
};
</script>

<style></style>
