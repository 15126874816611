<template>
  <default-layout
    route-name="Feriados"
    :has-filters="tab == 'list'"
    has-tabs
    :loading="loading"
    :instructions="instructions"
    @search="search"
  >
    <template #header-actions>
      <authorization-gate
        auth-route="/holiday"
        auth-type="write"
        class="d-flex flex-item ml-auto"
      >
        <default-save-button @click="add">
          Adicionar
        </default-save-button>
      </authorization-gate>
    </template>

    <template #tabs>
      <v-tabs v-model="tab" color="secondary">
        <v-tab href="#calendar" class="font-weight-bold" cy-data="tab_calendar">
          Calendário
        </v-tab>

        <v-tab href="#list" class="font-weight-bold" cy-data="tab_list">
          Lista
        </v-tab>
      </v-tabs>
    </template>

    <template #content>
      <v-container fluid>
        <v-tabs-items v-model="tab">
          <v-tab-item value="calendar">
            <v-row>
              <v-col cols="12">
                <holiday-calendar-actions
                  @next="$refs.calendar.next()"
                  @prev="$refs.calendar.prev()"
                  v-model="value"
                />
              </v-col>
              <v-col cols="12">
                <v-calendar
                  ref="calendar"
                  data-cy="calendar"
                  :events="events"
                  v-model="value"
                  mode="stack"
                  type="month"
                  event-overlap-mode="stack"
                  event-overlap-threshold="30"
                  @click:event="showHoliday"
                />
              </v-col>

              <v-col cols="12">
                <holiday-card
                  v-model="showHolidayInfo"
                  :holiday="selectedHoliday"
                  @remove="triggerRemoveFlow"
                  @edit="edit"
                />
              </v-col>
            </v-row>
          </v-tab-item>

          <v-tab-item value="list">
            <v-row>
              <v-col cols="12">
                <holiday-table
                  :items="events"
                  @remove="triggerRemoveFlow"
                  @edit="edit"
                />
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </template>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import AuthorizationGate from "@/components/Authorization/AuthorizationGate.vue";
import DefaultSaveButton from "@/components/layout/DefaultSaveButton.vue";
import HolidayCalendarActions from "./components/HolidayCalendarActions.vue";

import HolidayService from "@/app/Services/HolidayService";
import HolidayCard from "./components/HolidayCard.vue";
import HolidayTable from "./components/HolidayTable.vue";
import { DateTime } from "luxon";
import { bus } from "@/main";
import FilterMixin from "@/components/Filters/FilterMixin";

export default {
  components: {
    DefaultLayout,
    HolidayCard,
    AuthorizationGate,
    HolidayCalendarActions,
    DefaultSaveButton,
    HolidayTable
  },
  name: "Holiday",
  mixins: [FilterMixin],
  data() {
    return {
      value: DateTime.now().toFormat("yyyy-LL-dd"),
      type: "month",
      mode: "stack",
      showHolidayInfo: false,
      selectedHoliday: {},
      events: [],
      tab: null,
      instructions: {
        search_for: [{ text: "Ano", field: "year" }],
        filter_for: []
      },
      pagination: {},
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1"
      ],
      loading: false
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        const query = this.getRequestQuery();

        this.loading = true;

        const response = await HolidayService.build().index({
          query
        });

        this.events = this.serializeEvents(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    getRequestQuery() {
      const query = {
        params: {
          itemsPerPage: -1,
          filter: this.filter
        }
      };

      return query;
    },
    serializeEvents(events) {
      const serialized = [];

      events.forEach(event => {
        const serializedEvent = this.serializeEvent(event);

        serialized.push(serializedEvent);
      });

      return serialized;
    },
    serializeEvent(event) {
      const date = this.toLuxonDate(event.date);

      const serializedDate = date.toFormat("yyyy-LL-dd");

      const start = new Date(date.startOf("day").toISO());

      const end = new Date(date.endOf("day").toISO());

      const randomColorIndex = this.getRandomInt(this.colors.length);
      const color = this.colors[randomColorIndex];

      const serialized = {
        id: event.id,
        name: event.name,
        start,
        end,
        color,
        date: serializedDate,
        timed: false
      };

      return serialized;
    },
    showHoliday({ event }) {
      this.selectedHoliday = event;
      this.showHolidayInfo = true;
    },
    add() {
      this.$router.push({
        name: "HolidaySave",
        params: {
          id: "new"
        }
      });
    },
    edit(holiday) {
      this.$router.push({
        name: "HolidaySave",
        params: {
          id: holiday.id
        }
      });
    },
    async triggerRemoveFlow(holiday) {
      this.showHolidayInfo = false;

      const result = await this.openRemoveDialog();

      if (result) {
        this.remove(holiday);
      }

      this.dialog = false;
    },
    openRemoveDialog() {
      return new Promise((resolve, reject) => {
        bus.$emit("showNotificationDialog", {
          status: "error",
          title: "Deseja Remover Esse Feriado?",
          continue: () => resolve(true),
          dispose: () => reject(false)
        });
      });
    },
    async remove(holiday) {
      try {
        this.loading = true;

        await HolidayService.build().destroy(holiday);

        this.events = this.events.filter(event => event.id != holiday.id);
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    }
  }
};
</script>

<style lang="scss">
.v-calendar-weekly__day-label > .v-btn {
  font-weight: 400 !important;
}

.v-calendar-weekly__week {
  min-height: 66px !important;
}
</style>
