import ReadFileChangeShipDate from "@/pages/ReadFileChangeShipDate/ReadFileChangeShipDate.vue";

const routes = [
  {
    description: "Alterar Data do Navio",
    path: "/read-file-change-ship-date",
    name: "ReadFileChangeShipDateRoutes",
    group_type: "",
    icon: "mdi-ferry",
    component: ReadFileChangeShipDate
  }
];

export default routes;
