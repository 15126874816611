<template>
  <v-app-bar
    v-if="!hide"
    class="elevation-0 border-top-secondary bg-default"
    dense
    height="73px"
    clipped-left
    app
  >
    <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->

    <v-toolbar-title>
      <v-img src="@/assets/logo_horizontal.svg" width="200"></v-img>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <user-profile-header />
  </v-app-bar>
</template>

<script>
import UserProfileHeader from "./UserProfileHeader/UserProfileHeader.vue";

export default {
  name: "AppNavigationTop",
  computed: {
    hide() {
      return this.$route.path === "/login" || this.$route.path === "/sign-up";
    }
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
  },
  data: () => ({
    drawer: true,
    source: "",
    user: {},
    items: [{ title: "Sair", path: "Login", icon: "mdi-logout" }],
    message: true,
    companyVisible: false
  }),
  methods: {},
  components: { UserProfileHeader }
};
</script>

<style>
.border-top-secondary {
  border-top: 2px solid #ea5c0d !important;
}
</style>
