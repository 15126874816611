import CompanyEntity from "@/pages/CompanyEntity/CompanyEntity.vue";

import Company from "@/pages/Company/Company.vue";
import CompanySave from "@/pages/CompanySave/CompanySave.vue";
import BankAccountSave from "@/pages/BankAccountSave/BankAccountSave.vue";

const routes = [
  {
    path: "/company-entity",
    name: "CompanyEntity",
    component: CompanyEntity
  },
  {
    description: "Beneficiários",
    path: "/company",
    group_type: "registers",
    name: "Company",
    component: Company
  },
  {
    path: "/company/:id?",
    name: "CompanySave",
    component: CompanySave
  },
  {
    path: "company/:company_id/bank/:id",
    name: "BankAccountSave",
    component: BankAccountSave
  }
];

export default routes;
