<template>
  <v-container fluid>
    <breadcrumb route-name="Retornos Bancários" />

    <v-card depressed>
      <v-container fluid>
        <v-row>
          <v-col>
            <bank-return-filters
              v-model="filters"
              @loadInvoices="loadInvoicesByFilter"
              :loading="loading"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <bank-return-table
              :items="items"
              :selected="selected"
              :pagination="pagination"
              @download="download"
            />
          </v-col>
        </v-row>
      </v-container>

      <pagination v-model="pagination" @change="loadData" :loading="loading" />
    </v-card>
  </v-container>
</template>

<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import FilterMixin from "@/components/Filters/FilterMixin";
import Pagination from "@/components/pagination/Pagination.vue";

import BankReturnService from "@/app/Services/BankReturnService";
import BankReturnTable from "./components/BankReturnTable.vue";
import BankReturnFilters from "./BankReturnFilters.vue";

import DownloadFile from "./domain/DownloadFile";

export default {
  components: {
    Breadcrumb,
    Pagination,
    BankReturnTable,
    BankReturnFilters
  },
  name: "BankReturn",
  data() {
    return {
      loading: false,
      items: [],
      selected: [],
      filters: {
        bank_our_number_or_txid: [],
        invoices: [],
        bl: [],
        booking: [],
        travel: [],
        ship: [],
        landing_point_name: [],
        due_date: [],
        invoice_date: [],
        created_at: {
          start: "",
          final: ""
        },
        recording_date: {
          start: "",
          final: ""
        },
        credit_date: {
          start: "",
          final: ""
        },
        invoice_status: [],
        BankReturnStatusByName: [],
        statusByBank: [],
        amount_paid: "",
        cnpj_cpf: []
      }
    };
  },
  mixins: [PaginationMixin, FilterMixin, DownloadFile],
  methods: {
    loadInvoicesByFilter() {
      this.pagination.page = 1;

      this.selected = [];

      this.loadData();
    },
    async loadData() {
      try {
        this.loading = true;

        const query = this.getApiQuery();

        const response = await BankReturnService.build().index({
          query
        });

        this.items = response.data;

        this.paginate(response);
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    add() {
      this.$router.push({
        name: "BankReturnAdd"
      });
    },
    getApiQuery() {
      if (this.filters.total_paid == 0) {
        this.filters.total_paid = "";
      }

      let filters = [];

      for (const item in this.filters) {
        if (item == "name_or_cnpj_cpf") {
          let cnpj = [];

          for (let payer of this.filters[item]) {
            cnpj.push(payer.cnpj_cpf);
          }

          filters["cnpj_cpf"] = cnpj;
        } else {
          filters[item] = this.filters[item];
        }
      }

      return {
        params: {
          ...this.pagination,
          filter: { ...filters }
        }
      };
    }
  }
};
</script>

<style></style>
