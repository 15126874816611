<template>
  <v-form v-model="form">
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="mb-n6">
          <span class="text-caption">
            Configurações de comunicação com o Banco
          </span>
        </v-col>

        <v-col cols="12">
          <v-divider />
        </v-col>
      </v-row>

      <v-row>
        <v-col xl="3" lg="4" xs="12">
          <v-combobox
            label="Versão da API"
            :items="apiVersion"
            v-model="model.version_api"
            :rules="[rules.required]"
          />
        </v-col>

        <v-col xl="3" lg="4" xs="12">
          <v-text-field
            label="Identificação do Cliente"
            v-model="model.client_id"
            :rules="[rules.required]"
          />
        </v-col>

        <v-col lg="4" sm="3" xs="12"></v-col>

        <v-col cols="12">
          <v-textarea
            label="Localização do Certificado"
            v-model="model.certificate"
            :rules="[rules.required]"
            rows="2"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import FormMixin from "@/components/misc/mixins/FormMixin";

export default {
  name: "BankAccountConfigForm",
  mixins: [ModelManagementMixin, FormMixin],
  props: {
    bank: Number
  },
  computed: {
    apiVersion() {
      if (this.bank || typeof this.bank == "undefined") {
        return [];
      }

      switch (this.bank.code) {
        case "269":
          return [{ text: "Versão 1", value: "v1" }];

        default:
          return [];
      }
    }
  }
};
</script>

<style></style>
