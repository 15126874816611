import Rest from "../Base/Rest";

/**
 * @typedef {HolidayService}
 */
export default class HolidayService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/holiday";
}
