import Rest from "../Base/Rest";

/**
 * @typedef {UserContractService}
 */
export default class UserContractService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/user/contract";
}
