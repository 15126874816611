import "material-design-icons-iconfont/dist/material-design-icons.css";
import Vue from "vue";
import Vuetify from "vuetify/lib";

import PixIcon from "@/components/misc/PixIcon.vue";
import HapagIcon from "@/components/misc/HapagIcon.vue";

Vue.use(Vuetify);

import pt from "vuetify/es5/locale/pt";

export default new Vuetify({
  icons: {
    iconfont: "md",
    values: {
      pixIcon: {
        component: PixIcon
      },
      hapagIcon: {
        component: HapagIcon
      }
    }
  },
  lang: {
    locales: { pt },
    current: "pt"
  },
  theme: {
    light: true,
    themes: {
      light: {
        primary: "#033572",
        primary_100: "#BEC8DD",
        primary_300: "#6a82b0",
        primary_400: "#4968a0",
        primary_500: "#214f92",
        secondary: "#EA5B0B",
        error: "#F35A6B",
        success: "#62cb7b",
        accent: "#EA5B0B",
        orange: "#FF6327",
        indigo: "#131862",
        text: "#7A7B80",
        disabled: "#EAE9E3",
        hover: "rgba(0,0,0,0.1)"
      }
    }
  }
});
