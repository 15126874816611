import PreRegister from "@/pages/PreRegister/PreRegister.vue";
import PixGenerated from "@/pages/PIXGenerated/PIXGenerated.vue";

const routes = [
  {
    path: "/pre-register",
    name: "PreRegister",
    component: PreRegister
  },
  {
    path: "/pix-generated/:invoice",
    name: "PixGenerated",
    component: PixGenerated
  }
];

export default routes;
