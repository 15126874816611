<template>
  <v-form ref="form" v-model="valid">
    <v-container fluid>
      <v-row>
        <v-col lg="3" md="4" sm="6" xs="12">
          <image-dialog v-model="model.image" :responsivity="responsivity" />
        </v-col>
      </v-row>

      <v-row>
        <v-col lg="3" md="4" sm="6" xs="12">
          <v-text-field
            v-model="model.name"
            label="Nome"
            :rules="[rules.required]"
          />
        </v-col>

        <v-col lg="3" md="4" sm="6" xs="12">
          <v-text-field
            v-model="model.email"
            label="E-mail"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>

      <v-row class="mb-n12">
        <v-col cols="12">
          <span class="text-caption">
            Opções de Senha
          </span>
        </v-col>
      </v-row>

      <v-row>
        <v-col lg="3" md="4" sm="6" xs="12">
          <v-text-field
            v-model="model.old_password"
            label="Senha Anterior"
            :rules="[passwordVerification_oldPasswordRequired]"
            type="password"
          />
        </v-col>
        <v-col lg="3" md="4" sm="6" xs="12">
          <v-text-field
            v-model="model.new_password"
            label="Nova Senha"
            :rules="[
              passwordVerification_newPasswordRequired,
              passwordVerification_passwordsMatch
            ]"
            type="password"
            @input="validate"
          />
        </v-col>
        <v-col lg="3" md="4" sm="6" xs="12">
          <v-text-field
            v-model="model.confirm_password"
            label="Confirmar Senha"
            :rules="[
              passwordVerification_confirmPasswordRequired,
              passwordVerification_passwordsMatch
            ]"
            type="password"
            @input="validate"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import FormMixin from "@/components/misc/mixins/FormMixin";
import ImageDialog from "@/components/ImageDialog/ImageDialog.vue";
import PasswordVerification from "../domain/PasswordVerification";

export default {
  components: { ImageDialog },
  name: "UserProfileFormGeneral",
  mixins: [FormMixin, ModelManagementMixin, PasswordVerification],
  data() {
    return {
      valid: true,
      responsivity: {
        maxWidth: "256px",
        maxHeight: "256px"
      }
    };
  },
  methods: {
    validate() {
      this.valid = this.$refs.form.validate();

      return this.valid;
    }
  }
};
</script>

<style></style>
