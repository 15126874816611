<template>
  <v-tooltip top :class="css">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        @click="click"
        :disabled="disabled"
        :loading="loading"
        :cy-data="cyData"
      >
        <slot name="icon">
          <v-icon>
            {{ icon }}
          </v-icon>
        </slot>
      </v-btn>
    </template>

    <span>
      <slot name="message">
        {{ message }}
      </slot>
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: "DefaultTooltipButton",
  props: {
    message: String,
    icon: String,
    disabled: Boolean,
    loading: Boolean,
    css: String,
    cyData: String
  },
  methods: {
    click() {
      this.$emit("click");
    }
  }
};
</script>

<style></style>
