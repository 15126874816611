<template>
  <default-layout
    :route-name="'Motivo de Desconto'"
    :loading="loading"
    has-filters
    :instructions="instructions"
    @search="search"
  >
    <template v-slot:header-actions>
      <div class="d-flex flex-row ml-auto">
        <v-spacer />

        <authorization-gate
          auth-route="/invoice/discount"
          auth-type="exec"
          class="my-auto"
        >
          <default-save-button @click="add">
            Adicionar
          </default-save-button>
        </authorization-gate>
      </div>
    </template>

    <template v-slot:content>
      <v-container fluid>
        <discount-reason-table
          @edit="edit"
          :pagination="pagination"
          :items="items"
          @remove="openRemoveDialog"
        />
      </v-container>
    </template>

    <template v-slot:footer>
      <pagination v-model="pagination" @change="loadData" />
    </template>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import DefaultSaveButton from "@/components/layout/DefaultSaveButton.vue";
import DiscountReasonTable from "./components/DiscountReasonTable.vue";
import DiscountReasonService from "@/app/Services/DiscountReasonService";
import AuthorizationGate from "@/components/Authorization/AuthorizationGate.vue";
import FilterMixin from "@/components/Filters/FilterMixin";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import { bus } from "@/main";

export default {
  components: {
    DefaultLayout,
    DiscountReasonTable,
    DefaultSaveButton,
    AuthorizationGate
  },
  name: "DiscountReason",
  created() {
    this.loadData();
  },
  data() {
    return {
      loading: false,
      items: [],
      instructions: {
        search_for: [{ text: "Motivo", field: "name" }]
      }
    };
  },
  mixins: [PaginationMixin, FilterMixin],
  methods: {
    openRemoveDialog(item) {
      bus.$emit("showNotificationDialog", {
        status: "error",
        title: "Deseja Remover esse motivo desconto?",
        continue: () => this.remove(item.id)
      });
    },
    async remove(id) {
      try {
        this.loading = true;

        await DiscountReasonService.build().destroy(id);

        this.showToast({
          message: "Motivo removido com sucesso"
        });

        this.loadData();
      } catch (error) {
        console.error(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    add() {
      this.$router.push({
        name: "DiscountReasonSave",
        params: { id: "new" }
      });
    },
    edit(item) {
      this.$router.push({
        name: "DiscountReasonSave",
        params: { id: item.id }
      });
    },
    async loadData() {
      try {
        const query = {
          params: {
            ...this.pagination,
            filter: this.filter
          }
        };

        this.loading = true;

        const response = await DiscountReasonService.build().index({ query });

        this.items = response.data;

        this.paginate(response);
      } catch (error) {
        console.log(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
        this.$forceUpdate();
      }
    },
    changePage(page) {
      this.pagination.page = page;

      this.loadData();
    }
  }
};
</script>

<style></style>
