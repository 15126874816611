<template>
  <v-card>
    <v-card-title>
      <v-row @click="toggleFilter">
        <v-col
          lg="8"
          md="4"
          xs="12"
          class="py-1 text-h5 my-auto font-weight-bold"
        >
          Filtros
        </v-col>
        <v-col lg="3" md="4" xs="4" class="py-1 d-flex flex-row flex-wrap">
          <v-container>
            <v-row no-gutters>
              <v-col class="d-flex px-xl-3 px-lg-3 px-md-3 p-sm-0">
                <generate-reports-button
                  table="bank-returns"
                  type="csv"
                  label="Gerar Relatório"
                  class="mx-4"
                  filename="relatorio_retornos_bancarios"
                  :filter="filter"
                />
              </v-col>

              <v-col class="d-flex pr-xl-3 pr-lg-3 pr-md-3 pr-sm-0">
                <v-btn
                  class="white--text my-1 w-100 flex-item"
                  color="primary"
                  @click.stop="loadInvoices"
                  :loading="loading"
                >
                  Visualizar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>

        <v-col>
          <v-btn x-large icon class="text-end my-auto">
            <v-icon @click.stop="toggleFilter" :class="getChevronDirection()">
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-expand-transition>
      <v-card-text v-if="showFilters">
        <v-container fluid>
          <v-row>
            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <payer-select
                v-model="filter.name_or_cnpj_cpf"
                :payers="payersCnpj"
                field="name_or_cnpj_cpf"
                itemValue="cnpj_cpf"
                label="Pagador/CNPJ"
                multiple
                return-object
              />
            </v-col>
            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <custom-paste-combobox
                label="Faturas"
                v-model="filter.invoices"
              />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <custom-paste-combobox label="Número do BL" v-model="filter.bl" />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <v-currency-field
                label="Valor pago"
                v-model="filter.amount_paid"
              />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <date-picker
                label="Data de emissão inicial"
                v-model="filter.created_at.start"
                clearable
              />
            </v-col>
            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <date-picker
                label="Data de emissão final"
                v-model="filter.created_at.final"
                clearable
              />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <date-picker
                label="Data do movimento inicial"
                v-model="filter.recording_date.start"
                clearable
              />
            </v-col>
            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <date-picker
                label="Data do movimento final"
                v-model="filter.recording_date.final"
                clearable
              />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <date-picker
                label="Data do pagamento inicial"
                v-model="filter.credit_date.start"
                clearable
              />
            </v-col>
            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <date-picker
                label="Data do pagamento final"
                v-model="filter.credit_date.final"
                clearable
              />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <status-select-filter
                v-model="filter.invoice_status"
                :status="statusByBank"
              />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <status-by-bank-select-filter
                refs="filterBankReturnStatusByName"
                v-model="filter.BankReturnStatusByName"
                :status="statusByBankSelect"
              />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12" class="mt-n1">
              <custom-paste-combobox
                v-model="filter.bank_our_number_or_txid"
                label="Nosso Número ou TxID"
                class="mb-n3"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { bus } from "@/main";
import DatePicker from "@/components/date_picker/DatePicker.vue";
import StatusSelectFilter from "./filters/StatusSelectFilter.vue";
import StatusByBankSelectFilter from "./filters/StatusByBankSelectFilter.vue";
import PayerSelect from "@/components/payer_select/PayerSelect.vue";

import CustomPasteCombobox from "./filters/CustomPasteCombobox.vue";
import GenerateReportsButton from "@/components/Reports/GenerateReportsButton.vue";
import BankReturnService from "@/app/Services/BankReturnService";

import InvoiceService from "@/app/Services/InvoiceService";
import BankReturnStatus from "./domain/BankReturnStatus";

export default {
  components: {
    DatePicker,
    PayerSelect,
    StatusSelectFilter,
    StatusByBankSelectFilter,
    CustomPasteCombobox,
    GenerateReportsButton
  },
  props: {
    loading: Boolean,
    value: Object,
    selected: Array
  },
  name: "BankReturnFilters",
  data() {
    return {
      showFilters: true,
      filter: this.value,
      payers: [],
      payersCnpj: [],
      statusByBankSelect: [],
      statusByBank: [],
      bank_return_status: [],
      foundInvoices: [],
      invoiceValidationError: "",
      loadingInvoiceValidate: false,
      notFoundDialog: false,
      timeoutVar: null,
      debounceValidation: this.$utils.registerDebounce(
        this.invoicesValidate,
        1000,
        this.timeoutVar
      )
    };
  },
  watch: {
    value() {
      this.filter = this.value;
    },
    filter() {
      this.$emit("input", this.filter);
    }
  },
  mounted() {
    this.loadBankReturnStatus();
    this.loadStatus();
  },
  methods: {
    async loadBankReturnStatus() {
      const loadBankReturnStatus = BankReturnStatus.load.bind(this);

      this.statusByBankSelect = await loadBankReturnStatus();
    },
    async loadStatus() {
      try {
        this.loading = true;

        const query = {
          params: {
            pagination: -1
          }
        };

        const response = await InvoiceService.build()
          .status()
          .index({ query });

        this.statusByBank = response.data;
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    toggleFilter() {
      this.showFilters = !this.showFilters;

      bus.$emit("resize", this.showFilters);
    },
    getChevronDirection() {
      if (this.showFilters) {
        return "filter-toggle-btn filter-toggle-btn__close";
      }

      return "filter-toggle-btn";
    },
    async invoicesValidate() {
      try {
        this.loadingInvoiceValidate = true;

        if (!this.filter.invoices.length) {
          return;
        }

        const query = {
          params: {
            filter: this.filter
          }
        };

        const response = await BankReturnService.build().index({
          query,
          url: "all"
        });

        this.foundInvoices = response.data;

        const foundInvoices = response.data.length;
        const searchInvoices = this.filter.invoices.length;

        if (searchInvoices > foundInvoices) {
          this.invoiceValidationError = `${foundInvoices} encontrados de ${searchInvoices} (ver mais)`;
        }
      } catch (error) {
        console.error(error);
        this.defaultCatchError(error);
      } finally {
        this.loadingInvoiceValidate = false;
      }
    },
    openInvoicesNotFoundDialog() {
      this.notFoundDialog = true;
    },
    loadInvoices() {
      this.showFilters = false;

      this.saveFilterOnStorage();

      bus.$emit("resize", this.showFilters);

      this.$emit("loadInvoices");
    },
    saveFilterOnStorage() {
      localStorage.setItem("invoice_filters", JSON.stringify(this.filter));
    }
  }
};
</script>

<style lang="scss">
.filter-toggle-btn {
  max-width: 42px !important;

  &__close {
    transform: rotate(180deg);
  }
}
</style>
