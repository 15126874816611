import Rest from "@/app/Base/Rest";

/**
 * @typedef {AuthService}
 */
export default class AuthService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/auth";

  /**
   *
   *
   * METODOS DE REQUISICAO
   *
   *
   */
  static requestPermissions() {
    return AuthService.build().request("permissions");
  }

  static refreshToken() {
    return AuthService.build().send({}, "/refresh");
  }
}
