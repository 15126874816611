<template>
  <v-dialog v-model="model" width="60%">
    <v-card>
      <v-container>
        <v-row>
          <v-col cols="12">
            <span class="text-h5">
              Inserir motivo para
              {{
                this.refund.status == "confirmed" ? "aprovação" : "reprovação"
              }}
            </span>
          </v-col>

          <v-col class="my-6" cols="12">
            <v-form v-model="valid" ref="form">
              <v-text-field
                label="Motivo"
                v-model="financial_motive"
                :rules="[rules.required]"
              />
            </v-form>
          </v-col>

          <v-col cols="12" class="d-flex flex-row">
            <v-spacer />

            <v-btn class="font-weight-bold" @click="cancel" text color="text">
              Fechar
            </v-btn>

            <v-btn
              class="font-weight-bold"
              color="primary"
              text
              @click="continueRefundFlow"
              :disabled="!valid"
            >
              Confirmar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import FormMixin from "@/components/misc/mixins/FormMixin";

export default {
  name: "RefundDialog",
  mixins: [ModelManagementMixin, FormMixin],
  props: {
    refund: Object
  },
  data() {
    return {
      valid: false,
      financial_motive: ""
    };
  },
  methods: {
    cancel() {
      this.$emit("cancelRefundFlow", this.refund);

      this.model = null;
    },
    continueRefundFlow() {
      const valid = this.$refs.form.validate();

      if (!valid) {
        return;
      }

      this.refund.financial_motive = this.financial_motive;

      this.$emit("continueRefundFlow", this.refund);

      this.model = false;
      this.financial_motive = "";
    }
  }
};
</script>

<style></style>
