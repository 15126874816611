<template>
  <v-form v-model="fluid" ref="form">
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="mb-n6">
          <span class="text-caption">
            Campos Extras de comunicação com o banco
          </span>
        </v-col>

        <v-col cols="12">
          <v-divider />
        </v-col>
      </v-row>

      <v-row>
        <v-data-table
          :items="withoutRemovedBankAccountConfigExtra"
          :headers="headers"
          :header-props="{ sortIcon: null }"
          class="w-100"
          hide-default-footer
        >
          <template #header.field>
            <v-text-field
              label="Campo"
              class="font-weight-regular"
              v-model="handleConfig.field"
              :readonly="!authorization.canEdit"
              :rules="[rules.required]"
            />
          </template>

          <template #header.value>
            <v-text-field
              label="Valor"
              class="font-weight-regular"
              v-model="handleConfig.value"
              :readonly="!authorization.canEdit"
              :rules="[rules.required]"
            />
          </template>

          <template #header.actions>
            <default-tooltip-button
              message="Adicionar"
              :icon="handleConfig.edited ? 'mdi-content-save' : 'mdi-plus'"
              :disabled="!authorization.canEdit"
              @click="save"
            />
          </template>

          <template #item.actions="{ item }">
            <div class="d-flex">
              <default-edit-button
                @click="edit(item)"
                :disabled="!authorization.canEdit"
              />

              <default-remove-button
                @click="remove(item)"
                :disabled="!authorization.canEdit"
              />
            </div>
          </template>
        </v-data-table>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import DefaultTooltipButton from "@/components/default/DefaultTooltipButton.vue";
import DefaultRemoveButton from "@/components/layout/DefaultRemoveButton.vue";
import DefaultEditButton from "@/components/layout/DefaultEditButton.vue";
import FormMixin from "@/components/misc/mixins/FormMixin";
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";

import { v1 as uuid } from "uuid";

export default {
  components: { DefaultTooltipButton, DefaultRemoveButton, DefaultEditButton },
  name: "BankAccountConfigExtraForm",
  mixins: [FormMixin, ModelManagementMixin, AuthorizationMixin],
  data() {
    return {
      headers: [
        { text: "", value: "field", sortable: false },
        { text: "", value: "value", sortable: false },
        { text: "", value: "actions", sortable: false, width: 150 }
      ],
      handleConfig: {},
      authorization: {
        route: "/bank-config"
      }
    };
  },
  computed: {
    withoutRemovedBankAccountConfigExtra() {
      return this.model.filter(configExtra => !configExtra.removed);
    }
  },
  methods: {
    save() {
      const valid = this.$refs.form.validate();

      if (!valid) {
        return;
      }

      if (this.handleConfig.id) {
        this.update();
      } else {
        this.create();
      }
    },
    create() {
      const toAdd = this.handleConfig;

      toAdd.id = uuid();
      toAdd.created = true;

      this.model.push(this.handleConfig);

      this.cleanHandle();
    },
    cleanHandle() {
      this.handleConfig = {
        value: "",
        field: ""
      };

      this.$refs.form.reset();
    },
    update() {
      const toUpdate = this.handleConfig;

      this.model = this.model.map(extraConfig => {
        if (extraConfig.id == toUpdate.id) {
          extraConfig = { ...extraConfig, ...toUpdate };

          extraConfig.edited = true;
        }

        return extraConfig;
      });

      this.cleanHandle();
    },
    remove(item) {
      this.model = this.model.map(extraConfig => {
        if (extraConfig.id == item.id) {
          extraConfig.removed = true;
        }

        return extraConfig;
      });
    },
    edit(item) {
      item.edited = true;

      this.handleConfig = { ...item };
    }
  }
};
</script>

<style></style>
