<template>
  <v-autocomplete
    v-model="selected"
    @change="setSelected"
    label="Contratos"
    color="primary"
    :items="items"
    :item-text="getSelectionText"
    item-value="id"
    :search-input.sync="searchInput"
    :loading="loading"
    clearable
    no-data-text="Digite para pesquisar..."
    :return-object="returnObject"
  />
</template>

<script>
import UserContractService from "@/app/Services/UserContractService";

export default {
  name: "ContractSelect",
  props: {
    user: Object,
    returnObject: Boolean,
    value: Object,
    rules: Array
  },
  data() {
    return {
      loading: false,
      searchInput: null,
      items: [],
      selected: null,
      timeoutVar: null,
      debounceSearch: this.$utils.registerDebounce(
        this.search,
        1000,
        this.timeoutVar
      )
    };
  },
  async created() {
    await this.search(true);
  },
  watch: {
    searchInput() {
      this.debounceSearch(this.searchInput);
    }
  },
  methods: {
    setSelected() {
      if (this.loading) return;

      this.$emit("change", this.selected);
    },
    removeSelection(item, index) {
      const toChange = [...this.selected];

      toChange.splice(index, 1);

      this.selected = toChange;
    },
    getSelectionText(item) {
      if (item.deleted_at) {
        return `${item.file_name} - Del: ${this.formatISODate(item.deleted_at, {
          withHour: true
        })}`;
      } else {
        return `${item.file_name}`;
      }
    },
    async search(load = false) {
      try {
        this.loading = true;

        const response = await UserContractService.build().show(this.user.id);

        if (response.data.length < 1) {
          return;
        }

        this.items = response.data;

        if (load) {
          const idx = this.items.findIndex(con => con.id == this.value);

          this.selected = this.items[idx];
        }
      } catch (error) {
        this.error = this.getExceptionResponseMessage(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
