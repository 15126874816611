var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.pagination.itemsPerPage,"hide-default-footer":true,"header-props":{ sortIcon: null }},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateHour")(item.created_at))+" ")]}},{key:"item.table",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTable(item))+" ")]}},{key:"item.operation",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getOperation(item))+" ")]}},{key:"item.ocurrency",fn:function(ref){
var item = ref.item;
return [_c('long-text-hover',{attrs:{"text":_vm.getOcurrency(item),"one-line":""}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('default-tooltip-button',{attrs:{"icon":"mdi-magnify","message":"Visualizar Campos"},on:{"click":function($event){return _vm.$emit('search_audit', item)}}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }