<template>
  <v-dialog
    offset-y
    v-model="model"
    :close-on-content-click="true"
    :activator="holiday"
    width="60%"
  >
    <v-card>
      <v-container>
        <v-row>
          <v-col class="d-flex flex-column">
            <span class="text-h4 text-capitalize">
              {{ holiday.name }}
            </span>

            <span class="text-span mt-6">
              {{ holiday.date | formatDate }}
            </span>
          </v-col>

          <v-col class="d-flex">
            <v-spacer />

            <default-edit-button @click="edit" />

            <default-remove-button @click="remove" />
          </v-col>
        </v-row>

        <v-row xs="12" class="mt-6 d-flex flex-row">
          <v-spacer />

          <v-btn class="font-weight-bold" @click="cancel" text>
            Fechar
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import DefaultEditButton from "@/components/layout/DefaultEditButton.vue";
import DefaultRemoveButton from "@/components/layout/DefaultRemoveButton.vue";

export default {
  components: { DefaultEditButton, DefaultRemoveButton },
  name: "HolidayCard",
  props: {
    holiday: Object
  },
  mixins: [ModelManagementMixin],
  methods: {
    cancel() {
      this.model = false;
    },
    remove() {
      this.$emit("remove", this.holiday);
    },
    edit() {
      this.$emit("edit", this.holiday);
    }
  }
};
</script>

<style></style>
