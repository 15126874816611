import Rest from "../Base/Rest";

/**
 * @typedef {PayerService}
 */
export default class PayerService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/payer";
}
