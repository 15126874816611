<template>
  <v-select
    v-model="selected"
    :items="status"
    :loading="loading"
    :readonly="loading"
    label="Status"
    item-text="name"
    clearable
    multiple
    return-object
    small-chips
  />
</template>

<script>
import InvoiceService from "@/app/Services/InvoiceService";

export default {
  name: "StatusSelectFilter",
  props: {
    value: Array
  },
  data() {
    return {
      selected: this.value,
      status: [],
      loading: false
    };
  },
  watch: {
    selected() {
      this.$emit("input", this.selected);
    },
    value() {
      this.selected = this.value;
    }
  },
  created() {
    this.loadStatus();
  },
  methods: {
    async loadStatus() {
      try {
        this.loading = true;

        const query = this.getApiQuery();

        const response = await InvoiceService.build()
          .status()
          .index({ query });

        this.status = response.data;
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    getApiQuery() {
      return {
        params: {
          pagination: -1
        }
      };
    }
  }
};
</script>

<style></style>
