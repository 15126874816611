<template>
  <v-autocomplete
    label="Cidade"
    v-model="model"
    :items="items"
    :loading="loading"
    :readonly="loading || !uf"
    :search-input.sync="searchInput"
    item-text="name"
    item-value="id"
    :filter="onFilter"
    :rules="rules != 'undefined' ? rules : []"
    data-cy="city_select"
  />
</template>

<script>
import ModelManagementMixin from "../misc/mixins/ModelManagementMixin";
import CityService from "@/app/Services/CityService";

export default {
  name: "CitySelect",
  props: {
    rules: Object,
    uf: String
  },
  mixins: [ModelManagementMixin],
  data() {
    return {
      items: [],
      loading: false,
      timeoutVar: null,
      searchInput: "",
      debounceSearch: this.$utils.registerDebounce(
        this.searchData,
        1000,
        this.timeoutVar
      )
    };
  },
  watch: {
    searchInput() {
      this.debounceSearch(this.searchInput);
    },
    uf() {
      this.loadign = true;

      this.searchData();
    },
    model() {
      if (this.model && !this.searchInput) {
        this.searchData();
      }
    }
  },
  methods: {
    getQueryParams() {
      const query = {
        params: {
          filter: {
            name: this.searchInput,
            uf: this.uf
          }
        }
      };

      if (this.model && !this.searchInput) {
        query.params.filter.id = this.model;
      }

      return query;
    },
    onFilter(item, queryText, itemText) {
      const text = itemText
        .toLocaleLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      const query = queryText
        .toLocaleLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");

      const result = text.indexOf(query) > -1;

      return result;
    },
    async searchData() {
      try {
        this.loading = true;

        const query = this.getQueryParams();

        const response = await CityService.build().index({
          query
        });

        this.items = response.data;
      } catch (error) {
        console.error(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
