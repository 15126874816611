import RefundMessageService from "@/app/Services/RefundMessageService";
import RefundService from "@/app/Services/RefundService";

import { bus } from "@/main";

export default {
  mounted() {
    if (this.isToEdit()) {
      this.loadData();
    }
  },
  data() {
    return {
      registering: false,
      arrMessages: [],
      files: [],
      status: {
        solicitation: 1,
        attendance: 2,
        waiting_customer: 3,
        waiting_customer_documentation: 4,
        under_registration: 5,
        under_approval: 6,
        approved: 7,
        repproved: 8,
        cancel: 9
      }
    };
  },
  methods: {
    validation() {
      if (
        this.value.status_id == 0 &&
        this.userTypePermissions.canEditInternalUsers == true
      ) {
        this.showToast({
          message: "Favor selecionar o status",
          status: "error"
        });

        return false;
      }

      if (this.message == "") {
        this.showToast({
          message: "Favor digitar a mensagem!",
          status: "error"
        });

        return false;
      }

      return true;
    },
    async sendMessage() {
      try {
        if (!this.validation()) {
          return;
        }

        this.registering = true;

        const status = await this.getStatusChat();

        if (
          [
            this.status.approved,
            this.status.repproved,
            this.status.cancel
          ].some(elem => elem == status)
        ) {
          const confirm = await bus.$emit("showNotificationDialog", {
            status: "",
            title: `Deseja realmente confirmar esse status?`,
            continue: async () => {
              await this.save();
              await this.returnToList();
            },
            dispose: () => false
          });

          if (!confirm) {
            return;
          }
        } else {
          await this.save();
        }
      } catch (error) {
        console.log(error);
        this.defaultCatchError(error);
      } finally {
        this.registering = false;
      }
    },
    async save() {
      try {
        this.registering = true;

        const mappedArchives = this.files.map(file => {
          file.reference = "chat";

          return file;
        });

        const status = await this.getStatusChat();

        const form = {
          refund_id: this.value.id,
          message: this.message,
          status_id: status,
          files: mappedArchives
        };

        await RefundMessageService.build().create(form);

        this.message = "";
        this.files = [];

        this.loadData();
      } catch (error) {
        console.log(error);
        this.defaultCatchError(error);
      } finally {
        this.registering = false;
      }
    },
    async getStatusChat() {
      const status =
        this.userTypePermissions.canEditInternalUsers == true ||
        (await this.checkIfYouCanRecordTheStatus())
          ? this.refund_modify.status_id
          : this.status.attendance;

      return status;
    },
    async checkIfYouCanRecordTheStatus() {
      const result = await this.canAccess({
        route: "/invoice/refund/status",
        type: "exec"
      });

      return result;
    },
    async loadData() {
      try {
        this.registering = true;

        const messages = await RefundMessageService.build().show(this.value.id);

        this.arrMessages = messages.data;

        this.$emit("loadData");

        this.refund_modify = Object.assign({}, this.value);
      } catch (error) {
        console.log(error);
        this.defaultCatchError(error);
      } finally {
        this.registering = false;
        this.visibleUpload = false;
      }
    },
    isToEdit() {
      const id = this.$route.params.id;

      return id && id != "new";
    },
    async removeFile(file) {
      try {
        if (file.created) {
          return;
        }

        const deleteParams = {
          id: file.id,
          refund_id: this.refund.id
        };

        await RefundService.build().send(deleteParams, "/delete-file");

        await this.loadData();
      } catch (error) {
        throw new Error("Ocorreu um erro ao atualizar os arquivos");
      }
    }
  }
};
