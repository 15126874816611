<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon @click="edit">
        <v-icon>
          {{ onlyShow ? "mdi-eye" : "mdi-pencil" }}
        </v-icon>
      </v-btn>
    </template>

    <span> {{ onlyShow ? "Visualizar" : "Editar" }} </span>
  </v-tooltip>
</template>

<script>
export default {
  name: "DefaultEditButton",
  props: {
    onlyShow: Boolean
  },
  methods: {
    edit() {
      this.$emit("edit");
      this.$emit("click");
    }
  }
};
</script>

<style></style>
