<template>
  <v-form v-model="valid" ref="form">
    <v-container>
      <v-row>
        <!-- <v-col cols="12" class="mb-n4">
          <v-text-field
            label="CNPJ do Pagador principal"
            v-model="prePayer.payer_cnpj_cpf"
            placeholder="Digite o Cnpj/Cpf do pagador principal"
            v-mask="$cnpjMask"
            outlined
            :rules="[rules.required]"
          />
        </v-col> -->

        <v-col cols="12" class="mb-n6">
          <v-text-field
            label="Nome do usuário"
            outlined
            color="primary"
            v-model="prePayer.name"
            :rules="[rules.required]"
          />
        </v-col>

        <v-col cols="12">
          <v-text-field
            label="E-mail"
            outlined
            color="primary"
            v-model="prePayer.email"
            :rules="[rules.required, rules.email]"
          />
        </v-col>

        <v-col cols="12">
          <file-dialog
            v-model="prePayer.files"
            file-add-button-message="Enviar Arquivo"
            file-upload-button-message="Clique aqui para adicionar as procurações"
            :accepted-formats="FileHandle.acceptedFormats"
            can-manipulate
          />
        </v-col>

        <v-col md="6" xs="12">
          <v-text-field
            label="Pagador"
            v-model="payer_cnpj"
            placeholder="Digite o Cnpj/Cpf do pagador..."
            v-mask="$cnpjMask"
          />
        </v-col>
        <v-col md="2" xs="2">
          <v-btn @click="addPayer" small :loading="searchingPayer">
            Adicionar
          </v-btn>
        </v-col>
        <v-col md="12" xs="12">
          <v-data-table
            :headers="headers"
            :items="prePayer.payerGroup"
            loading-text="Aguarde...carregando"
            :hide-default-footer="true"
            class="elevation-0"
            :header-props="{ sortIcon: null }"
          >
            <template #[`item.cnpj_cpf`]="{ item }">
              {{ maskCNPJ(item.cnpj_cpf) }}
            </template>
          </v-data-table>
        </v-col>

        <v-col cols="12" v-if="!captchaIsVisible">
          <v-btn
            @click="toggleCaptcha"
            :loading="registering"
            color="primary"
            large
            class="w-100"
            :disabled="!valid"
          >
            CADASTRAR
          </v-btn>
        </v-col>

        <v-col cols="12" v-if="captchaIsVisible">
          <recaptcha v-model="robot" @change="register" />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import PrePayerService from "@/app/Services/PrePayerService";
import FileDialog from "@/components/FileUpload/FileDialog.vue";
import FormMixin from "@/components/misc/mixins/FormMixin";
import { bus } from "@/main";
import Recaptcha from "@/pages/Recaptcha.vue";
import FileHandle from "../domain/FileHandle";

export default {
  name: "PreRegisterForm",
  components: {
    Recaptcha,
    FileDialog
  },
  mixins: [FormMixin],
  props: {
    value: Object
  },
  data() {
    return {
      payer: {},
      payer_cnpj: {},
      prePayer: {
        payer_name: "",
        payer_cnpj_cpf: "",
        name: "",
        payerGroup: [],
        files: []
      },
      valid: false,
      captchaIsVisible: false,
      robot: false,
      error: "",
      timeout: 0,
      loading: false,
      registering: false,
      FileHandle,
      headers: [
        {
          text: "Nome",
          align: "start",
          value: "name"
        },
        {
          text: "CNPJ/CPF",
          align: "start",
          value: "cnpj_cpf"
        }
      ]
    };
  },
  methods: {
    upload() {
      const upload = this.FileHandle.upload.bind(this);

      upload();
    },
    setValidation(valid) {
      this.valid = valid;
    },
    toggleCaptcha() {
      if (!this.validate() || this.payerIsntDefined) {
        return;
      }

      this.captchaIsVisible = !this.captchaIsVisible;
    },
    validate() {
      return this.$refs.form.validate();
    },
    async register() {
      try {
        const valid = this.validate();

        if (!valid || !this.robot) {
          return;
        }

        this.registering = true;

        await PrePayerService.build().store(this.prePayer);

        this.showPreRegisterConfirm();
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.registering = false;
        this.captchaIsVisible = false;
      }
    },
    async addPayer() {
      try {
        const idx = this.prePayer.payerGroup.findIndex(
          con => con.cnpj_cpf == String(this.payer_cnpj).replace(/[.\-/]/gi, "")
        );

        if (idx >= 0) {
          this.showToast({
            message: "Esse pagador já foi adicionado a lista de pagadores!",
            status: "error"
          });

          this.payer_cnpj = "";

          return;
        }

        this.searchingPayer = true;

        const params = {
          cnpj: this.payer_cnpj
        };

        const response = await PrePayerService.build().load(params);

        if (response.data.length < 1) {
          this.searchingPayer = false;

          return;
        }

        this.prePayer.payerGroup.push(response.data[0]);

        this.payer_cnpj = "";
      } catch (error) {
        this.searchingPayer = false;
        this.error = this.getExceptionResponseMessage(error);
        this.defaultCatchError(error);
      } finally {
        this.searchingPayer = false;
      }
    },
    showPreRegisterConfirm() {
      bus.$emit("showNotificationDialog", {
        status: "success",
        title: "Pré cadastro realizado com sucesso",
        dispose: this.redirectToLogin,
        message:
          "Em breve você receberá um e-mail com a confirmação do seu cadastro."
      });
    },
    redirectToLogin() {
      this.$router.push({ name: "login" });
    }
  }
};
</script>

<style></style>
