<template>
  <v-dialog v-model="dialog" width="60%">
    <v-card :loading="loading">
      <v-container fluid>
        <v-row>
          <v-col xs="12">
            <span class="text-h5">
              Enviar Convite
            </span>
          </v-col>
        </v-row>

        <v-row v-if="loading">
          <centralized-message>
            <template #message>
              Carregando...
            </template>
          </centralized-message>
        </v-row>

        <v-row v-else-if="error && !loading">
          <centralized-message>
            <template #message>
              {{ error.message }}
            </template>

            <template #complemention>
              {{ error.complemention }}
            </template>
          </centralized-message>
        </v-row>

        <v-row v-else>
          <v-col sm="12">
            <user-invitation-checkbox
              v-for="user in users"
              :key="user.id"
              :user="user"
              v-model="selected"
            />
          </v-col>

          <v-divider />

          <v-col sm="12" class="flex-row d-flex">
            <v-spacer />

            <v-btn class="font-weight-bold" @click="cancel" text color="text">
              Cancelar
            </v-btn>

            <v-btn
              class="font-weight-bold"
              color="primary"
              text
              @click="sendInvitation"
              :loading="sending"
              :disabled="this.error"
            >
              Convidar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { bus } from "@/main";
import CentralizedMessage from "@/components/misc/CentralizedMessage.vue";

import PayerInvitationService from "@/app/Services/PayerInvitationService";
import UserInvitationCheckbox from "./UserInvitationCheckbox.vue";

export default {
  components: { CentralizedMessage, UserInvitationCheckbox },
  name: "SendInvitationDialog",
  data() {
    return {
      dialog: false,
      loading: false,
      sending: false,
      users: [],
      selected: []
    };
  },
  created() {
    bus.$on("payer.openInvitationDialog", this.openInvitationDialog);
  },
  methods: {
    openInvitationDialog(payers) {
      this.dialog = true;

      this.selected = [];

      this.previewUsersToInvitation(payers);
    },
    async sendInvitation() {
      try {
        this.dialog = false;
        this.$emit("loading", true);

        await PayerInvitationService.build().send({
          users: this.selected
        });

        this.showToast({
          message:
            "E-mails de convite enviados, usuários já podem acessar o sistema",
          status: "success"
        });
      } catch (error) {
        console.error(error);
        this.defaultCatchError(error);
      } finally {
        this.$emit("loading", false);
      }
    },
    async previewUsersToInvitation(payers) {
      try {
        this.loading = true;

        const response = await PayerInvitationService.build()
          .preview()
          .send({ payers });

        this.users = response.data;
      } catch (error) {
        console.error(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;

        this.validate();
      }
    },
    cancel() {
      this.dialog = false;
    },
    validate() {
      if (this.users.length < 1) {
        this.error = {
          message: "Nenhum Usuário para convidar",
          complemention:
            "registre manualmente o usuário ou peça para realizar o seu pré-cadastro"
        };
      }
    }
  }
};
</script>
