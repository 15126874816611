<template>
  <v-container fluid>
    <v-card :loading="loading">
      <v-container fluid>
        <v-form ref="form" v-model="valid">
          <v-tabs color="secondary" flat class="containerInner" v-model="tab">
            <v-tab href="#general" class="font-weight-bold">Gerais</v-tab>
            <v-tab
              v-if="payerGroup.id > 0 && payerGroup.files != undefined"
              href="#contracts"
              class="font-weight-bold"
              >Contratos</v-tab
            >
            <div class="d-flex flex-row alignButtons">
              <default-cancel-button />

              <default-save-button
                @click="save"
                :disabled="!valid"
                :loading="saving"
                @loadData="loadData()"
              />
            </div>
            <v-tab-item value="general">
              <payer-group-form-general v-model="payerGroup" />
            </v-tab-item>
            <v-tab-item value="contracts">
              <payer-group-form-contracts
                v-if="payerGroup.id > 0 && payerGroup.files != undefined"
                v-model="payerGroup"
                @loadData="loadData()"
              />
            </v-tab-item>
          </v-tabs>
        </v-form>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import DefaultCancelButton from "@/components/layout/DefaultCancelButton.vue";
import PayerGroupService from "@/app/Services/PayerGroupService";
import DefaultSaveButton from "@/components/layout/DefaultSaveButton.vue";

import PayerGroupFormGeneral from "./components/PayerGroupFormGeneral.vue";
import PayerGroupFormContracts from "./components/PayerGroupFormContracts.vue";

export default {
  components: {
    DefaultCancelButton,
    DefaultSaveButton,
    PayerGroupFormGeneral,
    PayerGroupFormContracts
  },
  name: "PayerGroupSave",
  data() {
    return {
      loading: false,
      saving: false,
      valid: false,
      payerGroup: {
        files: []
      }
    };
  },
  created() {
    if (this.isToEdit()) {
      this.loadData();
    }
  },
  methods: {
    isToEdit() {
      return this.$route.params.id != "new";
    },
    async save() {
      try {
        this.saving = true;

        const service = PayerGroupService.build();

        if (this.isToEdit()) {
          delete this.payerGroup.payers;

          await service.update(this.payerGroup);

          // this.payerGroup = null;
          this.returnToListPage();
        } else {
          // delete this.payerGroup.id;

          this.payerGroup = (await service.store(this.payerGroup)).data;

          this.$router.push({
            name: "PayerGroupSave",
            params: { id: this.payerGroup.id }
          });
        }
      } catch (error) {
        console.log(error);
        this.defaultCatchError(error);
      } finally {
        this.saving = false;
      }
    },
    async loadData() {
      try {
        const id = this.$route.params.id;

        this.loading = true;

        const response = await PayerGroupService.build().show(id);

        this.payerGroup = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    validate() {
      const valid = this.$refs.payerGroupForm.validate();

      return valid;
    },
    setValidation(valid) {
      this.valid = valid;
    },
    returnToListPage() {
      this.$router.push({ name: "PayerGroup" });
    }
  }
};
</script>

<style></style>
