<template>
  <v-container fluid>
    <extra-info-dialog />

    <include-discount-dialog @discount="discount" />

    <preview-billet-dialog @generatedBillet="generatedBillet" />

    <preview-pix-dialog @altered="alteredPix" />

    <alter-arrival-dialog @altered="alteredArrival" />

    <global-desunification-dialog />

    <unify-dialog
      v-model="unification"
      :selected="selected"
      @unified="unified"
    />

    <alter-arrival-date-dialog-for-many
      v-model="alter_arrival_date_for_many_dialog"
      @altered="alteredArrival"
      :selected="selected"
    />

    <v-row>
      <v-col sm="12">
        <invoice-filters
          v-model="filters"
          @loadInvoices="loadInvoicesByFilter"
          :selected="selected"
          @unify="unify"
          @alter_arrival_date_for_many="alterArrivalDateForMany"
          :loading="loading"
          :authorization="authorization"
          @loadInvoicesReport="loadInvoicesReport"
          :items="items"
          :loadingReport="loadingReport"
        />
      </v-col>
    </v-row>

    <v-row v-show="items.length">
      <v-col sm="12">
        <keep-alive>
          <invoice-table
            v-model="selected"
            :loading="loading"
            :items="items"
            :pagination="pagination"
            @loadInvoices="loadInvoices"
            :authorization="authorization"
          >
            <template #pagination>
              <pagination
                v-model="pagination"
                @change="loadInvoices"
                :loading="loading"
              />
            </template>
          </invoice-table>
        </keep-alive>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { bus } from "@/main";
import InvoiceFilters from "./components/InvoiceFilters.vue";
import InvoiceTable from "./components/InvoiceTable.vue";

import InvoiceService from "@/app/Services/InvoiceService";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import UnifyDialog from "./components/unification/UnifyDialog.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import GlobalDesunificationDialog from "./components/unification/GlobalDesunificationDialog.vue";
import ExtraInfoDialog from "./components/extra_info_dialog/ExtraInfoDialog.vue";
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";
import IncludeDiscountDialog from "@/components/IncludeDiscount/IncludeDiscountDialog.vue";
import PreviewBilletDialog from "@/components/PreviewBilletDialog/PreviewBilletDialog.vue";
import AlterArrivalDialog from "@/components/AlterArrivalDate/Dialog/AlterArrivalDialog.vue";
import AlterArrivalDateDialogForMany from "@/components/AlterArrivalDate/DialogForMany/AlterArrivalDateDialogForMany.vue";
import PreviewPixDialog from "@/components/PreviewPixDialog/PreviewPIXDialog.vue";
import FileSaver from "file-saver";

export default {
  components: {
    InvoiceFilters,
    InvoiceTable,
    UnifyDialog,
    Pagination,
    GlobalDesunificationDialog,
    ExtraInfoDialog,
    IncludeDiscountDialog,
    AlterArrivalDialog,
    PreviewBilletDialog,
    AlterArrivalDateDialogForMany,
    PreviewPixDialog
  },
  data() {
    return {
      loading: false,
      loadingReport: false,
      items: [],
      selected: [],
      selectedHeaders: [],
      unification: false,
      filters: {
        our_number: [],
        ids: [],
        type: "",
        invoices: [],
        payers: [],
        bl: [],
        booking: [],
        travel: [],
        ship: [],
        landing_point_name: [],
        due_date: {},
        invoice_date: {}
      },
      pagination: {
        itemsPerPage: 5
      },
      authorization: {
        route: "/home"
      },
      alter_arrival_date_for_many_dialog: false
    };
  },
  created() {
    this.loadAuthorizations();
  },
  mounted() {
    bus.$on("desunificated", this.desunificated);
    bus.$on("load-data-invoices", this.loadInvoices);
    bus.$on(
      "header-table-columns-selected",
      this.setHeaderTableColumnsSelected
    );
  },
  name: "Invoice",
  mixins: [PaginationMixin, AuthorizationMixin],
  methods: {
    async loadAuthorizations() {
      this.authorization.canGeneratePix = await this.canAccess({
        route: "/invoice/generate-pix",
        type: "exec"
      });

      this.authorization.canGenerateBill = await this.canAccess({
        route: "/invoice/generate-billet",
        type: "exec"
      });

      this.authorization.canIncludeDiscount = await this.canAccess({
        route: "/invoice/discount",
        type: "exec"
      });

      this.authorization.canChangeShipDate = await this.canAccess({
        route: "/invoice/change-ship-date",
        type: "exec"
      });

      this.authorization.canUnificate = await this.canAccess({
        route: "/invoice/unification",
        type: "exec"
      });

      this.authorization.canDesunificate = await this.canAccess({
        route: "/invoice/desunification",
        type: "exec"
      });

      this.authorization.canInvoiceChangeStatus = await this.canAccess({
        route: "/invoice/change-status",
        type: "exec"
      });

      this.$forceUpdate();
    },
    isFilteringSpecificInvoices() {
      const filter = this.filters;

      return (
        filter.invoices.length ||
        filter.our_number.length ||
        filter.ids.length ||
        filter.bl.length ||
        filter.booking.length ||
        filter.travel.length ||
        filter.ship.length ||
        filter.landing_point_name.length
      );
    },
    defineCustomUnifiedFilter() {
      const filter = this.filters;

      const doesntDefinedStatus = !filter.status || !filter.status.length;

      if (this.isFilteringSpecificInvoices()) {
        filter.without_unified_invoices = false;
      } else {
        filter.without_unified_invoices = doesntDefinedStatus;
      }

      filter.without_cancelled_invoices = doesntDefinedStatus;

      const filters = {};

      for (const item in filter) {
        if (item == "payers") {
          const cnpj = [];

          for (const payer of filter[item]) {
            cnpj.push(payer.cnpj_cpf);
          }

          filters["cnpj_cpf"] = cnpj;
        } else {
          filters[item] = filter[item];
        }
      }

      return filters;
    },
    getApiQuery() {
      const filter = this.defineCustomUnifiedFilter();

      return {
        filter,
        ...this.pagination
      };
    },
    async loadInvoices() {
      try {
        this.loading = true;

        this.removePaginationIfIsSearchInvoices();

        const query = this.getApiQuery();

        const response = await InvoiceService.build().indexMain(query);

        this.items = [];
        this.items = response.data;

        this.paginate(response);
      } catch (error) {
        console.error(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    async loadInvoicesReport() {
      try {
        this.loadingReport = true;

        this.removePaginationIfIsSearchInvoices();

        const filter = this.defineCustomUnifiedFilter();

        const response = await InvoiceService.build().report({
          filter: filter,
          headers: this.selectedHeaders
        });

        // const response = await InvoiceService.build().indexMain({
        //   filter: filter,
        //   headers: this.selectedHeaders
        // });

        const reportData = await InvoiceService.build().download(response.file);

        const blob = new Blob([reportData], { type: "application/csv;" });

        const filename = response.name + `.csv`;

        FileSaver.saveAs(blob, filename);

        this.showToast({ message: "Relatório gerado com sucesso!" });
      } catch (error) {
        console.error(error);
        this.defaultCatchError(error);
      } finally {
        this.loadingReport = false;
      }
    },
    removePaginationIfIsSearchInvoices() {
      const isSearchInvoices =
        this.filters &&
        this.filters.invoices &&
        this.filters.invoices.length > 0;

      if (isSearchInvoices) {
        this.pagination.itemsPerPage = -1;
      } else {
        this.pagination.itemsPerPage = this.default_pagination.itemsPerPage;
      }
    },
    updateList() {
      this.loadInvoices();
    },
    loadInvoicesByFilter() {
      this.pagination.page = 1;

      this.selected = [];

      this.loadInvoices();
    },
    alterArrivalDateForMany() {
      this.alter_arrival_date_for_many_dialog = true;

      this.$forceUpdate();
    },
    unify() {
      this.unification = true;

      this.$forceUpdate();
    },
    unified() {
      this.selected = [];

      this.loadInvoices();

      this.$forceUpdate();
    },
    desunificated() {
      this.desunificated = [];
      this.loadInvoices();
    },
    generatedBillet() {
      this.loadInvoices();
    },
    alteredArrival() {
      this.loadInvoices();
    },
    alteredPix() {
      this.loadInvoices();
    },
    discount() {
      this.loadInvoices();
    },
    setHeaderTableColumnsSelected(selected) {
      this.selectedHeaders = selected;
    }
  }
};
</script>

<style>
::-webkit-scrollbar {
  width: 20px !important;
  height: 20px !important;
}
</style>
