<template>
  <v-form ref="form" v-model="valid">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <file-dialog-contract
            v-model="model.files"
            file-add-button-message="Enviar Arquivo"
            file-upload-button-message="Clique aqui para adicionar as procurações"
            :accepted-formats="FileHandle.acceptedFormats"
            @change="changeFiles"
            @download="download"
            :can-manipulate="canEditContract"
            :can-manipulate-due-date="canEditContract"
          />
        </v-col>

        <v-col md="6" xs="12">
          <v-text-field
            label="Pagador"
            v-model="payer"
            placeholder="Digite o Cnpj/Cpf do pagador..."
            v-mask="$cnpjMask"
          />
        </v-col>
        <v-col md="2" xs="2">
          <v-btn @click="addPayer" :loading="registering" small>
            Adicionar
          </v-btn>
        </v-col>

        <v-col md="12" xs="12">
          <v-data-table
            :headers="headers"
            :items="model.payerGroup"
            loading-text="Aguarde...carregando"
            :hide-default-footer="true"
            class="elevation-0"
            :header-props="{ sortIcon: null }"
          >
            <template #[`item.payer_status`]="{ item }">
              <v-switch
                inset
                color="secondary"
                v-model="item.payer_status"
                readonly
              />
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";
import FormMixin from "@/components/misc/mixins/FormMixin";
import FileDialogContract from "@/components/FileUploadContract/FileDialogContract.vue";
import FileHandle from "../domain/FileHandle";
import DownloadFile from "../domain/DownloadFile";
import UserProfileService from "@/app/Services/UserProfileService";

export default {
  components: { FileDialogContract },
  name: "UserProfileFormContract",
  mixins: [FormMixin, ModelManagementMixin, AuthorizationMixin, DownloadFile],
  data() {
    return {
      valid: false,
      FileHandle,
      canEditContract: false,
      payer: "",
      registering: false,
      responsivity: {
        maxWidth: "256px",
        maxHeight: "256px"
      },
      headers: [
        {
          text: "Id",
          align: "start",
          value: "id",
          width: "40"
        },
        {
          text: "Nome",
          align: "start",
          value: "name"
        },
        {
          text: "CNPJ/CPF",
          align: "start",
          value: "cnpj_cpf",
          width: "100"
        },
        {
          text: "Status",
          value: "payer_status",
          align: "center",
          width: "30"
        }
      ]
    };
  },
  watch: {
    valid() {
      this.$emit("valid", this.valid);
    },
    "model.files": {
      handler() {
        this.valid = true;
      }
    }
  },
  async created() {
    this.canEditContract = await this.canAccess({
      route: "/user/contract",
      type: "write"
    });
  },
  methods: {
    async addPayer() {
      try {
        const payers = Object.assign([], this.model.payerGroup);

        payers.unshift({
          created: true,
          cnpj_cpf: this.payer
        });

        const form = {
          id: this.value.id,
          group_id: this.model.group_id,
          payerGroup: payers
        };

        this.payer = "";

        this.registering = true;

        const userService = UserProfileService.build();

        await userService.update(form);

        this.registering = false;

        this.$forceUpdate();

        this.$emit("loadData");
      } catch (e) {
        console.log(e);

        this.registering = false;

        this.$forceUpdate();

        this.defaultCatchError(e);
      } finally {
        this.registering = false;

        this.$forceUpdate();
      }
    },
    upload() {
      const upload = this.FileHandle.upload.bind(this);

      upload();
    },
    validate() {
      this.valid = this.$refs.form.validate();

      return this.valid;
    },
    changeFiles() {
      this.valid = true;

      this.$emit("valid", this.valid);
    }
  }
};
</script>

<style></style>
