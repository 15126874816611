<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="pagination.itemsPerPage"
    hide-default-footer
    v-if="render"
  >
    <template #item.status_id="{ item }">
      <v-chip>{{ getStatusType(item) }}</v-chip>
    </template>

    <template #item.justification="{ item }">
      <span class="word-truncate">{{ item.justification }}</span>
    </template>

    <template #item.created_at="{ item }">
      {{ item.created_at | formatDateHour }}
    </template>

    <template #item.action="{ item }">
      <v-container fluid class="pa-0 ma-0 mx-auto">
        <v-row no-gutters class="d-flex flex-row">
          <default-tooltip-button
            icon="mdi-close"
            message="Reprovar"
            @click="openDisapproveFlow(item)"
            :loading="item.disapproving"
            v-if="canHandle && isAttendance(item)"
            data-cy="disapprove"
          />

          <default-tooltip-button
            icon="mdi-check"
            message="Aprovar"
            @click="openApproveFlow(item)"
            :loading="item.disapproving"
            v-if="canHandle && isAttendance(item)"
            data-cy="disapprove"
          />

          <v-btn
            icon
            @click="edit(item)"
            medium
            class="my-auto flex-item"
            data-cy="edit"
            :class="canHandle ? 'mx-auto' : 'ml-auto'"
          >
            <v-icon>
              {{ alreadyConfirmed(item) ? "mdi-eye" : "mdi-pencil" }}
            </v-icon>
          </v-btn>
        </v-row>
      </v-container>
    </template>
  </v-data-table>
</template>

<script>
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";
import DefaultTooltipButton from "@/components/default/DefaultTooltipButton.vue";

export default {
  components: { DefaultTooltipButton },
  name: "RefundTable",
  props: {
    items: Array,
    pagination: Object
  },
  mixins: [AuthorizationMixin],
  data() {
    return {
      accessSave: false,
      canHandle: false,
      render: true,
      status: [
        { name: "Solicitação", refs: "solicitation", value: 1 },
        { name: "Atendimento", refs: "attendance", value: 2 },
        {
          name: "Aguardando retorno do cliente",
          refs: "waiting_customer",
          value: 3
        },
        {
          name: "Aguardando documentação do cliente",
          refs: "waiting_customer_documentation",
          value: 4
        },
        { name: "Sob cadastro", refs: "under_registration", value: 5 },
        { name: "Sob aprovação", refs: "under_approval", value: 6 },
        { name: "Aprovado", refs: "approved", value: 7 },
        { name: "Reprovado", refs: "repproved", value: 8 },
        { name: "Cancelado", refs: "cancel", value: 9 }
      ],
      headers: [
        {
          text: "Empresa",
          align: "start",
          sortable: false,
          value: "company_name"
        },
        {
          text: "Referência",
          align: "start",
          sortable: false,
          value: "reference"
        },
        {
          text: "Data da Requisição",
          sortable: false,
          value: "created_at",
          width: "180"
        },
        {
          text: "Justificativa",
          sortable: false,
          value: "justification"
        },
        {
          text: "Status",
          sortable: false,
          value: "status_id",
          width: "100"
        },
        {
          text: "Ações",
          sortable: false,
          value: "action",
          align: "center",
          width: 150
        }
      ],
      loading: false,
      authorization: {
        route: "/invoice/refund"
      }
    };
  },
  created() {
    this.accessOnlySave();
  },
  methods: {
    async accessHandle() {
      const canAccessHandle = await this.canAccess({
        route: "/invoice/refund/handle",
        type: "exec"
      });

      return canAccessHandle;
    },
    async accessOnlySave() {
      const canAccessRefund = await this.canAccess({
        route: "/invoice/refund",
        type: "write"
      });

      const canAccessHandle = await this.accessHandle();

      this.canHandle = canAccessHandle;

      this.accessSave = canAccessRefund;

      this.render = false;

      this.$nextTick(() => {
        this.render = true;
      });
    },
    openApproveFlow(item) {
      this.$emit("approve", item);
    },
    openDisapproveFlow(item) {
      this.$emit("disapprove", item);
    },
    edit(item) {
      this.$emit("edit", item);
    },
    alreadyConfirmed(item) {
      return item.status == "confirmed" || item.status == "canceled";
    },
    isAttendance(item) {
      if (item.status_id <= this.getIdStatusByType("under_approval")) {
        return true;
      }

      return false;
    },
    getIdStatusByType(refs) {
      const status = this.status.filter(con => con.refs == refs);

      if (status.length > 0) {
        return status[0].id;
      }

      return 0;
    },
    getStatusType(item) {
      const status = this.status.filter(con => con.value == item.status_id);

      if (status.length > 0) {
        return status[0].name;
      }

      return "";
    }
  }
};
</script>

<style>
.word-truncate {
  word-break: break-all;
}
</style>
