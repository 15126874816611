<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="pagination.itemsPerPage"
    :hide-default-footer="true"
    :header-props="{ sortIcon: null }"
    class="elevation-0"
  >
    <template #item.created_at="{ item }">
      {{ item.created_at | formatDateHour }}
    </template>

    <template #item.table="{ item }">
      {{ getTable(item) }}
    </template>

    <template #item.operation="{ item }">
      {{ getOperation(item) }}
    </template>

    <template #item.ocurrency="{ item }">
      <long-text-hover :text="getOcurrency(item)" one-line />
    </template>

    <template #item.actions="{ item }">
      <default-tooltip-button
        icon="mdi-magnify"
        message="Visualizar Campos"
        @click="$emit('search_audit', item)"
      />
    </template>
  </v-data-table>
</template>

<script>
import AuditHandle from "../domain/AuditHandle";
import LongTextHover from "./LongTextHover.vue";
import DefaultTooltipButton from "@/components/default/DefaultTooltipButton.vue";

export default {
  name: "AuditTable",
  components: {
    LongTextHover,
    DefaultTooltipButton
  },
  props: {
    items: Array,
    type: String,
    pagination: Object
  },
  data() {
    return {
      headers: [
        { text: "Página", value: "table" },
        { text: "Função", value: "functionality.message" },
        { text: "Operação", value: "operation" },
        { text: "Pagador", value: "payer.name" },
        { text: "Usuário", value: "user.name" },
        { text: "Ocorrência", value: "ocurrency", width: "20%" },
        { text: "Data de movimento", value: "created_at" },
        { text: "Ações", value: "actions" }
      ]
    };
  },
  methods: {
    getTable(item) {
      const auditHandle = new AuditHandle();

      return auditHandle.getTable(item);
    },
    getOperation(item) {
      switch (item.operation) {
        case "update":
          return "Edição";
        case "create":
          return "Registro";
        case "delete":
          return "Exclusão";
        default:
          return "Não definido";
      }
    },
    getOcurrency(item) {
      if (item.audit_system != null && item.audit_system.length > 0) {
        return new AuditHandle().getAuditOcurrency(item.audit_system);
      }

      if (item.audit_payer != null && item.audit_payer.length > 0) {
        return new AuditHandle().getAuditOcurrency(item.audit_payer);
      }

      if (item.audit_invoice != null && item.audit_invoice.length > 0) {
        return new AuditHandle().getAuditOcurrency(item.audit_invoice);
      }
    }
  }
};
</script>
