import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import Vuex from "vuex";

import App from "./App.vue";
import router from "./router";

import VCurrencyField from "@/plugins/VCurrencyField";
import AuthState from "@/states/auth";
import VueMask from "v-mask";
import vuetify from "./plugins/vuetify";
import Utils from "./plugins/utils";
import FileManager from "./plugins/FilleManager";

import constants from "../config/constants";
import VueCrontab from "vue-crontab";
import PushNotifications from "./plugins/PushNotifications";

// eslint-disable-next-line no-undef
Vue.prototype.CONFIG = process.env;
Vue.prototype.CONSTANTS = constants;
Vue.prototype.router = router;
Vue.prototype.auth = AuthState;

Vue.config.productionTip = false;
Vue.config.silent = true;

import Analytics from "./plugins/analytics";
import "sweetalert2/dist/sweetalert2.min.css";
import clickOutside from "./plugins/clickOutside";
Vue.use(Analytics);
Vue.use(VueSweetalert2);
Vue.use(VueMask);
Vue.use(Vuex);
Vue.use(AuthState);
Vue.use(PushNotifications);
Vue.use(Utils);
Vue.use(FileManager);
Vue.use(VueCrontab);
Vue.use(clickOutside);

const vue = new Vue({
  VCurrencyField,
  router,
  store: AuthState,
  vuetify,
  components: { App },
  render: h => h(App)
}).$mount("#app");

/**
 * instancia pura do vue para manipulacao de eventos
 */

export const bus = new Vue();

export default vue;
