<template>
  <div class="d-flex flex-item w-100">
    <v-progress-circular
      indeterminate
      color="primary"
      v-show="!rendered"
      class="mx-auto"
    />

    <vue-recaptcha
      id="recaptcha"
      ref="recaptcha"
      @verify="onVerify"
      @expired="onExpired"
      @render="onRender"
      sitekey="6LcnwzMcAAAAABimaq2sbaT1Lj7ygvSSpTbssagW"
      :loadRecaptchaScript="true"
      class="recaptcha"
      v-show="rendered"
    />
  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "Recaptcha",
  components: {
    VueRecaptcha
  },
  props: {
    value: String
  },
  data() {
    return {
      rendered: false
    };
  },
  methods: {
    onExpired() {
      this.$emit("input", false);
      this.$emit("change");
    },
    onVerify(response) {
      this.$emit("input", response);
      this.$emit("change");
    },
    onRender() {
      setTimeout(() => {
        this.rendered = true;
      }, 500);
    }
  }
};
</script>

<style>
.recaptcha {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

#rc-imageselect {
  transform: scale(0.77);
  -webkit-transform: scale(0.77);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
}

@media screen and (max-height: 575px) {
  #rc-imageselect,
  .g-recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}
</style>
