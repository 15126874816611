<template>
  <v-autocomplete
    label="Núcleo de Pagadores"
    color="primary"
    :items="items"
    item-text="name"
    :search-input.sync="searchInput"
    :loading="loading"
    :rules="rules != 'undefined' ? rules : []"
    v-model="selected"
    clearable
    return-object
  />
</template>

<script>
import PayerGroupService from "@/app/Services/PayerGroupService";

export default {
  name: "PayerGroupSelect",
  props: {
    value: Object,
    rules: Array
  },
  data() {
    return {
      selected: this.value,
      items: [],
      loading: false,
      searchInput: null,
      timeoutVar: null,
      debounceSearch: this.$utils.registerDebounce(
        this.search,
        1000,
        this.timeoutVar
      )
    };
  },
  created() {
    this.search();
  },
  watch: {
    searchInput() {
      this.loading = true;

      this.debounceSearch(this.searchInput);
    },
    selected() {
      this.$emit("input", this.selected);
    },
    value() {
      const haveValuesForSelectAtFirstTime = this.value && !this.items.length;

      if (haveValuesForSelectAtFirstTime) {
        const isArray = this.value.length > 0;

        if (isArray) {
          this.items = [...this.items, ...this.value];
        } else {
          this.items = [...this.items, { ...this.value }];
        }
      }

      this.selected = this.value;
    }
  },
  methods: {
    getPayerText(item) {
      const formatedCnpj = this.maskCNPJ(item.cnpj);

      return `${formatedCnpj} - ${item.name}`;
    },
    async search(value = "") {
      try {
        const query = {
          params: {
            filter: {
              status: 1
            }
          }
        };

        if (value) {
          query.params.filter.name = value;
        }

        const response = await PayerGroupService.build().all(query);

        this.items = response;

        if (response.length < 1) {
          throw new Error("Nenhum grupo encontrado");
        }
      } catch (error) {
        this.error = this.getExceptionResponseMessage(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
