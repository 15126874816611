<template>
  <default-layout :loading="loading" route-name="Justificativa de Reembolso">
    <template v-slot:header-actions>
      <div class="d-flex flex-row ml-auto">
        <v-spacer />

        <default-cancel-button @click="cancel" />

        <default-save-button :loading="saving" @click="save" />
      </div>
    </template>

    <template v-slot:content>
      <v-container fluid class="mt-n6">
        <refund-reason-save-form v-model="refund" />
      </v-container>
    </template>
  </default-layout>
</template>

<script>
import RefundReasonSaveForm from "./components/RefundReasonSaveForm.vue";
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import DefaultCancelButton from "@/components/layout/DefaultCancelButton.vue";
import DefaultSaveButton from "@/components/layout/DefaultSaveButton.vue";

import RefundReasonService from "@/app/Services/RefundReasonService";

export default {
  components: {
    RefundReasonSaveForm,
    DefaultLayout,
    DefaultCancelButton,
    DefaultSaveButton
  },
  name: "RefundReasonSave",
  data() {
    return {
      loading: false,
      saving: false,
      refund: {}
    };
  },
  created() {
    if (this.isToEdit()) {
      this.loadData();
    }
  },
  methods: {
    async save() {
      try {
        this.saving = true;

        const service = RefundReasonService.build();
        const id = this.$route.params.id;

        if (id != "new") {
          await service.update(this.refund);
        } else {
          delete this.refund.id;

          await service.store(this.refund);
        }

        this.refund = null;

        this.$router.push({ name: "RefundReason" });
      } catch (error) {
        console.log(error);
        this.defaultCatchError(error);
      } finally {
        this.saving = false;
        this.$forceUpdate();
      }
    },
    async loadData() {
      try {
        const id = this.$route.params.id;

        this.loading = true;
        this.refund = {};

        const response = await RefundReasonService.build().show(id);

        this.refund = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    isToEdit() {
      const id = this.$route.params.id;

      return id && id != "new";
    },
    validate() {
      const valid = this.$refs.RefundReasonSaveForm.validate();

      return valid;
    }
  }
};
</script>

<style></style>
