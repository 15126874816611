export default {
  props: {
    value: Object
  },
  data() {
    return {
      model: this.value
    };
  },
  watch: {
    model() {
      this.$emit("input", this.model);
    },
    value() {
      this.model = this.value;
    }
  }
};
