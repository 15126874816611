<template>
  <v-col cols="12">
    <span class="text-caption text--secondary">
      Encontradas
    </span>

    <v-divider />

    <div v-if="model.length">
      <v-list-item
        v-for="(writeOffPayment, index) in foundInvoices"
        :key="writeOffPayment.id"
        :class="index % 2 ? 'bg-primary-100' : ''"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ getInvoice(writeOffPayment) }}
          </v-list-item-title>

          <v-list-item-subtitle>
            {{ getInvoicePayerName(writeOffPayment) }}
          </v-list-item-subtitle>

          <v-list-item-subtitle class="text-caption">
            {{ getInvoiceStatus(writeOffPayment) }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </div>

    <v-list-item v-if="!model.length">
      <v-list-item-content>
        <v-list-item-title>
          Nenhuma Fatura
        </v-list-item-title>

        <v-list-item-subtitle> </v-list-item-subtitle>

        <v-list-item-subtitle class="text-caption"> </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-col>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";

export default {
  name: "InvoiceSearchDialogList",
  mixins: [ModelManagementMixin],
  computed: {
    foundInvoices() {
      const found = this.model.filter(writeOffPayment => {
        if (writeOffPayment.status == "success") {
          return true;
        }

        return false;
      });

      return found;
    }
  },
  methods: {
    getInvoiceStatus(writeOffPayment) {
      if (
        !writeOffPayment.related_invoice.status ||
        !writeOffPayment.related_invoice.status.name
      ) {
        return "Status Desconhecido";
      }

      return writeOffPayment.related_invoice.status.name;
    },
    getInvoice(writeOffPayment) {
      if (!writeOffPayment.related_invoice) {
        return writeOffPayment.invoice;
      }

      return writeOffPayment.related_invoice.invoice;
    },
    getInvoicePayerName(writeOffPayment) {
      if (
        !writeOffPayment.related_invoice ||
        !writeOffPayment.related_invoice.payer
      ) {
        return "PAgador desconhecido";
      }

      return writeOffPayment.related_invoice.payer.name;
    }
  }
};
</script>

<style></style>
