<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="pagination.itemsPerPage"
    :hide-default-footer="true"
    :header-props="{ sortIcon: null }"
    item-key="index"
    class="elevation-0"
  >
    <template #[`item.invoice`]="{ item }">
      {{ item.invoice || "Unificado" }}
    </template>

    <template #[`item.generation_date`]="{ item }">
      {{ getGenerationDate(item) }}
    </template>

    <template #[`item.created_at`]="{ item }">
      {{ item.created_at | formatDate }}
    </template>

    <template #[`item.payment_date`]="{ item }">
      {{ getPaymentDate(item) }}
    </template>

    <template #[`item.status`]="{ item }">
      <v-chip>{{ item.status.name }}</v-chip>
    </template>

    <template #[`item.total`]="{ item }">
      {{ item.total ? toCurrency(item.total) : "Unificado" }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "BilletTable",
  props: {
    items: Array,
    pagination: Object
  },
  data() {
    return {
      headers: [
        { text: "Fatura", value: "invoice" },
        { text: "Pagador", value: "payer.name" },
        { text: "Valor", value: "total" },
        { text: "Data de Emissão", value: "created_at" },
        { text: "Data da Geração do Boleto", value: "generation_date" },
        { text: "Data do Pagamento", value: "payment_date" },
        { text: "Status", value: "status" }
      ]
    };
  },
  methods: {
    getGenerationDate(item) {
      let generationDate = null;

      if (!item.histories) {
        return "";
      }

      item.histories.forEach(history => {
        const generateBilletStatus = [2, 3];

        if (generateBilletStatus.includes(history.status_id)) {
          generationDate = history.created_at;
        }
      });

      if (!generationDate) {
        return "";
      }

      const formated = this.formatISODate(generationDate, {
        withHour: true
      });

      return formated;
    },
    getPaymentDate(item) {
      if (!item.bank_return_file) {
        return "";
      }

      const paymentConfimedStatusCod = "06";
      const bankReturnFile = item.bank_return_file.find(
        item => item.status_cod == paymentConfimedStatusCod
      );

      if (!bankReturnFile) {
        return;
      }

      return this.formatMysqlDate(bankReturnFile.credit_date, {
        withHour: false
      });
    }
  }
};
</script>

<style></style>
