import InvoiceService from "@/app/Services/InvoiceService";
import ArrivalResponseState from "../states/ArrivalResponseState";

export default class AlterArrivalDate {
  static async alter() {
    try {
      this.altering = true;

      const promise = [];

      this.selected.forEach((invoiceArrivalInfo, index) => {
        const alterForRequest = AlterArrivalDate.alterForRequest.bind(this);
        const request = alterForRequest(
          invoiceArrivalInfo,
          index,
          this.new_arrival_date
        );

        promise.push(request);
      });

      await Promise.all(promise);

      this.showToast({ message: "Data do Navio alterada com sucesso" });

      this.$emit("altered");
    } catch (error) {
      this.defaultCatchError(error);
    }
  }

  static async alterForRequest(invoice, index, new_arrival_date) {
    const updateOnResponseState = AlterArrivalDate.updateOnResponseState.bind(
      this
    );

    try {
      invoice.loading = true;
      updateOnResponseState(index, invoice);

      const arrivalDateRequest = {
        bl: invoice.bl,
        ship: invoice.ship,
        travel: invoice.travel,
        landing_point_name: invoice.landing_point_name,
        date: new_arrival_date
      };

      await InvoiceService.build().store(
        arrivalDateRequest,
        "change-ship-date"
      );

      invoice.realized = true;
    } catch (error) {
      invoice.error = error;
      updateOnResponseState(index, invoice);
    } finally {
      invoice.loading = false;

      if (invoice.error == "Carregando...") {
        invoice.error = "";
      }

      updateOnResponseState(index, invoice);
    }
  }

  static updateOnResponseState(index, invoice) {
    ArrivalResponseState().dispatch("changeArrivalResponse", {
      index,
      arrivalResponse: invoice
    });
  }
}
