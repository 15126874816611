<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="pagination.per_page"
    hide-default-footer
  >
    <template v-slot:header.action>
      <!-- <bank-account-management-add-button
        @click="create"
        id="bank_account_create"
        v-if="authorization.canEdit"
      /> -->
      <!--
      <span v-else>
        Ações
      </span> -->
    </template>

    <template v-slot:item.action="">
      <div class="d-flex">
        <!-- <default-edit-button
          @click="edit(item)"
          id="bank_account_edit"
          :only-show="!authorization.canEdit"
        /> -->

        <!-- <default-remove-button
          @click="remove(item)"
          id="bank_account_remove"
          v-if="authorization.canEdit"
        /> -->
      </div>
    </template>

    <template v-slot:item.status="{ item }">
      <v-switch
        v-model="item.status"
        inset
        :readonly="!authorization.canEdit || item.status"
        color="secondary"
        class="mx-auto d-flex flex-item mt-1"
        :true-value="1"
        :false-value="0"
        @change="changeStatus(item)"
      />
    </template>
  </v-data-table>
</template>

<script>
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";

export default {
  components: {},
  name: "BankAccountManagementTable",
  props: {
    items: Array,
    pagination: Object
  },
  data() {
    return {
      headers: [
        {
          text: "Banco",
          align: "start",
          sortable: false,
          value: "bank.name"
        },
        {
          text: "Agência",
          align: "start",
          sortable: false,
          value: "agency"
        },
        {
          text: "Dígito da Agência",
          align: "start",
          sortable: false,
          value: "agency_digit"
        },
        {
          text: "Conta",
          align: "start",
          sortable: false,
          value: "account"
        },
        {
          text: "Dígito da Conta",
          align: "start",
          sortable: false,
          value: "account_digit"
        },
        {
          text: "Status",
          sortable: false,
          value: "status",
          width: 75
        },
        {
          text: "Ações",
          sortable: false,
          value: "action",
          align: "end",
          width: 75
        }
      ],
      params: [],
      authorization: {
        route: "/bank"
      }
    };
  },
  mixins: [AuthorizationMixin],
  methods: {
    create(item) {
      this.$emit("create", item);
    },
    edit(item) {
      this.$emit("edit", item);
    },
    remove(item) {
      this.$emit("remove", item);
    },
    changeStatus(item) {
      this.$emit("changeStatus", item);
    }
  }
};
</script>

<style></style>
