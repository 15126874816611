<template>
  <default-layout
    :loading="loading"
    route-name="Grupos Aprovação de Desconto"
    has-filters
  >
    <template #header-filter>
      <default-filter :instructions="instructions" @search="search" />
    </template>

    <template #header-actions>
      <div class="d-flex flex-row ml-auto my-auto">
        <v-spacer />

        <authorization-gate
          auth-route="/discount-approval-groups"
          auth-type="write"
        >
          <default-save-button @click="add">
            Adicionar
          </default-save-button>
        </authorization-gate>
      </div>
    </template>

    <template #content>
      <v-container fluid>
        <discount-approval-groups-table
          @edit="edit"
          :pagination="pagination"
          :items="items"
          @changeStatus="changeStatus"
        />
      </v-container>
    </template>

    <template #footer>
      <pagination v-model="pagination" @change="loadData" :loading="loading" />
    </template>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import AuthorizationGate from "@/components/Authorization/AuthorizationGate.vue";
import DefaultSaveButton from "@/components/layout/DefaultSaveButton.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";

import DiscountApprovalGroupsService from "@/app/Services/DiscountApprovalGroupsService";
import DiscountApprovalGroupsTable from "@/pages/DiscountApprovalGroups/components/DiscountApprovalGroupsTable.vue";
import DefaultFilter from "@/components/Filters/DefaultFilter.vue";
import FilterMixin from "@/components/Filters/FilterMixin";

export default {
  components: {
    DefaultLayout,
    AuthorizationGate,
    DefaultSaveButton,
    Pagination,
    DiscountApprovalGroupsTable,
    DefaultFilter
  },
  name: "DiscountApprovalGroups",
  mixins: [PaginationMixin, AuthorizationMixin, FilterMixin],
  data() {
    return {
      items: [],
      loading: false,
      filter: [],
      authorization: {
        route: "/discount-approval-groups"
      },
      instructions: {
        search_for: [
          { text: "Nome do Grupo", field: "name" }
          // { text: "Nome do Pagador", field: "payer_name" }
        ],
        filter_for: [
          { text: "Ativos", field: "status", value: "active" },
          { text: "Inativos", field: "status", value: "inactive" }
        ]
      }
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;

        const query = this.getApiQuery();

        const response = await DiscountApprovalGroupsService.build().index({
          query
        });

        this.items = response.data;

        this.paginate(response);
      } catch (error) {
        console.error(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    getApiQuery() {
      return {
        params: {
          filter: this.filter,
          ...this.pagination
        }
      };
    },
    add() {
      this.$router.push({
        name: "DiscountApprovalGroupsSave",
        params: { id: "new" }
      });
    },
    edit(item) {
      this.$router.push({
        name: "DiscountApprovalGroupsSave",
        params: { id: item.id }
      });
    },
    async changeStatus(item) {
      try {
        this.loading = true;

        const response = await DiscountApprovalGroupsService.build().update({
          id: item.id,
          status: item.status
        });

        this.toHandle = null;

        this.showToast({ message: "Status foi alterado" });

        return response.data;
      } catch (error) {
        console.log(error);
        item.status = !item.status;
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
