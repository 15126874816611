<template>
  <v-container fluid>
    <v-card :loading="loading">
      <v-container fluid>
        <v-form ref="form" v-model="valid">
          <v-tabs color="secondary" flat class="containerInner" v-model="tab">
            <v-tab href="#general" class="font-weight-bold">Gerais</v-tab>
            <v-tab href="#contract" class="font-weight-bold">Contratos</v-tab>
            <div class="d-flex flex-row alignButtons">
              <default-cancel-button />

              <default-save-button
                @click="save"
                :disabled="!valid"
                :loading="saving"
              />
            </div>
            <v-tab-item value="general">
              <user-profile-form-general v-model="user" />
            </v-tab-item>
            <v-tab-item value="contract">
              <user-profile-form-contract
                v-if="user.id > 0"
                v-model="user"
                @loadData="loadData()"
              />
            </v-tab-item>
          </v-tabs>
        </v-form>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import FormMixin from "@/components/misc/mixins/FormMixin";
import PasswordVerification from "./domain/PasswordVerification";
import UserProfileInfo from "./domain/UserProfileInfo";
import UserProfileFormGeneral from "./components/UserProfileFormGeneral.vue";
import UserProfileFormContract from "./components/UserProfileFormContract.vue";
import UserProfileService from "@/app/Services/UserProfileService";
import DefaultCancelButton from "@/components/layout/DefaultCancelButton.vue";
import DefaultSaveButton from "@/components/layout/DefaultSaveButton.vue";

export default {
  components: {
    UserProfileFormGeneral,
    UserProfileFormContract,
    DefaultSaveButton,
    DefaultCancelButton
  },
  name: "UserProfile",
  mixins: [FormMixin, ModelManagementMixin, PasswordVerification],
  data() {
    return {
      user: {
        payerGroup: [],
        files: []
      },
      tab: {},
      loading: false,
      valid: true,
      responsivity: {
        maxWidth: "256px",
        maxHeight: "256px"
      }
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    validate() {
      this.valid = this.$refs.form.validate();

      return this.valid;
    },
    async save() {
      try {
        const valid = this.$refs.form.validate();

        if (!valid) {
          return;
        }

        this.loading = true;

        const files = [];

        Array.from(this.user.files).forEach(file => {
          if (file.created || file.removed || file.updated) {
            files.push(file);
          }
        });

        const user = { ...this.user };

        user.files = files;

        const response = await UserProfileService.build().update(user);

        this.updateLocalStorageUser(response);

        this.user = response.data;

        this.showToast({ message: "Perfil Alterado com sucesso" });

        // this.$router.go(-1);
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
        this.saving = false;
      }
    },
    updateLocalStorageUser(response) {
      const { image } = response.data;

      const userProfileInfo = new UserProfileInfo();

      userProfileInfo.updateUserImage(image);
    },
    changeValidation(valid) {
      this.valid = valid;
    },
    getUserProfileId() {
      const userProfileInfo = new UserProfileInfo();

      const user = userProfileInfo.getUserInfo();

      return user.id;
    },
    async loadData() {
      try {
        this.loading = true;

        const userId = this.getUserProfileId();

        const response = await UserProfileService.build().read({ id: userId });

        this.user = response.data;

        const { image } = this.user;

        this.user.image = {
          preview: image
        };

        this.user.files = this.user.files || [];
        this.user.payerGroup = this.user.payerGroup || [];

        this.$forceUpdate();
      } catch (error) {
        console.error(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style>
.custom-btn {
  font-size: 14px;
  font-family: "Helvetica Neue" !important;
  text-transform: none;
  margin: 10px;
  color: white;
}
.btnCancel {
  background: #a8aab0 !important;
}
.alignButtons {
  margin-left: auto;
}
</style>
