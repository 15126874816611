<template>
  <v-combobox
    :label="label"
    v-model="selected"
    append-icon=""
    :rules="rules"
    :loading="loading"
    multiple
    clearable
  >
    <template v-slot:selection="{ item, index }">
      <v-chip
        small
        close
        @click="closeItem(item, index)"
        @click:close="closeItem(item, index)"
      >
        {{ item }}
      </v-chip>
    </template>
  </v-combobox>
</template>

<script>
export default {
  name: "customPasteCombobox",
  props: {
    value: Array,
    label: String,
    loading: Boolean
  },
  watch: {
    value() {
      this.selected = this.value;
    },
    selected() {
      this.$emit("input", this.selected);
    }
  },
  data() {
    return {
      selected: this.value || []
    };
  },
  mounted() {
    this.listenPasteEvent();

    this.selected = this.value;
  },
  methods: {
    listenPasteEvent() {
      this.$el.addEventListener("paste", event => {
        const paste = (event.clipboardData || window.clipboardData).getData(
          "text"
        );

        event.preventDefault();

        if (paste) {
          this.onPasteHandler(paste, event);
        }
      });
    },
    onPasteHandler(value) {
      const matchesAllBlankCharacters = new RegExp(/[ \t\r\n\v\f]/g);

      const splittedInvoices = value.split(matchesAllBlankCharacters);

      const result = splittedInvoices.filter(value => !!value);

      this.selected = [...this.selected, ...result];
    },
    closeItem(item, index) {
      const toChange = [...this.selected];

      toChange.splice(index, 1);

      this.selected = toChange;
    }
  }
};
</script>

<style></style>
