<template>
  <v-container class="pa-2" :class="zebra ? 'bg-primary-100' : ''">
    <v-row no-gutters>
      <v-col sm="12" class="mb-n12 d-flex flex-row w-100" v-if="!noGoTo">
        <span class="text-caption font-weight-bold flex-item mr-auto"> </span>

        <v-btn
          text
          color="primary"
          class="mr-4 unified-goto-btn"
          @click="click"
        >
          Ir Para
        </v-btn>
      </v-col>

      <v-col class="d-flex flex-column">
        <span class="text-overline"> Fatura {{ invoice }} </span>
        <span class="text-subtitle-1 font-weight-bold">
          {{ payer }}
        </span>
        <span class="text-subtitle-1 font-weight-normal" v-if="loading">
          Carregando...
        </span>
        <span class="text-subtitle-1 font-weight-normal" v-else>
          {{ currency }} {{ value | withoutSymbolCurrency }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "RelatedInvoiceTile",
  props: {
    invoice: String,
    payer: String,
    value: String,
    currency: String,
    zebra: Boolean,
    noGoTo: Boolean,
    loading: Boolean
  },
  methods: {
    click() {
      this.$emit("click");
    }
  }
};
</script>

<style scoped>
.unified-goto-btn {
  transform: translateY(24px);
}
</style>
