export const PayerProcedureDiscountRulesEnum = Object.freeze({
  invoicesIssuedPaymentCurrentMonth: {
    description:
      "Pagamento entre o primeiro dia útil e o dia {{on_day}} do mês atual para faturas emitidas entre os dias {{from_start}} e {{from_end}}",
    params: ["on_day", "from_end", "from_start"]
  },
  invoicesIssuedPaymentNextMonth: {
    description:
      "Pagamento entre o primeiro dia útil e o dia {{on_day}} do próximo mês para faturas emitidas entre {{from_start}} e {{from_end}}",
    params: ["on_day", "from_end", "from_start"]
  },
  invoiceUntilDays: {
    description:
      "Pagamento em {{days}} dias úteis após a emissão (com ajuste de fim de semana)",
    params: ["days"]
  }
});
