<template>
  <v-list-item
    :to="access.path"
    :tooltip="access.description"
    :key="access.path"
    :id="getId(access)"
    class="secondary--text"
    :data-cy="getId(access)"
  >
    <v-list-item-action>
      <v-icon>
        {{ access.icon }}
      </v-icon>
    </v-list-item-action>

    <v-list-item-content>
      <v-list-item-title>
        {{ access.description }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  name: "DrawerItem",
  props: {
    access: Object
  },
  created() {},
  data() {
    return {};
  },
  methods: {
    getId(access) {
      return `${access.path}`.replace(/[^\w\s]/gi, "");
    },
    accessPage() {
      this.$router.push({ path: this.access.path });
    }
  }
};
</script>

<style></style>
