<template>
  <v-card>
    <not-found-invoices-dialog
      :found="foundInvoices"
      :search="filter.invoices"
      v-model="notFoundDialog"
    />

    <v-card-title>
      <v-row @click="toggleFilter">
        <v-col
          lg="5"
          md="3"
          xs="12"
          class="py-1 text-h5 my-auto font-weight-bold"
        >
          Filtros
        </v-col>
        <v-col lg="6" md="8" xs="12" class="py-1 d-flex flex-row flex-wrap">
          <v-container>
            <v-row no-gutters>
              <v-col class="d-flex pl-xl-3 pl-lg-3 pl-md-3 p-sm-0">
                <v-btn
                  v-if="authorization.canChangeShipDate"
                  class="text--secondary my-1 w-100 flex-item"
                  :disabled="!hasInvoices"
                  @click.stop="alterArrivalDateForMany"
                >
                  Alt. Dt. Navio
                </v-btn>
              </v-col>

              <v-col class="d-flex pl-xl-3 pl-lg-3 pl-md-3 p-sm-0">
                <v-btn
                  v-if="authorization.canUnificate"
                  class="text--secondary my-1 w-100 flex-item"
                  :disabled="!hasInvoices"
                  @click.stop="unify"
                >
                  Unificar
                </v-btn>
              </v-col>

              <v-col class="d-flex pl-xl-3 pl-lg-3 pl-md-3 p-sm-0">
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      @click.stop="loadInvoicesReport"
                      class="text--secondary my-1 w-100 flex-item"
                      :disabled="items.length == 0"
                      :loading="loadingReport"
                    >
                      <v-icon>
                        mdi-table-arrow-down
                      </v-icon>
                    </v-btn>
                  </template>

                  <span> Gerar Relatório </span>
                </v-tooltip>
              </v-col>

              <v-col class="d-flex pl-xl-3 pl-lg-3 pl-md-3 p-sm-0">
                <v-btn
                  class="white--text my-1 w-100 flex-item"
                  color="primary"
                  @click.stop="loadInvoices"
                  :loading="loading"
                >
                  Visualizar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>

        <v-col>
          <v-btn x-large icon class="text-end my-auto">
            <v-icon @click.stop="toggleFilter" :class="getChevronDirection()">
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-expand-transition>
      <v-card-text v-if="showFilters">
        <v-container fluid>
          <v-row>
            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <date-picker
                label="Data Emissão Inicial"
                v-model="filter.invoice_date.start"
                clearable
              />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <date-picker
                label="Data Emissão Final"
                v-model="filter.invoice_date.final"
                clearable
              />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <v-text-field label="Código da Viagem" v-model="filter.travel" />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <v-text-field label="Código do Navio" v-model="filter.ship" />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <v-text-field
                label="Porto de Embarque"
                v-model="filter.boarding_point_name"
              />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <v-text-field
                label="Porto de Desembarque"
                v-model="filter.landing_point_name"
              />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <v-currency-field
                label="Valor da Fatura"
                v-model="filter.total"
              />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <currency-select-filter v-model="filter.currency" />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <custom-paste-combobox
                label="Número do Booking"
                v-model="filter.booking"
              />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <custom-paste-combobox label="BL" v-model="filter.bl" />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <invoice-type-select v-model="filter.type" />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <status-select-filter v-model="filter.status" />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <payer-select
                v-model="filter.payers"
                field="cnpj_cpf"
                label="CNPJ"
                multiple
                return-object
              />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12">
              <payer-select
                v-model="filter.payers"
                :payers="payers"
                field="name"
                label="Nome do Pagador"
                multiple
                return-object
              />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12" class="mt-n1">
              <custom-paste-combobox
                v-model="filter.ids"
                label="Identificação do Título pelo Sistema (ID)"
                class="mb-n3"
              />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12" class="mt-n1">
              <custom-paste-combobox
                v-model="filter.our_number"
                label="Nosso Número ou TxId"
                class="mb-n3"
              />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="6" xs="12" class="mt-n1">
              <custom-paste-combobox
                v-model="filter.invoices"
                label="Número da Fatura"
                class="mb-n3"
              />

              <span
                @click="openInvoicesNotFoundDialog"
                class="error--text cursor-pointer"
              >
                {{ invoiceValidationError }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { bus } from "@/main";
import DatePicker from "@/components/date_picker/DatePicker.vue";
import StatusSelectFilter from "./filters/StatusSelectFilter.vue";
import PayerSelect from "@/components/payer_select/PayerSelect.vue";
import CurrencySelectFilter from "./filters/CurrencySelectFilter.vue";
import CustomPasteCombobox from "./filters/CustomPasteCombobox.vue";
import InvoiceTypeSelect from "./filters/InvoiceTypeSelect.vue";
import NotFoundInvoicesDialog from "./NotFoundInvoicesDialog.vue";
import InvoiceService from "@/app/Services/InvoiceService";

export default {
  components: {
    DatePicker,
    StatusSelectFilter,
    PayerSelect,
    CurrencySelectFilter,
    CustomPasteCombobox,
    InvoiceTypeSelect,
    NotFoundInvoicesDialog
  },
  props: {
    loading: Boolean,
    loadingReport: Boolean,
    value: Object,
    authorization: Object,
    selected: Array,
    items: Array
  },
  name: "InvoiceFilters",
  data() {
    return {
      showFilters: true,
      filter: this.value,
      payers: [],
      foundInvoices: [],
      invoiceValidationError: "",
      loadingInvoiceValidate: false,
      notFoundDialog: false,
      timeoutVar: null,
      debounceValidation: this.$utils.registerDebounce(
        this.invoicesValidate,
        1000,
        this.timeoutVar
      )
    };
  },
  watch: {
    value() {
      this.filter = this.value;
    },
    filter() {
      this.$emit("input", this.filter);
    },
    "filter.invoices": {
      handler() {
        this.invoiceValidationError = "";

        this.debounceValidation();
      }
    },
    "filter.total": {
      handler() {
        if (!this.filter.total) {
          delete this.filter.total;
        }
      }
    }
  },
  computed: {
    hasInvoices() {
      return this.selected && this.selected.length >= 1;
    }
  },
  methods: {
    toggleFilter() {
      this.showFilters = !this.showFilters;

      bus.$emit("resize", this.showFilters);
    },
    getChevronDirection() {
      if (this.showFilters) {
        return "filter-toggle-btn filter-toggle-btn__close";
      }

      return "filter-toggle-btn";
    },
    async invoicesValidate() {
      try {
        this.loadingInvoiceValidate = true;

        if (!this.filter.invoices.length) {
          return;
        }

        const query = {
          filter: this.filter
        };

        const response = await InvoiceService.build().indexMainFilter({
          query,
          url: "all"
        });

        this.foundInvoices = response.data;

        const foundInvoices = response.data.length;
        const searchInvoices = this.filter.invoices.length;

        if (searchInvoices > foundInvoices) {
          this.invoiceValidationError = `${foundInvoices} encontrados de ${searchInvoices} (ver mais)`;
        }
      } catch (error) {
        console.error(error);
        this.defaultCatchError(error);
      } finally {
        this.loadingInvoiceValidate = false;
      }
    },
    openInvoicesNotFoundDialog() {
      this.notFoundDialog = true;
    },
    loadInvoices() {
      this.showFilters = false;

      this.saveFilterOnStorage();

      bus.$emit("resize", this.showFilters);

      this.$emit("loadInvoices");
    },
    saveFilterOnStorage() {
      localStorage.setItem("invoice_filters", JSON.stringify(this.filter));
    },
    unify() {
      this.$emit("unify");
    },
    loadInvoicesReport() {
      this.$emit("loadInvoicesReport");
    },
    alterArrivalDateForMany() {
      this.$emit("alter_arrival_date_for_many");
    },
    changePayers(value) {
      this.payers = value;
    }
  }
};
</script>

<style lang="scss">
.filter-toggle-btn {
  max-width: 42px !important;

  &__close {
    transform: rotate(180deg);
  }
}
</style>
