<template>
  <v-autocomplete
    :label="label"
    color="primary"
    :return-object="returnObject"
    :items="items"
    :item-text="getPayerSelectionText"
    :filter="customFilter"
    :item-value="itemValue != '' ? itemValue : 'id'"
    :search-input.sync="searchInput"
    :loading="loading"
    :clearable="!readonly"
    :outlined="outlined"
    :multiple="multiple"
    :small-chips="multiple"
    :readonly="readonly"
    :rules="rules != 'undefined' ? rules : []"
    no-data-text="Digite para pesquisar..."
    v-model="selected"
    v-mask="field == 'cnpj_cpf' ? $cnpjMask : ''"
  >
    <template v-slot:selection="{ item }">
      <v-chip
        small
        close
        @click="removeSelection(item, index)"
        @click:close="removeSelection(item, index)"
        v-if="multiple"
      >
        {{ getPayerItemText(item) }}
      </v-chip>

      <span v-else> {{ getPayerItemText(item) }} </span>
    </template>
  </v-autocomplete>
</template>

<script>
import PayerService from "@/app/Services/PayerService";

export default {
  name: "PayerSelect",
  props: {
    returnObject: Boolean,
    value: Object,
    field: String,
    itemValue: String,
    fieldFilterArray: Array,
    label: String,
    outlined: Boolean,
    multiple: Boolean,
    rules: Array,
    readonly: Boolean
  },
  data() {
    return {
      loading: false,
      searchInput: null,
      items: [],
      selected: null,
      timeoutVar: null,
      debounceSearch: this.$utils.registerDebounce(
        this.search,
        1000,
        this.timeoutVar
      )
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        const haveValuesForSelectAtFirstTime = this.value && !this.items.length;

        if (haveValuesForSelectAtFirstTime) {
          const isArray = this.value.length >= 0 || this.value == [];

          if (isArray) {
            this.items = [...this.value];
          } else {
            this.items = [{ ...this.value }];
          }
        }

        this.selected = this.value;
      }
    },
    selected() {
      this.$emit("input", this.selected);
    },
    loading() {
      this.$emit("loading", this.loading);
    },
    items() {
      this.$emit("changeList", this.items);
    },
    searchInput() {
      this.loading = true;

      this.debounceSearch(this.searchInput);
    }
  },
  methods: {
    removeSelection(item, index) {
      const toChange = [...this.selected];

      toChange.splice(index, 1);

      this.selected = toChange;
    },
    customFilter(item, queryText) {
      const filterName = String(item.name).toLowerCase();
      const filterCnpjCpf = String(item.cnpj_cpf).toLowerCase();
      const searchText = String(queryText).toLowerCase();

      return (
        filterName.indexOf(searchText) > -1 ||
        filterCnpjCpf.indexOf(String(searchText).replace(/[./-]/gi, "")) > -1
      );
    },
    getPayerSelectionText(item) {
      const formatedCnpj = this.maskCNPJ(item.cnpj_cpf);

      return `${formatedCnpj} - ${item.name}`;
    },
    getPayerItemText(item) {
      if (this.field == "name_or_cnpj_cpf") {
        return this.maskCNPJ(item.cnpj_cpf) + " - " + item.name;
      } else if (this.field != "cnpj_cpf") {
        return item[this.field];
      }

      const formatedCnpj = this.maskCNPJ(item.cnpj_cpf);

      return `${formatedCnpj}`;
    },
    async search(value) {
      try {
        if (!value) {
          return;
        }

        const params = {
          filter: {}
        };

        params.filter[this.field] =
          this.field == "cnpj_cpf" ? this.unmaskText(value) : value;
        params.filter["status"] = 1;

        const response = await PayerService.build().show("", { params });

        if (response.data.length < 1) {
          return;
        }

        this.items = [...this.items, ...response.data];
      } catch (error) {
        this.error = this.getExceptionResponseMessage(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
