<template>
  <v-card :loading="loading" v-if="showTable">
    <v-container v-if="!items.length">
      <v-row>
        <centralized-message>
          <template #message>
            Lista não Gerada
          </template>

          <template #complemention>
            Adicione filtros ou clique em visualizar para continuar
          </template>
        </centralized-message>
      </v-row>
    </v-container>
    <v-container class="overflow-x-auto" v-else fluid>
      <v-row>
        <v-col>
          <v-data-table
            show-select
            :headers="getSelectedHeaders"
            v-model="table.selected"
            fixed-header
            :mobile-breakpoint="0"
            :items="items"
            height="calc(100vh - 340px)"
            :items-per-page="pagination.itemsPerPage"
            :item-class="getItemClass"
            hide-default-footer
            @toggle-select-all="selectAllToggle"
          >
            <template #[`header.actions`]="">
              <invoice-table-header-select
                v-model="headers.selected"
                :headers="headers.all"
              />
            </template>

            <template
              #[`item.data-table-select`]="{ item, isSelected, select }"
            >
              <v-simple-checkbox
                :value="isSelected"
                :readonly="invoiceVerify.wasUnificated(item)"
                :disabled="invoiceVerify.wasUnificated(item)"
                :indeterminate="invoiceVerify.wasUnificated(item)"
                indeterminate-icon="mdi-close-box-outline"
                @input="select($event)"
              />
            </template>

            <template #[`item.result_total`]="{ item }">
              BRL {{ formatedCurrency(item.result_total) }}
            </template>

            <template #[`item.type`]="{ item }">
              {{ getType(item.type) }}
            </template>

            <template #[`item.invoice`]="{ item }">
              {{ item.invoice || "Unificado" }}
            </template>

            <template #[`item.invoice_date`]="{ item }">
              {{ item.invoice_date | formatDate }}
            </template>

            <template #[`item.due_date`]="{ item }">
              {{ (item.due_date || "-") | formatDate }}
            </template>

            <template #[`item.created_at`]="{ item }">
              {{ item.created_at | formatDate }}
            </template>

            <template #[`item.emited_date`]="{ item }">
              {{ item.emited_date | formatDate }}
            </template>

            <template #[`item.status`]="{ item }">
              <v-chip>{{ item.status.name }}</v-chip>
            </template>

            <template #[`item.payer.cnpj_cpf`]="{ item }">
              {{ getMaskedPayer(item) }}
            </template>

            <template #[`item.computed_discount`]="{ item }">
              BRL
              {{ formatedCurrency(item.result_entries) }}
            </template>

            <template #[`item.total`]="{ item }">
              {{ item.currency }} {{ formatedCurrency(item.total) }}
            </template>

            <template #[`item.identification`]="{ item }">
              <span> {{ getSerializedIdentification(item) }} </span>
            </template>

            <template #[`item.actions`]="{ item }">
              <invoice-table-item-actions
                :item="item"
                :authorization="authorization"
              />
            </template>

            <template #[`item.transaction`]="{item}">
              {{ getBankOurNumber(item) }}
            </template>

            <template #[`item.currency_value`]="{ item }">
              {{
                "BRL " +
                  formatedCurrency(
                    item.currency_value || item.actual_currency_value,
                    {
                      maximumFractionDigits: 4,
                      minimumFractionDigits: 4
                    }
                  )
              }}
              <!-- {{
                "BRL " +
                  formatedCurrency(getCurrencyValue(item), {
                    maximumFractionDigits: 4,
                    minimumFractionDigits: 4
                  })
              }} -->
            </template>
          </v-data-table>

          <slot name="pagination"> </slot>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialogChangeStatusToLegal" persistent max-width="600">
      <v-card>
        <v-card-title class="text-h5">
          Alterar Status da Fatura:
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                Alterar o status da fatura
                <b v-if="invoiceSelected.payer">{{
                  `${invoiceSelected.invoice || "Unificado"} - ${
                    invoiceSelected.payer.name
                  }`
                }}</b>
                para:
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  :items="optStatus"
                  label="Status"
                  item-text="name"
                  item-value="id"
                  v-model="optStatusSelected"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="dialogChangeStatusToLegal = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            :disabled="!optStatusSelected"
            text
            @click="
              updateInvoiceChangeStatusToLegal(
                invoiceSelected,
                optStatusSelected
              )
            "
          >
            Alterar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { bus } from "@/main";
import FileSaver from "file-saver";
import CentralizedMessage from "@/components/misc/CentralizedMessage.vue";
import InvoiceTableHeaderMixin from "../domain/InvoiceTableHeaderMixin";
import InvoiceTableHeaderSelect from "./InvoiceTableHeaderSelect.vue";
import InvoiceTableItemActions from "./InvoiceTableItemActions.vue";
import InvoiceService from "@/app/Services/InvoiceService";
import InvoiceVerify from "../domain/InvoiceVerify";
import { DateTime } from "luxon";
// import { DateTime } from "luxon";

export default {
  components: {
    InvoiceTableHeaderSelect,
    InvoiceTableItemActions,
    CentralizedMessage
  },
  name: "InvoiceTable",
  props: {
    value: Array,
    items: Array,
    loading: Boolean,
    pagination: Object,
    authorization: Object
  },
  created() {
    this.invoiceVerify = new InvoiceVerify();

    this.fillSelectWithStoredPreferences();

    bus.$on("resize", this.resize);
    bus.$on("invoice_change_status", this.invoiceChangeStatusToLegal);
    bus.$on(
      "open_electronic_invoice_receipt",
      this.openElectronicInvoiceEeceipt
    );
  },
  data() {
    return {
      selectAll: false,
      table: {
        selected: []
      },
      showTable: true,
      dialogChangeStatusToLegal: false,
      invoiceSelected: {},
      optStatus: [
        {
          id: 1,
          name: "Entrada de Cobrança"
        },
        {
          id: 10,
          name: "Cobrança Jurídica"
        },
        {
          id: 9,
          name: "Baixa Manual"
        }
      ],
      optStatusSelected: null
    };
  },
  watch: {
    value() {
      this.table.selected = this.value;
    },
    "table.selected": {
      handler() {
        this.$emit("input", this.table.selected);
      }
    },
    "headers.selected": {
      handler() {
        this.storeHeaderSelectPreferences();
      }
    }
  },
  mixins: [InvoiceTableHeaderMixin],
  methods: {
    resize(showFilter) {
      this.showTable = !showFilter;
    },
    fillSelectWithStoredPreferences() {
      const unserializedPreferences = localStorage.getItem(
        "invoice_header_select"
      );

      const selected = JSON.parse(unserializedPreferences);

      if (!selected) {
        return;
      }

      this.headers.selected = selected;

      this.$forceUpdate();
    },
    getSerializedIdentification(item) {
      if (item.identification == "import") {
        return "Importação";
      }

      if (item.identification == "export") {
        return "Exportação";
      }

      return "-";
    },
    getBankOurNumber(item) {
      if (!item.transaction) {
        return "";
      }

      if (!item.transaction.bank_our_number) {
        return "";
      }

      return item.transaction.bank_our_number;
    },
    storeHeaderSelectPreferences() {
      const headerSelect = JSON.stringify(this.headers.selected);

      localStorage.setItem("invoice_header_select", headerSelect);
    },
    getItemClass(item) {
      if (this.invoiceVerify.wasUnificated(item) && item.invoice) {
        return "text--disabled unified";
      }
    },
    getComputedTotal(item) {
      const result = this.invoiceVerify.getTotalWithDiscounts(item);

      return this.formatedCurrency(result);
    },
    getMaskedPayer(item) {
      if (!item.payer || !item.payer.cnpj_cpf) {
        return "";
      }

      return this.maskCNPJ(item.payer.cnpj_cpf);
    },
    selectAllToggle(props) {
      this.selectAll = !this.selectAll;

      if (!this.selectAll) {
        return;
      }

      props.items.forEach(item => {
        if (!this.invoiceVerify.isUnified(item)) {
          this.table.selected.push(item);
        }
      });
    },
    getType(type) {
      switch (type) {
        case "invoice":
          return "Frete";
        case "demurrage":
          return "Demurrage";
        case "credit_note":
          return "Nota de Crédito";
        case "unified":
          return "Unificado";
      }
    },
    formatedCurrency(item, options) {
      const value = this.withoutSymbolCurrency(item, options);

      const intValue = `${value}`.replace("-", "");

      return intValue;
    },
    invoiceChangeStatusToLegal(invoice) {
      this.dialogChangeStatusToLegal = true;
      this.invoiceSelected = invoice;
    },
    async updateInvoiceChangeStatusToLegal(invoice, status_id) {
      this.loading = true;
      this.dialogChangeStatusToLegal = false;
      await InvoiceService.build().changeStatus(invoice.id, status_id);
      this.showToast({
        message: `Status da fatura ${invoice.invoice} - ${invoice.payer.name} alterada!`,
        status: "success"
      });

      bus.$emit("load-data-invoices");
    },
    async openElectronicInvoiceEeceipt(item) {
      if (item.file_electronic_invoice) {
        this.loading = true;
        const response = await InvoiceService.build().send(
          { invoice: item.invoice },
          "read-electronic-invoice/download/receipt/",
          {
            responseType: "arraybuffer"
          }
        );

        const blob = new Blob([response], { type: "application/pdf;" });

        this.loading = false;

        FileSaver.saveAs(blob, item.file_electronic_invoice);
      } else {
        this.showToast({
          message: `Não possui Fatura cadastrado.`,
          status: "info"
        });
      }
    },
    getCurrencyValue(item) {
      if (item.payer.exchange_day_roe) {
        const updated_at = DateTime.fromISO(item.payer.updated_at);
        const diff_days = DateTime.now()
          .setZone("utc-3")
          .diff(updated_at, "days");

        if (
          diff_days.days <= item.payer.exchange_day_roe &&
          item.actual_currency_value
        ) {
          return item.payer.exchange_rate_roe;
        } else {
          return item.currency_value || item.actual_currency_value;
        }
      } else {
        return item.currency_value || item.actual_currency_value;
      }
    }
  }
};
</script>
