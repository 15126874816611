<template>
  <div>
    <router-loading />

    <router-view />
  </div>
</template>

<script>
import RouterLoading from "./components/misc/RouterLoading.vue";

export default {
  components: { RouterLoading },
  name: "App"
};
</script>

<style lang="scss">
@import "@/styles/main.scss";
@import "@/styles/swal.scss";

html {
  overflow: hidden !important;
}
body {
  font-size: 12px;
}
strong {
  font-family: "Helvetica Neue";
}
.v-label {
  font-size: 12px !important;
}
.v-toolbar--dense .v-toolbar__content,
.v-toolbar--dense .v-toolbar__extension {
  padding-top: 0;
  padding-bottom: 0;
  border-top: 5px solid #ff6327;
}
.v-application--wrap {
  background: rgb(245, 245, 245) !important;
}
.linkRouter {
  list-style: none;
  display: inline-block;
}
.mdi-pencil {
  cursor: pointer;
}
.interList {
  background: white;
}
.v-pagination .v-pagination__item--active {
  color: white !important;
  outline: none !important;
}
</style>
