import VueGtag from "vue-gtag";

export default {
  install(Vue) {
    Vue.use(
      VueGtag,
      {
        config: {
          id: Vue.prototype.CONFIG.VUE_APP_ANALYTICS,
          params: {
            send_page_view: false
          }
        },
        appName: "Hapag-LIoyd",
        pageTrackerScreenviewEnabled: true,
        pageTrackerTemplate(to) {
          return {
            page_title: to.path,
            page_path: to.path
          };
        }
      },
      Vue.prototype.router
    );
  }
};
