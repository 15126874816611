import InvoiceService from "@/app/Services/InvoiceService";

export default class LoadArrivalInfos {
  static async load() {
    try {
      this.loading = true;

      if (!this.selected || !this.selected.length) {
        this.error = "Nenhuma fatura definida no arquivo";
      }

      const query = LoadArrivalInfos.getApiQueryRequest(this.selected);

      const response = await InvoiceService.build().show(
        "change-ship-date",
        query
      );

      this.data = response.data;

      if (!this.data.length) {
        this.error = "Nenhuma Fatura elegível para mudança de data do navio";
      }
    } catch (error) {
      this.defaultCatchError(error);

      const errorMessage = this.getExceptionResponseMessage(error);

      this.error = errorMessage;
    } finally {
      this.loading = false;
    }
  }

  static getApiQueryRequest(invoices) {
    const invoiceIds = LoadArrivalInfos.pluck(invoices, "id");

    return {
      params: {
        filter: {
          id: invoiceIds
        }
      }
    };
  }

  static pluck(array, key) {
    const withKeyValues = array.map(item => (item ? item[key] : undefined));

    const plucked = withKeyValues.filter(item => typeof item !== "undefined");

    return plucked;
  }
}
