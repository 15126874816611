import tables from "../domain/tables";

export default class AuditHandle {
  getAuditOcurrency(fields) {
    let serializedResult = "[";

    fields.forEach(field => {
      const serialize = `${field.serialized_field_name ||
        field.field}`.toUpperCase();

      serializedResult += ` ${serialize}:${
        field.updated_value_formatted
          ? field.updated_value_formatted
          : field.updated_value != null
          ? field.updated_value
          : ""
      }`;
    });

    serializedResult += "]";

    return serializedResult;
  }

  getTable(item) {
    try {
      const table = tables.find(table => table.value == item.table);

      if (item && table) {
        return `${table.text}`;
      } else {
        return `${item.table}`;
      }
    } catch (error) {
      console.log(item);
      console.error(error);

      return "";
    }
  }

  getFields(item) {
    try {
      if (item.audit_system != null && item.audit_system.length > 0) {
        return item.audit_system;
      }

      if (item.audit_payer != null && item.audit_payer.length > 0) {
        return item.audit_payer;
      }

      if (item.audit_invoice != null && item.audit_invoice.length > 0) {
        return item.audit_invoice;
      }
    } catch (error) {
      console.log(error);
    }

    return [];
  }
}
