<template>
  <v-container class="dialog__limited_height pa-0 mx-n2">
    <v-row class="ma-0 w-100">
      <v-col
        cols="12"
        class="my-n3"
        v-for="(invoiceArrivalInfo, index) in arrivals"
        :key="index"
      >
        <arrival-info :invoice="invoiceArrivalInfo" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ArrivalInfo from "@/components/AlterArrivalDate/components/ArrivalInfo.vue";
import ArrivalResponseState from "../states/ArrivalResponseState";

export default {
  name: "ArrivalInfoList",
  components: {
    ArrivalInfo
  },
  computed: {
    arrivals() {
      return ArrivalResponseState().state.data;
    }
  }
};
</script>

<style>
.dialog__limited_height {
  max-height: 172px !important;
  overflow: auto !important;
}
</style>
