<template>
  <v-form ref="form" v-model="valid">
    <v-container fluid>
      <v-row>
        <v-col lg="4" md="6" xs="12">
          <bank-select
            v-model="model.bank"
            :rules="[rules.required]"
            return-object
          />
        </v-col>

        <v-col lg="3" md="4" xs="9">
          <v-text-field
            label="Agência"
            v-model="model.agency"
            type="number"
            :rules="[rules.required]"
          />
        </v-col>

        <v-col lg="1" md="2" xs="3">
          <v-text-field
            label="Dígito da Agência"
            v-model="model.agency_digit"
          />
        </v-col>

        <v-col lg="3" md="4" xs="9">
          <v-text-field
            label="Conta"
            v-model="model.account"
            :rules="[rules.required]"
          />
        </v-col>

        <v-col lg="1" md="2" xs="3">
          <v-text-field
            label="Dígito da Conta"
            v-model="model.account_digit"
            type="number"
          />
        </v-col>

        <v-col lg="4" md="6" xs="12">
          <v-text-field
            label="Carteira"
            v-model="model.wallet"
            type="number"
            :rules="[rules.required]"
          />
        </v-col>

        <v-col lg="4" md="6" xs="12">
          <v-text-field label="Contrato ou Convênio" v-model="model.contract" />
        </v-col>

        <v-col lg="4" md="6" xs="12">
          <v-select
            label="Status"
            v-model="model.status"
            :items="status_select_items"
            :rules="[rules.exactRequired]"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import FormMixin from "@/components/misc/mixins/FormMixin";
import BankSelect from "@/components/BankSelect/BankSelect.vue";

export default {
  components: { BankSelect },
  name: "BankSaveForm",
  props: {
    oldData: Object
  },
  mixins: [ModelManagementMixin, FormMixin],
  data() {
    return {
      status_select_items: [
        { text: "Ativo", value: 1 },
        { text: "Inativo", value: 0 }
      ],
      loading: false,
      false: false,
      valid: true
    };
  },
  watch: {
    valid() {
      this.$emit("valid", this.valid);
    }
  },
  mounted() {
    this.$refs.form.validate();

    this.$emit("valid", this.valid);
  },
  methods: {}
};
</script>

<style></style>
