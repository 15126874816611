<template>
  <Dialog v-model="model" :loading="loading" :error="error">
    <template #header-title>
      Alterar Data do Navio
    </template>

    <template #content>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <span class="text-caption text--disabled font-weight-bold">
              Viagens a serem alteradas
            </span>

            <arrival-info-list />
          </v-col>

          <v-col cols="12" class="my-4">
            <v-form v-model="valid">
              <date-picker
                label="Nova Data de chegada"
                v-model="new_arrival_date"
                :rules="[rules.required]"
              />
            </v-form>
          </v-col>

          <v-col>
            <v-slide-y-transition>
              <v-container class="pa-0" v-if="altering">
                <v-row>
                  <v-col cols="12" class="d-flex flex-column py-4">
                    <progress-indicator />
                  </v-col>

                  <v-col cols="12">
                    <error-list />
                  </v-col>
                </v-row>
              </v-container>
            </v-slide-y-transition>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #actions>
      <v-spacer />
      <default-cancel-button text custom-function @cancel="closeDialog" />

      <default-save-button
        text
        @click="alterArrivalDateDialog"
        :disabled="!valid || altering"
      >
        Alterar
      </default-save-button>
    </template>
  </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog/Dialog.vue";
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import LoadArrivalInfos from "./domain/LoadArrivalInfos";
import DefaultCancelButton from "@/components/layout/DefaultCancelButton.vue";
import DefaultSaveButton from "@/components/layout/DefaultSaveButton.vue";
import DatePicker from "@/components/date_picker/DatePicker.vue";
import FormMixin from "@/components/misc/mixins/FormMixin";
import ArrivalInfoList from "./components/ArrivalInfoList.vue";
import ProgressIndicator from "./components/ProgressIndicator.vue";
import ErrorList from "./components/ErrorList.vue";
import AlterArrivalDate from "./domain/AlterArrivalDate";
import ArrivalResponseState from "./states/ArrivalResponseState";

export default {
  components: {
    Dialog,
    DefaultCancelButton,
    DefaultSaveButton,
    DatePicker,
    ArrivalInfoList,
    ProgressIndicator,
    ErrorList
  },
  name: "AlterArrivalDateDialogForMany",
  mixins: [ModelManagementMixin, FormMixin],
  props: {
    selected: Array
  },
  computed: {
    data: {
      get() {
        return ArrivalResponseState().state.data;
      },
      set(value) {
        ArrivalResponseState().dispatch("changeData", value);
      }
    }
  },
  data() {
    return {
      altering: false,
      new_arrival_date: null,
      valid: false,
      error: null,
      loading: false
    };
  },
  watch: {
    model() {
      const areOpen = this.model;

      if (areOpen) {
        ArrivalResponseState().dispatch("clearHandle");
        this.altering = false;
        this.error = null;

        const loadArrivalInfos = LoadArrivalInfos.load.bind(this);

        loadArrivalInfos();
      } else {
        ArrivalResponseState().dispatch("clearHandle");

        this.altering = false;
        this.error = null;
      }
    }
  },
  methods: {
    closeDialog() {
      this.model = false;
    },
    alterArrivalDateDialog() {
      const alterArrivalDate = AlterArrivalDate.alter.bind(this);

      alterArrivalDate();
    }
  }
};
</script>

<style></style>
