var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showTable)?_c('v-card',{attrs:{"loading":_vm.loading}},[(!_vm.items.length)?_c('v-container',[_c('v-row',[_c('centralized-message',{scopedSlots:_vm._u([{key:"message",fn:function(){return [_vm._v(" Lista não Gerada ")]},proxy:true},{key:"complemention",fn:function(){return [_vm._v(" Adicione filtros ou clique em visualizar para continuar ")]},proxy:true}],null,false,1686613946)})],1)],1):_c('v-container',{staticClass:"overflow-x-auto",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"show-select":"","headers":_vm.getSelectedHeaders,"fixed-header":"","mobile-breakpoint":0,"items":_vm.items,"height":"calc(100vh - 340px)","items-per-page":_vm.pagination.itemsPerPage,"item-class":_vm.getItemClass,"hide-default-footer":""},on:{"toggle-select-all":_vm.selectAllToggle},scopedSlots:_vm._u([{key:"header.actions",fn:function(){return [_c('invoice-table-header-select',{attrs:{"headers":_vm.headers.all},model:{value:(_vm.headers.selected),callback:function ($$v) {_vm.$set(_vm.headers, "selected", $$v)},expression:"headers.selected"}})]},proxy:true},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"value":isSelected,"readonly":_vm.invoiceVerify.wasUnificated(item),"disabled":_vm.invoiceVerify.wasUnificated(item),"indeterminate":_vm.invoiceVerify.wasUnificated(item),"indeterminate-icon":"mdi-close-box-outline"},on:{"input":function($event){return select($event)}}})]}},{key:"item.result_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" BRL "+_vm._s(_vm.formatedCurrency(item.result_total))+" ")]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getType(item.type))+" ")]}},{key:"item.invoice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.invoice || "Unificado")+" ")]}},{key:"item.invoice_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.invoice_date))+" ")]}},{key:"item.due_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")((item.due_date || "-")))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}},{key:"item.emited_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.emited_date))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(item.status.name))])]}},{key:"item.payer.cnpj_cpf",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getMaskedPayer(item))+" ")]}},{key:"item.computed_discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" BRL "+_vm._s(_vm.formatedCurrency(item.result_entries))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currency)+" "+_vm._s(_vm.formatedCurrency(item.total))+" ")]}},{key:"item.identification",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.getSerializedIdentification(item))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('invoice-table-item-actions',{attrs:{"item":item,"authorization":_vm.authorization}})]}},{key:"item.transaction",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBankOurNumber(item))+" ")]}},{key:"item.currency_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s("BRL " + _vm.formatedCurrency( item.currency_value || item.actual_currency_value, { maximumFractionDigits: 4, minimumFractionDigits: 4 } ))+" ")]}}],null,true),model:{value:(_vm.table.selected),callback:function ($$v) {_vm.$set(_vm.table, "selected", $$v)},expression:"table.selected"}}),_vm._t("pagination")],2)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600"},model:{value:(_vm.dialogChangeStatusToLegal),callback:function ($$v) {_vm.dialogChangeStatusToLegal=$$v},expression:"dialogChangeStatusToLegal"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Alterar Status da Fatura: ")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Alterar o status da fatura "),(_vm.invoiceSelected.payer)?_c('b',[_vm._v(_vm._s(((_vm.invoiceSelected.invoice || "Unificado") + " - " + (_vm.invoiceSelected.payer.name))))]):_vm._e(),_vm._v(" para: ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.optStatus,"label":"Status","item-text":"name","item-value":"id"},model:{value:(_vm.optStatusSelected),callback:function ($$v) {_vm.optStatusSelected=$$v},expression:"optStatusSelected"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){_vm.dialogChangeStatusToLegal = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"green darken-1","disabled":!_vm.optStatusSelected,"text":""},on:{"click":function($event){return _vm.updateInvoiceChangeStatusToLegal(
              _vm.invoiceSelected,
              _vm.optStatusSelected
            )}}},[_vm._v(" Alterar ")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }