import Rest from "../Base/Rest";

/**
 * @typedef {RefundService}
 */
export default class RefundService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/invoice/refund";
}
