<template>
  <v-select
    v-model="selected"
    :items="currencies"
    :loading="loading"
    :readonly="loading"
    label="Moeda"
    multiple
    return-object
    clearable
  />
</template>

<script>
export default {
  name: "CurrencySelectFilter",
  props: {
    value: Array
  },
  data() {
    return {
      selected: this.value,
      currencies: [
        {
          text: "Dólar",
          value: "USD"
        },
        {
          text: "Euro",
          value: "EUR"
        },
        {
          text: "Real",
          value: "BRL"
        }
      ],
      loading: false
    };
  },
  watch: {
    selected() {
      this.$emit("input", this.selected);
    },
    value() {
      this.selected = this.value;
    }
  }
};
</script>

<style></style>
