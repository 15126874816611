<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="pagination.itemsPerPage"
    :hide-default-footer="true"
    :header-props="{ sortIcon: null }"
    class="elevation-0"
  >
    <template #[`item.invoice`]="{ item }">
      {{ getInvoice(item) }}
    </template>

    <template #[`item.invoice_date`]="{ item }">
      {{ item.invoice_date | formatDate }}
    </template>

    <template #[`item.nominal_value`]="{ item }">
      {{ toCurrency(item.net_total - item.total_discount) }}
    </template>

    <template #[`item.amount_paid`]="{ item }">
      {{
        item.credit_date != null
          ? toCurrency(item.net_total - item.total_discount)
          : "0,00"
      }}
    </template>

    <template #[`item.credit_date`]="{ item }">
      {{ getCreditDate(item) }}
    </template>

    <template #[`item.bank_our_number`]="{ item }">
      {{ getBankOurNumber(item) }}
    </template>

    <template #[`item.transaction_created_at`]="{ item }">
      {{ getRecordingDate(item) }}
    </template>

    <template #[`item.bank`]="{ item }">
      {{ getBank(item) }}
    </template>

    <template #[`item.status_desc`]="{ item }">
      {{ getStatus(item) }}
    </template>

    <template #[`item.invoice_status`]="{ item }">
      {{ item.status_name }}
    </template>

    <template #[`item.days_overdue`]="{ item }">
      {{ getDaysOverdue(item) }}
    </template>

    <template #[`item.action`]="{ item }">
      <v-btn
        icon
        @click="download(item)"
        :loading="item.loading"
        :disabled="doesntHaveBankReturnFileToDownload(item)"
      >
        <v-icon>
          mdi-download
        </v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "BankReturnTable",
  props: {
    items: Array,
    pagination: Object
  },
  data() {
    return {
      headers: [
        { text: "Pagador", value: "payer_name" },
        { text: "Fatura", value: "invoice" },
        { text: "Nosso Número", value: "bank_our_number" },
        { text: "Identificador da Cobrança (PIX)", value: "txid" },
        { text: "Status da Fatura", value: "invoice_status" },
        { text: "Status Bancário", value: "status_desc" },
        { text: "Valor Pago", value: "amount_paid" },
        { text: "Valor do Título", value: "nominal_value" },
        { text: "Data da Emissão", value: "invoice_date" },
        { text: "Data do Movimento", value: "transaction_created_at" },
        { text: "Data do Pagamento", value: "credit_date" },
        { text: "Dias Vencidos", value: "days_overdue" },
        { text: "Banco", value: "bank" },
        { text: "", value: "action" }
      ]
    };
  },
  methods: {
    getInvoice(item) {
      if (!item.invoice) {
        return "UNIFICADO";
      }

      return item.invoice;
    },
    doesntHaveBankReturnFileToDownload(item) {
      if (!item || !item.file_name) {
        return true;
      }

      return false;
    },
    download(item) {
      this.$emit("download", item);
    },
    handleInvoiceReturn(item) {
      if (!item) {
        return "not found";
      }

      return item.type_transaction;
    },
    getDaysOverdue(item) {
      if (item.days_overdue <= 0) {
        return "0 Dias";
      }

      return `${item.days_overdue} Dias`;
    },
    getStatus(item) {
      if (this.handleInvoiceReturn(item) == "not found") {
        return "NÃO ENCONTRADO";
      }

      if (!this.handleInvoiceReturn(item)) {
        return "";
      }

      if (item.type_transaction == "billet") {
        return item.status ? `${item.status_desc} (${item.status_cod})` : "";
      } else {
        return item.status ? item.status : "";
      }
    },
    getMessage(item) {
      if (this.handleInvoiceReturn(item) == "not found") {
        return "NÃO ENCONTRADO";
      }

      if (!this.handleInvoiceReturn(item)) {
        return "";
      }

      if (item.type_transaction == "billet") {
        return item.message;
      } else {
        return "";
      }
    },
    getRecordingDate(item) {
      if (
        this.handleInvoiceReturn(item) == "not found" ||
        !this.handleInvoiceReturn(item)
      ) {
        return "";
      }

      return this.formatMysqlDate(item.transaction_created_at);
    },
    getCreditDate(item) {
      if (
        this.handleInvoiceReturn(item) == "not found" ||
        !this.handleInvoiceReturn(item)
      ) {
        return "";
      }

      return this.formatMysqlDate(item.credit_date);
    },
    getBankOurNumber(item) {
      return item.status_refs == "unified"
        ? item.father_bank_our_number
        : item.bank_our_number;
    },
    getBank(item) {
      if (!item.bank_account) {
        return "";
      }

      return `${item.bank_code} - ${item.agency} - ${item.bank_account}`;
    }
  }
};
</script>

<style></style>
