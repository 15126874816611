<template>
  <v-card class="overflow-x-hidden">
    <v-card-title>
      <slot name="title">
        <span class="text-headline"> </span>
      </slot>
    </v-card-title>

    <v-card-text>
      <slot name="content">
        <div class="pa-6 d-flex flex-column text-center">
          <v-icon x-large class="flex-item text-h2" :color="options.status">
            {{ iconByStatus }}
          </v-icon>
          <span
            class="flex-item text-h6 font-weight-regular text--primary mt-3"
          >
            {{ options.title }}
          </span>
          <span class="flex-item text-subtitle-1 text--secondary">
            {{ options.message }}
          </span>
        </div>
      </slot>
    </v-card-text>

    <v-divider />
    <v-card-actions>
      <slot name="actions">
        <v-spacer />

        <v-btn @click="dispose" text class="text--secondary">
          <span class="font-weight-medium">
            {{ getDisposeText() }}
          </span>
        </v-btn>

        <v-btn
          text
          :color="options.status == 'question' ? 'primary' : options.status"
          class="font-weight-bold"
          v-if="options.continue"
          @click="confirm"
        >
          {{ options.continueText || "Confirmar" }}
        </v-btn>
      </slot>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "NotificationDialog",
  props: {
    options: Object
  },
  methods: {
    dispose() {
      this.$emit("dispose");

      if (this.options.dispose) {
        this.options.dispose();
      }
    },
    confirm() {
      this.$emit("dispose");

      if (this.options.continue) {
        this.options.continue();
      }
    },
    getDisposeText() {
      if (this.options.continue) {
        return this.options.disposeText || "Cancelar";
      }

      return "Confirmar";
    }
  },
  computed: {
    iconByStatus() {
      switch (this.options.status) {
        case "success":
          return "mdi-check";
        case "error":
          return "mdi-close";
        case "warning":
          return "mdi-alert-circle-outline";
        case "question":
          return "mdi-help";
        default:
          return "";
      }
    }
  }
};
</script>

<style></style>
