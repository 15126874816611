import Payer from "@/pages/Payer/Payer.vue";
import PayerRecord from "@/pages/Payer/PayerRecord.vue";

import PayerGroup from "@/pages/PayerGroup/PayerGroup.vue";
import PayerGroupSave from "@/pages/PayerGroupSave/PayerGroupSave.vue";

const routes = [
  {
    description: "Núcleo de Pagadores",
    path: "/group",
    name: "PayerGroup",
    group_type: "registers",
    component: PayerGroup
  },
  {
    path: "/group/:id",
    name: "PayerGroupSave",
    component: PayerGroupSave
  },
  {
    description: "Pagadores",
    path: "/payer",
    name: "Payer",
    group_type: "registers",
    component: Payer
  },
  {
    path: "/payer/:id?",
    name: "PayerRecord",
    component: PayerRecord
  }
];

export default routes;
