<template>
  <v-select
    label="Estado"
    v-model="model"
    :items="items"
    item-text="name"
    item-value="uf"
    :loading="loading"
    :rules="rules != 'undefined' ? rules : []"
    data-cy="state_select"
  />
</template>

<script>
import StateService from "@/app/Services/StateService";
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";

export default {
  name: "StateSelect",
  props: {
    rules: Object
  },
  data() {
    return {
      loading: false,
      saving: false
    };
  },
  mixins: [ModelManagementMixin],
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;

        const response = await StateService.build().search();

        this.items = response;
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
