import Rest from "../Base/Rest";

/**
 * @typedef {AccountBankService}
 */
export default class AccountBankService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/bank";
}
