<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="vh-100 vw-100 d-flex ">
        <main-loading v-if="loading" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PrePayerService from "@/app/Services/PrePayerService";
import MainLoading from "@/components/misc/MainLoading.vue";
import { bus } from "@/main";

export default {
  components: { MainLoading },
  name: "HandlePreUser",
  data() {
    return {
      hash: "",
      loading: true
    };
  },
  created() {
    this.hash = this.$route.params.hash;

    setTimeout(() => {
      this.getResponseFromHandle();
    }, 1000);
  },
  methods: {
    async getResponseFromHandle() {
      try {
        if (this.hash == "") {
          return;
        }

        this.loading = true;

        const route = this.getServiceRoute();

        await PrePayerService.build().send({ hash: this.hash }, route);
        this.hash = "";
        this.showSuccessDialog();
      } catch (error) {
        this.showErrorDialog(error);
      } finally {
        this.loading = false;
      }
    },
    showSuccessDialog() {
      const desc = this.isToAllow() ? "ativado" : "removido";

      bus.$emit("showNotificationDialog", {
        status: "success",
        message:
          "Um email foi enviado e logo o usuário poderá acessar o sistema",
        title: `Usuário ${desc} com sucesso`,
        dispose: this.goHome
      });
    },
    showErrorDialog(error) {
      const desc = this.isToAllow() ? "ativar" : "remover";

      bus.$emit("showNotificationDialog", {
        status: "error",
        message: this.getExceptionResponseMessage(error),
        title: `Ocorreu um erro ao ${desc} o usuário`,
        dispose: this.goHome
      });
    },
    getServiceRoute() {
      if (this.isToAllow()) {
        return "allow-user";
      }

      return "discard-user";
    },
    goHome() {
      this.$router.push({ name: "Home" });
    },
    isToAllow() {
      return this.$route.path.indexOf("allow") != -1;
    }
  }
};
</script>

<style></style>
