<template>
  <v-container class="pa-0 mb-4">
    <v-row no-gutters>
      <v-col sm="12" class="text-caption font-weight-bold">
        Unificações
      </v-col>

      <v-col
        sm="12"
        class="flex-column d-flex flex-nowrap mh-vh-50 overflow-y-auto"
      >
        <related-invoice-tile
          v-for="(unification, index) in invoice.unifications"
          :key="unification.id"
          :payer="invoice.payer.name"
          :invoice="unification.invoice"
          :currency="unification.currency"
          :value="unification.total"
          :zebra="index % 2 == 0"
          @click="goToUnified(unification)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RelatedInvoiceTile from "./RelatedInvoiceTile.vue";
import InvoiceVerify from "@/pages/Invoice/domain/InvoiceVerify";

const invoiceTools = new InvoiceVerify();

export default {
  components: { RelatedInvoiceTile },
  name: "UnificatorInfo",
  props: {
    invoice: Object
  },
  methods: {
    goToUnified(unification) {
      const memorySetUnification = { ...unification };

      this.$emit("goToUnified", memorySetUnification);
    },
    getTotal(unification) {
      return invoiceTools.getTotalWithDiscounts(unification);
    }
  }
};
</script>
