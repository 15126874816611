var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0 mt-3 w-100",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.date))+" ")]}},{key:"item.sales_tax",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.sales_tax))+" ")]}},{key:"item.sales_ptax",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.getPtax(item.sales_tax)))+" ")]}},{key:"item.buy_tax",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.buy_tax))+" ")]}},{key:"item.buy_ptax",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(_vm.getPtax(item.buy_tax)))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('authorization-gate',{staticClass:"my-auto",attrs:{"auth-route":"/exchange","auth-type":"write"}},[(item.type == 'MANUAL')?_c('exchange-table-actions',{attrs:{"id":item.id},on:{"edit":_vm.edit,"remove":_vm.remove}}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }