<template>
  <v-dialog v-model="dialog" width="60%">
    <v-card :loading="loading" v-if="render">
      <v-container fluid>
        <v-row>
          <v-col cols="12" xs="12">
            <span class="text-h5 flex-item">
              Alterar Data do Navio
            </span>
          </v-col>

          <v-col sm="12" v-if="!error">
            <v-divider />
          </v-col>
        </v-row>

        <v-row v-if="loading">
          <centralized-message>
            <template #message>
              Carregando...
            </template>
          </centralized-message>
        </v-row>

        <v-row v-else-if="error">
          <centralized-message>
            <template #message>
              {{ error.message }}
            </template>

            <template #complemention>
              {{ error.complemention }}
            </template>
          </centralized-message>
        </v-row>

        <v-row v-else class="mt-n6">
          <v-col cols="12">
            <arrival-info :invoice="invoice" />
          </v-col>

          <v-col cols="12">
            <v-form ref="form" v-model="valid">
              <v-container fluid class="mx-0 px-0">
                <v-row>
                  <v-col cols="12">
                    <date-picker
                      label="Data de chegada"
                      readonly
                      v-model="invoice.old_arrival_date"
                      v-if="
                        invoice.old_arrival_date &&
                          invoice.old_arrival_date !== 'Invalid DateTime'
                      "
                    />

                    <v-text-field
                      v-else
                      append-icon="mdi-calendar"
                      readonly
                      label="Data de Chegada"
                      value="Não Definido"
                    />
                  </v-col>

                  <v-col cols="12">
                    <date-picker
                      label="Nova Data de chegada"
                      v-model="invoice.arrival_date"
                      :rules="[rules.required]"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-col>

          <v-col sm="12" class="mt-6 d-flex flex-row">
            <v-spacer />

            <v-btn class="font-weight-bold" @click="cancel" text color="text">
              Fechar
            </v-btn>

            <v-btn
              class="font-weight-bold"
              color="primary"
              text
              @click="alterArrivalDate"
              :loading="altering"
              :disabled="!valid || this.error"
            >
              Alterar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { bus } from "@/main";
import InvoiceVerify from "@/pages/Invoice/domain/InvoiceVerify";
import InvoiceService from "@/app/Services/InvoiceService";
import DatePicker from "../../date_picker/DatePicker.vue";
import FormMixin from "../../misc/mixins/FormMixin";
import CentralizedMessage from "@/components/misc/CentralizedMessage.vue";
import ArrivalInfo from "../components/ArrivalInfo.vue";

const invoiceVerify = new InvoiceVerify();

export default {
  components: { DatePicker, CentralizedMessage, ArrivalInfo },
  name: "AlterArrivalDialog",
  data() {
    return {
      dialog: false,
      loading: false,
      invoice: {},
      valid: false,
      altering: false,
      render: true,
      error: null
    };
  },
  created() {
    bus.$on("alter_arrival_dialog_show", this.showDialog);
  },
  mixins: [FormMixin],
  watch: {},
  methods: {
    showDialog(invoice) {
      this.invoice = {};
      this.dialog = true;
      this.error = null;

      const result = this.validate(invoice);

      if (result) {
        this.invoice = { ...invoice };
        this.invoice.old_arrival_date = this.invoice.arrival_date;
      }

      this.render = false;

      this.$nextTick(() => {
        this.render = true;
      });
    },
    validate(invoice) {
      const alreadyDefined =
        invoiceVerify.billOrPixGenerated(invoice) ||
        invoiceVerify.alreadyPayed(invoice);

      const isUnified =
        invoiceVerify.wasUnificated(invoice) ||
        invoiceVerify.isUnificationInvoice(invoice);

      if (isUnified) {
        this.error = {
          message: "Fatura unificada",
          complemention:
            "A data do navio só pode ser alterada em faturas não unificadas"
        };

        return false;
      }

      if (alreadyDefined) {
        this.error = {
          message: "Fatura já gerada",
          complemention:
            "A data do navio só pode ser alterada para faturas ainda em aberto"
        };

        return false;
      }

      return true;
    },
    async alterArrivalDate() {
      try {
        this.altering = true;

        const arrivalDateRequest = {
          bl: this.invoice.bl,
          ship: this.invoice.ship,
          travel: this.invoice.travel,
          landing_point_name: this.invoice.landing_point_name,
          date: this.invoice.arrival_date
        };

        await InvoiceService.build().store(
          arrivalDateRequest,
          "change-ship-date"
        );

        this.showToast({ message: "Data do Navio alterada com sucesso" });

        this.dialog = false;
        this.$emit("altered");
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.altering = false;
      }
    },
    cancel() {
      this.dialog = false;

      this.$emit("altered");
    }
  }
};
</script>

<style></style>
