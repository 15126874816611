<template>
  <v-tooltip bottom>
    <template #activator="{ on, attrs }">
      <span
        v-on="on"
        v-bind="attrs"
        :class="oneLine ? 'one-line' : 'line-clamping'"
        class="audit-table-text--limiter d-flex"
      >
        {{ text || "NULL" }}
      </span>
    </template>

    <span class="audit-table-span">
      {{ text }}
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: "LongTextHover",
  props: {
    text: String,
    oneLine: Boolean
  }
};
</script>

<style>
.line-clamping {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.one-line {
  -webkit-line-clamp: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.audit-table-text--limiter {
  max-width: 400px;
  overflow: hidden;
}

.audit-table-span {
  max-width: 600px;
  line-break: anywhere;
  display: flex;
}
</style>
