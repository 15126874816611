<template>
  <v-row align="center">
    <v-col md="5" xs="12">
      <v-autocomplete
        v-model="user"
        :items="users"
        :loading="searchUserLoading"
        :search-input.sync="searchUser"
        item-text="name"
        item-value="id"
        label="Adicionar Usuário"
        placeholder="Digite para pesquisar..."
        clearable
        prepend-icon="mdi-magnify"
        return-object
      >
        <template v-slot:item="{ item }">
          {{ item.name }} ({{ item.email }})
        </template>
      </v-autocomplete>
    </v-col>
    <v-col md="2" xs="2">
      <v-btn @click="addDiscountUser" :loading="registering" small>
        Adicionar
      </v-btn>
    </v-col>
    <v-col cols="12" md="12" xs="12">
      <v-data-table
        :headers="headers"
        :items="groupUsers"
        loading-text="Aguarde...carregando"
        :hide-default-footer="true"
        disable-pagination
        class="elevation-0"
        :header-props="{ sortIcon: null }"
        :loading="loading"
      >
        <template #[`item.action`]="{ item }">
          <default-remove-button @click="removeUser(item)" />
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import FormMixin from "@/components/misc/mixins/FormMixin";
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";
import InvoicesDiscountsTypesService from "@/app/Services/InvoicesDiscountsTypesService";
import UserService from "@/app/Services/UserService";
import DefaultRemoveButton from "@/components/layout/DefaultRemoveButton.vue";
import { bus } from "@/main";
import debounce from "debounce";
// import DiscountApprovalGroupsService from "@/app/Services/DiscountApprovalGroupsService";

// import { bus } from "@/main";

export default {
  name: "DiscountApprovalGroupsUsers",
  mixins: [FormMixin, AuthorizationMixin],
  components: {
    DefaultRemoveButton
  },
  async created() {
    await this.getApprovalGroupUser();
  },
  props: {
    approvalGroup: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      user: null,
      users: [],
      searchUser: null,
      searchUserLoading: false,
      loading: true,
      groupUsers: [],
      headers: [
        {
          text: "Id",
          align: "center",
          sortable: false,
          value: "user.id",
          width: 15
        },
        {
          text: "Tipo",
          align: "start",
          sortable: false,
          value: "user.name"
        },
        {
          text: "E-mail",
          align: "start",
          sortable: false,
          value: "user.email"
        },
        {
          text: "Ações",
          sortable: false,
          value: "action",
          align: "center",
          width: 15
        }
      ]
    };
  },
  methods: {
    async addDiscountUser() {
      if (this.user) {
        try {
          this.loading = true;
          const user = {
            id_user: this.user.id,
            id_group: this.approvalGroup.id
          };

          await InvoicesDiscountsTypesService.build().storeApprovalGroupUser(
            user
          );
          await this.getApprovalGroupUser();

          this.loading = false;
          this.user = null;
          this.users = [];
        } catch (error) {
          this.error = this.getExceptionResponseMessage(error);
          this.defaultCatchError(error);
        } finally {
          this.searchUserLoading = false;
          this.loading = false;
        }
      }
    },
    async getApprovalGroupUser() {
      try {
        this.groupUsers = [];
        this.loading = true;

        const response = await InvoicesDiscountsTypesService.build().getApprovalGroupUser(
          this.approvalGroup.id
        );

        this.groupUsers = response;
      } catch (error) {
        this.error = this.getExceptionResponseMessage(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    removeUser(item) {
      bus.$emit("showNotificationDialog", {
        status: "error",
        title: `Deseja remover esse usuário do grupo: "${item.user.name}"`,
        continue: async () => {
          try {
            await InvoicesDiscountsTypesService.build().deleteApprovalGroupUser(
              item.id
            );
            await this.getApprovalGroupUser();
          } catch (error) {
            this.error = this.getExceptionResponseMessage(error);
            this.defaultCatchError(error);
          } finally {
            this.loading = false;
          }
        }
      });
    }
  },
  watch: {
    searchUser: debounce(async function(val) {
      try {
        if (this.searchUserLoading) {
          return;
        }

        // const nIds = this.groupUsers.map(type => type.id_type);

        const query = {
          params: {
            per_page: -1,
            filter: {
              name_like: val
              // type_not_in: nIds
            }
          }
        };

        this.searchUserLoading = true;

        const response = await UserService.build().index({
          query
        });

        this.users = response.data;
      } catch (error) {
        this.error = this.getExceptionResponseMessage(error);
        this.defaultCatchError(error);
      } finally {
        this.searchUserLoading = false;
      }
    }, 700)
  }
};
</script>

<style></style>
