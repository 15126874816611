import Exchange from "@/pages/Exchange/Exchange.vue";
import ExchangeSave from "@/pages/ExchangeSave/ExchangeSave.vue";
import Holiday from "@/pages/Holiday/Holiday.vue";
import HolidaySave from "@/pages/HolidaySave/HolidaySave.vue";

const routes = [
  {
    description: "Câmbio",
    path: "/exchange",
    name: "Exchange",
    group_type: "registers",
    component: Exchange
  },
  {
    path: "/exchange/:id",
    name: "ExchangeSave",
    component: ExchangeSave
  },
  {
    description: "Feriados",
    path: "/holiday",
    name: "Holiday",
    group_type: "registers",
    component: Holiday
  },
  {
    path: "/holiday/:id",
    name: "HolidaySave",
    component: HolidaySave
  }
];

export default routes;
