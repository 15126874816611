import Rest from "../Base/Rest";

/**
 * @typedef {EmittedBilletService}
 */
export default class EmittedBilletService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/reports/emitted-billet";
}
