import Rest from "@/app/Base/Rest";

/**
 * @typedef {WriteOffPaymentService}
 */
export default class WriteOffPaymentService extends Rest {
  /**
   * @type {String}
   */
  static resource = "invoice/write-off-payment";
}
