<template>
  <v-container class="pa-0 mb-4">
    <v-row no-gutters>
      <v-col sm="12" class="text-caption font-weight-bold">
        Informações da Fatura
      </v-col>

      <v-col sm="12" class="flex-row d-flex flex-wrap pa-2">
        <extra-info-tile>
          <template #title>
            Nº Fatura
          </template>

          <template #message>
            {{ invoice.invoice }}
          </template>
        </extra-info-tile>

        <extra-info-tile>
          <template #title>
            BL
          </template>

          <template #message>
            {{ invoice.bl }}
          </template>
        </extra-info-tile>

        <extra-info-tile>
          <template #title>
            Booking
          </template>

          <template #message>
            {{ invoice.booking }}
          </template>
        </extra-info-tile>

        <extra-info-tile>
          <template #title>
            Data de Saída
          </template>

          <template #message>
            {{ invoice.departure_date | formatDate }}
          </template>
        </extra-info-tile>

        <extra-info-tile>
          <template #title>
            Porto de Embarque
          </template>

          <template #message>
            {{ invoice.boarding_point_name }}
          </template>
        </extra-info-tile>

        <extra-info-tile>
          <template #title>
            Navio
          </template>

          <template #message>
            {{ invoice.ship }}
          </template>
        </extra-info-tile>

        <extra-info-tile>
          <template #title>
            Viagem
          </template>

          <template #message>
            {{ invoice.travel }}
          </template>
        </extra-info-tile>

        <extra-info-tile>
          <template #title>
            Data de Chegada
          </template>

          <template #message>
            {{ invoice.arrival_date | formatDate }}
          </template>
        </extra-info-tile>

        <extra-info-tile>
          <template #title>
            Porto de Desembarque
          </template>

          <template #message>
            {{ invoice.landing_point_name }}
          </template>
        </extra-info-tile>

        <extra-info-tile
          v-if="
            invoice.valid_customer_procedure_discount === true &&
              invoice.payer.procedure_discount
          "
        >
          <template #title>
            <div class="d-flex align-center">
              <span class="text-bold">Desconto de Procedimento (%)</span>

              <!-- Popover Menu -->
              <v-menu top offset-y nudge-width="auto" max-width="700">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="ml-2">
                    mdi-information-outline
                  </v-icon>
                </template>

                <v-card>
                  <v-list>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >Desconto de Procedimento</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>

                  <v-divider></v-divider>

                  <v-card-text>
                    <p>
                      A aplicação do
                      <strong>Desconto de Procedimento</strong> será realizada
                      exclusivamente no momento da geração do Boleto ou PIX.
                    </p>
                    <p>
                      O direito ao desconto está sujeito a condições, que variam
                      conforme a data de emissão.
                    </p>
                    <p>
                      Caso o desconto tenha sido previamente concedido, mas a
                      fatura não seja quitada, o valor do desconto será
                      recalculado na próxima geração do Boleto ou PIX.
                    </p>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="menu = false">
                      OK
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </div>
          </template>

          <template #message>
            {{ invoice.payer.procedure_discount }}%
          </template>
        </extra-info-tile>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ExtraInfoTile from "./ExtraInfoTile.vue";

export default {
  components: {
    ExtraInfoTile
  },
  name: "InvoiceInfo",
  props: {
    invoice: Object
  },
  methods: {}
};
</script>

<style></style>
