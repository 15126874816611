<template>
  <v-combobox
    label="Justificativa"
    color="primary"
    :items="items"
    item-text="justification"
    item-value="justification"
    :search-input="searchInput"
    :loading="loading"
    :rules="rules != 'undefined' ? rules : []"
    v-model="selected"
    data-cy="refund_select"
    @blur="selectByWriteOnSearch"
    :readonly="readonly"
    :clearable="!readonly"
  />
</template>

<script>
import RefundReasonService from "@/app/Services/RefundReasonService";

export default {
  name: "RefundReasonSelect",
  props: {
    value: Object,
    rules: Array,
    readonly: Boolean
  },
  data() {
    return {
      selected: this.value,
      items: [],
      loading: false,
      searchInput: "",
      timeoutVar: null,
      debounceSearch: this.$utils.registerDebounce(
        this.search,
        1000,
        this.timeoutVar
      )
    };
  },
  created() {
    this.search();
  },
  watch: {
    searchInput() {
      // esse servico deve trazer todos os dados na primeira busca, portanto o debounce nao eh utilizado
      if (this.items.length > 0) {
        return;
      }

      this.loading = true;

      this.debounceSearch(this.searchInput);
    },
    selected() {
      this.$emit("input", this.selected);
    },
    value() {
      const haveValuesForSelectAtFirstTime = this.value && !this.items.length;

      if (haveValuesForSelectAtFirstTime) {
        this.searchInput = `${this.value}`;

        this.items = [...this.items, { justification: this.value }];
      }

      this.selected = this.value;
    }
  },
  methods: {
    selectByWriteOnSearch(event) {
      const writed = event.target.value;

      if (this.selected != writed) {
        this.selected = `${writed}`;
      }
    },
    async search(value = "") {
      try {
        const query = {
          params: {
            filter: {}
          }
        };

        this.loading = true;

        if (value) {
          query.params.filter.name = value;
        }

        const response = await RefundReasonService.build().index({ query });

        this.items = [...this.items, ...response.data];
      } catch (error) {
        this.error = this.getExceptionResponseMessage(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
