import Rest from "../Base/Rest";

/**
 * @typedef {DiscountReasonService}
 */
export default class DiscountReasonService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/invoice/refund-reason";
}
