import Rest from "../Base/Rest";

/**
 * @typedef {PrePayerService}
 */
export default class PrePayerService extends Rest {
  /**
   * @type {String}
   */
  static resource = "pre-payer/";

  load(params) {
    if (!params.name && !params.cnpj) {
      throw new Error("Nenhum Filtro Definido");
    }

    return this.get("api/" + PrePayerService.resource, { params });
  }
}
