<template>
  <input
    ref="file_input"
    v-show="false"
    :value="files"
    :accept="acceptedFormats"
    @change="onFileChange"
    type="file"
  />
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import FileValidator from "../domain/FileValidator";

export default {
  name: "ImageUploadInput",
  mixins: [ModelManagementMixin],
  data() {
    return {
      files: null,
      acceptedFormats: "image/png, image/jpeg"
    };
  },
  methods: {
    selectFile() {
      this.$refs.file_input.click();
    },
    onFileChange(event) {
      try {
        const [file] = event.target.files;

        FileValidator.validate(file);

        this.selectedFile = file;

        this.$emit("input", this.selectedFile);
      } catch (error) {
        this.defaultCatchError(error);
      }
    }
  }
};
</script>

<style></style>
