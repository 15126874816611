<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12">
        <bank-account-management-table
          @edit="edit"
          @create="create"
          @changeStatus="changeStatus"
          @remove="triggerRemoveFlow"
          :items="items"
          :pagination="pagination"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PaginationMixin from "@/components/pagination/PaginationMixin";

import BankAccountManagementTable from "./BankAccountManagementTable.vue";

import CompanyState from "@/components/Company/State";

import { bus } from "@/main";

export default {
  name: "BankAccountManagement",
  components: {
    BankAccountManagementTable
  },
  props: {
    company: Object
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    items() {
      const items = CompanyState().state.accounts || [];

      return items.filter(item => !item.removed);
    }
  },
  mixins: [PaginationMixin],
  methods: {
    /**
     * Actions
     */
    create() {
      CompanyState().dispatch("updateAccountEditingStatus", true);

      this.$router.push({
        name: "BankAccountSave",
        params: { id: "new", company_id: this.company.id }
      });
    },
    edit(item) {
      CompanyState().dispatch("updateAccountEditingStatus", true);

      this.$router.push({
        name: "BankAccountSave",
        params: { id: item.id, company_id: this.company.id }
      });
    },
    getQuery() {
      return {
        params: {
          ...this.pagination
        }
      };
    },
    async triggerRemoveFlow(item) {
      const accounts = CompanyState().state.accounts;

      if (item.status == 1) {
        this.showCannotRemoveActiveBankNotification();

        return;
      }

      if (accounts.length == 1) {
        this.showCannotRemoveLastBankNotification();

        return;
      }

      const result = await this.openRemoveDialog(item);

      if (result) {
        this.remove(item);
      }
    },
    remove(item) {
      const accounts = CompanyState().state.accounts;

      const accountWithoutRemoved = accounts.map(account => {
        if (account.id == item.id) {
          account.removed = true;
        }

        return account;
      });

      CompanyState().dispatch("updateAccounts", accountWithoutRemoved);

      this.showSuccessRemoveNotification();
    },
    showCannotRemoveActiveBankNotification() {
      this.showToast({
        message: "Não é possível remover sua conta ativa",
        status: "error"
      });
    },
    showCannotRemoveLastBankNotification() {
      this.showToast({
        message: "É necessário possuir ao menos uma conta ao beneficiário",
        status: "error"
      });
    },
    changeStatus(item) {
      if (item.status) {
        this.alterAllAnotherAccountBankStatusToOff(item);
      }
    },
    alterAllAnotherAccountBankStatusToOff(handle) {
      const accounts = CompanyState().state.accounts;

      const updatedAccounts = accounts.map(account => {
        if (account.id != handle.id && account.bank.id == handle.bank.id) {
          account.status = 0;
        }

        if (!account.created) {
          account.edited = true;
        }

        return account;
      });

      CompanyState().dispatch("updateAccounts", updatedAccounts);
    },
    /**
     *
     * DIALOGOS & MENSAGENS
     *
     */
    openRemoveDialog() {
      return new Promise((resolve, reject) => {
        bus.$emit("showDialog", {
          status: "error",
          title: "Deseja Remover Essa Conta?",
          continue: () => resolve(true),
          dispose: () => reject(false)
        });
      });
    },
    showSuccessRemoveNotification() {
      this.showToast({ message: "Conta removida com sucesso" });
    }
  }
};
</script>

<style></style>
