import Rest from "../Base/Rest";

/**
 * @typedef {LoginRecoverService}
 */
export default class LoginRecoverService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/auth/login-recover";
}
