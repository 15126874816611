import Rest from "../Base/Rest";

/**
 * @typedef {DiscountService}
 */
export default class DiscountService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/reports/discount";
}
