<template>
  <v-menu
    ref="dateMenu"
    v-model="menu"
    :close-on-content-click="true"
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="290px"
    :data-cy="`${dataCy}_menu`"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="formattedDate"
        :label="label || 'Data'"
        :append-icon="appendIcon || 'mdi-calendar'"
        :clearable="clearable"
        @click:clear="date = null"
        @keydown.enter="onEnter"
        placeholder=""
        :readonly="readonly"
        :loading="loading"
        v-bind="readonly ? null : attrs"
        v-on="readonly ? null : on"
        v-mask="'##/##/####'"
        :rules="rules != 'undefined' ? rules : []"
        :data-cy="`${dataCy}_input`"
        :class="className"
      />
    </template>

    <v-date-picker
      v-model="date"
      @input="menu = false"
      :data-cy="`${dataCy}_picker`"
    />
  </v-menu>
</template>

<script>
export default {
  name: "DatePicker",
  data() {
    return {
      date: this.value,
      menu: false
    };
  },
  props: {
    clearable: Boolean,
    label: String,
    value: String,
    rules: Array,
    loading: Boolean,
    appendIcon: String,
    readonly: Boolean,
    dataCy: String,
    className: String
  },
  computed: {
    formattedDate: {
      get() {
        if (!this.date) {
          return null;
        }

        const result = this.formatMysqlDate(this.date);

        return result;
      },
      set(value) {
        try {
          const result = this.toSystemDate(value);

          this.date = result;
        } catch (e) {
          this.date = null;
        }
      }
    }
  },
  watch: {
    date() {
      this.$emit("input", this.date);
    },
    value() {
      this.date = this.value;
    }
  },
  methods: {
    onEnter() {
      this.$emit("onEnter", this.value);
    }
  }
};
</script>

<style lang="scss">
.v-date-picker-table .v-btn {
  font-weight: 400 !important;
}
</style>
