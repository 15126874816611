<template>
  <v-navigation-drawer
    clipped
    permanent
    expand-on-hover
    app
    @transitionend="collapseAll"
    mini-variant-width="73px"
    class="bg-default secondary--text h-100"
    data-cy="navigation_drawer"
    v-model="drawer"
  >
    <v-list dense>
      <drawer-item
        v-for="(access, i) in nonGroupedAccesses"
        :key="i"
        :access="access"
      />

      <drawer-grouper
        v-for="(grouper, i) in withoutEmptyGroupedMenus"
        :key="i"
        :id="i"
        :accesses="grouper.accesses"
        :name="grouper.name"
        :icon="grouper.icon"
        :active="grouper.active"
      />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import Accesses from "./domain/accesses";
import DrawerGrouper from "./components/DrawerGrouper.vue";
import DrawerItem from "./components/DrawerItem.vue";

export default {
  name: "Drawer",
  components: {
    DrawerGrouper,
    DrawerItem
  },
  computed: {
    withoutEmptyGroupedMenus() {
      if (this.groupedAccesses == {} || !this.groupedAccesses) {
        return [];
      }

      for (const [key, grouper] of Object.entries(this.groupedAccesses)) {
        if (grouper.accesses.length < 1) {
          delete this.groupedAccesses[key];
        }

        grouper.active = false;
      }

      return this.groupedAccesses;
    }
  },
  created() {
    this.nonGroupedAccesses = [];
    this.groupedAccesses = {};

    this.accesses = new Accesses();

    this.accesses.loadAccesses(this.$store);

    this.nonGroupedAccesses = this.accesses.nonGroupedAccesses;
    this.groupedAccesses = this.accesses.groupedAccesses;
  },
  mounted() {},
  data() {
    return {
      roles: [],
      accesses: null,
      nonGroupedAccesses: [],
      groupedAccesses: {},
      drawer: true
    };
  },
  methods: {
    drawerReload() {
      this.nonGroupedAccesses = [];
      this.groupedAccesses = {};

      this.accesses.loadAccesses(this.$store);

      this.nonGroupedAccesses = this.accesses.nonGroupedAccesses;
      this.groupedAccesses = this.accesses.groupedAccesses;
    },
    collapseAll() {
      if (!this.groupedAccesses || this.groupedAccesses.length < 1) {
        return;
      }

      Object.keys(this.groupedAccesses).forEach(group => {
        this.groupedAccesses[group].active = false;
      });

      this.$forceUpdate();
    }
  }
};
</script>

<style>
.router-link-active {
  color: rgb(255, 99, 39) !important;
}
.router-link-active::before {
  color: rgb(255, 99, 39) !important;
}

.v-navigation-drawer__content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #e7e7e7;
  background-color: #e7e7e7;
}
.v-navigation-drawer__content::-webkit-scrollbar {
  width: 0px;
}
.v-navigation-drawer__content::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px #c8c8c8;
  background-color: #c8c8c8;
}
</style>
