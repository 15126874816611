import InvoiceService from "@/app/Services/InvoiceService";

const InvoiceSearch = {
  async search(invoices) {
    try {
      this.loading = true;

      if (!invoices || !invoices.length) {
        this.error = "Nenhuma fatura definida no arquivo";
      }

      this.searchedInvoices = invoices;

      const getApiQueryRequest = this.InvoiceSearch.getApiQueryRequest.bind(
        this
      );

      const query = getApiQueryRequest(invoices);

      const response = await InvoiceService.build().index({
        query,
        url: "all"
      });

      this.data = response.data;
    } catch (error) {
      this.defaultCatchError(error);

      this.error = "Ocorreu um problema ao carregar as faturas";
    } finally {
      this.loading = false;
    }
  },

  async getInvoices(item) {
    const response = await InvoiceService.build().show(
      `write-off-payment/${item.id}`
    );

    return response.data;
  },

  getApiQueryRequest(invoices) {
    return {
      params: {
        filter: {
          invoices
        }
      }
    };
  }
};

export default InvoiceSearch;
