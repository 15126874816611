<template>
  <v-form v-model="valid" ref="form">
    <v-container fluid>
      <v-row>
        <v-col md="6" xs="12">
          <v-select
            label="Moeda"
            v-model="exchange.currency"
            placeholder="Selecione..."
            :items="currencies"
            :rules="[rules.required]"
            clearable
          />
        </v-col>

        <v-col md="6" xs="12">
          <date-picker
            label="Data do Câmbio"
            :rules="[rules.required]"
            v-model="exchange.date"
          />
        </v-col>

        <v-col md="6" xs="12">
          <v-currency-field
            label="Valor de Venda"
            :decimal-length="4"
            v-model="exchange.sales_tax"
            :rules="[rules.required]"
          />
        </v-col>

        <v-col md="6" xs="12">
          <v-currency-field
            label="Valor de Compra"
            :decimal-length="4"
            v-model="exchange.buy_tax"
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormMixin from "@/components/misc/mixins/FormMixin";
import DatePicker from "@/components/date_picker/DatePicker.vue";

export default {
  components: { DatePicker },
  name: "ExchangeSaveForm",
  props: {
    value: Object,
    loading: Boolean
  },
  mixins: [FormMixin],
  data() {
    return {
      exchange: this.value,
      valid: false,
      currencies: [
        {
          text: "Dólar",
          value: "USD"
        },
        {
          text: "Euro",
          value: "EUR"
        }
      ]
    };
  },
  watch: {
    value(newValue) {
      this.exchange = newValue;
    },
    exchange() {
      this.$emit("input", this.exchange);
    }
  },
  methods: {
    validate() {
      const valid = this.$refs.form.validate();

      return valid;
    }
  }
};
</script>

<style></style>
