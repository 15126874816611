<template>
  <v-container class="d-flex flex-row py-0 mr-n1">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      min-width="250px"
      left="34%"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          small
          class="ml-auto invoice-table-header__select-btn"
        >
          <v-icon>
            mdi-filter-variant
          </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          <span class="text-h6">
            Dados Visíveis
          </span>
        </v-card-title>

        <v-card-text class="w-100">
          <v-container>
            <v-row no-gutters>
              <v-col
                lg="4"
                md="6"
                sm="12"
                v-for="header in handleHeaders"
                :key="header.value"
              >
                <v-checkbox
                  class="pa-0 ma-n1"
                  v-model="selected"
                  :value="header.value"
                  :label="header.text"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-menu>
  </v-container>
</template>

<script>
import { bus } from "@/main";

export default {
  name: "InvoiceTableHeaderSelect",
  props: {
    value: Array,
    headers: Array
  },
  data() {
    return {
      selected: [],
      menu: false,
      cannotHide: ["actions", "select", "type"]
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.selected = this.value;
        bus.$emit("header-table-columns-selected", {
          selected: this.value,
          headers: this.handleHeaders
        });
      }
    },
    menu() {
      if (!this.menu) {
        this.$emit("input", this.selected);
      }
    }
  },
  computed: {
    handleHeaders() {
      return this.headers.filter(header => this.canHandleHeader(header));
    }
  },
  methods: {
    changeMenu() {
      this.menu = true;
    },
    canHandleHeader(header) {
      return !this.cannotHide.some(value => header.value == value);
    }
  }
};
</script>

<style scoped>
.min-width-250 {
  min-width: 250px;
}
</style>
