import Rest from "../Base/Rest";

/**
 * @typedef {DiscountApprovalGroupsService}
 */
export default class DiscountApprovalGroupsService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/discount-approval-groups";

  all(filters = {}) {
    filters["all"] = true;

    const query = {
      ...filters,
      per_page: 100
    };

    return this.search(query);
  }
}
