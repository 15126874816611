export default {
  texts: {
    errors: {
      connection:
        "Ocorreu um erro ao conectar com o servidor, favor verificar com o TI!"
    },
    table: {
      noResultText: "Nenhum item foi encontrado",
      rowPerPageText: "Itens por página",
      noDataText: "Nenhum registro",
      loadingText: "Aguarde, buscado dados..."
    },
    dialog: {
      loading: "Aguarde, os dados estão sendo carregados...",
      deleteSales: "Aguarde, os dados estão sendo excluidos...",
      saveSales: "Aguarde, os dados estão sendo salvos!",
      updadeSales: "Aguarde, os dados estão sendo atualizados..."
    },
    register: {
      required: "Campos com preenchimento obrigatório"
    }
  },
  arrays: {
    table: {
      itensToView: [5, 10, 20, 50]
    }
  },
  integer: {
    table: {
      numberOfRowPerPage: 10,
      totalPagesVisible: 10,
      totalPagesVisibleOnMobile: 3
    },
    search: {
      numberOfCharacterToSearch: 3
    }
  },
  colors: {
    error: "#DD6B55",
    success: "#4caf50",
    warning: "",
    cancel: "#cccccc"
  }
};
