var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-col',{staticClass:"file-preview-item cursor-pointer ma-2 px-3 pt-1 pb-3 rounded",class:hover ? 'bg-primary-400' : 'bg-primary-300',on:{"click":function($event){return _vm.addNewFile(_vm.file)}}},[_c('v-row',{staticClass:"file-preview",attrs:{"no-gutters":""}},[(_vm.canManipulate || (_vm.file && _vm.file.deleted_at == null))?_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text ml-left",attrs:{"icon":""},on:{"click":_vm.click}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-download ")])],1)]}}],null,true)},[_c('span',[_vm._v("Baixar Arquivo")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.file)?_c('v-btn',_vm._g(_vm._b({staticClass:"white--text ml-auto",attrs:{"icon":""},on:{"click":_vm.removeFile}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"large":""}},[_vm._v(" mdi-close ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Remover Arquivo")])])],1):_vm._e(),(_vm.file && _vm.file.deleted_at != null)?_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [(_vm.file && _vm.file.deleted_at != null)?_c('span',{staticClass:"captionDeletedAt"},[_vm._v(" Deletado "),_c('br'),_vm._v(" "+_vm._s(_vm._f("formatDateHour")(_vm.file.deleted_at)))]):_vm._e(),(_vm.file && _vm.file.deleted_at == null)?_c('span',{staticClass:"captionCreatedAt"},[_c('br'),_vm._v(" "+_vm._s(_vm._f("formatDateHour")(_vm.file.created_at))+" ")]):_vm._e()]}}],null,true)})],1):_vm._e(),_c('v-col',{staticClass:"d-flex py-6",attrs:{"cols":"12"}},[_c('v-icon',{staticClass:"white--text mx-auto",attrs:{"x-large":""}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c('v-col',{staticClass:"white--text text-subtitle-2 file-preview-truncate text-center",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.message)+" ")]),_c('v-col',{staticClass:"white--text text-subtitle-2 file-preview-truncate text-center",attrs:{"cols":"12"}},[(_vm.file)?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"readonly":(!_vm.canManipulateDueDate && _vm.file && _vm.file.deleted_at == null) ||
                  (_vm.file && _vm.file.deleted_at != null),"mask":"##/##/####","label":"Data de validade"},model:{value:(_vm.file.validation_date),callback:function ($$v) {_vm.$set(_vm.file, "validation_date", $$v)},expression:"file.validation_date"}},on))]}}],null,true),model:{value:(_vm.datePicker1),callback:function ($$v) {_vm.datePicker1=$$v},expression:"datePicker1"}},[_c('v-date-picker',{attrs:{"disabled":(!_vm.canManipulateDueDate && _vm.file && _vm.file.deleted_at == null) ||
                (_vm.file && _vm.file.deleted_at != null),"no-title":"","locale":"pt-br"},on:{"input":function($event){_vm.datePicker1 = false}},model:{value:(_vm.dtIniPickerModel),callback:function ($$v) {_vm.dtIniPickerModel=$$v},expression:"dtIniPickerModel"}})],1):_vm._e()],1),(_vm.loading)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-progress-linear',{attrs:{"indeterminate":""}})],1):_vm._e()],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }