<template>
  <default-layout
    route-name="Boletos"
    has-filters
    unique-filter
    :instructions="instructions"
    :loading="loading"
    @search="search"
    @changeFilters="changeFilters"
  >
    <template #header-actions>
      <v-spacer />

      <generate-reports-button
        :table="getReportsTable()"
        label="Gerar Relatório"
        :filename="filedescription"
        :filter="filter"
      />
    </template>

    <template #content>
      <billet-table :items="items" :pagination="pagination" />
    </template>

    <template #footer>
      <pagination v-model="pagination" @change="loadData" :loading="loading" />
    </template>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import FilterMixin from "@/components/Filters/FilterMixin";
import Pagination from "@/components/pagination/Pagination.vue";
import GenerateReportsButton from "@/components/Reports/GenerateReportsButton.vue";

import EmittedBilletService from "@/app/Services/EmittedBilletService";
import BilletService from "@/app/Services/BilletService";
import BilletTable from "./components/BilletTable.vue";

export default {
  components: {
    DefaultLayout,
    Pagination,
    GenerateReportsButton,
    BilletTable
  },
  name: "Billet",
  data() {
    return {
      loading: false,
      items: [],
      filter: {
        status_id: [2, 3]
      },
      instructions: {
        search_for: [
          { text: "Fatura", field: "invoice" },
          { text: "Valor", field: "total", type: "currency" },
          { text: "Pagador", field: "payer_name" }
        ],
        filter_for: [
          { text: "Faturas em Aberto", field: "status_id", value: [1] },
          { text: "Boletos Emitido", field: "emitted_billets", value: true },
          { text: "Pagamento Confirmado", field: "status_id", value: [4] }
        ]
      }
    };
  },
  created() {
    this.loadData();
  },
  mixins: [PaginationMixin, FilterMixin],
  computed: {
    filedescription() {
      const status = this.filter.status_id;

      if (this.isEmittedBilletReport()) {
        return "relatorio_boletos_emitidos";
      } else if (status.includes(1)) {
        return "relatorio_faturas_em_aberto";
      } else {
        return "relatorio_boletos_liquidados";
      }
    }
  },
  methods: {
    getReportsTable() {
      if (this.isEmittedBilletReport()) {
        return "emitted-billets";
      } else {
        return "billets";
      }
    },
    async loadData() {
      try {
        this.loading = true;

        const query = this.getApiQuery();

        const service = this.isEmittedBilletReport()
          ? EmittedBilletService
          : BilletService;

        const response = await service.build().index({
          query
        });

        this.defineResponseData(response);

        this.paginate(response);
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    defineResponseData(response) {
      if (this.isEmittedBilletReport()) {
        this.items = response.data.map((invoiceHistory, index) => {
          const invoice = { ...invoiceHistory.invoice };

          invoice.histories = [{ ...invoiceHistory }];
          invoice.index = index;

          return invoice;
        });

        return;
      }

      this.items = response.data;
    },
    isEmittedBilletReport() {
      return !this.filter.status_id;
    },
    getApiQuery() {
      return {
        params: {
          ...this.pagination,
          filter: { ...this.filter }
        }
      };
    },
    removeDateFilters() {
      const withoutAnotherDateSearchingOptions = this.instructions.search_for.filter(
        search => search.type != "date_range"
      );

      return withoutAnotherDateSearchingOptions;
    },
    changeFilters(filters) {
      const status = filters.status_id;

      if (!status) {
        const withoutAnotherDateSearchingOptions = this.removeDateFilters();

        withoutAnotherDateSearchingOptions.push({
          text: "Data da Geração do Boleto",
          field: "generation_date",
          type: "date_range"
        });

        this.instructions.search_for = withoutAnotherDateSearchingOptions;
      } else if (status.includes(1)) {
        const withoutAnotherDateSearchingOptions = this.removeDateFilters();

        withoutAnotherDateSearchingOptions.push({
          text: "Data de Emissão",
          field: "created_at",
          type: "date_range"
        });

        this.instructions.search_for = withoutAnotherDateSearchingOptions;
      } else if (status.includes(4)) {
        const withoutAnotherDateSearchingOptions = this.removeDateFilters();

        withoutAnotherDateSearchingOptions.push({
          text: "Data do Pagamento",
          field: "payment_date",
          type: "date_range"
        });

        this.instructions.search_for = withoutAnotherDateSearchingOptions;
      }
    }
  }
};
</script>

<style></style>
