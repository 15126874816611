<template>
  <v-container class="h-100">
    <forget-password v-if="recoverUser" v-on:openLogin="openLogin()" />

    <v-row v-if="!recoverUser" align-content="space-between" class="h-100">
      <v-col cols="12" class="text-center">
        <h1 class="display-2 font-weight-bold my-4 row" :class="getLogo()"></h1>

        <p class="text-center text-subtitle-1 mb-4">
          Olá, seja bem-vindo(a)!
        </p>

        <p
          v-if="msg_error != ''"
          class="text-center text-subtitle-2 error--text"
        >
          {{ msg_error }}
        </p>
      </v-col>

      <v-col cols="12">
        <v-form ref="form" v-model="valid">
          <v-container fluid>
            <v-row class="h-100">
              <v-col lg="8" cols="12" class="mx-auto" v-if="!captchaIsVisible">
                <v-text-field
                  id="username"
                  ref="name"
                  label="Login"
                  v-model="user.name"
                  :rules="[rules.required]"
                  outlined
                  light
                  hide-details="auto"
                >
                </v-text-field>
              </v-col>
              <v-col lg="8" cols="12" class="mx-auto" v-if="!captchaIsVisible">
                <v-text-field
                  label="Senha"
                  id="password"
                  v-model="user.password"
                  :append-icon="!e1 ? 'visibility' : 'visibility_off'"
                  @click:append="() => (e1 = !e1)"
                  :type="e1 ? 'password' : 'text'"
                  :rules="[rules.required]"
                  outlined
                  light
                  hide-details="auto"
                  @keydown.enter="toggleCaptcha"
                >
                </v-text-field>
              </v-col>

              <v-col lg="8" cols="12" class="mx-auto" v-if="!captchaIsVisible">
                <v-btn
                  @click="toggleCaptcha"
                  color="primary"
                  class="white--text flex-item w-100"
                  id="login"
                  large
                  :loading="loading"
                  :disabled="!valid"
                >
                  ENTRAR
                </v-btn>
              </v-col>

              <v-col
                lg="8"
                cols="12"
                class="text-center mx-auto"
                v-if="captchaIsVisible"
              >
                <recaptcha
                  v-model="robot"
                  @change="submit"
                  v-if="captchaIsVisible"
                />
              </v-col>

              <v-col
                lg="8"
                cols="12"
                class="text-right mx-auto"
                v-if="!captchaIsVisible"
              >
                <a @click="forgetPassword()" class="primary--text text-button">
                  ESQUECI MINHA SENHA
                </a>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>

      <v-col cols="12">
        <v-card
          elevation="2"
          class="pre-register bg-secondary py-4 cursor-pointer"
          @click="goToPreRegister"
        >
          <v-container fluid>
            <v-row no-gutters class="h-100">
              <v-col md="2" sm="2" xs="2">
                <v-btn class="pre-register--btn" icon>
                  <v-icon class="pre-register--btn-icon white--text">
                    mdi-account-plus
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col md="10" sm="10" cols="12">
                <v-container fluid class="pa-0 ma-0">
                  <v-row no-gutters>
                    <v-col lg="6" cols="12">
                      <span
                        class="pre-register-label text-subtitle-1 white--text"
                      >
                        Já é nosso cliente e ainda não tem acesso ao portal de
                        cobrança?
                      </span>
                    </v-col>

                    <v-col lg="6" cols="12">
                      <v-btn
                        x-large
                        color="primary"
                        depressed
                        class="pre-register-btn white--text px-12"
                        @click.stop="goToPreRegister"
                      >
                        CLIQUE AQUI
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

import LoginService from "@/app/Services/LoginService";
import ForgetPassword from "./ForgetPassword";
import Recaptcha from "./Recaptcha.vue";
import FormMixin from "@/components/misc/mixins/FormMixin";

const loginService = LoginService.build();

export default Vue.extend({
  components: {
    ForgetPassword,
    Recaptcha
  },
  mixins: [FormMixin],
  name: "Login",
  data: () => ({
    e1: true,
    msg_error: "",
    msg_success: "",
    user: {
      name: "",
      password: ""
    },
    valid: false,
    captchaIsVisible: false,
    robot: "",
    recoverUser: false,
    loading: false,
    items: []
  }),
  mounted() {
    this.loginFocus();
  },
  methods: {
    getLogo() {
      // eslint-disable-next-line no-undef
      if (process.env.NODE_ENV == "production") {
        return "logo";
      }

      return "logo_homologation";
    },
    forgetPassword() {
      this.recoverUser = true;
    },
    openLogin() {
      this.recoverUser = false;
    },
    goToPreRegister() {
      this.$router.push({ name: "PreRegister" });
    },
    toggleCaptcha() {
      // eslint-disable-next-line no-undef
      if (process.env.NODE_ENV !== "production") {
        this.submit();

        return;
      }

      if (!this.validate()) {
        return;
      }

      this.captchaIsVisible = !this.captchaIsVisible;
    },
    async submit() {
      // eslint-disable-next-line no-undef
      if (!this.robot && process.env.NODE_ENV === "production") {
        return;
      }

      if (this.validate()) {
        this.loading = true;

        try {
          const response = await loginService.send(this.user);

          this.$store.dispatch("login", response.user.user_role_permissions);

          const data = response;

          localStorage.user = JSON.stringify(data);

          if (data.user.password_prox_login == 1) {
            this.$router.push(`/change-password/${data.user.id}`);

            return;
          }

          const postLogin = this.$route.query.post_login;

          if (postLogin) {
            this.$router.push(postLogin);

            return;
          }

          this.$router.push({ name: "Home" });
        } catch (httpError) {
          try {
            if (httpError.response.status == 401) {
              this.msg_error = "Usuário ou senha inválido!";
            } else {
              this.msg_error =
                "Houve um problema ao tentar se comunicar com o servidor, favor tentar mais tarde ou verificar com a equipe de TI!";
            }
          } catch (error) {
            console.error(httpError);
            this.msg_error =
              "Houve um problema ao tentar se comunicar com o servidor, favor tentar mais tarde ou verificar com a equipe de TI!";
          }
        } finally {
          this.loading = false;
          this.captchaIsVisible = false;
        }
      }
    },
    loginFocus() {
      this.$refs.name.focus();
    },
    validate() {
      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.user.name == "") {
        this.msg_error = "Favor digitar o nome do usuário!";

        return false;
      }

      if (this.user.password == "") {
        this.msg_error = "Favor digitar a senha corretamente!";

        return false;
      }

      return true;
    }
  }
});
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.pre-register {
  &--btn {
    width: 64px;
    height: 64px;
    font-size: 64px;
    background-color: $light--hover-color;

    &-icon {
      font-size: 36px !important;
    }
  }

  &-spacer {
    min-height: 120px;
  }
}
</style>
