<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        class="text-center backgroundImageMobile"
        style="background: white"
      >
        <v-form ref="form">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12" lg="4" xs="12">
                <v-text-field
                  ref="name"
                  label="Razão Social *"
                  v-model="payer.name"
                  required
                  color="primary"
                  @keydown.enter="submit"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" lg="4" xs="12">
                <v-text-field
                  label="CNPJ *"
                  v-mask="'##.###.###/####-##'"
                  v-model="payer.cnpj_cpf"
                  :rules="[validateCnpj]"
                  color="primary"
                  @keydown.enter="submit"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" lg="4" xs="12">
                <v-text-field
                  label="Endereço"
                  v-model="payer.street"
                  required
                  :rules="[rules.required]"
                  color="primary"
                  @keydown.enter="submit"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" lg="4" xs="12">
                <v-text-field
                  label="Número"
                  v-model="payer.number"
                  required
                  type="number"
                  color="primary"
                  @keydown.enter="submit"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" lg="4" xs="12">
                <v-text-field
                  label="Complemento"
                  v-model="payer.complement"
                  required
                  color="primary"
                  @keydown.enter="submit"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" lg="4" xs="12">
                <v-text-field
                  label="Bairro"
                  v-model="payer.neighborhood"
                  required
                  color="primary"
                  @keydown.enter="submit"
                >
                </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="12" lg="4" xs="12">
                <v-select
                  :items="items_state"
                  :value="payer.state_id"
                  color="primary"
                  label="UF"
                  item-text="name"
                  item-value="id"
                  return-object
                  @change="changeState"
                  :rules="[rules.required]"
                  clearable
                />
              </v-col>
              <v-col cols="12" sm="12" lg="4" xs="12">
                <city-select v-model="payer.city_id" :uf="payer.uf" />
              </v-col>

              <v-col cols="12" sm="12" lg="4" xs="12">
                <v-text-field
                  label="CEP"
                  v-model="payer.zip_code"
                  required
                  v-mask="'##.###-###'"
                  color="primary"
                  @keydown.enter="submit"
                >
                </v-text-field>
              </v-col>
              <v-col class="d-flex" cols="12" sm="12" lg="4" xs="12">
                <v-select
                  :items="items_status"
                  v-model="payer.status"
                  color="primary"
                  label="Status"
                  clearable
                />
              </v-col>

              <v-col class="d-flex" cols="12" sm="12" lg="4" xs="12">
                <company-select
                  v-model="payer.company"
                  :rules="[rules.required]"
                />
              </v-col>

              <v-col class="d-flex" cols="12" sm="12" lg="4" xs="12">
                <bank-select v-model="payer.bank_id" />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StateService from "@/app/Services/StateService";
import CityService from "@/app/Services/CityService";
import { cnpjValidation } from "@/plugins/utils";
import FormMixin from "@/components/misc/mixins/FormMixin";
import CompanySelect from "@/components/CompanySelect/CompanySelect.vue";
import BankSelect from "@/components/BankSelect/BankSelect.vue";
import CitySelect from "@/components/CitySelect/CitySelect.vue";

const stateService = StateService.build();
const cityService = CityService.build();

export default {
  name: "Info",
  mixins: [FormMixin],
  props: {
    value: Object
  },
  components: {
    CompanySelect,
    BankSelect,
    CitySelect
  },
  data() {
    return {
      city: "",
      items_city: [],
      items_state: [],
      items_status: [
        { value: 1, text: "Ativo" },
        { value: 2, text: "Desativado" }
      ],
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      loading_city: false,
      model: null,
      search: null,
      search_timerout: null,
      payer: this.value,
      timeoutVar: null,
      debounceSearch: this.$utils.registerDebounce(
        this.loadCity,
        1000,
        this.timeoutVar
      )
    };
  },

  watch: {
    payer() {
      this.$emit("input", this.payer);
    },
    value() {
      this.payer = this.value;
    },

    search() {
      this.debounceSearch();
    }
  },
  mounted() {
    this.loadState();
  },
  methods: {
    changeState(state) {
      this.payer.state_id = state.id;
      this.payer.uf = state.uf;
    },
    validateCnpj(value) {
      const serializedCnpj = this.unmaskText(value);
      const result = cnpjValidation(serializedCnpj);

      return result ? null : "CNPJ Inválido";
    },
    getQueryRequestForSelectAll() {
      return {
        query: {
          per_page: -1
        }
      };
    },
    async loadState() {
      try {
        const query = this.getQueryRequestForSelectAll();

        const response = await stateService.index({ query });

        this.items_state = response;
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loadCity(true);

        this.setupState();
      }
    },
    setupState() {
      const found = this.items_state.find(
        state => state.id == this.payer.state_id
      );

      this.payer.uf = found.uf;
    },
    async loadCity(firstTime = false) {
      if (this.payer.state_id != "") {
        try {
          this.loading_city = true;

          const state = this.items_state.find(
            state => state.id == this.payer.state_id
          );

          const filter = { name: this.search, uf: state.uf };

          if (this.payer.city_id && firstTime) {
            filter.id = this.payer.city_id;
          }

          const response = await cityService.index({
            query: {
              params: {
                filter
              }
            }
          });

          this.items_city = [];

          this.items_city = response.data;
        } catch (error) {
          console.error(error);
        } finally {
          this.loading_city = false;
        }
      }
    }
  }
};
</script>
