import Rest from "../Base/Rest";

/**
 * @typedef {DiscountApprovalService}
 */
export default class DiscountApprovalService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/discount-approval-panel";
}
