<template>
  <div class="my-6 w-100" style="min-width:150px;">
    <v-btn small icon class="" @click="returnToPreviousPage">
      <v-icon class="text--secondary">
        mdi-arrow-left
      </v-icon>
    </v-btn>

    <span
      class="
        font-weight-bold
        text-subtitle-1
        text--secondary
        text-center
        line-heigth-15re
        mt-1
        "
    >
      {{ routeName }} |
    </span>

    <v-btn small icon class="mb-1" @click="goHome">
      <v-icon class="text--secondary">
        mdi-home
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: {
    routeName: String,
    customFunction: Boolean
  },
  methods: {
    goHome() {
      this.$router.push({ name: "Home" });
    },
    returnToPreviousPage() {
      if (this.customFunction) {
        this.$emit("returnToPreviousPage");

        return;
      }

      this.$router.go(-1);

      this.$emit("returnToPreviousPage");
    }
  }
};
</script>

<style lang="scss" scoped>
.line-heigth-1rem {
  line-height: 1rem;
}

.line-heigth-15rem {
  line-height: 1.5rem;
}
</style>
