<template>
  <v-container class="pa-0 mb-4">
    <v-row no-gutters>
      <related-invoice-tile
        :payer="invoice.payer.name"
        invoice="Unificada"
        :value="getTotal(father_invoice)"
        :loading="loading"
        @click="goToUnificator"
        currency="BRL"
        zebra
      />
    </v-row>
  </v-container>
</template>

<script>
import RelatedInvoiceTile from "./RelatedInvoiceTile.vue";
import InvoiceService from "@/app/Services/InvoiceService";

export default {
  components: { RelatedInvoiceTile },
  name: "UnifiedInfo",
  data() {
    return {
      loading: false,
      father_invoice: {}
    };
  },
  props: {
    invoice: Object
  },
  created() {
    this.loadFatherInvoice();
  },
  methods: {
    async loadFatherInvoice() {
      try {
        if (
          this.invoice.unified_father == undefined ||
          this.invoice.unified_father == null
        ) {
          return false;
        }

        this.loading = true;

        const response = await InvoiceService.build().show(
          this.invoice.unified_father.id
        );

        this.father_invoice = response.data;
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.$forceUpdate();
        this.loading = false;
      }
    },
    goToUnificator() {
      this.$emit("goToUnificator", this.invoice.unified_father);
    },
    getTotal(unification) {
      return unification.result_total;
    }
  }
};
</script>

<style scoped>
.unified-goto-btn {
  transform: translateY(72px);
}
</style>
