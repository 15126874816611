<template>
  <v-container fluid class="text-caption v-container--clean-view">
    <v-row>
      <v-col xl="6" lg="6" md="12" sm="12" xs="12">
        <pix-payment-info v-model="invoice" v-if="invoice.id" />
      </v-col>

      <v-col xl="6" lg="6" md="12" sm="12" xs="12" id="payment-qr-code">
        <pix-qr-code :qrcode="model" :loading="loading" :error="error" />
      </v-col>
    </v-row>

    <v-row no-gutters class="pt-2 mx-n3">
      <app-navigation-footer />
    </v-row>
  </v-container>
</template>

<script>
import AppNavigationFooter from "@/components/AppNavigationFooter.vue";
import InvoiceService from "@/app/Services/InvoiceService";
import PixQrCode from "./components/PixQrCode/PixQrCode.vue";
import PixPaymentInfo from "./components/PixPaymentInfo.vue";
import { DateTime } from "luxon";
import FileSaver from "file-saver";
import { bus } from "@/main";

export default {
  components: {
    AppNavigationFooter,
    PixQrCode,
    PixPaymentInfo
  },
  name: "PIXGenerated",
  data() {
    return {
      invoice: {
        payer: {}
      },
      model: {
        image: "",
        qrcode: ""
      },
      pdf: "",
      loading: true,
      error: null
    };
  },
  async created() {
    try {
      await this.loadInvoice();
      await this.loadQrCode();
      this.loading = false;

      bus.$emit("render-qrcode", {
        model: this.model,
        invoice: this.invoice
      });

      await bus.$emit("render-pix");

      bus.$on("download-pix-pdf", this.downloadPdf);

      await bus.$emit("download-pix-pdf");
    } catch (error) {
      this.defaultCatchError(error);

      this.error = this.getExceptionResponseMessage(error);
    }
  },
  methods: {
    async loadQrCode() {
      try {
        const response = await InvoiceService.build()
          .generatePix()
          .send({ id: this.invoice.id });

        this.model = response.data;
      } catch (error) {
        this.defaultCatchError(error);

        this.error = this.getExceptionResponseMessage(error);
      }
    },
    async loadInvoice() {
      try {
        this.loading = true;

        const invoiceId = this.$route.params.invoice;

        const response = await InvoiceService.build().show(invoiceId);

        this.invoice = response.data;
      } catch (error) {
        this.defaultCatchError(error);

        this.error = this.getExceptionResponseMessage(error);
      }
    },
    getTime() {
      const now = DateTime.now();

      return now.toFormat("dd_LL_yyyy");
    },
    async downloadPdf() {
      if (this.pdf == "") {
        const response = await InvoiceService.build().viewPixInformation(
          this.invoice.id,
          "pdf"
        );

        this.pdf = response;
      }

      const blob = new Blob([this.pdf], { type: "application/pdf;" });

      FileSaver.saveAs(
        blob,
        `pix_` + this.invoice.id + "_" + this.getTime() + `.pdf`
      );
    }
  }
};
</script>

<style>
.v-container--clean-view {
  padding-bottom: 0px !important;
  min-height: 100vh !important;
  background-color: white !important;
}

.bordered-info {
  border: 1px solid lightgray;
}

.qrcode--error::before {
  background: url("https://w7.pngwing.com/pngs/144/829/png-transparent-qr-code-information-qr-code-android-qrcode-text-rectangle-monochrome-thumbnail.png");
  opacity: 0.4;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}
</style>
