<template>
  <v-list-item-group color="primary">
    <v-list-item @click="exit">
      <v-list-item-title>
        <v-icon medium class="mr-2"> mdi-logout </v-icon>

        Sair
      </v-list-item-title>
    </v-list-item>
  </v-list-item-group>
</template>

<script>
export default {
  name: "UserProfileHeaderSystemExitButton",
  methods: {
    exit() {
      localStorage.removeItem("user");

      this.$router.push({ name: "Login" });
    }
  }
};
</script>

<style></style>
