export default {
  name: "AuthorizationMixin",
  data() {
    return {
      authorization: {
        canEdit: false,
        route: ""
      }
    };
  },
  created() {
    this.loadAccesses();
  },
  methods: {
    async loadAccesses() {
      const canEdit = await this.canAccess({
        route: this.authorization.route,
        type: "write"
      });

      const canExec = await this.canAccess({
        route: this.authorization.route,
        type: "exec"
      });

      this.authorization.canEdit = canEdit || canExec;
    },
    async canAccess({ route, type }) {
      const permissions = await this.$store.dispatch("getRoles");

      if (!permissions.length) {
        return false;
      }

      const hasPermission = this.goThroughRolesToMatchRoute(
        permissions,
        route,
        type
      );

      if (!hasPermission) {
        return false;
      }

      return true;
    },

    goThroughRolesToMatchRoute(accesses, route, operation) {
      if (!route) {
        return false;
      }

      const found = accesses.find(access => this.matchRoute(access, route));

      if (found) {
        return this.operationMatch(found, operation);
      }

      return found;
    },

    operationMatch(access, operation) {
      return access.operation == operation;
    },

    matchRoute(access, route) {
      if (access.children && access.children.length) {
        const result = this.goThroughRolesToMatchRoute(access.children, route);

        return result;
      }

      const result = this.routeVerify(route, access.route);

      return result;
    },

    routeVerify(route, role) {
      if (typeof route == "string") {
        return route == role;
      } else if (typeof route == "object") {
        return route.includes(role);
      }
    }
  }
};
