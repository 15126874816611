<template>
  <v-container fluid>
    <v-row class="mt-6 mb-6 background">
      <v-timeline align-top dense v-if="arrMessages.length > 0">
        <v-timeline-item
          :color="
            item.status.color != null && item.status.color != ''
              ? item.status.color
              : 'purple'
          "
          small
          v-for="(item, index) in arrMessages"
          :key="index"
        >
          <v-row class="pt-1">
            <strong class="text-h6 font-weight-regular mr-6">
              {{ item.user.name }}
            </strong>
            <strong class="font-weight-regular mr-6">
              <v-chip
                class="white--text ml-0"
                :color="
                  item.status.color != null && item.status.color != ''
                    ? item.status.color
                    : 'purple'
                "
                label
                small
              >
                {{ item.status.name }}
              </v-chip>
            </strong>
          </v-row>
          <v-row
            v-if="
              item.status.internal != 1 ||
                userTypePermissions.canEditInternalUsers == true
            "
          >
            {{ item.message }}
          </v-row>
          <v-row>
            <v-icon :left="true" small>
              mdi-calendar-clock-outline
            </v-icon>
            {{ item.created_at | formatDateHour }}
            <v-icon :left="true" :right="true" small>
              mdi-email-arrow-right-outline
            </v-icon>
            {{ item.user.email }}
          </v-row>
          <v-row>
            <file-chip
              v-for="file in item.files"
              :key="file.id"
              :file="file"
              :message="getFileName(file)"
              icon="mdi-paperclip"
              @click="download(file)"
              @removeFile="removeFile(file)"
              :canManipulate="userTypePermissions.canEditInternalUsers == true"
            />
          </v-row>
        </v-timeline-item>
      </v-timeline>
      <v-col v-else class="text-center pt-12 pb-12" cols="12">
        <span v-if="isOpenChat()">Envie uma mensagem</span>
        <span v-else>Atendimento encerrado</span>
      </v-col>
    </v-row>
    <v-row
      class="mt-1"
      v-show="
        (userTypePermissions.canEditInternalUsers || editStatus) && isOpenChat()
      "
    >
      <v-col cols="3">
        <RefundSelectChatStatus
          :registering="registering"
          v-model="refund_modify.status_id"
          :canManipulate="canManipulate"
        />
      </v-col>
    </v-row>
    <v-row class="mt-12">
      <v-textarea
        filled
        auto-grow
        rows="4"
        row-height="30"
        outlined
        name="input-7-4"
        label="Mensagem"
        value=""
        :counter="500"
        v-model="message"
        :loading="registering"
        append-icon="mdi-paperclip"
        @click:append="addFiles"
        v-if="isOpenChat() && canManipulate"
      >
        <file-dialog
          v-model="files"
          @change="changeFiles"
          @download="download"
        />
      </v-textarea>
    </v-row>
    <v-row class="mt-1" justify="end" v-if="isOpenChat()">
      <file-dialog-chat
        ref="upload"
        v-model="files"
        @change="changeFiles"
        @download="download"
        :file-add-button-message="'Enviar Comprovante'"
      />
    </v-row>
    <v-row class="mt-4" justify="end" v-if="isOpenChat()">
      <v-btn
        v-if="canManipulate"
        @click="addMessage"
        small
        :loading="registering"
      >
        Enviar
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import SendMessage from "../domain/SendMessage";
import FileDialogChat from "@/components/FileUploadChat/FileDialogChat.vue";
import RefundSelectChatStatus from "@/components/Refund/RefundSelectChatStatus.vue";
import FileChip from "./FileChip.vue";
import DownloadFile from "../domain/DownloadFile";
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";

export default {
  components: { FileDialogChat, RefundSelectChatStatus, FileChip },
  name: "RefundChat",
  props: {
    value: Object,
    canManipulate: Boolean,
    userTypePermissions: Object
  },
  mixins: [SendMessage, DownloadFile, AuthorizationMixin],
  data() {
    return {
      message: "",
      refund: this.value,
      refund_modify: {},
      visibleUpload: false,
      editStatus: false,
      files: []
    };
  },
  async mounted() {
    this.refund_modify = Object.assign({}, this.value);

    this.editStatus = await this.checkIfYouCanRecordTheStatus();
  },
  methods: {
    isOpenChat() {
      const status_refs = [
        "attendance",
        "waiting_customer",
        "waiting_customer_documentation",
        "under_registration",
        "under_approval"
      ];

      return (
        this.value &&
        this.value.status &&
        status_refs.some(elem => elem == this.value.status.refs)
      );
    },
    getFileName(item) {
      return item ? item.name || item.file_name : "";
    },
    async addMessage() {
      await this.sendMessage();
    },
    addFiles() {
      this.$refs["upload"].addFiles();
      this.visibleUpload = true;
    },
    returnToList() {
      this.$router.push({ name: "Refund" });
    }
  }
};
</script>

<style>
.background {
  background: #e9e9e9;
  padding: 15px;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px 1px #eee;
}
</style>
