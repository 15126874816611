import Rest from "../Base/Rest";

/**
 * @typedef {StateService}
 */
export default class StateService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/state";
}
