<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="pagination.itemsPerPage"
    hide-default-footer
    loading-text="Carregando dados, aguarde..."
    class="elevation-0"
  >
    <template #item.action="{ item }">
      <default-edit-button
        @click="edit(item)"
        :only-show="!authorization.canEdit"
      />
    </template>

    <template #item.payers="{ item }">
      <v-chip-group
        class="chip-group-width"
        show-arrows
        mobile-breakpoint="none"
      >
        <v-chip
          class="text-truncate"
          v-for="(name, index) in getPayersNames(item)"
          :key="index"
        >
          {{ name }}
        </v-chip>
      </v-chip-group>
    </template>

    <template #item.status="{ item }">
      <v-switch
        v-model="item.status"
        inset
        :readonly="!authorization.canEdit"
        color="secondary"
        class="mx-auto d-flex flex-item mt-1"
        @change="changeStatus(item)"
      />
    </template>
  </v-data-table>
</template>

<script>
import DefaultEditButton from "@/components/layout/DefaultEditButton.vue";
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";

export default {
  components: { DefaultEditButton },
  name: "PayerGroupTable",
  props: {
    items: Array,
    pagination: Object
  },
  data() {
    return {
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
          width: "50",
          cellClass: "text-truncate"
        },
        {
          text: "Nome do Núcleo",
          align: "start",
          sortable: false,
          value: "name",
          width: "500",
          cellClass: "text-truncate"
        },
        {
          text: "Pagadores",
          sortable: false,
          value: "payers",
          width: "50%"
        },
        {
          text: "Status",
          sortable: false,
          value: "status",
          width: "100"
        },
        {
          text: "Ações",
          sortable: false,
          value: "action",
          width: "100"
        }
      ],
      authorization: {
        route: "/group"
      }
    };
  },
  mixins: [AuthorizationMixin],
  methods: {
    edit(item) {
      this.$emit("edit", item);
    },
    getPayersNames(item) {
      const names = item.payers.map(payer => payer.name);

      return names;
    },
    async changeStatus(item) {
      this.$emit("changeStatus", item);
    }
  }
};
</script>

<style lang="scss">
.chip-group-width {
  & > .v-slide-group__wrapper {
    @media (min-width: 600px) {
      max-width: 200px !important;
    }

    @media (min-width: 1060px) {
      max-width: 300px !important;
    }

    @media (min-width: 1220px) {
      max-width: 400px !important;
    }

    @media (min-width: 1600px) {
      max-width: 800px !important;
    }
  }
}
</style>
