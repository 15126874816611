<template>
  <v-col cols="12">
    <span class="text-caption text--secondary">
      Encontradas
    </span>

    <v-divider />

    <div v-if="model.length">
      <v-list-item
        v-for="(readElectronicInvoice, index) in foundInvoices"
        :key="readElectronicInvoice.id"
        :class="index % 2 ? 'bg-primary-100' : ''"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ readElectronicInvoice.invoice }}
          </v-list-item-title>

          <v-list-item-subtitle>
            {{ readElectronicInvoice.related_invoice.payer.name }}
          </v-list-item-subtitle>

          <v-list-item-subtitle class="text-caption">
            NF-e:
            <a
              :href="
                readElectronicInvoice.related_invoice.url_electronic_invoice
              "
              target="_blank"
            >
              {{ readElectronicInvoice.related_invoice.url_electronic_invoice }}
              <v-icon small>mdi-open-in-new</v-icon>
            </a>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </div>

    <v-list-item v-if="!model.length">
      <v-list-item-content>
        <v-list-item-title>
          Nenhuma Fatura
        </v-list-item-title>

        <v-list-item-subtitle> </v-list-item-subtitle>

        <v-list-item-subtitle class="text-caption"> </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-col>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";

export default {
  name: "InvoiceSearchDialogList",
  mixins: [ModelManagementMixin],
  props: {
    model: Array
  },
  computed: {
    foundInvoices() {
      const found = this.model.filter(readElectronicInvoice => {
        if (readElectronicInvoice.status == "success") {
          return true;
        }

        return false;
      });

      return found;
    }
  }
};
</script>

<style></style>
