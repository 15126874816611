<template>
  <v-form ref="form">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <file-dialog-contract
            v-model="model.files"
            file-add-button-message="Enviar Arquivo"
            file-upload-button-message="Clique aqui para adicionar as procurações"
            :accepted-formats="FileHandle.acceptedFormats"
            @download="download"
            @removeFile="saveFiles"
            :can-manipulate="canEditContract"
            :can-manipulate-due-date="canEditContract"
            @updateDateValidate="saveFiles"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";
import FormMixin from "@/components/misc/mixins/FormMixin";
import FileDialogContract from "@/components/FileUploadContract/FileDialogContract.vue";
import FileHandle from "../domain/FileHandle";
import DownloadFile from "../domain/DownloadFile";
import UserService from "@/app/Services/UserService";

import { bus } from "@/main";

export default {
  components: { FileDialogContract },
  name: "PayerGroupFormContracts",
  mixins: [FormMixin, ModelManagementMixin, AuthorizationMixin, DownloadFile],
  data() {
    return {
      FileHandle,
      responsivity: {
        maxWidth: "256px",
        maxHeight: "256px"
      },
      canEditContract: false
    };
  },
  watch: {
    "model.files": {
      handler() {
        this.valid = true;
      }
    }
  },
  async created() {
    this.canEditContract = await this.canAccess({
      route: "/user/contract",
      type: "write"
    });
  },
  methods: {
    remove(item) {
      bus.$emit("showNotificationDialog", {
        status: "error",
        title: "Deseja remover esse pagador?",
        continue: () => {
          const idx = this.model.payers.findIndex(con => con.id == item.id);

          this.model.payers.splice(idx, 1);

          this.saveFiles();
        }
      });
    },
    async saveFiles() {
      try {
        this.loading = true;

        const form = {
          id: this.model.user_id,
          files: this.model.files
        };

        const userService = UserService.build();

        await userService.update(form);

        this.$emit("loadData");
      } catch (error) {
        this.$emit("loadData");
        console.error(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    upload() {
      const upload = this.FileHandle.upload.bind(this);

      upload();
    },
    changeFiles() {
      this.saveFiles();
    }
  }
};
</script>

<style></style>
