var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-card',{class:{
        'mx-auto': true,
        'grey lighten-1': true,
        'darken-2': _vm.cardIsActive == _vm.discountApprovalStatus.PENDING
      },attrs:{"dark":""},on:{"click":function($event){return _vm.handleClickUpdateFilterStatus(_vm.discountApprovalStatus.PENDING)}}},[_c('v-card-title',[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v(" mdi-tag-hidden ")]),_c('span',{staticClass:"text-h6 font-weight-light"},[_vm._v("Pendente")])],1),_c('v-card-text',[_c('div',{staticClass:"ml-11 text-h5"},[_vm._v(" "+_vm._s(_vm.totalsApproval && _vm.totalsApproval[_vm.discountApprovalStatus.PENDING] ? _vm.totalsApproval[_vm.discountApprovalStatus.PENDING] : 0)+" ")])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-card',{class:{
        'mx-auto': true,
        green: true,
        'lighten-3': _vm.cardIsActive != _vm.discountApprovalStatus.APPROVED
      },attrs:{"dark":""},on:{"click":function($event){return _vm.handleClickUpdateFilterStatus(_vm.discountApprovalStatus.APPROVED)}}},[_c('v-card-title',[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v(" mdi-tag-check-outline ")]),_c('span',{staticClass:"text-h6 font-weight-light"},[_vm._v("Aprovado")])],1),_c('v-card-text',[_c('div',{staticClass:"ml-11 text-h5"},[_vm._v(" "+_vm._s(_vm.totalsApproval && _vm.totalsApproval[_vm.discountApprovalStatus.APPROVED] ? _vm.totalsApproval[_vm.discountApprovalStatus.APPROVED] : 0)+" ")])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4","sm":"12"}},[_c('v-card',{class:{
        'mx-auto': true,
        red: true,
        'lighten-3': _vm.cardIsActive != _vm.discountApprovalStatus.DISAPPROVED
      },attrs:{"dark":""},on:{"click":function($event){return _vm.handleClickUpdateFilterStatus(_vm.discountApprovalStatus.DISAPPROVED)}}},[_c('v-card-title',[_c('v-icon',{attrs:{"large":"","left":""}},[_vm._v(" mdi-tag-remove-outline ")]),_c('span',{staticClass:"text-h6 font-weight-light"},[_vm._v("Reprovado")])],1),_c('v-card-text',[_c('div',{staticClass:"ml-11 text-h5"},[_vm._v(" "+_vm._s(_vm.totalsApproval && _vm.totalsApproval[_vm.discountApprovalStatus.DISAPPROVED] ? _vm.totalsApproval[_vm.discountApprovalStatus.DISAPPROVED] : 0)+" ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }