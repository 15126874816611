<template>
  <v-hover v-slot="{ hover }">
    <v-col
      class="file-preview-item cursor-pointer ma-2 px-3 pt-1 pb-3 rounded"
      :class="[hover ? 'bg-primary-400' : 'bg-primary-300']"
      v-if="
        canManipulate || (!canManipulate && file && file.deleted_at == null)
      "
    >
      <v-row no-gutters class="file-preview">
        <v-col cols="12" class="d-flex" v-if="canManipulate">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                @click="removeFile"
                class="white--text ml-auto"
              >
                <v-icon large> mdi-close </v-icon>
              </v-btn>
            </template>

            <span>Remover Arquivo</span>
          </v-tooltip>
        </v-col>

        <v-col cols="12" class="d-flex">
          <v-tooltip bottom>
            <template v-slot:activator="{}">
              <span
                v-if="file && file.deleted_at != null"
                class="captionDeletedAt"
              >
                Deletado <br />
                {{ file.deleted_at | formatDateHour }}</span
              >
              <span
                v-if="file && file.deleted_at == null"
                class="captionCreatedAt"
              >
                <br />
                {{ file.created_at | formatDateHour }}
              </span>
            </template>
          </v-tooltip>
        </v-col>

        <v-col cols="12" class="d-flex py-6">
          <v-icon x-large class="white--text mx-auto" @click="click">
            mdi-download
          </v-icon>
        </v-col>

        <v-col
          cols="12"
          class="white--text text-subtitle-2 file-preview-truncate text-center"
        >
          {{ message }}
        </v-col>

        <v-col cols="12" v-if="loading">
          <v-progress-linear indeterminate />
        </v-col>
      </v-row>
    </v-col>
  </v-hover>
</template>

<script>
import { bus } from "@/main";

export default {
  name: "FileChip",
  props: {
    file: Object,
    message: String,
    icon: String,
    loading: Boolean,
    hideRemove: Boolean,
    canManipulate: Boolean
  },
  methods: {
    click() {
      this.$emit("click");
    },
    removeFile() {
      if (this.file && this.file.deleted_at == null) {
        bus.$emit("showNotificationDialog", {
          status: "error",
          title: "Deseja remover esse arquivo?",
          continue: () => this.$emit("removeFile")
        });
      }
    }
  }
};
</script>

<style>
.file-preview {
  overflow: hidden;
}

.file-preview-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.captionDeletedAt {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #a10035 !important;
  text-align: center;
  width: 100%;
}
.captionCreatedAt {
  color: white;
  font-size: 12px;
  text-align: center;
  width: 100%;
}
</style>
