/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */

import Echo from "laravel-echo";
import Pusher from "pusher-js";

const PushNotifications = {};

PushNotifications.install = function(Vue, options) {
  window.Pusher = Pusher;

  const authEndpoint = `${process.env.VUE_APP_API}/api/broadcasting/auth`;

  window.Echo = new Echo({
    broadcaster: "pusher",
    key: process.env.VUE_APP_PUSHER_KEY,
    authEndpoint: authEndpoint,
    cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    encrypted: true,
    forceTLS: false
  });
};

export default PushNotifications;
