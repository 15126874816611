<template>
  <v-container fluid>
    <v-row align-content="stretch">
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        class="text-center backgroundImageMobile"
        style="background: white"
      >
        <v-form ref="form" v-model="valid">
          <v-container fluid>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                lg="4"
                md="6"
                xs="12"
                v-if="$route.params.id != 'new'"
              >
                <v-text-field
                  ref="login"
                  label="Login"
                  v-model="model.login"
                  readonly
                  :disabled="$route.params.id == 'new'"
                  color="text"
                  @keydown.enter="submit"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" lg="4" md="6" xs="12">
                <v-text-field
                  label="Nova senha"
                  v-model="model.password"
                  :append-icon="!e1 ? 'visibility' : 'visibility_off'"
                  @click:append="() => (e1 = !e1)"
                  :type="e1 ? 'password' : 'text'"
                  counter
                  :rules="[requiredIfIsNew]"
                  color="text"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" lg="4" md="6" xs="12">
                <v-text-field
                  label="Confirmar a senha"
                  v-model="model.password_confirm"
                  :append-icon="!e2 ? 'visibility' : 'visibility_off'"
                  @click:append="() => (e2 = !e2)"
                  :type="e2 ? 'password' : 'text'"
                  counter
                  :rules="[requiredIfIsNew]"
                  color="text"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="12" lg="4" md="6" xs="12">
                <v-switch
                  inset
                  color="secondary"
                  v-model="model.password_prox_login"
                  v-on:change="changePasswordProx($event)"
                  label="Alterar senha no próximo login"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormMixin from "@/components/misc/mixins/FormMixin";
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import Vue from "vue";

export default Vue.extend({
  name: "UsersRecordAccess",
  props: ["user"],
  data: () => ({
    loading: false,
    e1: true,
    e2: true
  }),
  mixins: [FormMixin, ModelManagementMixin],
  methods: {
    requiredIfIsNew(value) {
      if (this.$route.params.id != "new") {
        return null;
      }

      if (!value) {
        return "Campo necessário";
      }

      return null;
    },
    changePasswordProx(option) {
      this.model.password_prox_login = option;
    },
    validate() {
      return this.$refs.form.validate();
    }
  },
  components: {}
});
</script>
