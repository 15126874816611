<template>
  <v-combobox
    label="Funcionalidade"
    v-model="model"
    :items="items"
    :loading="loading"
    item-text="message"
    item-value="message"
    clearable
    :return-object="false"
  />
</template>

<script>
import AuditFunctionalityService from "@/app/Services/AuditFunctionalityService";
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";

export default {
  name: "AuditFunctionalitySelect",
  mixins: [ModelManagementMixin],
  data() {
    return {
      loading: false
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;

        const query = this.getApiQueryRequest();

        const response = await AuditFunctionalityService.build().index({
          query
        });

        this.items = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    getApiQueryRequest() {
      return {
        params: {
          itemsPerPage: -1
        }
      };
    }
  }
};
</script>

<style></style>
