<template>
  <v-data-table
    :headers="headers"
    :items="accesses"
    hide-default-footer
    disable-pagination
    class="w-100"
  >
    <template v-slot:item.exec="{ item }">
      <v-switch
        small
        inset
        class="mx-auto"
        color="secondary"
        :input-value="isToExecute(item)"
        @click="changePermission(item, 'exec')"
      />
    </template>
  </v-data-table>
</template>

<script>
import UserTypePermissionsMixin from "../domain/UserTypePermissionsMixin";

export default {
  name: "AccessFunctionalityTable",
  props: {
    accesses: Array
  },
  data() {
    return {
      headers: [
        { value: "name", text: "Funcionalidade", width: "90%" },
        { value: "exec", text: "Disponível", width: "10%" }
      ]
    };
  },
  mixins: [UserTypePermissionsMixin]
};
</script>

<style></style>
