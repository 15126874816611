<template>
  <v-list-group
    :prepend-icon="icon"
    class="secondary--text my-auto"
    v-model="active"
  >
    <template v-slot:activator>
      <v-list-item-content>
        <v-list-item-title>
          {{ name }}
        </v-list-item-title>
      </v-list-item-content>
    </template>

    <drawer-item v-for="(access, i) in accesses" :key="i" :access="access" />
  </v-list-group>
</template>

<script>
import DrawerItem from "./DrawerItem.vue";

export default {
  name: "DrawerGrouper",
  components: { DrawerItem },
  props: {
    name: String,
    icon: String,
    accesses: Object,
    active: Boolean,
    key: String
  }
};
</script>

<style></style>
