var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.pagination.itemsPerPage,"hide-default-footer":true,"header-props":{ sortIcon: null },"item-key":"index"},scopedSlots:_vm._u([{key:"item.invoice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.invoice || "Unificado")+" ")]}},{key:"item.generation_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getGenerationDate(item))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")]}},{key:"item.payment_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPaymentDate(item))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(item.status.name))])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.total ? _vm.toCurrency(item.total) : "Unificado")+" ")]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }