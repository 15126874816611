import Vuex from "vuex";
import AuthService from "@/app/Services/AuthService";

const auth = new Vuex.Store({
  state: {
    roles: []
  },
  getters: {
    hasPermissions(state) {
      return state.roles && state.roles.length > 0;
    }
  },
  mutations: {
    updateRoles(state, roles) {
      state.roles = roles;
    }
  },
  actions: {
    login(context, roles) {
      context.commit("updateRoles", roles);
    },
    async getRoles(context) {
      const roles = context.state.roles;

      if (!roles || roles.length <= 0) {
        return await this.dispatch("getAuth");
      }

      return roles;
    },
    async getAuth(context) {
      try {
        const response = await AuthService.requestPermissions();

        await context.commit(
          "updateRoles",
          response.data.user_role_permissions
        );

        context.state.roles = response.data.user_role_permissions;

        return response.data.user_role_permissions;
      } catch (error) {
        return [];
      }
    }
  }
});

export default auth;
