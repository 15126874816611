<template>
  <svg
    width="99"
    height="71"
    viewBox="0 0 99 71"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 70L1 47L28.5 1H59.5L40 33H45.5L65 1H95.5L76 33H97.5L89 47H68L84.5 70H54L37.5 47H31.5L48 70H18Z"
      :fill="color || '#033572'"
      :stroke="color || '#033572'"
    />
  </svg>
</template>

<script>
export default {
  name: "HapagIcon",
  props: {
    color: String
  }
};
</script>
