<template>
  <default-layout
    :loading="loading"
    route-name="Auditoria"
    :showHeaderActions="false"
    @search="search"
    unique-filter
  >
    <template #filter>
      <v-row>
        <v-col
          lg="12"
          md="12"
          xs="12"
          class="py-1 text-h5 my-auto font-weight-bold"
        >
          <v-row>
            <v-col cols="12" lg="10" md="10" sm="10" xs="10">
              Filtros
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="2" xs="2">
              <v-btn class="text--secondary mt-2" @click="loadData">
                Pesquisar
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col lg="12" md="12" xs="12" class="py-1 d-flex flex-row flex-wrap">
          <v-container fluid>
            <v-row>
              <v-col cols="12" lg="3" md="6" sm="6" xs="12">
                <v-text-field
                  label="Fatura"
                  v-model="custom_filter.invoice"
                  @input="debounceSearch"
                  @keydown.enter="loadData"
                />
              </v-col>
              <v-col cols="12" lg="3" md="6" sm="6" xs="12">
                <v-text-field
                  label="Buscar dado alterado"
                  v-model="custom_filter.altered_value"
                  @input="debounceSearch"
                  @keydown.enter="loadData"
                />
              </v-col>
              <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                <v-select
                  label="Página"
                  v-model="selectedTable"
                  :items="auditTables"
                  item-text="text"
                  item-value="value"
                  :return-object="false"
                  clearable
                />
              </v-col>
              <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                <audit-functionality-select v-model="functionality" />
              </v-col>
              <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                <v-text-field
                  label="Pagador"
                  v-model="custom_filter.payer_name"
                  @input="debounceSearch"
                  @keydown.enter="loadData"
                />
              </v-col>
              <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                <v-text-field
                  label="Usuário"
                  v-model="custom_filter.user_name"
                  @input="debounceSearch"
                  @keydown.enter="loadData"
                />
              </v-col>
              <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                <v-text-field
                  label="Nosso Número"
                  v-model="custom_filter.bank_our_number"
                  @input="debounceSearch"
                  @keydown.enter="loadData"
                />
              </v-col>
              <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                <v-text-field
                  label="Identificação do Título pelo Sistema (ID)"
                  v-model="custom_filter.parent_id"
                  @input="debounceSearch"
                  @keydown.enter="loadData"
                />
              </v-col>
              <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                <date-picker
                  label="Data Inicial"
                  v-model="custom_filter.date_range.start"
                  clearable
                />
              </v-col>
              <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                <date-picker
                  label="Data Final"
                  v-model="custom_filter.date_range.end"
                  clearable
                />
              </v-col>
              <v-col cols="12" lg="3" md="3" sm="6" xs="12">
                <v-select
                  label="Operação"
                  v-model="custom_filter.operation"
                  :items="auditOperations"
                  item-text="text"
                  item-value="value"
                  :return-object="false"
                  clearable
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </template>

    <template #content>
      <audit-fields-model ref="audit_fields_model" />

      <audit-table
        :items="items"
        :pagination="pagination"
        @search_audit="search_audit"
      />
    </template>

    <template #footer>
      <pagination v-model="pagination" @change="loadData" :loading="loading" />
    </template>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import FilterMixin from "@/components/Filters/FilterMixin";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import Pagination from "@/components/pagination/Pagination.vue";
import DatePicker from "@/components/date_picker/DatePicker.vue";

import AuditService from "@/app/Services/AuditService";
import AuditTable from "./components/AuditTable.vue";
import tables from "./domain/tables";

import AuditFunctionalitySelect from "./components/AuditFunctionalitySelect";
import AuditFieldsModel from "./components/AuditFieldsModel.vue";

export default {
  name: "Audit",
  components: {
    DefaultLayout,
    Pagination,
    AuditTable,
    AuditFunctionalitySelect,
    AuditFieldsModel,
    DatePicker
  },
  mixins: [PaginationMixin, FilterMixin],
  data() {
    return {
      loading: false,
      pagination: {
        itemsPerPage: 25
      },
      payers: [],
      selectedTable: null,
      filter: {},
      custom_filter: {
        payer_name: "",
        user_name: "",
        date_range: {
          start: "",
          end: ""
        },
        bank_our_number: ""
      },
      items: [],
      auditTables: tables,
      auditOperations: [
        { value: "create", text: "Criação" },
        { value: "update", text: "Atualização" },
        { value: "delete", text: "Deleção" }
      ],
      functionality: ""
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    changePayers(value) {
      this.payers = value;
    },
    search_audit(item) {
      this.$refs.audit_fields_model.search(item);
    },
    async loadData() {
      try {
        this.loading = true;

        const query = this.getQuery();

        const response = await AuditService.build().index({
          query
        });

        this.items = response.data;

        this.paginate(response);
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    getQuery() {
      return {
        params: {
          ...this.pagination,
          filter: {
            ...this.filter,
            ...this.custom_filter,
            table: this.selectedTable,
            functionality: this.functionality
          }
        }
      };
    }
  }
};
</script>

<style></style>
