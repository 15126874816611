<template>
  <v-row justify="center">
    <v-dialog v-model="value" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >{{ editId ? "Editar" : "Adicionar" }} Regra</span
          >
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-select
                  v-model="selectedRule"
                  :items="ruleOptions"
                  label="Selecione a Regra"
                  item-value="key"
                  item-text="description"
                ></v-select>
              </v-col>
              <v-col v-for="param in selectedParams" :key="param" cols="12">
                <v-text-field
                  v-model="ruleParams[param]"
                  :label="`Digite o valor para ${param}`"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">
            Fechar
          </v-btn>
          <v-btn
            color="blue darken-1"
            :loading="loading"
            text
            @click="saveRule"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { PayerProcedureDiscountRulesEnum } from "../Enums/PayerProcedureDiscountRulesEnum";
import PayerDiscountRuleService from "@/app/Services/PayerDiscountRuleService";
import FormMixin from "@/components/misc/mixins/FormMixin";
import { bus } from "@/main";

const payerDiscountRuleService = PayerDiscountRuleService.build();

export default {
  props: ["value", "payer"],
  mixins: [FormMixin],
  name: "PayerProcedureDiscountRulesDialog",
  data() {
    return {
      selectedRule: null,
      ruleParams: {},
      loading: false,
      editId: null
    };
  },
  created() {
    bus.$on("editDiscountRule", this.editDiscountRule);
  },
  computed: {
    ruleOptions() {
      return Object.entries(PayerProcedureDiscountRulesEnum).map(
        ([key, value]) => ({
          key,
          description: value.description,
          params: value.params
        })
      );
    },

    selectedParams() {
      const rule = this.ruleOptions.find(
        option => option.key === this.selectedRule
      );
      return rule ? rule.params : [];
    }
  },
  methods: {
    closeDialog() {
      this.selectedRule = null;
      this.ruleParams = {};
      this.editId = null;
      this.$emit("input", false);
    },
    async saveRule() {
      this.loading = true;

      if (this.editId) {
        await payerDiscountRuleService.update({
          id: this.editId,
          params: this.ruleParams
        });
      } else {
        await payerDiscountRuleService.store({
          method: this.selectedRule,
          params: this.ruleParams,
          payer_id: this.$route.params.id
        });
      }

      this.showToast({
        status: "success",
        message: "Regra adicionada com sucesso"
      });

      this.selectedRule = null;
      this.ruleParams = {};
      this.editId = null;
      bus.$emit("loadPayerData");
      this.closeDialog();
      this.loading = false;
    },
    editDiscountRule(item) {
      this.selectedRule = item.method;
      // eslint-disable-next-line no-unused-vars
      const { payer_id, created_at, updated_at, id, ...data } = item;
      this.ruleParams = data.params;
      this.editId = id;
    }
  }
};
</script>

<style scoped></style>
