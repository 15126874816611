import Rest from "../Base/Rest";

/**
 * @typedef {BankReturnService}
 */
export default class BankReturnService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/audit/return-files";
}
