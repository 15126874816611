export default class UserTypeAccessMethods {
  setScope(scope) {
    this.scope = scope;

    return this;
  }

  checkIfScopeIsDefined(scope) {
    if (scope) {
      return true;
    }

    return false;
  }

  async canEditThisUser(user) {
    if (this.checkIfScopeIsDefined()) {
      return false;
    }

    const permissions = await this.getAccess();

    if (this.isExternal(user) && permissions.canEditExternalUsers) {
      return true;
    }

    if (!this.isExternal(user) && permissions.canEditInternalUsers) {
      return true;
    }

    return false;
  }

  async getAccess() {
    const canEditInternalUsers = await this.scope.canAccess({
      route: "/user/internal",
      type: "write"
    });

    const canEditExternalUsers = await this.scope.canAccess({
      route: "/user/external",
      type: "write"
    });

    return { canEditExternalUsers, canEditInternalUsers };
  }

  async loadUserTypeByPermission() {
    if (this.checkIfScopeIsDefined()) {
      return false;
    }

    const internalUser = { value: "internal", text: "Interno" };
    const externalUser = { value: "external", text: "Externo" };

    const hasToCreate = this.scope.$route.params.id == "new";

    if (!hasToCreate) {
      return [internalUser, externalUser];
    }

    const permissions = await this.getAccess();
    const userTypes = [];

    if (permissions.canEditExternalUsers) {
      userTypes.push(externalUser);
    }

    if (permissions.canEditInternalUsers) {
      userTypes.push(internalUser);
    }

    return userTypes;
  }

  static isExternal(user) {
    return user.payer_id || user.group_id;
  }

  static verifyIfCanEditByUserAccess(user, access) {
    const permissions = access || {};

    if (
      UserTypeAccessMethods.isExternal(user) &&
      permissions.canEditExternalUsers
    ) {
      return true;
    }

    if (
      !UserTypeAccessMethods.isExternal(user) &&
      permissions.canEditInternalUsers
    ) {
      return true;
    }

    return false;
  }
}
