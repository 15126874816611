<template>
  <div class="w-100">
    <image-upload-input v-model="selectedFile" ref="img_upload_input" />

    <image-upload-button
      @click="selectFile"
      ref="img_upload_button"
      v-if="doesntHaveFileAndPreview"
      v-model="selectedFile"
    />

    <image-cropper
      v-else-if="haveFileButNotMakePreviewYet"
      v-model="model"
      :image="selectedFile"
      :aspectRatio="aspectRatio"
      :aspect-ratio="responsivity.aspectRatio"
      :responsivity="{
        mdWidth: responsivity.mdWidth,
        mdHeight: responsivity.mdHeight,
        smWidth: responsivity.smWidth,
        smHeight: responsivity.smHeight
      }"
    />

    <image-preview
      v-else
      v-model="model"
      :responsivity="{
        maxWidth: responsivity.maxWidth,
        maxHeight: responsivity.maxHeight
      }"
      @change="selectFile"
    />
  </div>
</template>

<script>
import ModelManagementMixin from "../misc/mixins/ModelManagementMixin";

import ImagePreview from "./components/ImagePreview.vue";
import ImageUploadButton from "./components/ImageUploadButton.vue";
import ImageUploadInput from "./components/ImageUploadInput.vue";
import ImageCropper from "./components/ImageCropper.vue";

import Responsivity from "./domain/Responsivity";

export default {
  components: {
    ImagePreview,
    ImageUploadInput,
    ImageCropper,
    ImageUploadButton
  },
  name: "ImageDialog",
  props: {
    ...Responsivity.props(),
    filename: String
  },
  computed: {
    haveFileButNotMakePreviewYet() {
      return (!this.model || !this.model.preview) && this.selectedFile;
    },
    doesntHaveFileAndPreview() {
      return (!this.model || !this.model.preview) && !this.selectedFile;
    }
  },
  mixins: [ModelManagementMixin],
  watch: {
    selectedFile() {
      if (!this.image) {
        this.$emit("input", this.image);
      }
    }
  },
  data() {
    return {
      selectedFile: null
    };
  },
  methods: {
    loadFile(file) {
      this.selectedFile = file;
    },
    selectFile() {
      this.$refs.img_upload_input.selectFile();
    }
  }
};
</script>

<style></style>
