import RefundService from "@/app/Services/RefundService";

export default {
  methods: {
    removeRequestedFiles() {
      const toRemove = this.getFilesRemoved();
      const orders = [];

      toRemove.forEach(file => {
        orders.push(this.removeFile(file));
      });

      return Promise.all(orders);
    },
    async removeFile(file) {
      try {
        if (file.created) {
          return;
        }

        const deleteParams = {
          id: file.id,
          refund_id: this.refund.id
        };

        await RefundService.build().send(deleteParams, "/delete-file");
      } catch (error) {
        throw new Error("Ocorreu um erro ao atualizar os arquivos");
      }
    },
    getFilesRemoved() {
      return this.refund.files.filter(
        file => file.removed && typeof file.removed != "undefined"
      );
    },
    async save(
      message = "Reembolso enviado com sucesso",
      vreturnToList = true
    ) {
      try {
        this.saving = true;

        await this.removeRequestedFiles();

        const serializedRefundToSave = await this.serializeRefundToSave();

        let result = [];

        if (this.isToEdit()) {
          result = await RefundService.build().update(
            serializedRefundToSave,
            ""
          );
        } else {
          result = await RefundService.build().create(serializedRefundToSave);
        }

        if (vreturnToList) {
          this.returnToList();
        } else {
          this.refund = result;

          this.edit(this.refund);
        }

        this.showToast({ message });

        this.saving = false;
      } catch (error) {
        console.log(error);

        this.saving = true;

        this.defaultCatchError(error);
      } finally {
        this.saving = false;
        this.approving = false;
        this.disapproving = false;
      }
    },
    async serializeRefundToSave() {
      try {
        const serializedRefund = { ...this.refund };

        serializedRefund.payment_date = `${this.refund.payment_date}`;

        serializedRefund.files = this.getFilesWithoutNotManipulated();

        serializedRefund.justification =
          serializedRefund.justification.justification ||
          serializedRefund.justification;

        return serializedRefund;
      } catch (error) {
        console.error(error);

        return this.refund;
      }
    },
    getFilesWithoutNotManipulated() {
      return this.refund.files.filter(
        file =>
          !file.removed && file.created && typeof file.created != "undefined"
      );
    },
    onSave() {
      delete this.refund.status;

      if (!this.validate()) {
        return;
      }

      this.saving = true;

      this.save();
    },
    validate() {
      const valid = this.$refs.form.validate();

      if (!valid) {
        return valid;
      }

      if (
        String(this.refund.company_name).trim() == "" ||
        this.refund.company_name == null
      ) {
        this.errorMessage =
          "É necessário ao menos informar a empresa ou pagador!";

        this.valid = false;

        return false;
      }

      if (!this.hasFile()) {
        this.errorMessage =
          "É necessário ao menos um arquivo para comprovação do pagamento";

        this.valid = false;

        return false;
      }

      return true;
    },
    hasFile() {
      return this.refund.files.some(file => !file.removed);
    },
    async edit(item) {
      this.$router.push({ name: "RefundSave", params: { id: item.id } });
    }
  }
};
