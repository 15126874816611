import UserTypeAccessSave from "@/pages/UserTypeAccessSave/UserTypeAccessSave.vue";
import UserTypeAccess from "@/pages/UserTypeAccess/UserTypeAccess.vue";
import User from "@/pages/User/User.vue";
import UserRecord from "@/pages/User/UserRecord.vue";
import UserProfile from "@/pages/UserProfile/UserProfile.vue";
import HandlePreUser from "@/pages/HandlePreUser/HandlePreUser.vue";

const routes = [
  {
    path: "/allow-user/:hash",
    name: "AllowUser",
    component: HandlePreUser
  },
  {
    path: "/discard-user/:hash",
    name: "DiscardUser",
    component: HandlePreUser
  },
  {
    path: "/user-role-permission/:id",
    name: "UserTypeAccessSave",
    component: UserTypeAccessSave
  },
  {
    path: "/user/profile",
    name: "UserProfile",
    component: UserProfile
  },
  {
    description: "Tipos de Usuário",
    path: "/user-role-permission",
    name: "UserTypeAccess",
    group_type: "users",
    component: UserTypeAccess
  },
  {
    permission: ["/user/internal", "/user/external"],
    description: "Lista de Usuários",
    path: "/user",
    name: "User",
    group_type: "users",
    component: User
  },
  {
    path: "/user/:id?",
    name: "UserRecord",
    component: UserRecord
  }
];

export default routes;
