<template>
  <v-form ref="form" v-model="valid">
    <v-container fluid>
      <v-row>
        <v-col md="6" xs="12">
          <v-text-field
            label="Descrição"
            v-model="value.name"
            placeholder="Digite..."
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row v-if="value && value.id > 0">
        <v-col md="5" xs="12">
          <payer-select
            v-model="payer"
            field="name"
            label="Nome do Pagador"
            return-object
          />
        </v-col>
        <v-col md="2" xs="2">
          <v-btn @click="addPayer" :loading="registering" small>
            Adicionar
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="value && value.id > 0">
        <v-col md="12" xs="12">
          <v-data-table
            :headers="headers"
            :items="value.payerGroup"
            loading-text="Aguarde...carregando"
            :hide-default-footer="true"
            disable-pagination
            class="elevation-0"
            :header-props="{ sortIcon: null }"
          >
            <template #[`item.payer_status`]="{ item }">
              <v-switch
                inset
                color="secondary"
                v-model="item.payer_status"
                @change="save"
              />
            </template>

            <template #[`item.cnpj_cpf`]="{ item }">
              {{ maskCNPJ(item.cnpj_cpf) }}
            </template>

            <template #[`item.updated_at`]="{ item }">
              <v-btn
                v-if="canEditContract"
                icon
                @click="remove(item)"
                class="ma-2"
              >
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormMixin from "@/components/misc/mixins/FormMixin";
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";
import PayerGroupService from "@/app/Services/PayerGroupService";
import PayerSelect from "@/components/payer_select/PayerSelect.vue";

import { bus } from "@/main";

export default {
  components: { PayerSelect },
  name: "PayerGroupFormGeneral",
  mixins: [FormMixin, AuthorizationMixin],
  props: {
    value: Object,
    loading: Boolean
  },
  watch: {
    valid() {
      this.$emit("valid", this.valid);
    },
    value(updatedPayerGroup) {
      this.payerGroup = updatedPayerGroup;
    }
  },
  async created() {
    this.canEditContract = await this.canAccess({
      route: "/user/contract",
      type: "write"
    });
  },
  data() {
    return {
      responsivity: {
        maxWidth: "256px",
        maxHeight: "256px"
      },
      registering: false,
      payer: "",
      valid: false,
      canEditContract: false,
      headers: [
        {
          text: "Id",
          align: "start",
          value: "id",
          width: "40"
        },
        {
          text: "Nome",
          align: "start",
          value: "name"
        },
        {
          text: "CNPJ/CPF",
          align: "start",
          value: "cnpj_cpf"
        },
        {
          text: "Status",
          value: "payer_status",
          align: "center",
          width: "30"
        },
        { text: "#", value: "updated_at", align: "center", width: "30" }
      ]
    };
  },
  methods: {
    validate() {
      this.valid = this.$refs.form.validate();

      return this.valid;
    },
    remove(item) {
      bus.$emit("showNotificationDialog", {
        status: "error",
        title: "Deseja remover esse pagador?",
        continue: () => {
          const idx = this.value.payerGroup.findIndex(con => con.id == item.id);

          this.value.payerGroup.splice(idx, 1);

          this.save();
        }
      });
    },
    async save() {
      try {
        const payers = Object.assign([], this.value.payerGroup);

        const formPayers = {
          id: this.value.id,
          payers: payers
        };

        const service = PayerGroupService.build();

        this.payer = "";

        this.registering = true;

        const data = await service.update(formPayers);

        this.value.payerGroup = data.payerGroup;
      } catch (e) {
        console.log(e);
        this.registering = false;
        this.defaultCatchError(e);
      } finally {
        this.registering = false;
      }
    },
    async addPayer() {
      try {
        const payers = Object.assign([], this.value.payerGroup);

        payers.unshift({
          created: true,
          cnpj_cpf: this.payer.cnpj_cpf
        });

        const formPayers = {
          id: this.value.id,
          payers: payers
        };

        const service = PayerGroupService.build();

        this.payer = "";

        this.registering = true;

        const data = await service.update(formPayers);

        this.value.payerGroup = data.payerGroup;
      } catch (e) {
        console.log(e);
        this.registering = false;
        this.defaultCatchError(e);
      } finally {
        this.registering = false;
      }
    }
  }
};
</script>

<style></style>
