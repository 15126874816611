<template>
  <v-container fluid>
    <v-row class="h-unset" no-gutters>
      <v-col class="d-flex my-auto flex-4 min-width-300">
        <v-combobox
          label="Pesquisar"
          v-model="search.value"
          @onEnter="onSearch"
          :loading="loading"
          :items="search.options.items"
          item-text="text"
          item-value="value"
          :return-object="false"
          v-if="search.options.type == 'select'"
        />

        <date-picker
          label="Pesquisar"
          v-model="search.value"
          mode="date"
          append-icon="mdi-magnify"
          @onEnter="onSearch"
          :loading="loading"
          v-else-if="search.options.type == 'date'"
          clearable
        />

        <div
          v-else-if="search.options.type == 'date_range'"
          class="d-flex flex-item flex-row"
        >
          <date-picker
            label="Pesquisar de"
            class-name="min-width-150"
            v-model="search.value_from"
            append-icon=" "
            @onEnter="onSearch"
            :loading="loading"
            clearable
          />

          <date-picker
            class-name="px-3 min-width-150"
            label="até"
            v-model="search.value_to"
            append-icon="mdi-magnify"
            @onEnter="onSearch"
            :loading="loading"
            clearable
          />
        </div>

        <v-currency-field
          label="Pesquisar"
          v-model="search.value"
          append-icon="mdi-magnify"
          @onEnter="onSearch"
          :loading="loading"
          v-else-if="search.options.type == 'currency'"
          clearable
        />

        <v-text-field
          label="Pesquisar"
          v-else
          v-model="search.value"
          append-icon="mdi-magnify"
          @keydown.enter="onSearch"
          :loading="loading"
          v-mask="search.options.mask || null"
        />
      </v-col>

      <v-col class="d-flex my-auto flex-2">
        <v-btn class="text--secondary ml-auto mr-2" @click="onSearch">
          Pesquisar
        </v-btn>
      </v-col>

      <v-col class="d-flex my-auto">
        <v-btn @click="onClickFilter" class="text--secondary ml-auto mr-2">
          <v-icon>
            mdi-filter-variant
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="h-unset">
      <v-expand-transition>
        <default-filter-select
          class="default-filter__card"
          v-show="filtersIsVisible"
          v-model="search.options"
          :instructions="instructions"
          :unique-filter="uniqueFilter"
          :show="opened"
          @changeFilter="changeFilter"
          @toggleFilters="toggleFiltersWhenOpen"
        />
      </v-expand-transition>
    </v-row>
  </v-container>
</template>

<script>
import DatePicker from "../date_picker/DatePicker.vue";
import DefaultFilterSelect from "./menu/DefaultFilterSelect.vue";

export default {
  components: {
    DefaultFilterSelect,
    DatePicker
  },
  name: "DefaultFilter",
  props: {
    routeName: String,
    instructions: Object,
    uniqueFilter: Boolean
  },
  data() {
    return {
      search: {
        value: null,
        options: {
          type: "text",
          search_for: null,
          filter_for: []
        }
      },
      opened: false,
      filtersIsVisible: false,
      loading: false,
      timeoutVar: null
      // debounceSearch: this.$utils.registerDebounce(
      //   this.onSearch,
      //   1000,
      //   this.timeoutVar
      // )
    };
  },
  watch: {
    "search.options.type": {
      handler() {
        this.search.value = null;
        this.search.value_from = null;
        this.search.value_to = null;
      }
    },
    "search.options.filter_for": {
      deep: true,
      handler() {
        // this.debounceSearch();
      }
    },
    instructions: {
      deep: true,
      handler() {
        if (this.instructions.search_for[0]) {
          this.search.options.search_for = this.instructions.search_for[0].field;
          this.search.options.type =
            this.instructions.search_for[0].type || "text";
        }

        this.search.value = null;
        this.search.value_from = null;
        this.search.value_to = null;
      }
    }
  },
  created() {
    try {
      this.search.options.search_for = this.instructions.search_for[0].field;
    } catch (error) {
      throw new Error("Parâmetros de busca não definidos");
    }

    if (
      this.instructions.saveLocalStorage &&
      localStorage.getItem(this.routeName) != null
    ) {
      try {
        this.search = JSON.parse(localStorage.getItem(this.routeName)) || {};
      } catch (error) {
        console.log(error);
      }
    }

    this.changeFilter();

    this.onSearch();

    if (this.search.options.type == "date") {
      const dateFormatted = this.formatISODate(this.search.value);

      this.search.value = dateFormatted;
    }
  },
  methods: {
    toggleFiltersWhenOpen() {
      if (this.filtersIsVisible && !this.opened) {
        this.toggleFilters();
      }
    },
    toggleFilters() {
      this.filtersIsVisible = !this.filtersIsVisible;
    },
    onClickFilter() {
      this.opened = true;

      this.toggleFilters();

      setTimeout(() => (this.opened = false), 1500);
    },
    serializeFilters() {
      const serializedFilters = {};

      if (this.search.options.type == "date") {
        if (String(this.search.value).indexOf("/").length > 1) {
          this.search.value = this.toLuxonDate(this.search.value);
        }
      }

      if (this.uniqueFilter) {
        const filter = this.search.options.filter_for;

        serializedFilters[filter.field] = filter.value;

        return serializedFilters;
      }

      this.search.options.filter_for.forEach(filter => {
        if (serializedFilters[filter.field]) {
          serializedFilters[filter.field] = [
            serializedFilters[filter.field],
            filter.value
          ];

          return;
        }

        serializedFilters[filter.field] = filter.value;
      });

      return serializedFilters;
    },
    changeFilter() {
      if (this.instructions.saveLocalStorage) {
        localStorage.setItem(this.routeName, JSON.stringify(this.search));
      }

      const serializedFilters = this.serializeFilters();

      this.$emit("changeFilter", serializedFilters);
    },
    getValue() {
      if (this.search.options.type == "date_range") {
        return {
          start: this.search.value_from,
          final: this.search.value_to
        };
      } else {
        return this.search.value;
      }
    },
    onSearch() {
      const field = this.search.options.search_for;
      const value = this.getValue();

      this.changeFilter();

      const filters = this.serializeFilters();

      filters[field] = this.search.options.mask
        ? this.unmaskText(value)
        : value;

      this.$emit("search", filters);
    }
  }
};
</script>

<style>
.h-unset {
  height: unset !important;
}

.min-width-150 {
  min-width: 150px;
}

.min-width-300 {
  min-width: 300px;
}

.flex-4 {
  flex: 4;
}

.default-filter__card {
  position: relative;
  margin-bottom: -500px;
  margin-left: auto;
  right: 0px;
  z-index: 3;
  height: 100%;
  max-width: 640px;
}
</style>
