import ReadElectronicInvoiceService from "../http/ReadFileChangeShipService";

const Http = {
  async loadData() {
    try {
      const getApiQueryRequest = this.Http.getApiQueryRequest.bind(this);

      const query = getApiQueryRequest();

      this.loading = true;

      const response = await ReadElectronicInvoiceService.build().index({
        query
      });

      this.items = response.data || [];

      this.paginate(response);
    } catch (error) {
      this.defaultCatchError(error);
    } finally {
      this.loading = false;
    }
  },

  getApiQueryRequest() {
    const query = {
      params: {
        ...this.pagination,
        filter: this.filter
      }
    };

    return query;
  },

  async getReadElectronicInvoices(id) {
    const response = await ReadElectronicInvoiceService.build().show(id);

    return response.data;
  }
};

export default Http;
