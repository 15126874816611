<template>
  <default-layout :loading="loading" route-name="Feriados">
    <template v-slot:header-actions>
      <div class="d-flex flex-row ml-auto">
        <v-spacer />

        <default-cancel-button @click="cancel" />

        <default-save-button :loading="saving" @click="save" />
      </div>
    </template>

    <template v-slot:content>
      <v-container fluid class="mt-n6">
        <holiday-save-form v-model="holiday" />
      </v-container>
    </template>
  </default-layout>
</template>

<script>
import HolidaySaveForm from "./components/HolidaySaveForm.vue";
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import DefaultCancelButton from "@/components/layout/DefaultCancelButton.vue";
import DefaultSaveButton from "@/components/layout/DefaultSaveButton.vue";

import HolidayService from "@/app/Services/HolidayService";

export default {
  components: {
    HolidaySaveForm,
    DefaultLayout,
    DefaultCancelButton,
    DefaultSaveButton
  },
  name: "HolidaySave",
  data() {
    return {
      loading: false,
      saving: false,
      holiday: {}
    };
  },
  created() {
    if (this.isToEdit()) {
      this.loadData();
    }
  },
  methods: {
    async save() {
      try {
        this.saving = true;

        const service = HolidayService.build();
        const id = this.$route.params.id;

        if (id != "new") {
          await service.update(this.holiday);
        } else {
          delete this.holiday.id;

          await service.store(this.holiday);
        }

        this.holiday = null;

        this.$router.push({ name: "Holiday" });
      } catch (error) {
        console.log(error);
        this.defaultCatchError(error);
      } finally {
        this.saving = false;
        this.$forceUpdate();
      }
    },
    async loadData() {
      try {
        const id = this.$route.params.id;

        this.loading = true;
        this.holiday = {};

        const response = await HolidayService.build().show(id);

        this.holiday = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    isToEdit() {
      const id = this.$route.params.id;

      return id && id != "new";
    },
    validate() {
      const valid = this.$refs.HolidaySaveForm.validate();

      return valid;
    }
  }
};
</script>

<style></style>
