<template>
  <default-layout route-name="Câmbio" :loading="loading">
    <template #tabs>
      <v-tabs v-model="tab" color="secondary">
        <v-tab href="#usd" class="font-weight-bold">
          DÓLAR
        </v-tab>

        <v-tab href="#eur" class="font-weight-bold">
          EURO
        </v-tab>
      </v-tabs>
    </template>

    <template v-slot:header-actions>
      <div class="d-flex flex-row ml-auto my-2">
        <v-spacer />

        <authorization-gate
          auth-route="/exchange"
          auth-type="write"
          class="my-auto"
        >
          <default-save-button @click="add">
            Adicionar
          </default-save-button>
        </authorization-gate>
      </div>
    </template>

    <template #content>
      <v-container class="mt-n12" fluid>
        <v-tabs-items v-model="tab">
          <v-tab-item value="usd" class="font-weight-regular">
            <exchange-tab currency="USD" @load="changeLoad" />
          </v-tab-item>

          <v-spacer />

          <v-tab-item value="eur">
            <exchange-tab currency="EUR" @load="changeLoad" />
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </template>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import DefaultSaveButton from "@/components/layout/DefaultSaveButton.vue";

import ExchangeTab from "./ExchangeTab.vue";
import AuthorizationGate from "@/components/Authorization/AuthorizationGate.vue";

export default {
  components: {
    DefaultLayout,
    DefaultSaveButton,
    ExchangeTab,
    AuthorizationGate
  },
  name: "Exchange",
  data() {
    return {
      items: [],
      loading: false,
      tab: "usd"
    };
  },
  created() {},
  methods: {
    changeLoad(value) {
      this.loading = value;
    },
    add() {
      this.$router.push({
        name: "ExchangeSave",
        params: { id: "new" }
      });
    }
  }
};
</script>

<style></style>
