<template>
  <v-container fluid class="px-3 mt-n6">
    <v-row class="pa-0">
      <v-col sm="12" class="text-caption font-weight-bold">
        Descontos
      </v-col>

      <v-col
        sm="12"
        v-for="entry in invoice.entries"
        :key="entry.id"
        class="pa-0 ma-0"
      >
        <v-card class="pa-3 my-2 elevation-1">
          <!-- Descrição desconto -->
          <v-row no-gutters>
            <v-col cols="9">
              <div class="flex-item text-caption font-weight-bold">
                {{
                  entry.history && entry.history.message
                    ? entry.history.message
                    : ""
                }}
              </div>

              <div class="flex-item text-body-1">
                BRL {{ withoutSymbolCurrency(entry.value) }}
              </div>
              <div class="flex-item text-caption text--secondary">
                {{
                  discountIsOnlyForTodayAndInvoiceArentPayed(entry)
                    ? "Desconto aplicado para o dia atual"
                    : ""
                }}
              </div>
            </v-col>

            <v-col class="flex-item" cols="3">
              <default-remove-button
                classname="d-block ml-auto"
                @click="onRemove(entry)"
                :disabled="isRemovingOrCannotRemove()"
                :loading="removing"
              />
            </v-col>

            <v-col cols="9" v-if="entry.removing" class="mt-2">
              <v-text-field
                label="Motivo"
                v-model="motive"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="3"
              v-if="entry.removing"
              class="d-flex ml-auto my-auto"
            >
              <v-btn
                icon
                @click="removeDiscount(entry)"
                class="d-block ml-auto"
                :loading="removing"
                :disabled="!motive"
              >
                <v-icon>
                  mdi-check
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DefaultRemoveButton from "@/components/layout/DefaultRemoveButton.vue";
import { bus } from "@/main";

import InvoiceService from "@/app/Services/InvoiceService";
import FormMixin from "@/components/misc/mixins/FormMixin";
import InvoiceVerify from "../../domain/InvoiceVerify";

export default {
  components: { DefaultRemoveButton },
  name: "InvoiceDiscountInfo",
  props: {
    invoice: Object
  },
  mixins: [FormMixin],
  data() {
    return {
      removing: false,
      motive: "Remoção de Desconto"
    };
  },
  methods: {
    discountIsOnlyForTodayAndInvoiceArentPayed(entry) {
      if (!entry.only_for_one_day) {
        return false;
      }

      if ([4, 5, 6, 8, 9].includes(this.invoice.status_id)) {
        return false;
      }

      return true;
    },
    isRemovingOrCannotRemove() {
      if (this.removing) {
        return true;
      }

      const invoiceVerify = new InvoiceVerify();

      if (
        invoiceVerify.alreadyPayed(this.invoice) ||
        invoiceVerify.billOrPixGenerated(this.invoice)
      ) {
        return true;
      }

      return false;
    },
    async onRemove(entry) {
      entry.removing = true;

      this.$forceUpdate();
    },
    async removeDiscount(entry) {
      try {
        this.$emit("loading", true);
        this.removing = true;

        const query = {
          params: {
            motive: this.motive
          }
        };

        await InvoiceService.build()
          .discount()
          .destroy(entry.id, { query });

        this.invoice.entries = this.invoice.entries.filter(
          item => item.id != entry.id
        );

        this.showToast({ message: "Desconto removido com sucesso" });

        entry.removing = false;
        bus.$emit("include_discount_remove");
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.$emit("loading", false);
        this.removing = false;
      }
    },
    showDeleteDiscountDialog() {
      return new Promise((resolve, reject) => {
        bus.$emit("showNotificationDialog", {
          status: "error",
          title: `Deseja realmente remover esse desconto?`,
          continue: () => resolve(true),
          dispose: () => reject(false)
        });
      });
    }
  }
};
</script>

<style></style>
