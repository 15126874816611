import Rest from "../Base/Rest";

/**
 * @typedef {UserService}
 */
export default class UserService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/user";

  static getUserImage(user) {
    if (!user || !user.image) {
      return "@/assets/login/avatar_user.png";
    }

    const route = UserService.build().prepareRoute(`files/${user.image}`);

    return route;
  }
}
