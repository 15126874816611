<template>
  <div class="d-flex mx-auto my-auto flex-item flex-column text-center">
    <v-progress-circular
      class="unset-loading-indicator mx-auto mb-4"
      :color="primary ? 'primary' : 'secondary'"
      :width="4"
      :size="64"
      indeterminate
    />
    <span
      class="flex-item unset-loading-indicator--span"
      :class="primary ? 'primary--text' : 'secondary--text'"
    >
      Carregando...
    </span>
  </div>
</template>

<script>
export default {
  props: {
    primary: Boolean
  },
  name: "MainLoading"
};
</script>

<style lang="scss" scoped>
.d-flex {
  display: flex;
}

.flex-item {
  flex: 1;
}

.text-center {
  align-items: center;
}

.mb-4 {
  margin-bottom: 16px;
}

.flex-column {
  flex-direction: column;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.unset-loading-indicator {
  color: #ea5b0b;
  caret-color: #ea5b0b;

  &--span {
    color: #ea5b0b;
    font-family: "Helvetica Neue" !important;
  }
}
</style>
