<template>
  <v-card>
    <v-container class="pa-2 mb-4 bg-primary-100" fluid>
      <v-row no-gutters>
        <v-col sm="12" class="text-caption font-weight-bold">
          Taxas e Valores
        </v-col>

        <v-col sm="12">
          <v-container>
            <v-row no-gutters>
              <v-col
                cols="12"
                class="d-flex flex-column w-100"
                v-for="(groupedTax, key) in serializedInvoiceTax"
                :key="key"
              >
                <span
                  class="text-caption font-weight-bold flex-item"
                  v-show="groupedTax.name"
                >
                  {{ groupedTax.name || "TAXAS DESCONHECIDAS" }}
                </span>

                <v-row no-gutters v-show="groupedTax.name">
                  <extra-info-tile
                    class="pa-1"
                    v-for="(tax, key) in groupedTax.values"
                    :key="key"
                  >
                    <template #extra_info>
                      {{ tax.container }}
                    </template>

                    <template #message>
                      {{ tax.currency }} {{ tax.total | withoutSymbolCurrency }}
                    </template>
                  </extra-info-tile>
                </v-row>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <extra-info-tile>
                <template #title>
                  VALOR FINAL
                </template>

                <template #message>
                  {{ invoice.currency }}
                  {{ invoice.total | withoutSymbolCurrency }}
                </template>
              </extra-info-tile>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import ExtraInfoTile from "../ExtraInfoTile.vue";

export default {
  components: {
    ExtraInfoTile
  },
  name: "FreightInfo",
  props: {
    invoice: Object
  },
  computed: {
    serializedInvoiceTax() {
      const taxes = {};

      this.invoice.tax.forEach(tax => {
        if (tax.total <= 0) {
          return;
        }

        if (!taxes[tax.name]) {
          taxes[tax.name] = {
            name: tax.name,
            values: []
          };
        }

        taxes[tax.name].values.push(tax);
      });

      return Object.values(taxes);
    }
  }
};
</script>

<style></style>
