<template>
  <v-row align="center">
    <v-col md="5" xs="12">
      <v-autocomplete
        v-model="type"
        :items="types"
        :loading="searchTypeLoading"
        :search-input.sync="searchType"
        item-text="name"
        item-value="id"
        label="Adicionar Tipo"
        placeholder="Digite para pesquisar..."
        clearable
        prepend-icon="mdi-magnify"
        return-object
      ></v-autocomplete>
    </v-col>
    <v-col md="2" xs="2">
      <v-btn @click="addDiscountType" :loading="registering" small>
        Adicionar
      </v-btn>
    </v-col>
    <v-col cols="12" md="12" xs="12">
      <v-data-table
        :headers="headers"
        :items="groupTypes"
        loading-text="Aguarde...carregando"
        :hide-default-footer="true"
        disable-pagination
        class="elevation-0"
        :header-props="{ sortIcon: null }"
        :loading="loading"
      >
        <template #[`item.action`]="{ item }">
          <default-remove-button @click="removeType(item)" />
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import FormMixin from "@/components/misc/mixins/FormMixin";
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";
import InvoicesDiscountsTypesService from "@/app/Services/InvoicesDiscountsTypesService";
import DefaultRemoveButton from "@/components/layout/DefaultRemoveButton.vue";
import { bus } from "@/main";
// import DiscountApprovalGroupsService from "@/app/Services/DiscountApprovalGroupsService";

// import { bus } from "@/main";

export default {
  name: "DiscountApprovalGroupsTypes",
  mixins: [FormMixin, AuthorizationMixin],
  components: {
    DefaultRemoveButton
  },
  async created() {
    await this.getApprovalGroupType();
  },
  props: {
    approvalGroup: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      type: null,
      types: [],
      searchType: null,
      searchTypeLoading: false,
      loading: true,
      groupTypes: [],
      headers: [
        {
          text: "Id",
          align: "center",
          sortable: false,
          value: "type.id",
          width: 15
        },
        {
          text: "Tipo",
          align: "start",
          sortable: false,
          value: "type.name"
        },
        {
          text: "Ações",
          sortable: false,
          value: "action",
          align: "center",
          width: 15
        }
      ]
    };
  },
  methods: {
    async addDiscountType() {
      if (this.type) {
        try {
          this.loading = true;
          const type = {
            id_type: this.type.id,
            id_group: this.approvalGroup.id
          };

          await InvoicesDiscountsTypesService.build().storeApprovalGroupType(
            type
          );
          await this.getApprovalGroupType();

          this.loading = false;
          this.type = null;
          this.types = [];
        } catch (error) {
          this.error = this.getExceptionResponseMessage(error);
          this.defaultCatchError(error);
        } finally {
          this.searchTypeLoading = false;
          this.loading = false;
        }
      }
    },
    async getApprovalGroupType() {
      try {
        this.groupTypes = [];
        this.loading = true;

        console.log(this.approvalGroup.id);

        const response = await InvoicesDiscountsTypesService.build().getApprovalGroupType(
          this.approvalGroup.id
        );

        this.groupTypes = response;
      } catch (error) {
        this.error = this.getExceptionResponseMessage(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    removeType(item) {
      bus.$emit("showNotificationDialog", {
        status: "error",
        title: `Deseja remover esse tipo de desconto: "${item.type.name}"`,
        continue: async () => {
          try {
            await InvoicesDiscountsTypesService.build().deleteApprovalGroupType(
              item.id
            );
            await this.getApprovalGroupType();
          } catch (error) {
            this.error = this.getExceptionResponseMessage(error);
            this.defaultCatchError(error);
          } finally {
            this.loading = false;
          }
        }
      });
    }
  },
  watch: {
    async searchType(val) {
      try {
        if (this.searchTypeLoading) {
          return;
        }

        const nIds = this.groupTypes.map(type => type.id_type);

        const query = {
          params: {
            per_page: -1,
            filter: {
              type_name_like: val,
              type_not_in: nIds
            }
          }
        };

        this.searchTypeLoading = true;

        const response = await InvoicesDiscountsTypesService.build().index({
          query
        });

        this.types = response.data;
      } catch (error) {
        this.error = this.getExceptionResponseMessage(error);
        this.defaultCatchError(error);
      } finally {
        this.searchTypeLoading = false;
      }
    }
  }
};
</script>

<style></style>
