<template>
  <v-container fluid>
    <v-row>
      <v-col xs="12" lg="4" md="6">
        <v-text-field label="Nome" v-model="userRole.name" />
      </v-col>

      <v-col xs="12" lg="4" md="6">
        <v-select
          label="Status"
          v-model="userRole.status"
          :items="status_items"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AcessInfoForm",
  props: {
    value: Object
  },
  data() {
    return {
      userRole: this.value,
      status_items: [
        { text: "Ativo", value: 1 },
        { text: "Inativo", value: 0 }
      ]
    };
  },
  watch: {
    value() {
      this.userRole = this.value;
    },
    userRole() {
      this.$emit("input", this.userRole);
    }
  }
};
</script>

<style></style>
