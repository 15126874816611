<template>
  <default-layout
    :route-name="'Aprovações de Desconto'"
    :loading="loading"
    has-filters
    :instructions="instructions"
    @search="search"
  >
    <template v-slot:content>
      <v-container fluid>
        <approval-status-cards
          :cardIsActive="cardIsActive"
          :totalsApproval="totalsApproval"
          v-show="!loading"
        />

        <v-row v-show="!loading">
          <v-col cols="12">
            <discount-approvals-table
              @edit="edit"
              :pagination="pagination"
              :items="items"
              @remove="openRemoveDialog"
              :loading="loading"
            />
          </v-col>
        </v-row>

        <v-sheet color="grey lighten-5" class="pa-3" v-show="loading">
          <v-row>
            <v-col cols="4">
              <v-skeleton-loader type="card" height="150px" />
            </v-col>
            <v-col cols="4">
              <v-skeleton-loader type="card" height="150px" />
            </v-col>
            <v-col cols="4">
              <v-skeleton-loader type="card" height="150px" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-skeleton-loader type="table">
                <template v-slot:items>
                  <v-skeleton-loader type="text" width="100%" />
                </template>
              </v-skeleton-loader>
            </v-col>
          </v-row>
        </v-sheet>
      </v-container>
    </template>

    <template v-slot:footer>
      <pagination v-model="pagination" @change="loadData" />
    </template>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import DiscountApprovalsTable from "@/pages/DiscountApprovals/components/DiscountApprovalsTable.vue";
import DiscountApprovalService from "@/app/Services/DiscountApprovalService";
import ApprovalStatusCards from "@/pages/DiscountApprovals/components/ApprovalStatusCards.vue";
import FilterMixin from "@/components/Filters/FilterMixin";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import { bus } from "@/main";

export default {
  components: {
    DefaultLayout,
    DiscountApprovalsTable,
    ApprovalStatusCards
  },
  name: "DiscountApprovals",
  created() {
    bus.$on(
      "handleClickUpdateFilterStatus",
      this.handleClickUpdateFilterStatus
    );
    bus.$on(
      "update-discount-approval-list",
      this.handleUpdateDiscountApprovalList
    );
  },
  data() {
    return {
      loading: false,
      items: [],
      instructions: {
        search_for: [{ text: "Fatura", field: "invoice" }]
      },
      cardIsActive: null
    };
  },
  mixins: [PaginationMixin, FilterMixin],
  methods: {
    openRemoveDialog(item) {
      bus.$emit("showNotificationDialog", {
        status: "error",
        title: `Deseja Remover esse tipo de desconto: "${item.name}"`,
        continue: () => this.remove(item.id)
      });
    },
    async remove(id) {
      try {
        this.loading = true;

        await DiscountApprovalService.build().destroy(id);

        this.showToast({
          message: "Motivo removido com sucesso"
        });

        await this.loadData();
      } catch (error) {
        console.error(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    add() {
      this.$router.push({
        name: "DiscountTypesSave",
        params: { id: "new" }
      });
    },
    edit(item) {
      this.$router.push({
        name: "DiscountTypesSave",
        params: { id: item.id }
      });
    },
    async loadData() {
      try {
        const query = {
          params: {
            ...this.pagination,
            filter: this.filter
          }
        };

        this.loading = true;

        const response = await DiscountApprovalService.build().index({
          query
        });

        this.items = response.data.data;
        this.totalsApproval = response.count;

        this.paginate(response);
      } catch (error) {
        console.log(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
        this.$forceUpdate();
      }
    },
    async changePage(page) {
      this.pagination.page = page;

      await this.loadData();
    },
    async handleClickUpdateFilterStatus(status) {
      if (status.status == this.cardIsActive) {
        this.cardIsActive = null;
        this.filter["status"] = "";
      } else {
        this.cardIsActive = status.status;
        this.filter["status"] = status;
      }

      await this.loadData();
    },
    async handleUpdateDiscountApprovalList() {
      await this.loadData();
    }
  }
};
</script>

<style></style>
