<template>
  <v-col cols="12">
    <span class="text-caption text--secondary">
      Não encontradas/Com Erros {{ notFoundInvoices.length }} faturas de
      {{ getInvoiceLength() }}
    </span>

    <v-divider />

    <v-list-item
      v-for="(writeOffPayment, index) in notFoundInvoices"
      :key="index"
      :class="index % 2 ? 'bg-primary-100' : ''"
    >
      <v-list-item-content>
        <v-list-item-title>
          {{ writeOffPayment.invoice }}
        </v-list-item-title>

        <v-list-item-subtitle> </v-list-item-subtitle>

        <v-list-item-subtitle class="text-caption">
          {{ writeOffPayment.message }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-if="!notFoundInvoices.length">
      <v-list-item-content>
        <v-list-item-title>
          Nenhuma Fatura
        </v-list-item-title>

        <v-list-item-subtitle> </v-list-item-subtitle>

        <v-list-item-subtitle class="text-caption"> </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-col>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";

export default {
  name: "InvoiceSearchDialogNotFoundList",
  props: {
    searchedInvoices: Array
  },
  mixins: [ModelManagementMixin],
  computed: {
    notFoundInvoices() {
      if (!this.model || !this.model.length) {
        return [];
      }

      const notFoundInvoices = [];

      this.model.forEach(writeOffPayment => {
        if (writeOffPayment.status != "success") {
          notFoundInvoices.push(writeOffPayment);
        }
      });

      return notFoundInvoices;
    }
  },
  methods: {
    getInvoiceLength() {
      if (!this.model || !this.model.length) {
        return "0";
      }

      return this.model.length;
    }
  }
};
</script>

<style></style>
