<template>
  <v-container fluid>
    <v-layout fluid style="background: rgb(245, 245, 245)">
      <v-flex>
        <v-subheader>
          <strong class="lblUser">Beneficiários</strong>
          <!--
          <strong class="spaceL">|</strong>
          <strong class="spaceL btnHome">
            <v-icon color="grey darken-2"> mdi-home </v-icon>
          </strong>
          -->
        </v-subheader>
      </v-flex>
    </v-layout>
    <div class="interList">
      <div class="d-flex flex-row">
        <div class="alignButtons"></div>
      </div>
      <v-row align="center">
        <v-col class="d-flex" cols="3" sm="3"> </v-col>
        <v-col class="d-flex" cols="6" sm="6">
          <v-select
            :value="getEntity()"
            v-on:change="select($event)"
            hide-details
            :items="items"
            label="Beneficiária"
            clearable
          />
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col class="d-flex" cols="3" sm="3"> </v-col>
      </v-row>
      <v-row align="center">
        <v-col class="d-flex" cols="3" sm="3"> </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Vue from "vue";
import CompanyService from "@/app/Services/CompanyService";

const companyService = CompanyService.build();

export default Vue.extend({
  name: "CompanyEntity",
  data: () => ({
    loading: false,
    items: [],
    configTable: {
      per_page: 1,
      page: 1,
      filter: []
    }
  }),
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.loading = true;

      try {
        const response = await companyService.search(this.configTable);

        const items = response.data.data;

        for (const item in items) {
          this.items.push({ value: items[item].id, text: items[item].name });
        }

        this.$forceUpdate();
      } catch (httpError) {
        console.log(httpError);
        this.$swal({
          text:
            "Houve um problema ao tentar buscar os dados, favor tentar mais tarde!",
          icon: "error",
          confirmButtonText: "Confirmar"
        });
      } finally {
        this.loading = false;
      }
    },
    getEntity() {
      return localStorage.getItem("entity") || 0;
    },
    select($value) {
      localStorage.setItem("entity", $value);
    }
  },
  components: {}
});
</script>

<style lang="css" scoped>
.v-subheader {
  height: 57px;
  padding: 0;
}
.elevation-1 {
  padding: 10px 37px;
}
.lblUser {
  font-size: 18px;
}
.alignButtons {
  width: 230px;
  margin: 0;
  margin-left: auto;
  padding: 23px 0;
}
.custom-btn {
  font-size: 14px;
  font-family: "Helvetica Neue" !important;
  text-transform: none;
  margin: 10px;
  color: white;
}
.btnAdd {
  width: 177px;
  font-size: 11px;
  font-family: "Helvetica Neue" !important;
  text-transform: none;
  position: absolute;
  right: 20px;
  margin: 10px;
}
.linkRouter {
  list-style: none;
  display: inline-block;
}
.spaceL {
  margin-left: 10px;
}
.btnHome {
  height: 27px;
}
.contentMarginButtons {
  margin-top: 0;
}
</style>
