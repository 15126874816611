<template>
  <v-app id="app">
    <dialog-handler />

    <v-main class="main-clean-view--fixes">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import DialogHandler from "./NotificationDialog/DialogHandler.vue";

export default {
  components: { DialogHandler },
  name: "CleanView"
};
</script>

<style>
.main-clean-view--fixes > .v-main__wrap {
  height: 100% !important;
  width: 100% !important;
}
</style>
