import UserService from "@/app/Services/UserService";
import FileSaver from "file-saver";

export default {
  methods: {
    async download(file) {
      try {
        this.loading = true;

        if (file.created) {
          return;
        }

        const downloadParams = {
          id: file.id,
          user_id: this.model.user_id
        };

        const response = await UserService.build().send(
          downloadParams,
          "/contract/download",
          {
            responseType: "blob"
          }
        );

        FileSaver.saveAs(response, file.file_name);
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
