import FileSaver from "file-saver";
import { DateTime } from "luxon";
import ReportService from "@/app/Services/ReportService";

export default {
  async generateReport() {
    try {
      this.loading = true;

      const query = this.buildQuery();

      const response = await ReportService.build().index({ query });

      const reportData = await ReportService.build().download(response.file);

      const filename = response.name + `.csv`;

      const blob = new Blob([reportData], { type: "application/csv;" });

      FileSaver.saveAs(blob, filename);

      this.showToast({ message: "Relatório gerado com sucesso!" });
    } catch (error) {
      this.defaultCatchError(error);
    } finally {
      this.loading = false;
    }
  },
  async handleReport(fileMessage) {
    try {
      if (!fileMessage || !fileMessage.file) {
        return;
      }

      const response = await ReportService.build().download(fileMessage.file);

      const now = DateTime.now();

      const filedate = now.toFormat("dd_LL_yyyy");
      const filedescription = fileMessage.filename || "relatorio";

      const filename = `${filedescription}_${filedate}.csv`;

      const blob = new Blob([response], { type: "application/csv;" });

      FileSaver.saveAs(blob, filename);
    } catch (error) {
      this.defaultCatchError(error);
    }
  }
};
