import Login from "@/pages/Login.vue";

import AlterPassword from "@/pages/AlterPassword.vue";
import ForgetPassword from "@/pages/ForgetPassword.vue";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/change-password/:id",
    name: "AlterPassword",
    component: AlterPassword
  },
  {
    path: "/recover/:hash",
    name: "Recover",
    component: AlterPassword
  },
  {
    path: "/forget-password",
    name: "ForgetPassword",
    component: ForgetPassword
  }
];

export default routes;
