var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.render)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.pagination.itemsPerPage,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.status_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(_vm.getStatusType(item)))])]}},{key:"item.justification",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"word-truncate"},[_vm._v(_vm._s(item.justification))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateHour")(item.created_at))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-container',{staticClass:"pa-0 ma-0 mx-auto",attrs:{"fluid":""}},[_c('v-row',{staticClass:"d-flex flex-row",attrs:{"no-gutters":""}},[(_vm.canHandle && _vm.isAttendance(item))?_c('default-tooltip-button',{attrs:{"icon":"mdi-close","message":"Reprovar","loading":item.disapproving,"data-cy":"disapprove"},on:{"click":function($event){return _vm.openDisapproveFlow(item)}}}):_vm._e(),(_vm.canHandle && _vm.isAttendance(item))?_c('default-tooltip-button',{attrs:{"icon":"mdi-check","message":"Aprovar","loading":item.disapproving,"data-cy":"disapprove"},on:{"click":function($event){return _vm.openApproveFlow(item)}}}):_vm._e(),_c('v-btn',{staticClass:"my-auto flex-item",class:_vm.canHandle ? 'mx-auto' : 'ml-auto',attrs:{"icon":"","medium":"","data-cy":"edit"},on:{"click":function($event){return _vm.edit(item)}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.alreadyConfirmed(item) ? "mdi-eye" : "mdi-pencil")+" ")])],1)],1)],1)]}}],null,false,1545762389)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }