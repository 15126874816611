<template>
  <default-layout :loading="loading" route-name="Configurações">
    <template v-slot:header-actions>
      <div class="d-flex flex-row ml-auto">
        <v-spacer />

        <default-save-button :loading="saving" @click="save" />
      </div>
    </template>

    <template v-slot:content>
      <v-container fluid class="mt-n6">
        <settings-save-form v-model="settings" ref="SettingsSaveFormRef" />
      </v-container>
    </template>
  </default-layout>
</template>

<script>
import SettingsSaveForm from "./components/SettingsSaveForm.vue";
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import DefaultSaveButton from "@/components/layout/DefaultSaveButton.vue";

import SettingsService from "@/app/Services/SettingsService";

export default {
  components: {
    SettingsSaveForm,
    DefaultLayout,
    DefaultSaveButton
  },
  name: "SettingsSave",
  data() {
    return {
      loading: false,
      saving: false,
      settings: {}
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async save() {
      const formRef = this.$refs.SettingsSaveFormRef.getFormRef();

      try {
        if (!formRef.validate()) {
          throw new Error("Preencha todos os campos");
        }

        this.saving = true;

        const service = SettingsService.build();

        await service.store(this.settings);

        this.showToast({ message: "Configurações salvas com sucesso" });
      } catch (error) {
        this.defaultCatchError(error, error);
      } finally {
        this.saving = false;
        this.$forceUpdate();
      }
    },
    async loadData() {
      try {
        this.loading = true;
        this.settings = {};

        const response = await SettingsService.build().show("");

        this.settings = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    validate() {
      const valid = this.$refs.SettingsSaveFormRef.validate();

      return valid;
    }
  }
};
</script>

<style></style>
