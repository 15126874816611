<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="pagination.itemsPerPage"
    hide-default-footer
  >
    <template #item.created_at="{ item }">
      {{ item.created_at | formatDateHour }}
    </template>

    <template #item.actions="{ item }">
      <v-container>
        <v-row no-gutters>
          <v-col>
            <default-tooltip-button
              icon="mdi-magnify"
              message="Faturas Baixadas"
              @click="$emit('search_invoices', item)"
              :cy-data="`${item.id}_search`"
            />
          </v-col>

          <v-col>
            <default-tooltip-button
              icon="mdi-download"
              message="Baixar Arquivo"
              @click="$emit('download', item)"
              :loading="item.downloading"
              :cy-data="`${item.id}_download`"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-data-table>
</template>

<script>
import DefaultTooltipButton from "@/components/default/DefaultTooltipButton.vue";

export default {
  props: {
    items: Array,
    pagination: Object
  },
  components: {
    DefaultTooltipButton
  },
  name: "PaymentWriteOffTable",
  data() {
    return {
      headers: [
        {
          text: "Arquivo",
          align: "start",
          value: "file_name"
        },
        {
          text: "Data de Emissão",
          align: "center",
          value: "created_at"
        },
        {
          text: "",
          align: "center",
          width: "150",
          value: "actions"
        }
      ]
    };
  }
};
</script>

<style></style>
