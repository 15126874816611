<template>
  <default-layout :loading="loading" route-name="Câmbio">
    <template v-slot:header-actions>
      <div class="d-flex flex-row ml-auto my-auto">
        <v-spacer />

        <default-cancel-button @click="cancel" />

        <default-save-button :loading="saving" @click="save" />
      </div>
    </template>

    <template v-slot:content>
      <v-container fluid>
        <exchange-save-form v-model="exchange" ref="form" />
      </v-container>
    </template>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import DefaultCancelButton from "@/components/layout/DefaultCancelButton.vue";
import DefaultSaveButton from "@/components/layout/DefaultSaveButton.vue";

import ExchangeRateService from "@/app/Services/ExchangeRateService";
import ExchangeSaveForm from "./ExchangeSaveForm.vue";

export default {
  components: {
    DefaultLayout,
    DefaultCancelButton,
    DefaultSaveButton,
    ExchangeSaveForm
  },
  name: "ExchangeSave",
  data() {
    return {
      loading: false,
      saving: false,
      exchange: {
        id: "new"
      }
    };
  },
  created() {
    if (this.isToEdit()) {
      this.load();
    }
  },
  methods: {
    async load() {
      try {
        const id = this.$route.params.id;

        this.loading = true;
        this.exchange = {};

        const response = await ExchangeRateService.build().show(id);

        this.exchange = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async save() {
      try {
        this.saving = true;

        const isValid = this.validate();

        if (!isValid) {
          return;
        }

        const exchange = this.serializeData();

        const service = ExchangeRateService.build();

        if (exchange.id != "new") {
          await service.update(exchange);
        } else {
          delete exchange.id;

          await service.store(exchange);
        }

        this.exchange = null;

        this.$router.push({ name: "Exchange" });
      } catch (error) {
        console.log(error);
        this.defaultCatchError(error);
      } finally {
        this.saving = false;
        this.$forceUpdate();
      }
    },

    serializeData() {
      const exchange = { ...this.exchange };

      return exchange;
    },

    isToEdit() {
      const id = this.$route.params.id;

      return id && id != "new";
    },
    validate() {
      const valid = this.$refs.form.validate();

      return valid;
    }
  }
};
</script>

<style></style>
