<template>
  <v-hover v-slot="{ hover }">
    <v-col
      class="file-preview-item cursor-pointer ma-2 px-3 pt-1 pb-3 rounded"
      :class="hover ? 'bg-primary-400' : 'bg-primary-300'"
      @click="click"
    >
      <v-row no-gutters class="file-preview">
        <v-col cols="12" class="d-flex">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="!hideRemove"
                v-on="on"
                v-bind="attrs"
                icon
                @click="removeFile"
                class="white--text ml-auto"
              >
                <v-icon large> mdi-close </v-icon>
              </v-btn>
            </template>

            <span>Remover Arquivo</span>
          </v-tooltip>
        </v-col>

        <v-col cols="12" class="d-flex py-6">
          <v-icon x-large class="white--text mx-auto">
            {{ icon }}
          </v-icon>
        </v-col>

        <v-col
          cols="12"
          class="white--text text-subtitle-2 file-preview-truncate text-center"
        >
          {{ message }}
        </v-col>

        <v-col cols="12" v-if="loading">
          <v-progress-linear indeterminate />
        </v-col>
      </v-row>
    </v-col>
  </v-hover>
</template>

<script>
export default {
  name: "FileChip",
  props: {
    message: String,
    icon: String,
    loading: Boolean,
    hideRemove: Boolean
  },
  methods: {
    click() {
      this.$emit("click");
    },
    removeFile() {
      this.$emit("removeFile");
    }
  }
};
</script>

<style>
.file-preview {
  overflow: hidden;
}

.file-preview-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
