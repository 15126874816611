<template>
  <v-dialog v-model="model" width="60%">
    <v-card :loading="loading" class="no-scroll">
      <v-container fluid>
        <v-row>
          <v-col cols="12" xs="12">
            <span class="text-h5 flex-item">
              <slot name="header-title"> </slot>
            </span>
          </v-col>

          <v-col sm="12" v-if="!error">
            <v-divider />
          </v-col>
        </v-row>

        <v-row v-if="loading">
          <slot name="loading">
            <centralized-message>
              <template #message>
                Carregando...
              </template>
            </centralized-message>
          </slot>
        </v-row>

        <v-row v-else-if="error" class="mt-n6">
          <centralized-message>
            <template #message>
              {{ error }}
            </template>
          </centralized-message>
        </v-row>

        <v-row v-else class="mt-n6">
          <slot name="content"> </slot>
        </v-row>

        <v-row sm="12" class="mt-6">
          <slot name="actions"> </slot>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import CentralizedMessage from "../misc/CentralizedMessage.vue";
import ModelManagementMixin from "../misc/mixins/ModelManagementMixin";

export default {
  components: { CentralizedMessage },
  name: "Dialog",
  props: {
    loading: Boolean,
    error: String
  },
  mixins: [ModelManagementMixin]
};
</script>

<style></style>
