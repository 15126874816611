// Core
import Vue from "vue";
import Router from "vue-router";

import RouterMiddleware from "./router.middleware";

import routes from "./routes.js";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes
});

router.beforeEach(
  async (to, from, next) =>
    await RouterMiddleware.trait({
      router,
      to,
      from,
      next
    })
);

export default router;
