<template>
  <default-layout
    :route-name="'Importação de Arquivos'"
    :loading="loading"
    has-filters
    :instructions="instructions"
    @search="search"
  >
    <template #header-actions></template>

    <template #content>
      <audit-import-table :items="items" :pagination="pagination" />
    </template>

    <template #footer>
      <pagination v-model="pagination" @change="loadData" :loading="loading" />
    </template>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import AuditImportService from "@/app/Services/AuditImportService";
import AuditImportTable from "./Components/AuditImportTable.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import FilterMixin from "@/components/Filters/FilterMixin";

export default {
  components: {
    DefaultLayout,
    AuditImportTable,
    Pagination
  },
  name: "AuditImport",
  data() {
    return {
      items: [],
      instructions: {
        search_for: [
          { text: "Número da Fatura", field: "invoice" },
          { text: "Nome do Arquivo", field: "file_name" },
          { text: "Data do Arquivo", field: "created_at", type: "date" }
        ],
        filter_for: [
          { text: "Sucesso", field: "operation", value: "success" },
          { text: "Ignorado", field: "operation", value: "ignored" },
          { text: "Com erro", field: "operation", value: "error" }
        ]
      },
      loading: false
    };
  },
  mixins: [PaginationMixin, FilterMixin],
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        const query = {
          params: {
            ...this.pagination,
            filter: this.filter
          }
        };

        this.loading = true;

        const response = await AuditImportService.build().index({ query });

        this.items = response.data.map(item => {
          item.loading = false;

          return item;
        });

        this.paginate(response);
      } catch (error) {
        console.error(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
        this.$forceUpdate();
      }
    },
    validate() {
      const valid = this.$refs.AuditImport.validate();

      return valid;
    }
  }
};
</script>
