<template>
  <v-menu>
    <template #activator="{ on: onMenu }">
      <v-tooltip bottom>
        <template #activator="{ on: onTooltip }">
          <v-btn v-on="{ ...onMenu, ...onTooltip }" icon>
            <v-icon>
              {{ getExtraButtonIcon() }}
            </v-icon>
          </v-btn>
        </template>

        <span>
          {{ getExtraButtonMessage() }}
        </span>
      </v-tooltip>
    </template>

    <v-list>
      <v-list-item
        v-for="(extraBtn, key) in extraButtonItems"
        v-show="canAccessMenu(extraBtn, key)"
        :key="key"
        @click="extraBtn.action"
      >
        <v-list-item-title>
          {{ extraBtn.title }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { bus } from "@/main";

import InvoiceVerify from "../../domain/InvoiceVerify";

const invoiceVerify = new InvoiceVerify();

export default {
  name: "InvoiceExtraInfoButton",
  props: {
    item: Object,
    authorization: Object
  },
  data() {
    return {
      buttons: {
        extra_info: {
          passing: true,
          title: "Mais Informações",
          action: () => {
            bus.$emit("extra_info_show", this.item);
          }
        },
        include_discount: {
          auth: "canIncludeDiscount",
          title: "Incluir Desconto",
          action: () => {
            bus.$emit("include_discount_show", this.item);
          }
        },
        desunify: {
          title: "Desunificar",
          auth: "canDesunificate",
          action: () => {
            bus.$emit("desunificate", this.item);
          }
        },
        change_ship_date: {
          auth: "canChangeShipDate",
          title: "Alterar data do navio",
          action: () => {
            bus.$emit("alter_arrival_dialog_show", this.item);
          }
        },
        invoice_change_status: {
          auth: "canInvoiceChangeStatus",
          title: "Alterar Status",
          action: () => {
            bus.$emit("invoice_change_status", this.item);
          }
        },
        open_electronic_invoice: {
          passing: true,
          title: "Abrir NF-e",
          action: () => {
            if (this.item.url_electronic_invoice) {
              window.open(this.item.url_electronic_invoice);
            } else {
              this.showToast({
                message: `Não possui NF-e cadastrada.`,
                status: "info"
              });
            }
          }
        },
        open_electronic_invoice_receipt: {
          passing: true,
          title: "Abrir Fatura",
          action: () => {
            bus.$emit("open_electronic_invoice_receipt", this.item);
          }
        }
      }
    };
  },
  computed: {
    extraButtonItems() {
      if (invoiceVerify.isCreditNote(this.item)) {
        return [];
      }

      if (
        invoiceVerify.alreadyPayed(this.item) ||
        invoiceVerify.hasCanceledBill(this.item)
      ) {
        return [
          this.buttons.extra_info,
          this.buttons.open_electronic_invoice,
          this.buttons.open_electronic_invoice_receipt
        ];
      }

      if (invoiceVerify.billOrPixGenerated(this.item)) {
        if (invoiceVerify.isUnified(this.item)) {
          return [
            this.buttons.extra_info,
            this.buttons.desunify,
            this.buttons.open_electronic_invoice,
            this.buttons.open_electronic_invoice_receipt
          ];
        } else {
          return [
            this.buttons.extra_info,
            this.buttons.open_electronic_invoice,
            this.buttons.open_electronic_invoice_receipt
          ];
        }
      } else if (invoiceVerify.hasInvoiceEntry(this.item)) {
        if (invoiceVerify.isUnified(this.item)) {
          return [
            this.buttons.extra_info,
            this.buttons.open_electronic_invoice,
            this.buttons.open_electronic_invoice_receipt,
            this.buttons.include_discount,
            this.buttons.desunify,
            this.buttons.invoice_change_status
          ];
        } else {
          return [
            this.buttons.extra_info,
            this.buttons.open_electronic_invoice,
            this.buttons.open_electronic_invoice_receipt,
            this.buttons.include_discount,
            this.buttons.change_ship_date,
            this.buttons.invoice_change_status
          ];
        }
      }

      return [
        this.buttons.extra_info,
        this.buttons.invoice_change_status,
        this.buttons.open_electronic_invoice,
        this.buttons.open_electronic_invoice_receipt
      ];
    }
  },
  methods: {
    canAccessMenu(item) {
      if (item.passing) {
        return true;
      }

      return this.authorization[item.auth];
    },
    toggleMenu() {
      this.$refs.menu_handler.click();
    },
    getExtraButtonMessage() {
      if (invoiceVerify.alreadyPayed(this.item)) {
        return "Informações Adicionais";
      }

      return "Mais opções";
    },
    getExtraButtonIcon() {
      if (invoiceVerify.alreadyPayed(this.item)) {
        return "mdi-information";
      }

      return "mdi-menu";
    }
  }
};
</script>

<style></style>
