<template>
  <v-form ref="form" v-model="valid">
    <v-container fluid>
      <v-row
        v-show="handleFinancialMessage || alreadyConfirmedRefund"
        class="mt-6"
      >
        <v-card class="bg-primary-100 rounded pa-3 w-100 elevation-0">
          <v-text-field
            label="Motivo de Aprovação/Reprovação"
            v-model="model.financial_motive"
            :rules="[requiredIfHandleFinancialReason]"
            data-cy="financial_motive"
            :readonly="alreadyConfirmedRefund"
          />
        </v-card>
      </v-row>

      <v-row>
        <v-col lg="4" xs="12">
          <v-text-field
            label="Empresa"
            :rules="[rules.required]"
            v-model="model.company_name"
            :readonly="alreadyConfirmedRefund"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col lg="4" xs="12">
          <v-text-field
            label="Referência (Fatura, BL, Etc...)"
            :rules="[rules.required]"
            v-model="model.reference"
            data-cy="reference"
            :readonly="!canManipulate"
          />
        </v-col>

        <v-col lg="4" xs="12">
          <date-picker
            label="Data do Pagamento"
            v-model="model.payment_date"
            :rules="[rules.required]"
            data-cy="payment_date"
            :readonly="!canManipulate"
          />
        </v-col>

        <v-col lg="4" xs="12">
          <v-currency-field
            label="Valor do Pagamento"
            v-model="model.payment_value"
            :rules="[rules.required]"
            data-cy="payment_hour"
            :readonly="!canManipulate"
          />
        </v-col>

        <v-col lg="4" xs="12">
          <v-currency-field
            label="Valor da Solicitação de Reembolso"
            v-model="model.refund_value"
            :rules="[rules.required]"
            data-cy="payment_value"
            :readonly="!canManipulate"
          />
        </v-col>

        <v-col lg="4" xs="12">
          <refund-reason-select
            v-model="model.justification"
            :rules="[rules.required]"
            :readonly="!canManipulate"
          />
        </v-col>

        <v-col cols="4" xs="12">
          <RefundSelectChatStatus
            :canManipulate="
              userTypePermissions && userTypePermissions.canEditInternalUsers
            "
            v-model="model.status_id"
          />
        </v-col>
        <v-col cols="12" xs="12">
          <file-dialog
            v-model="model.files"
            @change="changeFiles"
            @download="download"
            :file-add-button-message="'Enviar Comprovante'"
            :can-manipulate="canManipulateUpload"
            :hide-remove="isHide()"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import FormMixin from "@/components/misc/mixins/FormMixin";
import DatePicker from "@/components/date_picker/DatePicker.vue";
import FileDialog from "@/components/FileUpload/FileDialog.vue";
import RefundReasonSelect from "./RefundReasonSelect.vue";
import RefundSelectChatStatus from "@/components/Refund/RefundSelectChatStatus.vue";
import Status from "../domain/Status";

export default {
  components: {
    FileDialog,
    RefundReasonSelect,
    DatePicker,
    RefundSelectChatStatus
  },
  name: "RefundSaveForm",
  props: {
    value: Object,
    canManipulate: Boolean,
    canManipulateUpload: Boolean,
    handleFinancialMessage: Boolean,
    userTypePermissions: Object
  },

  computed: {
    alreadyConfirmedRefund() {
      const approved = 7;
      const repproved = 8;
      const cancel = 9;

      return (
        this.model.status_id &&
        [approved, repproved, cancel].some(elem => elem == this.model.status_id)
      );
    }
  },
  mixins: [ModelManagementMixin, FormMixin, Status],
  data() {
    return {
      justifications: [],
      valid: false,
      statusFinish: [],
      isManipuled: false
    };
  },
  watch: {
    valid() {
      this.$emit("valid", this.valid);
    },
    "model.files": {
      handler() {
        this.valid = true;
      }
    }
  },
  methods: {
    isHide() {
      if (this.isToEdit()) {
        const result =
          this.userTypePermissions &&
          this.userTypePermissions.canEditInternalUsers;

        return !result;
      } else {
        return false;
      }
    },
    isToEdit() {
      const id = this.$route.params.id;

      return id && id != "new";
    },
    requiredIfHandleFinancialReason(value) {
      if (this.handleFinancialMessage) {
        return !!value || "Campo Necessário";
      }

      return null;
    },
    requiredIfPayer(value) {
      if (!this.model.payer) {
        return !!value || "Campo Necessário";
      }

      return true;
    },
    changeFiles() {
      this.valid = true;

      this.$emit("valid", this.valid);
    },
    download(file) {
      this.$emit("download", file);
    },
    validate() {
      return this.$refs.form.validate();
    }
  }
};
</script>

<style></style>
