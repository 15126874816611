<template>
  <v-data-table
    :headers="headers"
    :items="items"
    hide-default-footer
    class="elevation-0 mt-3 w-100"
  >
    <template #item.date="{ item }">
      {{ item.date | formatDate }}
    </template>

    <template #item.sales_tax="{ item }">
      {{ formatCurrency(item.sales_tax) }}
    </template>

    <template #item.sales_ptax="{ item }">
      {{ formatCurrency(getPtax(item.sales_tax)) }}
    </template>

    <template #item.buy_tax="{ item }">
      {{ formatCurrency(item.buy_tax) }}
    </template>

    <template #item.buy_ptax="{ item }">
      {{ formatCurrency(getPtax(item.buy_tax)) }}
    </template>

    <template #item.actions="{ item }">
      <authorization-gate
        auth-route="/exchange"
        auth-type="write"
        class="my-auto"
      >
        <exchange-table-actions
          :id="item.id"
          @edit="edit"
          @remove="remove"
          v-if="item.type == 'MANUAL'"
        />
      </authorization-gate>
    </template>
  </v-data-table>
</template>

<script>
import ExchangeTableActions from "./ExchangeTableActions.vue";

export default {
  components: { ExchangeTableActions },
  name: "ExchangeTable",
  props: {
    items: Array,
    pagination: Object
  },
  data() {
    return {
      headers: [
        {
          text: "Câmbio",
          sortable: false,
          value: "currency"
        },
        {
          text: "Data",
          sortable: false,
          value: "date"
        },
        {
          text: "PTAX Venda",
          sortable: false,
          value: "sales_tax"
        },
        {
          text: "PTAX Venda +4%",
          sortable: false,
          value: "sales_ptax"
        },
        {
          text: "PTAX Compra",
          sortable: false,
          value: "buy_tax"
        },
        {
          text: "PTAX Compra +4%",
          sortable: false,
          value: "buy_ptax"
        },
        {
          text: "",
          sortable: false,
          value: "actions",
          width: "100"
        }
      ]
    };
  },
  methods: {
    formatCurrency(value) {
      return this.toCurrency(value, {
        maximumFractionDigits: 4,
        minimumFractionDigits: 4
      });
    },
    getPtax(item) {
      const value = Number(item) * 0.04;

      const definedValue = value + Number(item);

      return definedValue;
    },
    edit(id) {
      this.$emit("edit", id);
    },
    remove(id) {
      this.$emit("remove", id);
    }
  }
};
</script>

<style></style>
