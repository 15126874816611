<template>
  <v-form v-model="valid" ref="form">
    <v-container>
      <v-row>
        <v-col cols="12" v-show="loading">
          <v-progress-linear
            indeterminate
            color="primary"
            class="d-flex flex-item mx-auto"
          />
        </v-col>

        <v-col xl="6" lg="6" md="6" sm="6" xs="12">
          <v-text-field
            label="Nome"
            v-model="discountType.name"
            placeholder="Digite..."
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormMixin from "@/components/misc/mixins/FormMixin";

export default {
  name: "DiscountTypesSaveForm",
  mixins: [FormMixin],
  props: {
    value: Object,
    loading: Boolean
  },
  data() {
    return {
      valid: {},
      discountType: this.value
    };
  },
  watch: {
    value(updatedDiscountType) {
      this.discountType = updatedDiscountType;
    },
    discountReason() {
      this.$emit("input", this.discountType);
    }
  },
  methods: {
    getFormRef() {
      return this.$refs.form;
    }
  }
};
</script>
