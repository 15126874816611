<template>
  <v-col class="w-100">
    <v-img
      :src="model"
      class="qrcode mx-auto d-flex"
      :max-width="qrcodeDefaultWidth"
      :max-height="qrcodeDefaultHeight"
    >
      <div
        class="qrcode-info flex-item d-flex flex-column flex-wrap mx-auto my-auto text-center py-2"
      ></div>
    </v-img>
  </v-col>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";

export default {
  name: "PixQrCodeError",
  mixins: [ModelManagementMixin],
  data() {
    return {
      qrcodeDefaultWidth: 280,
      qrcodeDefaultHeight: 280
    };
  }
};
</script>

<style lang="scss"></style>
