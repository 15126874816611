import axios from "axios";
import { bus } from "@/main";
import qs from "qs";

const standard = axios.create({
  headers: {
    "Content-Type": "application/json",
    post: {
      "Access-Control-Allow-Origin": "*"
    }
  },
  baseURL:
    // eslint-disable-next-line no-undef
    process.env.VUE_APP_API,
  timeout: 1020000,
  transformResponse: [
    function(data) {
      return data;
    }
  ],
  paramsSerializer: params => qs.stringify(params)
});

export function getAuthentication() {
  if (localStorage.getItem("user")) {
    const rawLoggedUser = localStorage.getItem("user");
    const loggedUser = JSON.parse(rawLoggedUser);

    return `${loggedUser.token_type} ${loggedUser.token}`;
  }

  return "none";
}

export function authenticateAllRoutes(config) {
  config.headers.common.Authorization = getAuthentication();

  return config;
}

const locationToRedirect = "/login";

export function redirectIfUnauthorized(error) {
  if (error && error.response && error.response.status === 401) {
    localStorage.removeItem("user");

    const alreadyInLogin =
      window.location.href.indexOf(locationToRedirect) != -1;

    if (!alreadyInLogin) {
      showUnauthenticatedDialog();
    }

    setTimeout(() => {
      if (!alreadyInLogin) {
        window.location = locationToRedirect;
      }
    }, 1300);
  }

  return Promise.reject(error);
}

export function showUnauthenticatedDialog() {
  bus.$emit("showToast", {
    status: "error",
    message:
      "Usuário sem permissão para essa operação, realize login e tente novamente."
  });
}

/**
 * Interceptor
 * responsavel por retornar o usuário a tela inicial
 * caso alguma requisicao retorne o codigo 401
 */
standard.interceptors.response.use(
  response => response,
  redirectIfUnauthorized
);

/**
 * Interceptor
 * adiciona o token a requisicao
 */
standard.interceptors.request.use(authenticateAllRoutes, error =>
  Promise.reject(error)
);

export default standard;
