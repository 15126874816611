export default {
  methods: {
    passwordVerification_oldPasswordRequired(value) {
      const anotherPasswordsFieldsAreDefined =
        !!this.model.new_password || !!this.model.confirm_password;

      if (anotherPasswordsFieldsAreDefined) {
        return !!value || "Campo necessário";
      }

      return null;
    },
    passwordVerification_newPasswordRequired(value) {
      const anotherPasswordsFieldsAreDefined =
        !!this.model.confirm_password || !!this.model.old_password;

      if (anotherPasswordsFieldsAreDefined) {
        return !!value || "Campo necessário";
      }

      return null;
    },
    passwordVerification_confirmPasswordRequired(value) {
      const anotherPasswordsFieldsAreDefined =
        !!this.model.old_password || !!this.model.new_password;

      if (anotherPasswordsFieldsAreDefined) {
        return !!value || "Campo necessário";
      }

      return null;
    },
    passwordVerification_passwordsMatch() {
      const areRedefiningPasswords = this.passwordVerification_areDefiningPasswords();

      const passwordsMatch =
        this.model.new_password == this.model.confirm_password;

      if (areRedefiningPasswords && !passwordsMatch) {
        return "Senhas não coincidem";
      }

      return null;
    },
    passwordVerification_areDefiningPasswords() {
      return (
        !!this.model.new_password ||
        !!this.model.confirm_password ||
        !!this.model.old_password
      );
    }
  }
};
