<template>
  <v-btn
    class="white--text ml-auto mr-6 my-auto"
    color="primary"
    @click="save"
    :text="text"
    :disabled="disabled"
    :loading="loading"
    data-cy="add"
  >
    <slot>Salvar</slot>
  </v-btn>
</template>

<script>
export default {
  name: "DefaultSaveButton",
  methods: {
    save() {
      this.$emit("save");
      this.$emit("click");
    }
  },
  props: {
    text: Boolean,
    disabled: Boolean,
    loading: Boolean
  }
};
</script>

<style></style>
