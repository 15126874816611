<template>
  <div class="w-100">
    <v-col cols="12" class="">
      <v-hover v-slot="{ hover }">
        <div
          @click="click"
          :class="hover ? 'bg-primary-300' : 'bg-primary-100'"
          class="
						text-center
						d-flex
						flex-column
						pa-12
						w-100
						cursor-pointer
					"
        >
          <span
            class="
							flex-item
							text-subtitle
							font-weight-bold
							my-4
						"
            :class="hover ? 'white--text' : 'text--secondary'"
          >
            {{ fileUploadButtonMessage }}
          </span>

          <v-icon
            class="flex-item mx-auto"
            :class="hover ? 'white--text' : 'text--secondary'"
            x-large
          >
            mdi-paperclip
          </v-icon>
        </div>
      </v-hover>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "FileUploadButton",
  props: {
    fileUploadButtonMessage: String
  },
  methods: {
    click() {
      this.$emit("click");
    }
  }
};
</script>

<style></style>
