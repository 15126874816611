<template>
  <v-dialog v-model="dialog" width="60%" @change="onToggle">
    <v-card :loading="loading">
      <v-container>
        <v-row>
          <v-col sm="12">
            <span class="text-h5"> Unificar </span>
          </v-col>
        </v-row>

        <v-col sm="12" class="d-flex flex-column" v-if="preview">
          <span class="flex-item text-subtitle-2 text--secondary mt-n2">
            Valor
          </span>
          <span class="flex-item text-h6">
            BRL {{ preview.total | withoutSymbolCurrency }}
          </span>

          <span class="flex-item text-subtitle-2 text--secondary mt-3">
            Número de Títulos
          </span>
          <span class="flex-item text-h6">
            {{ preview && preview.invoices ? preview.invoices.length : 0 }}
            títulos
          </span>
        </v-col>

        <v-row v-if="error">
          <centralized-message>
            <template #message>
              {{ error.message }}
            </template>

            <template #complemention>
              {{ error.complemention }}
            </template>
          </centralized-message>
        </v-row>

        <v-row v-else class="mt-n3">
          <v-col sm="12">
            <span class="text-subtitle-2 text--secondary">
              Faturas para unificar:
            </span>
          </v-col>

          <v-col sm="12" class="mh-vh-50 overflow-y-auto mt-n3">
            <div
              class="d-flex flex-column w-100 px-4 py-3"
              :class="index % 2 == 0 ? 'bg-hover' : ''"
              v-for="(item, index) in selected"
              :key="item.id"
            >
              <span class="text-overline mb-n2">
                Fatura: {{ item.invoice }}
              </span>

              <span class="text-h6">
                {{ item.payer.name }}
              </span>

              <span class="text-subtitle-1 mt-n1">
                {{ item.currency }}
                {{ formatedCurrency(item.total) }}
              </span>
            </div>
          </v-col>

          <v-divider />

          <v-col sm="12" class="mt-6 d-flex flex-row">
            <v-spacer />

            <v-btn class="font-weight-bold" @click="cancel" text color="text">
              Cancelar
            </v-btn>

            <v-btn
              class="font-weight-bold"
              color="primary"
              text
              @click="unify"
              :loading="loadingUnification"
              :disabled="this.error || loadingUnification"
            >
              Unificar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import InvoiceService from "@/app/Services/InvoiceService";
import CentralizedMessage from "@/components/misc/CentralizedMessage.vue";
import InvoiceVerify from "@/pages/Invoice/domain/InvoiceVerify";

export default {
  components: { CentralizedMessage },
  name: "UnifyDialogs",
  props: {
    value: Boolean,
    selected: Array
  },
  data() {
    return {
      dialog: this.value,
      loadingUnification: false,
      loading: false,
      error: false,
      preview: null
    };
  },
  watch: {
    value() {
      this.dialog = this.value;
    },
    dialog() {
      this.$emit("input", this.dialog);

      this.onToggle();
    }
  },
  methods: {
    onToggle() {
      if (!this.dialog) {
        return;
      }

      this.preview = null;

      this.updateUnifications();

      if (this.error) {
        return;
      }

      this.simulate();
    },
    formatedCurrency(item, options) {
      const value = this.withoutSymbolCurrency(item, options);

      const intValue = `${value}`.replace("-", "");

      return intValue;
    },
    calculatedTotal(item) {
      const invoiceTools = new InvoiceVerify();

      const total = invoiceTools.getTotalWithDiscounts(item);

      return total || 0;
    },
    updateUnifications() {
      this.error = null;

      if (!this.selected.length) {
        this.error = {
          message: "Nenhuma Fatura Selecionada",
          complemention: "Selecione uma fatura para continuar"
        };
      }

      if (this.selected.length == 1) {
        this.error = {
          message: "Não é possível unificar apenas um registro",
          complemention: ""
        };
      }

      if (this.hasDifferentPayers()) {
        this.error = {
          message: "Empresas Diferentes Selecionadas",
          complemention:
            "A unificação só pode ocorrer entre faturas da mesma empresa."
        };
      }

      if (this.hasDifferentTypes()) {
        this.error = {
          message: "Frete e Demurrage selecionados",
          complemention:
            "A unificação só pode ocorrer entre faturas ou demurrages, nunca entre os dois."
        };
      }
    },
    hasDifferentPayers() {
      const [firstInvoice] = this.selected;

      const hasDifferentPayers = this.selected.some(
        invoice => invoice.payer.id != firstInvoice.payer.id
      );

      return hasDifferentPayers;
    },

    hasDifferentTypes() {
      const [firstInvoice] = this.selected;

      const hasDifferentTypes = this.selected.some(invoice => {
        const result =
          invoice.type != firstInvoice.type &&
          firstInvoice.type != "credit_note" &&
          invoice.type != "credit_note";

        return result;
      });

      return hasDifferentTypes;
    },
    async simulate() {
      try {
        this.preview = {};
        this.loadingUnification = true;

        const invoicesIds = this.selected.map(invoice => invoice.id);
        const invoices = invoicesIds.join(",");

        const response = await InvoiceService.build().send(
          { invoices },
          "/simulate-unification"
        );

        this.preview = response.data;
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loadingUnification = false;
      }
    },
    async unify() {
      try {
        this.loadingUnification = true;

        const invoicesIds = this.selected.map(invoice => invoice.id);
        const invoices = invoicesIds.join(",");

        const response = await InvoiceService.build().send(
          { invoices },
          "/unification"
        );

        this.showToast({
          message: "Unificação realizada com Sucesso",
          status: "success"
        });

        this.dialog = false;

        this.$emit("unified", response.data);
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loadingUnification = false;
      }
    },
    cancel() {
      this.dialog = false;
    }
  }
};
</script>

<style></style>
