/**
 * @typedef Route
 * @property {String} url
 * @property {String|undefined} group_type
 * @property {String} description
 */

// Layouts
import AppView from "@/components/AppView.vue";
import LoginView from "@/components/LoginView.vue";
import CleanView from "@/components/CleanView.vue";

// Paginas
import AuthRoutes from "./routes/AuthRoutes";
import InvoiceRoutes from "./routes/InvoiceRoutes";
import AuditRoutes from "./routes/AuditRoutes";
import UserRoutes from "./routes/UserRoutes";
import CompanyRoutes from "./routes/CompanyRoutes";
import PayerRoutes from "./routes/PayerRoutes";
import SystemRoutes from "./routes/SystemRoutes";
import UtilityRoutes from "./routes/UtilityRoutes";
import ReadElectronicInvoiceRoutes from "./routes/ReadElectronicInvoiceRoutes";
import ReadFileChangeShipDateRoutes from "./routes/ReadFileChangeShipDateRoutes";
import SettingsRoutes from "./routes/SettingsRoutes";
import DiscountRoutes from "./routes/DiscountRoutes";

const appRoutes = [
  ...InvoiceRoutes,
  ...AuditRoutes,
  ...UserRoutes,
  ...CompanyRoutes,
  ...PayerRoutes,
  ...SystemRoutes,
  ...ReadElectronicInvoiceRoutes,
  ...ReadFileChangeShipDateRoutes,
  ...SettingsRoutes,
  ...DiscountRoutes
];

export { appRoutes };

/**
 * @type {Route[]}
 */
const routes = [
  {
    path: "/",
    component: LoginView,
    children: [...AuthRoutes]
  },
  {
    path: "/",
    component: CleanView,
    children: [...UtilityRoutes]
  },
  {
    path: "/",
    component: AppView,
    children: [
      ...appRoutes,
      {
        path: "*",
        redirect: "/home"
      }
    ]
  }
];

export default routes;
