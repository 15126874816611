var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"items-per-page":_vm.pagination.itemsPerPage,"hide-default-footer":true,"show-select":"","header-props":{ sortIcon: null }},scopedSlots:_vm._u([{key:"item.cnpj",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("VMask")(item.cnpj_cpf,"##.###.###/####-##")))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","color":"secondary","readonly":!_vm.authorization.canEdit,"true-value":1,"false-value":0},on:{"change":function($event){return _vm.changeStatus(item)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('default-edit-button',{attrs:{"onlyShow":!_vm.authorization.canEdit},on:{"click":function($event){return _vm.edit(item)}}})]}},{key:"item.control",fn:function(ref){
var item = ref.item;
return [_c('send-invitation-btn',{attrs:{"payer":item}})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})}
var staticRenderFns = []

export { render, staticRenderFns }