<template>
  <v-container fluid>
    <v-card :loading="loading">
      <v-container fluid>
        <v-form ref="form" v-model="valid">
          <v-tabs color="secondary" flat class="containerInner" v-model="tab">
            <v-tab href="#general" class="font-weight-bold">Gerais</v-tab>
            <v-tab
              href="#discount-type"
              class="font-weight-bold"
              v-if="approvalGroup && approvalGroup.id > 0"
              >Tipo de Desconto</v-tab
            >
            <v-tab
              href="#discount-user"
              class="font-weight-bold"
              v-if="approvalGroup && approvalGroup.id > 0"
              >Usuários</v-tab
            >
            <div class="d-flex flex-row alignButtons">
              <default-cancel-button />

              <default-save-button
                @click="save"
                :disabled="!valid"
                :loading="saving"
                @loadData="loadData()"
              />
            </div>
            <v-tab-item value="general">
              <discount-approval-groups-form-general
                v-model="approvalGroup"
                ref="FormGeneralRef"
              />
            </v-tab-item>

            <v-tab-item
              value="discount-type"
              v-if="approvalGroup && approvalGroup.id > 0"
            >
              <discount-approval-groups-types :approvalGroup="approvalGroup" />
            </v-tab-item>

            <v-tab-item
              value="discount-user"
              v-if="approvalGroup && approvalGroup.id > 0"
            >
              <discount-approval-groups-users :approvalGroup="approvalGroup" />
            </v-tab-item>
          </v-tabs>
        </v-form>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import DefaultCancelButton from "@/components/layout/DefaultCancelButton.vue";
import DiscountApprovalGroupsService from "@/app/Services/DiscountApprovalGroupsService";
import DefaultSaveButton from "@/components/layout/DefaultSaveButton.vue";

import DiscountApprovalGroupsFormGeneral from "@/pages/DiscountApprovalGroups/components/DiscountApprovalGroupsFormGeneral.vue";
import DiscountApprovalGroupsTypes from "@/pages/DiscountApprovalGroups/components/DiscountApprovalGroupsTypes.vue";
import DiscountApprovalGroupsUsers from "@/pages/DiscountApprovalGroups/components/DiscountApprovalGroupsUsers.vue";

export default {
  components: {
    DefaultCancelButton,
    DefaultSaveButton,
    DiscountApprovalGroupsFormGeneral,
    DiscountApprovalGroupsTypes,
    DiscountApprovalGroupsUsers
  },
  name: "DiscountApprovalGroupsSave",
  data() {
    return {
      loading: false,
      saving: false,
      valid: false,
      approvalGroup: {
        range: [0, 99999]
      }
    };
  },
  created() {
    if (this.isToEdit()) {
      this.loadData();
    }
  },
  methods: {
    isToEdit() {
      return this.$route.params.id != "new";
    },
    async save() {
      const formRef = this.$refs.FormGeneralRef.getFormRef();

      try {
        if (!formRef.validate()) {
          throw new Error("Preencha todos os campos");
        }

        this.saving = true;

        const service = DiscountApprovalGroupsService.build();

        if (this.isToEdit()) {
          delete this.approvalGroup.payers;

          await service.update(this.approvalGroup);

          this.returnToListPage();
        } else {
          this.approvalGroup = (await service.store(this.approvalGroup)).data;

          this.$router.push({
            name: "DiscountApprovalGroupsSave",
            params: { id: this.approvalGroup.id }
          });
        }
      } catch (error) {
        this.defaultCatchError(error, error);
      } finally {
        this.saving = false;
      }
    },
    async loadData() {
      try {
        const id = this.$route.params.id;

        this.loading = true;

        const response = await DiscountApprovalGroupsService.build().show(id);

        this.approvalGroup = {
          ...response.data,
          range: [response.data.min_value, response.data.max_value]
        };
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    validate() {
      const valid = this.$refs.approvalGroupForm.validate();

      return valid;
    },
    setValidation(valid) {
      this.valid = valid;
    },
    returnToListPage() {
      this.$router.push({ name: "DiscountApprovalGroups" });
    }
  }
};
</script>

<style></style>
