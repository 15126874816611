import Rest from "../Base/Rest";

/**
 * @typedef {PayerDiscountRuleService}
 */
export default class PayerDiscountRuleService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/payer-discount-rule";
}
