<template>
  <v-footer padless style="background-color: #eeeeee;" class="w-100">
    <v-col cols="12" class="footer--fix-menu d-flex flex-row">
      <v-spacer />

      <a
        href="https://innovaconnect.com.br/"
        class="d-flex flex-column text-decoration-none"
      >
        <span class="text-caption text--secondary font-weight-medium">
          {{ new Date().getFullYear() }} - Desenvolvido por
        </span>

        <innova-connect-logo
          class="mr-6"
          width="150px"
          text-color="grey"
          logo-dark-color="grey"
          logo-light-color="lightgrey"
        />
      </a>
    </v-col>
  </v-footer>
</template>

<script>
import innovaConnectLogo from "./misc/innovaConnectLogo.vue";

export default {
  components: { innovaConnectLogo },
  name: "AppNavigationFooter"
};
</script>

<style lang="scss">
.footer--fix-menu {
  padding-left: 100px;
}
</style>
