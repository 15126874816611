<template>
  <v-autocomplete
    label="Beneficiário"
    :multiple="multiple"
    color="primary"
    :items="items"
    :item-text="getCompanyText"
    :search-input.sync="searchInput"
    :loading="loading"
    :rules="rules != 'undefined' ? rules : []"
    :readonly="readonly"
    v-model="selected"
    return-object
  />
</template>

<script>
import CompanyService from "@/app/Services/CompanyService";

export default {
  name: "CompanySelect",
  props: {
    value: Object,
    rules: Array,
    multiple: Boolean,
    readonly: Boolean
  },
  data() {
    return {
      items: [],
      selected: this.value,
      loading: false,
      searchInput: "",
      timeoutVar: null,
      debounceSearch: this.$utils.registerDebounce(
        this.search,
        1000,
        this.timeoutVar
      )
    };
  },
  mounted() {
    this.search();
  },
  watch: {
    searchInput() {
      const isntMultipleSelect = !this.multiple;
      const areSelectingCompany = this.searchInput == this.select?.name;

      if (isntMultipleSelect && areSelectingCompany) {
        return;
      }

      this.loading = true;

      this.debounceSearch(this.searchInput);
    },
    selected() {
      this.$emit("input", this.selected);
    },
    value() {
      if ((!this.items || !this.items.length) && this.value) {
        if (this.multiple) {
          this.items = [...this.value];
        } else {
          this.items = [this.value];
        }
      }

      this.selected = this.value;
    }
  },
  methods: {
    getCompanyText(item) {
      const formatedCnpj = this.maskCNPJ(item.cnpj);

      return `${formatedCnpj} - ${item.name}`;
    },
    async search(value = "") {
      try {
        const query = {
          params: {
            filter: {}
          }
        };

        if (value) {
          query.params.filter.name = value;
        }

        const response = await CompanyService.build().index({ query });

        if (response.data.length < 1) {
          return;
        }

        this.items = [...this.items, ...response.data];

        this.$emit("loadedData", this.items);

        this.$forceUpdate();
      } catch (error) {
        this.error = this.getExceptionResponseMessage(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
