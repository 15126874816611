<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col sm="12" class="text-caption font-weight-bold">
        Containers
      </v-col>

      <v-col
        sm="12"
        v-for="(demurrage, index) in invoice.charge_period"
        :key="demurrage.id"
        :class="index % 2 == 0 ? 'bg-primary-100' : ''"
        class="flex-row d-flex flex-wrap pa-2"
      >
        <extra-info-tile>
          <template #title>
            Nº do Container
          </template>

          <template #message>
            {{ demurrage.container }}
          </template>
        </extra-info-tile>

        <extra-info-tile>
          <template #title>
            Tipo de Container
          </template>

          <template #message>
            {{ demurrage.container_type }}
          </template>
        </extra-info-tile>

        <extra-info-tile />

        <extra-info-tile>
          <template #title>
            Periodo de Cobrança
          </template>

          <template #message>
            {{ demurrage.start_date | formatDate }} -
            {{ demurrage.end_date | formatDate }} ({{
              getDaysBetween(demurrage)
            }})
          </template>
        </extra-info-tile>

        <extra-info-tile>
          <template #title>
            Valor Dia
          </template>

          <template #message>
            {{ demurrage.currency }}
            {{ withoutSymbolCurrency(demurrage.unitary_value) }}
          </template>
        </extra-info-tile>

        <extra-info-tile>
          <template #title>
            Valor Total
          </template>

          <template #message>
            {{ demurrage.currency }}
            {{ withoutSymbolCurrency(demurrage.total) }}
          </template>
        </extra-info-tile>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ExtraInfoTile from "../ExtraInfoTile.vue";

export default {
  components: { ExtraInfoTile },
  name: "ExtraInfoDialog",
  props: {
    invoice: Object
  },
  methods: {
    getDaysBetween(demurrage) {
      return `${demurrage.quantity || 0} Dias`;
    }
  }
};
</script>
