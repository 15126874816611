<template>
  <v-container fluid>
    <breadcrumb route-name="Usuários" />

    <v-card depressed>
      <v-container fluid>
        <v-row>
          <v-col xl="6" lg="6" md="6" sm="12" xs="12">
            <default-filter :instructions="instructions" @search="search" />
          </v-col>

          <v-col xl="6" lg="6" md="6" sm="12" xs="12" class="d-flex">
            <v-spacer />

            <authorization-gate
              :auth-route="['/user/external', '/user/internal']"
              auth-type="write"
              class="d-flex flex-item"
            >
              <generate-reports-button
                table="powers_of_attorney"
                label="Gerar Relatório de Procurações"
                filename="relatorio_procuracoes"
                :filter="filter"
              />

              <v-btn
                class="ml-auto text-normal my-auto mr-6"
                color="primary"
                @click="add()"
              >
                Adicionar
              </v-btn>
            </authorization-gate>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="items"
              :items-per-page="pagination.itemsPerPage"
              @update:options="sortRequest"
              v-bind:loading="loading"
              loading-text="Aguarde...carregando"
              :hide-default-footer="true"
              class="elevation-0"
              :header-props="{ sortIcon: null }"
            >
              <template #[`item.status`]="{ item }">
                <v-switch
                  inset
                  color="secondary"
                  v-model="item.custom_status"
                  :readonly="!verifyIfCanEditByUserAccess(item)"
                  @change="changeStatus(item)"
                />
              </template>

              <template #[`item.resend`]="{ item }">
                <v-btn @click="reesendPassword(item)" medium class="mr-2" icon>
                  <v-icon>
                    mdi-lock
                  </v-icon>
                </v-btn>
              </template>

              <template #[`item.last_login`]="{ item }">
                {{ formatMysqlDate(item.last_login, { withHour: true }) }}
              </template>

              <template #[`item.action`]="{ item }">
                <v-icon @click="edit(item)" medium class="mr-2">
                  {{
                    verifyIfCanEditByUserAccess(item) ? "mdi-pencil" : "mdi-eye"
                  }}
                </v-icon>
              </template>
            </v-data-table>
          </v-col>
          <v-col cols="12">
            <v-container class="max-width" fluid>
              <pagination v-model="pagination" @change="loadData" />
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import Vue from "vue";
import UserService from "@/app/Services/UserService";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";
import Pagination from "@/components/pagination/Pagination.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import { bus } from "@/main";
import FilterMixin from "@/components/Filters/FilterMixin";
import DefaultFilter from "@/components/Filters/DefaultFilter.vue";
import AuthorizationGate from "@/components/Authorization/AuthorizationGate.vue";
import SortableMixin from "@/components/sort/SortableMixin";
import UserTypeAccessMethods from "./Domain/UserTypeAccessMethods";
import GenerateReportsButton from "@/components/Reports/GenerateReportsButton.vue";

const userService = UserService.build();

export default Vue.extend({
  name: "Users",
  components: {
    Breadcrumb,
    Pagination,
    DefaultFilter,
    AuthorizationGate,
    GenerateReportsButton
  },
  data() {
    return {
      loading: false,
      instructions: {
        search_for: [
          { text: "Nome", field: "name" },
          { text: "Login", field: "login" },
          { text: "E-mail", field: "email" },
          { text: "Tipo de Usuário", field: "user_role" },
          {
            text: "Status de contrato",
            field: "contract_status",
            type: "select",
            items: [
              { value: "vencido", field: "status", text: "Vencidos" },
              { value: "vencer", field: "status", text: "Á Vencer" }
            ]
          }
        ],
        filter_for: [
          { text: "Ativos", field: "status", value: 1 },
          { text: "Inativos", field: "status", value: 0 }
        ]
      },
      headers: [
        {
          text: "Nome",
          align: "start",
          value: "name"
        },
        { text: "E-mail", value: "email" },
        { text: "Login", value: "login" },
        { text: "Tipo de usuários", value: "user_role.name", sortable: false },
        { text: "Status", value: "status", align: "center", width: "100" },
        {
          text: "Últ. Acesso",
          value: "last_login",
          align: "center",
          width: "100"
        },
        {
          text: "Ações",
          value: "action",
          align: "center",
          width: "100",
          sortable: false
        }
      ],
      reesend_password_header: {
        text: "Reenvio Senha",
        value: "resend",
        align: "center",
        width: "5%",
        sortable: false
      },
      authorization: {
        route: "/user"
      },
      user_type_permissions: null,
      items: [],
      configTable: {
        per_page: 10,
        page: 1,
        filter: []
      }
    };
  },
  created() {
    this.loadData();

    this.loadPermissions();
  },
  mixins: [AuthorizationMixin, PaginationMixin, FilterMixin, SortableMixin],
  methods: {
    async loadPermissions() {
      const reesendPassword = await this.canAccess({
        route: "/user/reesend-password",
        type: "exec"
      });

      if (reesendPassword) {
        this.headers.push(this.reesend_password_header);
      }

      await new UserTypeAccessMethods()
        .setScope(this)
        .loadUserTypeByPermission();

      this.user_type_permissions = await new UserTypeAccessMethods()
        .setScope(this)
        .getAccess();

      this.$forceUpdate();
    },
    verifyIfCanEditByUserAccess(user) {
      return UserTypeAccessMethods.verifyIfCanEditByUserAccess(
        user,
        this.user_type_permissions
      );
    },
    async loadData() {
      try {
        this.loading = true;

        const query = {
          params: {
            ...this.pagination,
            filter: this.filter,
            orderBy: this.orderBy()
          }
        };

        const response = await userService.index({ query });

        this.items = response.data;

        this.items = this.items.map(item => {
          item.status = item.status == 1;
          item.custom_status = item.status;

          return item;
        });

        this.paginate(response);

        this.$forceUpdate();
      } catch (error) {
        console.log(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    handlePageChange(page) {
      this.configTable.page = page;
      this.loadData();
    },
    async reesendPassword(user) {
      const result = await this.showReesendPasswordDialog(user);

      if (!result) {
        return;
      }

      try {
        this.loading = true;

        await userService.send(user, "reesend-password");

        this.showToast({
          message: "E-mail para recuperação de senha enviado",
          status: "success"
        });
      } catch (httpError) {
        this.defaultCatchError(httpError);
      } finally {
        this.loading = false;
      }
    },
    showReesendPasswordDialog(user) {
      return new Promise((resolve, reject) => {
        bus.$emit("showNotificationDialog", {
          status: "",
          title: `Realmente deseja enviar um e-mail de recuperação de senha para ${user.name}?`,
          continue: () => resolve(true),
          dispose: () => reject(false)
        });
      });
    },
    async showInvitationProccess(user) {
      const response = await this.showInvitationDialog();

      if (!response) {
        user.custom_status = !user.custom_status;

        return;
      }

      this.sendInvitation(user);
    },
    async sendInvitation(user) {
      try {
        this.loading = true;

        await UserService.build().send(user, "send-invitation");

        this.showToast({
          message:
            "Email de convite enviado, o usuário já pode acessar o sistema",
          status: "success"
        });
      } catch (error) {
        console.error(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    showInvitationDialog() {
      return new Promise((resolve, reject) => {
        bus.$emit("showNotificationDialog", {
          status: "",
          title: `Usuário ainda não ativo`,
          message: "Deseja enviar um convite para acesso deste usuário?",
          continue: () => resolve(true),
          dispose: () => reject(false)
        });
      });
    },
    async changeStatus(item) {
      if (item.custom_status && !item.last_login) {
        await this.showInvitationProccess(item);
      } else {
        try {
          this.loading = true;
          await userService.update({ id: item.id, status: item.custom_status });
        } catch (e) {
          console.log(e);
          this.defaultCatchError(e);

          item.custom_status = !item.custom_status;
        } finally {
          this.loading = false;
        }
      }
    },
    add() {
      this.$router.push({ name: "UserRecord", params: { id: "new" } });
    },
    edit(row) {
      this.$router.push({ name: "UserRecord", params: { id: row.id } });
    }
  }
});
</script>

<style lang="css" scoped>
.v-subheader {
  height: 57px;
  padding: 0;
}
.elevation-1 {
  padding: 10px 37px;
}
.lblUser {
  font-size: 18px;
}
.custom-btn {
  font-size: 14px;
  font-family: "Helvetica Neue" !important;
  text-transform: none;
  margin: 10px;
  color: white;
}
.alignButtons {
  width: 230px;
  margin: 0;
  margin-left: auto;
  padding: 23px 0;
}
.btnAdd {
  font-size: 11px;
  font-family: "Helvetica Neue" !important;
  text-transform: none;
  position: absolute;
  right: 20px;
  margin: 10px;
}
.spaceL {
  margin-left: 10px;
}
.btnHome {
  height: 27px;
}
</style>
