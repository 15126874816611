import Rest from "../Base/Rest";

/**
 * @typedef {RefundMessageService}
 */
export default class RefundMessageService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/invoice/refund/message";

  loadChatStatus(params) {
    return this.get("api/invoice/refund/status", {
      params
    });
  }
}
