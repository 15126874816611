<template>
  <v-dialog v-model="dialog" width="60%">
    <v-card :loading="loading">
      <v-row class="py-2">
        <v-col cols="12" xs="12">
          <span class="text-h5 flex-item"> {{ getText }} Boleto </span>
        </v-col>
      </v-row>

      <v-row v-if="loading">
        <centralized-message>
          <template #message> Carregando... </template>
        </centralized-message>
      </v-row>

      <v-row v-else-if="error">
        <centralized-message>
          <template #message>
            {{ error.message }}
          </template>

          <template #complemention>
            {{ error.complemention }}
          </template>
        </centralized-message>
      </v-row>

      <v-row v-else class="mt-n6">
        <v-col cols="12" xs="12" class="d-flex flex-row">
          <preview-billet-dialog-form v-model="preview" />
        </v-col>
      </v-row>

      <v-col sm="12" class="pb-6 d-flex flex-row">
        <!-- disclaimer -->
        <v-container>
          <v-row no-gutters>
            <v-col cols="12" class="d-flex flex-row">
              <v-spacer />

              <v-btn class="font-weight-bold" @click="cancel" text color="text">
                Fechar
              </v-btn>

              <v-btn
                class="font-weight-bold"
                color="primary"
                text
                @click="generateBillet"
                :loading="generating"
                :disabled="loading || error || !preview"
              >
                {{ getText }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row
            no-gutters
            class="mb-n6"
            v-show="invoice && invoice.status_id == 1"
          >
            <v-col cols="12" class="d-flex flex-row">
              <span class="text--secondary text-caption ml-auto">
                Ao clicar em Gerar, o boleto será registrado com a data de hoje
                ({{ getTime() }})
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import { bus } from "@/main";
import { DateTime } from "luxon";

import FileSaver from "file-saver";
import InvoiceService from "@/app/Services/InvoiceService";
import PreviewBilletDialogForm from "./components/PreviewBilletDialogForm.vue";
import CentralizedMessage from "../misc/CentralizedMessage.vue";

export default {
  components: { PreviewBilletDialogForm, CentralizedMessage },
  name: "PreviewBilletDialog",
  data() {
    return {
      preview: null,
      invoice: null,
      error: null,
      loading: false,
      generating: false,
      dialog: false
    };
  },
  created() {
    bus.$on("preview_billet_dialog_show", this.showDialog);
  },
  computed: {
    getText() {
      if (this.invoice && this.invoice.status_id == 1) {
        return "Gerar";
      }

      return "Visualizar";
    }
  },
  methods: {
    getTime() {
      const now = DateTime.now();

      return now.setLocale("pt-BR").toFormat("D");
    },
    showDialog(invoice) {
      this.invoice = invoice;
      this.dialog = true;

      this.loadPreview(invoice);
    },
    cancel() {
      this.dialog = false;
    },
    async loadPreview(invoice) {
      try {
        this.loading = true;

        const response = await InvoiceService.build().generateSimulate(
          invoice.id
        );

        this.preview = response.data;
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    async generateBillet() {
      try {
        this.generating = true;

        const response = await InvoiceService.build().generateBillet(
          this.invoice.id
        );

        const now = DateTime.now();

        const formatedDate = now.setLocale("pt-BR").toFormat("dd_LL_yyyy_");

        const blob = new Blob([response], { type: "application/pdf;" });

        FileSaver.saveAs(
          blob,
          `${formatedDate}_${this.preview.invoice || "unificada"}.pdf`
        );

        this.$emit("generatedBillet");
        this.dialog = false;
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.generating = false;
      }
    }
  }
};
</script>

<style></style>
