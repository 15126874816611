<template>
  <div class="d-flex flex-row">
    <default-edit-button @click="edit" />

    <default-remove-button @click="remove" />
  </div>
</template>

<script>
import DefaultEditButton from "@/components/layout/DefaultEditButton.vue";
import DefaultRemoveButton from "@/components/layout/DefaultRemoveButton.vue";

export default {
  components: { DefaultEditButton, DefaultRemoveButton },
  name: "HolidayActions",
  props: {
    id: Number
  },
  methods: {
    edit() {
      this.$emit("edit", this.id);
    },
    remove() {
      this.$emit("remove", this.id);
    }
  }
};
</script>

<style></style>
