import Rest from "../Base/Rest";

/**
 * @typedef {PayerInvitationService}
 */
export default class PayerInvitationService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/send-invitation";

  preview() {
    this.relationship = "preview";

    return this;
  }
}
