<template>
  <v-hover v-slot="{ hover }">
    <v-col
      class="file-preview-item cursor-pointer ma-2 px-3 pt-1 pb-3 rounded"
      :class="hover ? 'bg-primary-400' : 'bg-primary-300'"
    >
      <v-row no-gutters class="file-preview">
        <v-col cols="12" class="d-flex">
          <v-btn icon @click="removeFile" class="white--text ml-auto">
            <v-icon large> mdi-close </v-icon>
          </v-btn>
        </v-col>

        <v-col cols="12" class="d-flex py-6">
          <v-icon x-large class="white--text mx-auto">
            {{ icon }}
          </v-icon>
        </v-col>

        <v-col
          cols="12"
          class="white--text text-subtitle-2 file-preview-truncate text-center"
        >
          {{ message }}
        </v-col>
      </v-row>
    </v-col>
  </v-hover>
</template>

<script>
import { bus } from "@/main";

export default {
  name: "FileChip",
  props: {
    message: String,
    icon: String,
    loading: Boolean,
    file: Object
  },
  data() {
    return {
      datePicker1: false,
      datePicker2: false,
      dtIniPickerModel: ""
    };
  },
  methods: {
    formDate(date) {
      if (!date) {
        return null;
      }

      const [year, month, day] = date.split("-");

      return `${day}/${month}/${year}`;
    },
    addNewFile(file) {
      if (!file) {
        this.$emit("click");
      }
    },
    click() {
      this.$emit("click");
    },
    removeFile() {
      bus.$emit("showNotificationDialog", {
        status: "error",
        title: "Deseja mesmo remover esse arquivo?",
        continue: () => {
          this.$emit("removeFile");
        }
      });
    }
  }
};
</script>

<style>
.file-preview {
  overflow: hidden;
}

.file-preview-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.captionDeletedAt {
  width: 100%;
  font-weight: bold;
  text-align: center;
  font-size: 13px;
  color: brown;
}
</style>
