import Rest from "../Base/Rest";

/**
 * @typedef {ReportService}
 */
export default class ReportService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/reports";

  download(file) {
    const query = {
      params: {
        file
      }
    };

    query.responseType = "arraybuffer";

    return this.show(`/download`, query, {
      responseType: "arraybuffer"
    });
  }
}
