<template>
  <default-layout :loading="loading" route-name="Tipo de Desconto">
    <template v-slot:header-actions>
      <div class="d-flex flex-row ml-auto">
        <v-spacer />

        <default-cancel-button @click="cancel" />

        <default-save-button :loading="saving" @click="save" />
      </div>
    </template>

    <template v-slot:content>
      <v-container fluid class="mt-n6">
        <discount-types-save-form v-model="type" ref="DiscountTypesformRef" />
      </v-container>
    </template>
  </default-layout>
</template>

<script>
import DiscountTypesSaveForm from "./components/DiscountTypesSaveForm.vue";
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import DefaultCancelButton from "@/components/layout/DefaultCancelButton.vue";
import DefaultSaveButton from "@/components/layout/DefaultSaveButton.vue";

import InvoicesDiscountsTypesService from "@/app/Services/InvoicesDiscountsTypesService";

export default {
  components: {
    DiscountTypesSaveForm,
    DefaultLayout,
    DefaultCancelButton,
    DefaultSaveButton
  },
  name: "DiscountTypesSave",
  data() {
    return {
      loading: false,
      saving: false,
      type: {}
    };
  },
  created() {
    if (this.isToEdit()) {
      this.loadData();
    }
  },
  methods: {
    async save() {
      const formRef = this.$refs.DiscountTypesformRef.getFormRef();

      try {
        if (!formRef.validate()) {
          throw new Error("Preencha todos os campos");
        }

        this.saving = true;

        const service = InvoicesDiscountsTypesService.build();
        const id = this.$route.params.id;

        if (id != "new") {
          await service.update(this.type);
        } else {
          delete this.type.id;

          await service.store(this.type);
        }

        this.type = null;

        this.$router.push({ name: "DiscountTypes" });
      } catch (error) {
        this.defaultCatchError(error, error);
      } finally {
        this.saving = false;
        this.$forceUpdate();
      }
    },
    async loadData() {
      try {
        const id = this.$route.params.id;

        this.loading = true;
        this.type = {};

        const response = await InvoicesDiscountsTypesService.build().show(id);

        this.type = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    isToEdit() {
      const id = this.$route.params.id;

      return id && id != "new";
    },
    validate() {
      const valid = this.$refs.DiscountTypesSaveForm.validate();

      return valid;
    }
  }
};
</script>

<style></style>
