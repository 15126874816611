import FileSaver from "file-saver";
import ReadFileChangeShipService from "./../http/ReadFileChangeShipService";

const FileHandle = {
  acceptedFormats: [".txt"],

  async upload() {
    try {
      this.uploading = true;

      const goThroughFilesToUpload = this.FileHandle.goThroughFilesToUpload.bind(
        this
      );

      const results = await goThroughFilesToUpload(this.files);

      const uploadedFiles = results.filter(result => !!result);

      this.items = [...this.items, ...uploadedFiles];

      const errors = results.filter(result => !result);

      const hasErrors = errors.length > 0;

      if (hasErrors) {
        this.defaultCatchError(errors.join("\n"));
      } else {
        this.showToast({ message: "Arquivos enviados com sucesso" });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.uploading = false;
    }
  },

  async download(item) {
    try {
      item.downloading = true;
      this.loading = true;

      const params = {};

      const options = {
        responseType: "arraybuffer"
      };

      const response = await ReadFileChangeShipService.build().request(
        `/download/${item.id}`,
        params,
        options
      );

      const blob = new Blob([response.data], { type: "text/plain;" });
      FileSaver.saveAs(blob, item.file_name);
    } catch (error) {
      console.error(error);
    } finally {
      item.downloading = false;
      this.loading = false;
    }
  },

  /**
   * goThroughFilesToUpload
   *
   * funcao responsavel por realizar o controle do upload dos arquivos
   * eh preferivel que se utilize da funcao Promise.all, nesse caso fora utilizado o for para reatividade do usuario
   * @param {File} files
   * @returns {Array} results
   */
  async goThroughFilesToUpload(files) {
    const fileUpload = this.FileHandle.fileUpload.bind(this);

    const results = [];

    for (const file of files) {
      if (file.removed) {
        return;
      }

      const result = await fileUpload(file);

      results.push(result);
    }

    return results;
  },

  async fileUpload(file) {
    const rerenderFileDialog = this.FileHandle.rerenderFileDialog.bind(this);

    try {
      file.loading = true;

      rerenderFileDialog();

      const formData = new FormData();

      formData.append("file_name", file.name);
      formData.append("file_content", file.file);
      if (file.payType) {
        formData.append("pay_type", file.payType);
      }

      const response = await ReadFileChangeShipService.build().upload(formData);

      return response.data;
    } catch (error) {
      return error;
    } finally {
      file.loading = false;

      rerenderFileDialog();
    }
  },

  rerenderFileDialog() {
    this.rerenderFileDialog = true;

    this.$nextTick(() => {
      this.rerenderFileDialog = false;
    });
  }

  /**
   *
   * DOWNLOAD
   *
   */
};

export default FileHandle;
