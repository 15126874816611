<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 36 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8572 5.80273H14.5715C15.2857 5.80273 16.7143 6.35537 16.7143 8.56589V20.7238"
      :stroke="color || 'black'"
      stroke-opacity="0.84"
      stroke-width="1.5"
    />
    <path
      d="M0.857178 20.7238V9.11852C0.857178 7.82905 1.54289 5.80273 4.28575 5.80273H8.14289C9.00004 5.80273 10.7143 5.80273 10.7143 8.01326V12.4343C10.7143 13.5396 10.2 15.7501 8.14289 15.7501H3.42861"
      :stroke="color || 'black'"
      stroke-opacity="0.84"
      stroke-width="1.5"
    />
    <path
      d="M15.4285 2.4871V1.93447M15.4285 2.4871L17.5713 1.93447M15.4285 2.4871L15.9508 3.16058M15.4285 2.4871L17.1428 3.03973M16.2856 3.59236L17.1428 1.38184M16.2856 3.59236H16.4999M16.2856 3.59236L15.9508 3.16058M16.7142 3.59236L15.857 1.38184M16.7142 3.59236H16.4999M16.7142 3.59236L17.1428 3.03973M17.5713 2.4871V1.93447M17.5713 2.4871L15.4285 1.93447M17.5713 2.4871L17.1428 3.03973M17.5713 2.4871L15.9508 3.16058M17.5713 1.93447L17.1428 1.38184M17.1428 1.38184H16.7142M15.857 1.38184L15.4285 1.93447M15.857 1.38184H16.2856M16.4999 1.38184V3.59236M16.4999 1.38184H16.2856M16.4999 1.38184H16.7142M17.1428 3.03973L16.2856 1.38184M15.9508 3.16058L16.7142 1.38184"
      :stroke="color || 'black'"
      stroke-width="1.5"
    />
    <path
      d="M19.7144 5.80273H21.608C21.9868 5.80273 22.9715 6.0721 23.8805 7.14959C24.7894 8.22707 26.0266 9.69364 26.5316 10.2922C27.2891 11.1901 28.0466 12.088 29.5615 10.2922L32.5914 6.70064C32.8439 6.40134 33.5762 5.80273 34.4851 5.80273H36.0001"
      :stroke="color || 'black'"
      stroke-width="1.5"
    />
    <path
      d="M36 19.6181L33.8571 19.6182C33.4784 19.6182 33.0518 19.5904 32.1429 18.5129C31.2339 17.4354 30.0764 15.7957 29.5714 15.1971C28.814 14.2992 27.6678 13.8854 26.1528 15.6812L23.5714 18.5129C23.1628 18.8581 22.3375 19.6181 21.4286 19.6181H19.7143"
      :stroke="color || 'black'"
      stroke-opacity="0.84"
      stroke-width="1.5"
    />
  </svg>
</template>

<script>
export default {
  name: "PixIcon",
  props: {
    color: String
  }
};
</script>
