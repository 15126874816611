<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <span class="text-caption ml-2 text--secondary">
          Emails
        </span>
        <v-list class="mt-n3" v-if="splittedEmails.length">
          <div v-for="(email, index) in splittedEmails" :key="index">
            <v-list-item>
              <v-list-item-content>
                {{ email }}
              </v-list-item-content>

              <v-list-item-action>
                <default-remove-button @click="removeEmail(email)" />
              </v-list-item-action>
            </v-list-item>

            <v-divider />
          </div>
        </v-list>
      </v-col>
      <v-col cols="12">
        <v-text-field
          class="mx-2 mt-n6"
          label="Email"
          v-model="email"
          append-icon="mdi-plus"
          @click:append="addEmail"
          @keydown.enter="addEmail"
          :rules="[rules.email]"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <span class="text-caption ml-2 text--secondary">
          Telefones do Pagador
        </span>
        <v-list class="mt-n3">
          <div v-for="(phone, index) in splittedPhones" :key="index">
            <v-list-item>
              <v-list-item-content>
                {{ phone.value }}
              </v-list-item-content>

              <v-list-item-action>
                <default-remove-button @click="removePhone(phone.value)" />
              </v-list-item-action>
            </v-list-item>

            <v-divider />
          </div>
        </v-list>
      </v-col>
      <v-col cols="12">
        <v-text-field
          class="mx-2 mt-n6"
          label="Telefone"
          v-model="phone"
          append-icon="mdi-plus"
          @click:append="addPhone"
          @keydown.enter="addPhone"
          v-mask="'(##) # ####-####'"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DefaultRemoveButton from "@/components/layout/DefaultRemoveButton.vue";
import FormMixin from "@/components/misc/mixins/FormMixin";
export default {
  components: { DefaultRemoveButton },
  props: {
    value: Object
  },
  name: "PayerRecordExtraInfo",
  data() {
    return {
      payer: this.value,
      email: "",
      phone: ""
    };
  },
  mixins: [FormMixin],
  computed: {
    splittedEmails: {
      get() {
        if (!this.payer.email) {
          return [];
        }

        return this.payer.email.split(",");
      },
      set(value) {
        if (!this.payer.email) {
          this.payer.email = `${value}`;

          return;
        }

        this.payer.email += `,${value}`;
      }
    },
    splittedPhones() {
      if (!this.payer.extra_info || !this.payer.extra_info.filter) {
        return [];
      }

      const phones = this.payer.extra_info.filter(info => info.name == "phone");

      return phones;
    }
  },
  methods: {
    addEmail() {
      this.splittedEmails = this.email;

      this.email = "";
    },
    removeEmail(email) {
      this.payer.email = this.payer.email.replace(`,${email}`, "");
      this.payer.email = this.payer.email.replace(`${email},`, "");
      this.payer.email = this.payer.email.replace(`${email}`, "");
    },
    addPhone() {
      this.payer.extra_info.push({
        name: "phone",
        value: this.phone
      });

      this.phone = "";
    },
    removePhone(value) {
      this.payer.extra_info = this.payer.extra_info.filter(info => {
        info.value != value;
      });
    }
  }
};
</script>

<style></style>
