<template>
  <v-container class="pa-0">
    <v-row no-gutters>
      <v-col>
        <default-tooltip-button
          message="Gerar PIX"
          :disabled="cannotGeneratePix()"
          @click="openPreviewPix"
          v-if="authorization.canGeneratePix"
        >
          <template v-slot:icon>
            <pix-icon :color="cannotGenerateBills() ? '#dcdcdc' : 'black'" />
          </template>
        </default-tooltip-button>
      </v-col>
      <v-col>
        <default-tooltip-button
          icon="mdi-barcode"
          v-if="authorization.canGenerateBill"
          @click="openPreviewBillet"
          :disabled="cannotGenerateBills()"
          :loading="loadingBilletBool"
          message="Gerar Boleto"
        />
      </v-col>
      <v-col>
        <invoice-extra-info-button
          :item="item"
          :authorization="authorization"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DefaultTooltipButton from "@/components/default/DefaultTooltipButton.vue";
import PixIcon from "@/components/misc/PixIcon.vue";
import InvoiceExtraInfoButton from "./buttons/InvoiceExtraInfoButton.vue";

import InvoiceService from "@/app/Services/InvoiceService";
import FileSaver from "file-saver";
import { bus } from "@/main";
import InvoiceVerify from "../domain/InvoiceVerify";

const invoiceVerify = new InvoiceVerify();

export default {
  components: { DefaultTooltipButton, PixIcon, InvoiceExtraInfoButton },
  name: "InvoiceTableItemActions",
  props: {
    item: Object,
    authorization: Object
  },
  data() {
    return {
      loadingBilletBool: false
    };
  },
  methods: {
    cannotGenerateBills() {
      if (invoiceVerify.isCreditNote(this.item)) {
        return true;
      }

      const enabledStatus = [1, 2];

      if (enabledStatus.includes(this.item.status_id)) {
        return false;
      }

      return true;
    },
    cannotGeneratePix() {
      if (invoiceVerify.isCreditNote(this.item)) {
        return true;
      }

      const enabledStatus = [1, 3];

      if (enabledStatus.includes(this.item.status_id)) {
        return false;
      }

      return true;
    },
    openPreviewBillet() {
      bus.$emit("preview_billet_dialog_show", this.item);
    },
    openPreviewPix() {
      bus.$emit("preview_pix_dialog_show", this.item);
    },
    async downloadBillet() {
      try {
        this.loadingBilletBool = true;

        const response = await InvoiceService.build().generateBillet(
          this.item.id
        );

        const blob = new Blob([response], { type: "application/pdf;" });

        FileSaver.saveAs(blob, `${this.item.invoice}.pdf`);
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loadingBilletBool = false;
      }
    }
  }
};
</script>

<style></style>
