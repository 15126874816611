export default {
  data() {
    return {
      pagination: {
        page: 1,
        pageStart: 0,
        pageStop: 0,
        itemsPerPage: 25,
        pageCount: 0,
        itemsLength: 0
      },
      default_pagination: {
        itemsPerPage: 25
      }
    };
  },
  methods: {
    paginate(response) {
      const toPagination = { ...response };

      delete toPagination.data;

      this.pagination.itemsLength = toPagination.total;
      this.pagination.pageCount = toPagination.last_page;
      this.pagination.pageStart = toPagination.from;
      this.pagination.pageStop = toPagination.to;
    }
  }
};
