import Rest from "../Base/Rest";

/**
 * @typedef {AuditFunctionalityService}
 */
export default class AuditFunctionalityService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/audit/functionality";
}
