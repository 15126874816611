<template>
  <v-row>
    <v-col cols="12" md="4" sm="12">
      <v-card
        @click="handleClickUpdateFilterStatus(discountApprovalStatus.PENDING)"
        :class="{
          'mx-auto': true,
          'grey lighten-1': true,
          'darken-2': cardIsActive == discountApprovalStatus.PENDING
        }"
        dark
      >
        <v-card-title>
          <v-icon large left>
            mdi-tag-hidden
          </v-icon>
          <span class="text-h6 font-weight-light">Pendente</span>
        </v-card-title>
        <v-card-text>
          <div class="ml-11 text-h5">
            {{
              totalsApproval && totalsApproval[discountApprovalStatus.PENDING]
                ? totalsApproval[discountApprovalStatus.PENDING]
                : 0
            }}
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="4" sm="12">
      <v-card
        @click="handleClickUpdateFilterStatus(discountApprovalStatus.APPROVED)"
        :class="{
          'mx-auto': true,
          green: true,
          'lighten-3': cardIsActive != discountApprovalStatus.APPROVED
        }"
        dark
      >
        <v-card-title>
          <v-icon large left>
            mdi-tag-check-outline
          </v-icon>
          <span class="text-h6 font-weight-light">Aprovado</span>
        </v-card-title>
        <v-card-text>
          <div class="ml-11 text-h5">
            {{
              totalsApproval && totalsApproval[discountApprovalStatus.APPROVED]
                ? totalsApproval[discountApprovalStatus.APPROVED]
                : 0
            }}
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="4" sm="12">
      <v-card
        @click="
          handleClickUpdateFilterStatus(discountApprovalStatus.DISAPPROVED)
        "
        :class="{
          'mx-auto': true,
          red: true,
          'lighten-3': cardIsActive != discountApprovalStatus.DISAPPROVED
        }"
        dark
      >
        <v-card-title>
          <v-icon large left>
            mdi-tag-remove-outline
          </v-icon>
          <span class="text-h6 font-weight-light">Reprovado</span>
        </v-card-title>
        <v-card-text>
          <div class="ml-11 text-h5">
            {{
              totalsApproval &&
              totalsApproval[discountApprovalStatus.DISAPPROVED]
                ? totalsApproval[discountApprovalStatus.DISAPPROVED]
                : 0
            }}
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { bus } from "@/main";

import DiscountApprovalStatus from "@/pages/DiscountApprovals/enums/DiscountApprovalStatus";

export default {
  name: "ApprovalStatusCards",
  props: ["cardIsActive", "totalsApproval"],
  data() {
    return {
      discountApprovalStatus: DiscountApprovalStatus
    };
  },
  methods: {
    handleClickUpdateFilterStatus(status) {
      bus.$emit("handleClickUpdateFilterStatus", { status: status });
    }
  }
};
</script>
