<template>
  <v-container fluid>
    <v-row class="mb-n12">
      <v-col class="d-flex flex-column">
        <span class="text-caption">
          Fatura
        </span>

        <span class="text-subtitle">
          {{ model.invoice || "Unificada" }}
        </span>
      </v-col>

      <v-col class="d-flex flex-column">
        <span class="text-caption">
          Data de Vencimento
        </span>

        <span class="text-subtitle">
          {{ (model.due_date || "-") | formatDate }}
        </span>
      </v-col>

      <v-col
        class="d-flex flex-column"
        v-if="
          model.valid_customer_procedure_discount === true &&
            model.payer.procedure_discount
        "
      >
        <div class="d-flex align-center">
          <span class="text-caption">Desconto de Procedimento (%)</span>

          <!-- Popover Menu -->
          <v-menu left offset-y nudge-width="auto" max-width="700">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" class="ml-2">
                mdi-information-outline
              </v-icon>
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      >Desconto de Procedimento</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-card-text>
                <p>
                  A aplicação do <strong>Desconto de Procedimento</strong> será
                  realizada exclusivamente no momento da geração do Boleto ou
                  PIX.
                </p>
                <p>
                  O direito ao desconto está sujeito a condições, que variam
                  conforme a data de emissão.
                </p>
                <p>
                  Caso o desconto tenha sido previamente concedido, mas a fatura
                  não seja quitada, o valor do desconto será recalculado na
                  próxima geração do Boleto ou PIX.
                </p>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="menu = false">
                  OK
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>

        <span class="text-subtitle">
          {{ model.payer.procedure_discount | withoutSymbolCurrency }}%
        </span>
      </v-col>

      <v-col class="d-flex flex-column" v-if="model.currency != 'BRL'">
        <span class="text-caption">
          Taxa de Conversão
        </span>

        <span class="text-subtitle">
          {{ getCurrencyValue }}
        </span>
      </v-col>
    </v-row>

    <v-row>
      <v-col class="d-flex flex-column">
        <span class="text-caption">
          Valor Original
        </span>

        <span class="text-subtitle">
          {{ model.currency }} {{ model.total | withoutSymbolCurrency }}
        </span>
      </v-col>

      <v-col class="d-flex flex-column">
        <span class="text-caption">
          Valor dos Descontos
        </span>

        <span class="text-subtitle">
          BRL {{ discounts | withoutSymbolCurrency }}
        </span>
      </v-col>

      <v-col class="d-flex flex-column">
        <span class="text-caption">
          Valor dos Juros
        </span>

        <span class="text-subtitle">
          {{ nonNegativeCurrency(taxValue) }}
        </span>
      </v-col>

      <v-col class="d-flex flex-column">
        <span class="text-caption">
          Valor Final
        </span>

        <span class="text-subtitle">
          {{ model.result_total | toCurrency }}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import InvoiceVerify from "@/pages/Invoice/domain/InvoiceVerify";

export default {
  components: {},
  name: "PreviewPIXDialogForm",
  mixins: [ModelManagementMixin],
  computed: {
    taxValue() {
      const chargesTotals = this.model.result_charges || 0;

      return chargesTotals || 0;
    },
    discounts() {
      const discount = this.model.result_entries || 0;

      if (discount < 0) {
        return -discount;
      }

      return discount || 0;
    },
    getCurrencyValue() {
      return this.formatExchangeCurrency(
        this.model.actual_currency_value || this.model.currency_value
      );
    }
  },
  created() {
    this.invoiceVerify = new InvoiceVerify();
  },
  methods: {
    nonNegativeCurrency(item) {
      const value = this.toCurrency(item);

      const withoutSymbol = `${value}`.replace("-", "");

      return withoutSymbol;
    },
    formatExchangeCurrency(value) {
      return this.toCurrency(value, {
        maximumFractionDigits: 4,
        minimumFractionDigits: 4
      });
    }
  }
};
</script>

<style></style>
