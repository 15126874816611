import Rest from "@/app/Base/Rest";

/**
 * @typedef {LoginService}
 */
export default class LoginService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/login";
}
