<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" class="mt-n7 mb-n12">
        <default-tooltip-button
          icon="mdi-arrow-left"
          message="Voltar para as informações da fatura"
          @click="toggleInvoiceHistory"
        />
      </v-col>

      <v-col cols="12">
        <v-list three-line>
          <template v-for="(step, index) in invoice.histories">
            <v-divider v-if="index > 0" :key="index"></v-divider>

            <v-list-item
              :key="index"
              :class="index % 2 == 0 ? 'bg-primary-100' : ''"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ getTimelineMessage(step) }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ step.created_at | formatDateHour }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DefaultTooltipButton from "@/components/default/DefaultTooltipButton.vue";
import StepsStatus from "./StepsStatus";

export default {
  components: { DefaultTooltipButton },
  name: "InvoiceHistory",
  props: {
    invoice: Object
  },
  methods: {
    toggleInvoiceHistory() {
      this.$emit("toggleInvoiceHistory");
    },
    getTimelineMessage(timeline) {
      if (!timeline) {
        return "";
      }

      if (timeline.message) {
        const serializedMessage = timeline.message.replace("!", "");

        return serializedMessage;
      }

      const result = StepsStatus.getMessageByStatusID(timeline.status_id);

      return result;
    }
  }
};
</script>

<style></style>
