<template>
  <v-dialog v-model="dialog" width="60%" @change="onToggle">
    <v-card :loading="loading">
      <v-container>
        <v-row>
          <v-col sm="12">
            <span class="text-h5"> Desunificar </span>
          </v-col>
          <v-col sm="12">
            <span class="text-subtitle-1 text-secondary">
              {{ company }}
            </span>
          </v-col>
        </v-row>
        <v-row v-if="error">
          <centralized-message>
            <template #message>
              {{ error.message }}
            </template>

            <template #complemention>
              {{ error.complemention }}
            </template>
          </centralized-message>
        </v-row>

        <v-row v-else>
          <v-col sm="12">
            <span class="text-subtitle-2 text--secondary">
              Faturas para desunificar:
            </span>
          </v-col>

          <v-col sm="12" class="mh-vh-50 overflow-y-auto">
            <related-invoice-tile
              v-for="(item, index) in invoices"
              :key="item"
              :payer="unificationInvoice.payer.name"
              :invoice="item.invoice"
              :currency="'BRL'"
              :value="getTotal(item)"
              :zebra="index % 2 == 0"
              no-go-to
            />
          </v-col>

          <v-divider />

          <v-col sm="12" class="mt-6 d-flex flex-row">
            <v-spacer />

            <v-btn class="font-weight-bold" @click="cancel" text color="text">
              Cancelar
            </v-btn>

            <v-btn
              class="font-weight-bold"
              color="primary"
              text
              @click="desunify"
              :loading="loadingUnification"
              :disabled="this.error"
            >
              Desunificar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { bus } from "@/main";

import InvoiceService from "@/app/Services/InvoiceService";
import CentralizedMessage from "@/components/misc/CentralizedMessage.vue";

import InvoiceVerify from "../../domain/InvoiceVerify";
import RelatedInvoiceTile from "../extra_info_dialog/unify/RelatedInvoiceTile.vue";

const invoiceVerify = new InvoiceVerify();

export default {
  components: { CentralizedMessage, RelatedInvoiceTile },
  name: "GlobalDesunificationDialog",
  props: {
    selected: Array
  },
  created() {
    bus.$on("desunificate", this.desunificate);
  },
  computed: {
    company() {
      try {
        const first = this.invoices[0];

        return `Empresa: ${first.payer.name}`;
      } catch (error) {
        return "";
      }
    }
  },
  data() {
    return {
      dialog: false,
      invoices: [],
      unificationInvoice: {},
      loadingUnification: false,
      error: false
    };
  },
  methods: {
    getTotal(invoice) {
      return invoice.result_total;
    },
    getCurrencyWithoutSymbol(item) {
      const value = this.toCurrency(item);

      const withoutSymbol = `${value}`.replace("R$", "");

      return withoutSymbol;
    },
    getItemTitle(item) {
      if (item.payer && item.payer.name) {
        return item.payer.name;
      }

      return "";
    },
    async desunificate(item) {
      this.dialog = true;

      if (invoiceVerify.isUnificationInvoice(item)) {
        await this.serializeInvoices(item);
      }

      if (invoiceVerify.wasUnificated(item)) {
        await this.loadUnificationInvoice(item);
      }

      this.validateDesunification(item);
    },
    validateDesunification(item) {
      this.error = null;

      if (
        item.status.refs != "invoice_entry" &&
        item.status.refs != "unified" &&
        item.status.refs != "generated_billet" &&
        item.status.refs != "generated_pix"
      ) {
        this.error = {
          message: "Pagamento já confirmado ou em confirmação",
          complemention:
            "Não é possível desunificar faturas que já estão nesse processo"
        };
      }
    },
    validateDesunificationIfGeneratedBilletOrPix() {
      if (
        this.unificationInvoice.status.refs == "generated_billet" ||
        this.unificationInvoice.status.refs == "generated_pix"
      ) {
        return false;
      }

      return true;
    },
    serializeInvoices(item) {
      this.unificationInvoice = item;
      this.invoices = item.unifications;
    },
    async loadUnificationInvoice(item) {
      try {
        this.loading = true;
        this.error = { message: "Carregando..." };

        const response = await InvoiceService.build().show(
          item.unified_father.id
        );

        this.unificationInvoice = response.data;

        this.invoices = this.unificationInvoice.unifications;
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.$forceUpdate();
        this.loading = false;
        this.error = null;
      }
    },
    async desunify() {
      this.dialog = false;

      if (this.validateDesunificationIfGeneratedBilletOrPix() == false) {
        const typeInvoice =
          this.unificationInvoice.status.refs == "generated_billet"
            ? "boleto"
            : "pix";

        bus.$emit("showNotificationDialog", {
          status: "error",
          title: `Foi detectado que houve a geração de ${typeInvoice} deste unificado, se você deseja desunificar, certifique-se de que este ${typeInvoice} não tenha sido pago. Deseja prosseguir?`,
          continue: async () => {
            await this.confirmDesunify();
          },
          dispose: () => false
        });
      } else {
        this.confirmDesunify();
      }
    },
    async confirmDesunify() {
      try {
        this.dialog = true;
        this.loadingUnification = true;

        await InvoiceService.build().destroy(this.unificationInvoice, {
          url: "/desunification"
        });

        await bus.$emit("desunificated");

        await this.showToast({
          message: "Desunificação realizada com Sucesso",
          status: "success"
        });

        this.dialog = false;
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loadingUnification = false;
      }
    },
    cancel() {
      this.dialog = false;
    }
  }
};
</script>

<style></style>
