import Rest from "../Base/Rest";

/**
 * @typedef {BankAccountConfigService}
 */
export default class BankAccountConfigService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/bank/account/config";
}
