<template>
  <v-container fluid>
    <breadcrumb route-name="Cadastro de Usuários" />

    <v-card :loading="loading">
      <v-container fluid>
        <v-tabs color="secondary" flat class="containerInner" v-model="tab">
          <v-tab href="#info" class="font-weight-bold">
            INFORMAÇÕES GERAIS
          </v-tab>
          <v-tab href="#access" class="font-weight-bold">
            ACESSO
          </v-tab>
          <v-tab href="#contracts" class="font-weight-bold">
            CONTRATOS
          </v-tab>
          <div class="d-flex flex-row alignButtons">
            <v-btn
              @click="cancel"
              :loading="saving"
              class="text--secondary text-normal ml-auto mr-6 my-auto"
            >
              Cancelar
            </v-btn>

            <authorization-gate
              :auth-route="['/user/external', '/user/internal']"
              auth-type="write"
              class="d-flex flex-item"
            >
              <v-btn
                class="mx-2 my-auto text-normal"
                color="primary"
                @click="save"
                :loading="saving"
                :disabled="!userTypeBeAbleToSave"
              >
                Salvar
              </v-btn>
            </authorization-gate>
          </div>
          <v-tab-item value="info">
            <user-record-info
              v-model="user"
              :list-type-user="listTypeUser"
              @verifyUserTypePermission="verifyUserTypePermission"
              @valid="changeValidInfo"
              ref="info"
            />
          </v-tab-item>
          <v-tab-item value="access">
            <user-record-access v-model="user" ref="access" />
          </v-tab-item>
          <v-tab-item value="contracts">
            <user-contracts
              v-model="user"
              :userTypePermissions="authorization"
              @loadData="loadData()"
              ref="contracts"
            />
          </v-tab-item>
        </v-tabs>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import Vue from "vue";
import UserRecordInfo from "./UserRecordInfo.vue";
import UserRecordAccess from "./UserRecordAccess.vue";
import UserContracts from "./UserContracts.vue";

import UserService from "@/app/Services/UserService";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";
import UserTypeAccessMethods from "./Domain/UserTypeAccessMethods";

const userService = UserService.build();

export default Vue.extend({
  name: "UserRecord",
  components: {
    UserRecordInfo,
    UserRecordAccess,
    UserContracts,
    Breadcrumb
  },
  data() {
    return {
      loading: false,
      saving: false,
      valid: false,
      tab: "info",
      user: {
        id: 0,
        name: "",
        status: 1,
        user_role_id: "",
        email: "",
        login: "",
        password: "",
        companies: [],
        group: {}
      },
      authorization: {
        route: "/user"
      },
      listTypeUser: [],
      company_user: [],
      user_type_permissions: null,
      userTypeBeAbleToSave: true
    };
  },
  mixins: [AuthorizationMixin],
  async created() {
    await this.loadPermissions();

    if (this.$router.history.current.params.id == "new") {
      return;
    }

    await this.loadData();
  },
  methods: {
    verifyUserTypePermission() {
      this.userTypeBeAbleToSave = UserTypeAccessMethods.verifyIfCanEditByUserAccess(
        this.user,
        this.user_type_permissions
      );
    },
    async loadPermissions() {
      this.user_type_permissions = await new UserTypeAccessMethods()
        .setScope(this)
        .getAccess();
    },
    async loadData() {
      try {
        await this.loadPermissions();

        this.loading = true;

        const response = await userService.read({
          id: this.$router.history.current.params.id
        });

        this.user = response.data;
        this.serializeUserType();

        this.$forceUpdate();
      } catch (error) {
        console.error(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    back() {
      this.$router.push("/user/");
    },
    cancel() {
      this.$router.push("/user/");
    },
    serializeUserType() {
      const hasPayerDefined = !!(this.user.payer_id || this.user.group_id);
      const hasCompanyDefined =
        this.user.companies && this.user.companies.length >= 1;

      if (hasPayerDefined) {
        this.user.user_type = "external";

        return;
      }

      if (hasCompanyDefined) {
        this.user.user_type = "internal";

        return;
      }
    },
    async save() {
      try {
        this.loading = true;
        this.saving = true;

        if (!this.validate()) {
          return;
        }

        const user = this.serializeUser();

        if (this.user.user_type == "internal") {
          this.user.group_id = null;
        }

        if (user.id) {
          await userService.update(user);
        } else {
          delete user.id;

          const response = await userService.create(user);

          this.user = response.data;
        }

        this.showToast({ message: "Usuário salvo com sucesso" });

        this.$router.push("/user/");
      } catch (error) {
        console.error(error);
        this.defaultCatchError(error);
      } finally {
        this.saving = false;
        this.loading = false;
      }
    },
    serializeUser() {
      const user = this.user;

      user.payer_id = user.payer ? user.payer.id : null;

      user.group_id = user.group ? user.group.id : null;

      return user;
    },
    validate() {
      const userInfoIsValid = this.validateInfo();

      if (!userInfoIsValid) {
        return false;
      }

      const userAccessIsValid = this.validateAccess();

      if (!userAccessIsValid) {
        return false;
      }

      return true;
    },
    changeValidInfo(valid) {
      this.valid = valid;
    },
    validateAccess() {
      if (!this.$refs.access && this.$route.params.id == "new") {
        this.tab = "access";

        this.showToast({
          message: "Acessos não definidos",
          status: "error"
        });

        return false;
      }

      if (!this.$refs.access) {
        return true;
      }

      if (this.$refs.access && this.$refs.access.validate()) {
        return true;
      }

      if (
        this.$route.params.id == "new" &&
        this.user.password &&
        this.user.password != this.user.password_confirm
      ) {
        this.showToast({
          message: "As Senhas não conferem",
          status: "error"
        });
      }

      return false;
    },
    validateInfo() {
      if (this.$refs.info && this.$refs.info.validate()) {
        return true;
      }

      return false;
    },
    showValidateError() {
      this.showToast({
        message: "Erro ao cadastrar, confira os campos e tente novamente",
        status: "error"
      });
    }
  }
});
</script>

<style lang="css" scoped>
.v-subheader {
  height: 57px;
  padding: 0;
}
.elevation-1 {
  padding: 60px 37px;
}
.lblUser {
  font-size: 18px;
}
.spaceL {
  margin-left: 10px;
}
.btnHome {
  height: 27px;
}

.custom-btn {
  font-size: 14px;
  font-family: "Helvetica Neue" !important;
  text-transform: none;
  margin: 10px;
  color: white;
}
.btnCancel {
  background: #a8aab0 !important;
}
.alignButtons {
  margin-left: auto;
}
</style>
