<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col class="d-flex">
        <v-btn icon class="mr-2" @click="prev">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>

        <span class="text-h4 mr-auto text--secondary text-capitalize">
          {{ handleMonth }}
        </span>
      </v-col>

      <v-spacer />

      <v-col class="d-flex">
        <v-btn icon class="ml-auto" @click="next">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";

export default {
  name: "HolidayCalendarActions",
  mixins: [ModelManagementMixin],
  computed: {
    handleMonth() {
      const date = this.toLuxonDate(this.model);

      const result = date.toLocaleString({ month: "long" });

      return result;
    }
  },
  methods: {
    prev() {
      this.$emit("prev");
    },
    next() {
      this.$emit("next");
    }
  }
};
</script>

<style></style>
