<template>
  <v-col cols="12" class="mx-0 px-0">
    <v-btn
      class="w-100"
      outlined
      color="grey lighten-1"
      @click="onClick"
      :disabled="disabled"
    >
      <span class="d-flex mr-auto black--text">
        Copiar Código
      </span>

      <v-icon color="grey darken-4">
        mdi-content-copy
      </v-icon>
    </v-btn>
  </v-col>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
export default {
  name: "PixActionCopyCodeButton",
  mixins: [ModelManagementMixin],
  props: {
    disabled: Boolean
  },
  methods: {
    onClick() {
      if (!this.model || !this.model.qrcode) {
        this.showToast({ message: "QRCODE não definido", status: "error" });

        return;
      }

      this.copyCode();
    },
    async copyCode() {
      try {
        await navigator.clipboard.writeText(this.model.qrcode);

        this.showToast({ message: "Código Copiado", status: "success" });
      } catch (error) {
        this.showToast({
          message: "Ocorreu um erro ao copiar o código",
          status: "error"
        });

        console.error(error);
      }
    }
  }
};
</script>

<style></style>
