<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon @click="edit" :class="classname" :disabled="disabled">
        <v-icon
          v-bind="attrs"
          v-on="on"
          :loading="loading"
          :color="attrs['aria-expanded'] == 'true' ? 'error' : 'text'"
        >
          {{
            attrs["aria-expanded"] == "true" ? "mdi-delete-empty" : "mdi-delete"
          }}
        </v-icon>
      </v-btn>
    </template>

    <span>Excluir</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "DefaultRemoveButton",
  props: {
    classname: String,
    loading: Boolean,
    disabled: Boolean
  },
  methods: {
    edit() {
      this.$emit("remove");
      this.$emit("click");
    }
  }
};
</script>

<style></style>
