<template>
  <v-btn
    class="text--secondary ml-auto mr-6 my-auto"
    :text="text"
    @click="cancel"
  >
    <slot>CANCELAR</slot>
  </v-btn>
</template>

<script>
export default {
  name: "DefaultCancelButton",
  props: {
    customFunction: Boolean,
    text: Boolean
  },
  methods: {
    cancel() {
      if (this.customFunction) {
        this.$emit("cancel");
        this.$emit("click");

        return;
      }

      this.$router.go(-1);
    }
  }
};
</script>

<style></style>
