import Rest from "../Base/Rest";

/**
 * @typedef {UserRoleService}
 */
export default class UserRoleService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/user-role";
}
