import Rest from "@/app/Base/Rest";

/**
 * @typedef {ReadElectronicInvoiceService}
 */
export default class ReadElectronicInvoiceService extends Rest {
  /**
   * @type {String}
   */
  static resource = "invoice/read-electronic-invoice";
}
