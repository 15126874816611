import BankReturnService from "@/app/Services/BankReturnService";

const BankReturnStatus = {
  async load() {
    try {
      this.loading = true;

      const response = await BankReturnService.build().show("/status");

      if (!response.data) {
        throw new Error("Nenhum status encontrado");
      }

      const serializedSelectItems = response.data.map(bankReturnStatus => ({
        text: bankReturnStatus.status_desc,
        value: bankReturnStatus.status_desc
      }));

      return serializedSelectItems;
    } catch (error) {
      console.error(error);
      this.defaultCatchError(error);
    } finally {
      this.loading = false;
    }
  }
};

export default BankReturnStatus;
