<template>
  <v-checkbox
    :value="user"
    v-model="selected"
    class="hover__default mt-0 checkbox-tile px-2"
    hide-details
  >
    <template #label>
      <div class="d-flex flex-column w-100 mx-2 py-2">
        <span
          class="flex-item text-subtitle-1 font-weight-bold text-capitalize"
        >
          {{ user.email }}
        </span>

        <span class="flex-item text-subtitle-2">
          {{ user.payer.name }}
        </span>
      </div>
    </template>
  </v-checkbox>
</template>

<script>
export default {
  name: "UserInvitationCheckbox",
  props: {
    value: Array,
    user: Object
  },
  data() {
    return {
      selected: this.value
    };
  },
  watch: {
    selected: {
      deep: true,
      handler() {
        this.$emit("input", this.selected);
      }
    },
    value() {
      this.selected = this.value;
    }
  }
};
</script>

<style lang="scss">
.checkbox-tile.v-input--is-label-active {
  background-color: #bec8dd !important;

  &:hover {
    background-color: #92a4ca !important;
  }
}
</style>
