<template>
  <default-layout
    :loading="loading"
    route-name="Reembolso"
    custom-function
    @returnToPreviousPage="returnToList"
  >
    <template #header-actions>
      <v-container fluid class="pa-0">
        <v-row>
          <v-spacer />
          <default-cancel-button
            :loading="saving"
            custom-function
            @click="clearHandleOrReturnToList"
          />

          <attendance-button
            @click="startAttendance"
            :loading="approving || saving"
            :disabled="alreadyConfirmed"
            :handle="handleApproveFinancialReason"
            v-if="
              (refund && refund.status && refund.status.refs == 'solicitation'
                ? true
                : false) &&
                verifyAuthentication.accessHandle &&
                !handleDisapproveFinancialReason &&
                refund.id
            "
          />

          <default-save-button
            :loading="saving"
            :disabled="alreadyConfirmed"
            @click="onSave"
            v-if="
              verifyAuthentication.accessSave &&
                !handleDisapproveFinancialReason &&
                !handleApproveFinancialReason
            "
          />
        </v-row>
        <v-row>
          <span class="ml-auto mr-6 mt-n4 text-caption error--text">
            {{ errorMessage }}
          </span>
        </v-row>
      </v-container>
    </template>

    <template #content>
      <v-tabs color="secondary" flat class="containerInner" v-model="tab">
        <v-tab href="#info" class="font-weight-bold">
          INFORMAÇÕES GERAIS
        </v-tab>
        <v-tab href="#chat" class="font-weight-bold" v-if="isOpenChat()">
          ATENDIMENTO
        </v-tab>
        <v-tab-item value="info">
          <refund-save-form
            v-model="refund"
            ref="form"
            @valid="changeValidation"
            @download="download"
            :user-type-permissions="user_type_permissions"
            :handle-financial-message="
              handleApproveFinancialReason || handleDisapproveFinancialReason
            "
            :can-manipulate="
              verifyAuthentication.accessSave && !alreadyConfirmed
            "
            :can-manipulate-upload="
              verifyAuthentication.accessSave && !alreadyConfirmed
            "
          />
        </v-tab-item>
        <v-tab-item value="chat">
          <refund-chat
            v-if="isOpenChat()"
            v-model="refund"
            ref="chat"
            :user-type-permissions="user_type_permissions"
            :can-manipulate="
              verifyAuthentication.accessSave && !alreadyConfirmed
            "
            @loadData="loadData"
          />
        </v-tab-item>
      </v-tabs>
    </template>
  </default-layout>
</template>

<script>
/**
 * Components
 */
import DefaultCancelButton from "@/components/layout/DefaultCancelButton.vue";
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import DefaultSaveButton from "@/components/layout/DefaultSaveButton.vue";
import RefundSaveForm from "./components/RefundSaveForm.vue";
import AttendanceButton from "./components/AttendanceButton.vue";
import RefundChat from "./components/RefundChat.vue";

/**
 *  Domains & mixins
 */
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";
import UserTypeAccessMethods from "./domain/UserTypeAccessMethods";

import DownloadFile from "./domain/DownloadFile";
import HandleRefundAction from "./domain/HandleRefundAction";
import LoadData from "./domain/LoadData";
import RemoveFile from "./domain/RemoveFile";
import VerifyAuthentication from "./domain/VerifyAuthentication";
import SaveData from "./domain/SaveData";
import Status from "./domain/Status";

export default {
  components: {
    DefaultSaveButton,
    DefaultLayout,
    DefaultCancelButton,
    RefundSaveForm,
    AttendanceButton,
    RefundChat
  },
  name: "RefundSave",
  data() {
    return {
      loading: false,
      saving: false,
      valid: false,
      errorMessage: "",
      user_type_permissions: {
        canEditExternalUsers: false,
        canEditInternalUsers: false
      },
      user: {},
      authorization: {
        route: "/invoice/refund"
      },
      listTypeUser: []
    };
  },
  mixins: [
    AuthorizationMixin,
    DownloadFile,
    HandleRefundAction,
    LoadData,
    RemoveFile,
    SaveData,
    Status,
    VerifyAuthentication
  ],
  async created() {
    await this.loadPermissions();

    if (this.$router.history.current.params.id == "new") {
      return;
    }

    if (this.isToEdit()) {
      await this.loadData();
    }
  },
  methods: {
    canManipulateUpload() {
      if (
        this.isToEdit() &&
        this.refund.status_id == this.status.solicitation
      ) {
        return true;
      } else if (this.user_type_permissions.canEditInternalUsers) {
        return true;
      }

      return this.authorization.canEdit;
    },
    isOpenChat() {
      const status_refs = [
        "attendance",
        "waiting_customer",
        "waiting_customer_documentation",
        "under_registration",
        "under_approval",
        "approved",
        "repproved",
        "cancel"
      ];

      return (
        this.refund &&
        this.refund.status &&
        status_refs.some(elem => elem == this.refund.status.refs)
      );
    },
    isToEditRecordByStatus() {
      const status_refs = [
        "attendance",
        "waiting_customer",
        "waiting_customer_documentation",
        "under_registration",
        "under_approval"
      ];

      return (
        this.refund &&
        this.refund.status &&
        status_refs.some(elem => elem == this.refund.status.refs)
      );
    },
    verifyUserTypePermission() {
      this.userTypeBeAbleToSave = UserTypeAccessMethods.verifyIfCanEditByUserAccess(
        this.user,
        this.user_type_permissions
      );
    },
    async loadPermissions() {
      this.user_type_permissions = await new UserTypeAccessMethods()
        .setScope(this)
        .getAccess();
    },
    changeValidation(valid) {
      this.errorMessage = "";

      this.valid = valid;
    },
    returnToList() {
      this.$router.push({ name: "Refund" });
    }
  }
};
</script>

<style></style>
