<template>
  <v-container>
    <v-row class="pa-0 ma-0" align-content="space-between">
      <v-col cols="12" class="text-start">
        <v-btn icon x-large>
          <v-icon @click="back()" color="grey darken-2">
            mdi-keyboard-backspace
          </v-icon>
        </v-btn>

        <h1 class="display-2 font-weight-bold mb-3 logo row"></h1>
        <p class="text-center mb-4">Recuperar Senha</p>

        <p v-if="msg_error != ''" class="text-center mb-4 red--text">
          {{ msg_error }}
        </p>
        <p v-if="msg_sucess != ''" class="text-center mb-4 success--text">
          {{ msg_success }}
        </p>
      </v-col>

      <v-col cols="12">
        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col cols="12" md="8" style="margin: 0 auto">
                <v-text-field
                  ref="name"
                  label="Login"
                  v-model="user.name"
                  outlined
                  light
                  hide-details="auto"
                  @keydown.enter="submit"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="8" style="margin: 0 auto; padding: 0">
                <strong>Ou</strong>
              </v-col>

              <v-col cols="12" md="8" style="margin: 0 auto">
                <v-text-field
                  ref="email"
                  label="E-mail"
                  v-model="user.email"
                  outlined
                  light
                  hide-details="auto"
                  @keydown.enter="submit"
                >
                </v-text-field>
              </v-col>

              <v-col cols="8" xs="12" style="margin: 0 auto">
                <v-btn
                  block
                  @click="submit()"
                  color="primary"
                  class="white--text"
                  :loading="loading"
                  large
                >
                  Recuperar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>

      <v-col cols="12">
        <div class="pre-register-spacer" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import RecoverPasswordService from "@/app/Services/RecoverPasswordService";

const recoverPasswordService = RecoverPasswordService.build();

export default Vue.extend({
  name: "ForgetPassword",
  data: () => ({
    loading: false,
    msg_error: "",
    msg_success: "",
    user: {
      name: "",
      email: ""
    },
    sendmail: false
  }),
  methods: {
    send() {
      this.sendmail = true;
    },
    async submit() {
      if (!this.valid()) {
        return;
      }

      try {
        this.loading = true;

        await recoverPasswordService.send(this.user);

        this.msg_error = "";
        this.msg_success = "E-mail de Recuperação enviado para o seu e-mail";
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    back() {
      this.$emit("openLogin");
    },
    valid() {
      if (this.user.name == "" && this.user.email == "") {
        this.showToast({
          message: "Favor digitar o login ou e-mail vinculado a conta!",
          status: "error"
        });

        return false;
      }

      return true;
    }
  },
  components: {}
});
</script>

<style lang="css" scoped></style>
