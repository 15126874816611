<template>
  <default-layout
    :loading="loading"
    route-name="Reembolso"
    :instructions="instructions"
    @search="search"
    has-filters
  >
    <template #header-actions>
      <refund-dialog
        v-model="refundFlowDialog"
        :refund="refund"
        @continueRefundFlow="continueRefundFlow"
        @cancelRefundFlow="cancelRefundFlow"
      />

      <v-container fluid class="pa-0 d-flex">
        <v-spacer />

        <default-save-button v-if="accessSave" @click="add">
          Adicionar
        </default-save-button>
      </v-container>
    </template>

    <template #content>
      <v-container fluid>
        <refund-table
          :items="items"
          :pagination="pagination"
          @edit="edit"
          @approve="approve"
          @disapprove="disapprove"
        />
      </v-container>
    </template>

    <template #footer>
      <pagination v-model="pagination" @change="loadData" :loading="loading" />
    </template>
  </default-layout>
</template>

<script>
import DefaultSaveButton from "@/components/layout/DefaultSaveButton.vue";
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";

import RefundTable from "./Components/RefundTable.vue";
import RefundService from "@/app/Services/RefundService";

import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";
import FilterMixin from "@/components/Filters/FilterMixin";
import Pagination from "@/components/pagination/Pagination.vue";
import RefundDialog from "./Components/RefundDialog.vue";

export default {
  components: {
    DefaultSaveButton,
    DefaultLayout,
    RefundTable,
    Pagination,
    RefundDialog
  },
  mixins: [PaginationMixin, AuthorizationMixin, FilterMixin],
  name: "Refund",
  created() {
    // this.loadData();

    this.accessOnlySave();
  },
  data() {
    return {
      items: [],
      loading: false,
      accessSave: false,
      instructions: {
        search_for: [
          { text: "Empresa", field: "company_name" },
          { text: "Referência", field: "reference" },
          { text: "Data do Pagamento", field: "payment_date", type: "date" },
          { text: "Justificativa", field: "justification" }
        ],
        filter_for: [
          {
            text: "Solicitação",
            field: "status_id",
            value: 1
          },
          {
            text: "Atendimento",
            field: "status_id",
            value: 2
          },
          {
            text: "Aguardando retorno do cliente",
            field: "status_id",
            value: 3
          },
          {
            text: "Aguardando documentação do cliente",
            field: "status_id",
            value: 4
          },
          {
            text: "Sob cadastro",
            field: "status_id",
            value: 5
          },
          {
            text: "Sob aprovação",
            field: "status_id",
            value: 6
          },
          { text: "Aprovado", field: "status_id", value: 7 },
          {
            text: "Reprovado",
            field: "status_id",
            value: 8
          },
          { text: "Cancelado", field: "status_id", value: 9 }
        ],
        saveLocalStorage: true
      },
      refundFlowDialog: false,
      refund: {},
      authorization: {
        route: "/invoice/refund"
      }
    };
  },
  methods: {
    approve(item) {
      item.status = "confirmed";

      this.refundFlowDialog = true;
      this.refund = item;
    },
    disapprove(item) {
      item.status = "canceled";

      this.refundFlowDialog = true;
      this.refund = item;
    },
    cancelRefundFlow(item) {
      item.status = "analyzing";
    },
    async accessHandle() {
      const canAccessHandle = await this.canAccess({
        route: "/invoice/refund/handle",
        type: "exec"
      });

      return canAccessHandle;
    },
    async accessOnlySave() {
      const canAccessRefund = await this.canAccess({
        route: "/invoice/refund",
        type: "write"
      });

      // const hasPayer = this.verifyIfHasAPayerDefined();
      // const canAccessHandle = await this.accessHandle();

      this.accessSave = canAccessRefund;
    },
    async continueRefundFlow(refund) {
      try {
        this.loading = true;

        delete refund.files;

        await RefundService.build().update(refund);

        const isApproved = refund.status == "confirmed";

        this.showToast({
          message: `Reembolso ${
            isApproved ? "aprovado" : "reprovado"
          } com sucesso`
        });

        this.loadData();
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
        this.refund = null;
      }
    },
    verifyIfHasAPayerDefined() {
      const unserializedData = localStorage.getItem("user");
      const data = JSON.parse(unserializedData);

      return data?.user?.payer_id;
    },
    async loadData() {
      try {
        const query = this.getApiQueryRequest();

        this.loading = true;

        const response = await RefundService.build().index({ query });

        this.items = response.data;

        this.paginate(response);
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    add() {
      this.$router.push({ name: "RefundSave", params: { id: "new" } });
    },
    edit(item) {
      this.$router.push({ name: "RefundSave", params: { id: item.id } });
    },
    getApiQueryRequest() {
      const query = {
        params: {
          ...this.pagination,
          filter: this.filter
        }
      };

      return query;
    }
  }
};
</script>

<style></style>
