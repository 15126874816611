export default class StepsStatus {
  static getStatusList() {
    return {
      1: "Entrada de Cobrança",
      2: "Boleto Gerado",
      3: "PIX gerado",
      4: "Pagamento Confirmado",
      5: "Pagamento Cancelado",
      6: "Pagamento por Crédito",
      7: "Unificado",
      8: "Pagamento Agendado",
      9: "Baixa Operacional",
      10: "Cobrança Jurídica",
      11: "Pago por TED"
    };
  }

  static getStatusIconList() {
    return {
      1: "mdi-database-check-outline",
      2: "mdi-text-box-check-outline",
      3: "$vuetify.icons.pixIcon",
      4: "mdi-check",
      5: "mdi-cancel",
      6: "mdi-transition",
      7: "mdi-select-group",
      8: "mdi-calendar-cursor",
      9: "mdi-lock-outline",
      10: "mdi-scale-balance",
      11: "mdi-bank-check"
    };
  }

  static getIconByStatusID(status_id) {
    const statusIconList = StepsStatus.getStatusIconList();

    const result = statusIconList[status_id];

    if (result) {
      return result;
    }

    return "mdi-help";
  }

  static getMessageByStatusID(status_id) {
    const statusList = StepsStatus.getStatusList();

    const result = statusList[status_id];

    if (result) {
      return result;
    }

    return "";
  }
}
