<template>
  <v-app id="app">
    <dialog-handler />

    <v-main class="v-main__without-spacer">
      <v-container fluid class="pa-0 w-100 h-100">
        <v-row align-content="stretch" class="h-100">
          <v-col cols="12" md="6" class="text-center background-image"> </v-col>

          <v-col cols="12" md="6" class="text-center bg-white">
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import DialogHandler from "@/components/NotificationDialog/DialogHandler.vue";

export default {
  name: "LoginView",
  components: { DialogHandler },
  created() {
    this.redirectIfAuthenticated();
  },
  methods: {
    redirectIfAuthenticated() {
      try {
        const userData = localStorage.getItem("user");

        if (!userData) {
          return;
        }

        const user = JSON.parse(userData || "");

        if (!user) {
          return;
        }

        const url = this.$route.query.post_login || "/home";

        this.$router.push(url);
      } catch (error) {
        // eslint-disable-next-line no-undef
        if (process.env.NODE_ENV !== "production") {
          console.error(error);
        }
      }
    }
  }
};
</script>

<style>
.row {
  height: 100%;
  padding: 0;
  margin: 0;
}
.hide {
  display: none;
}
.background-image {
  background: url("../assets/backgroundLogin.webp");
  background-position: center;
  width: 100%;
}

.logo {
  background: url("../assets/logo_horizontal.svg");
  background-repeat: no-repeat;
  background-size: 60%;
  background-position-x: center;
  font-size: 4rem !important;
  width: 100%;
  height: 73px;
  margin: 15px auto;
  padding: 50px 0;
}

.logo_homologation {
  background: url("../assets/logo_horizontal_development.svg");
  background-repeat: no-repeat;
  background-size: 60%;
  background-position-x: center;
  font-size: 4rem !important;
  width: 100%;
  height: 73px;
  margin: 15px auto;
  padding: 50px 0;
}

.titleWelcome {
  margin-bottom: 15px;
  color: #000;
}
.msg_error {
  margin-bottom: 15px;
  color: red !important;
}
.buttonEnter {
  background: rgb(19, 24, 98) !important;
  color: white;
  height: 44px !important;
}
.linkForgetPassword {
  color: #777;
  float: right;
  margin-top: 10px;
  font-weight: bold;
}
.boxNewUser {
  background: rgb(255, 99, 39) !important;
  margin: 50px 30px;
  display: flex;
  padding: 5px;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
}
.boxNewUserIcon {
  display: inline-block;
  background: rgb(204, 204, 204) !important;
  opacity: 0.5;
  border-radius: 50%;
  font-size: 50px;
  padding: 5px;
  vertical-align: sub;
  height: 58px;
}
.boxNewUserLbl {
  margin: 6px 0px;
  font-weight: bold;
}
.boxNewUserButton {
  background: rgb(19, 24, 98) !important;
  color: white;
  height: 45px;
  margin: 7px 0;
}
.bJCmFu {
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: flex !important;
}
</style>
