export default {
  data() {
    return {
      approving: false,
      disapproving: false,
      handleApproveFinancialReason: false,
      handleDisapproveFinancialReason: false
    };
  },
  computed: {
    alreadyConfirmed() {
      return (
        this.refund.status_id == this.status.approved ||
        this.refund.status_id == this.status.repproved ||
        this.refund.status_id == this.status.cancel
      );
    }
  },
  methods: {
    onApprove() {
      if (this.handleApproveFinancialReason) {
        if (!this.validate()) {
          return;
        }

        this.approve();
      }

      this.handleApproveFinancialReason = true;
    },
    approve() {
      this.approving = true;

      this.refund.status_id = this.status.approved;

      this.save("Reembolso aprovado com sucesso");
    },
    onDisapprove() {
      if (this.handleDisapproveFinancialReason) {
        if (!this.validate()) {
          return;
        }

        this.disapprove();
      }

      this.handleDisapproveFinancialReason = true;
    },
    disapprove() {
      this.disapproving = true;

      this.refund.status_id = this.status.cancel;

      this.save("Reembolso reprovado com sucesso");
    },
    async startAttendance() {
      this.refund.status_id = this.status.attendance;

      await this.save("Reembolso em atendimento", false);

      window.location.reload();
    },
    clearHandleOrReturnToList() {
      if (
        this.handleApproveFinancialReason ||
        this.handleDisapproveFinancialReason
      ) {
        this.handleApproveFinancialReason = false;
        this.handleDisapproveFinancialReason = false;

        return;
      }

      this.returnToList();
    }
  }
};
