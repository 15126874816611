<template>
  <v-data-table
    v-model="selected"
    :headers="headers"
    :items="items"
    :loading="loading"
    :items-per-page="pagination.itemsPerPage"
    :hide-default-footer="true"
    show-select
    :header-props="{ sortIcon: null }"
    class="elevation-0"
  >
    <template #[`item.cnpj`]="{ item }">
      <span>{{ item.cnpj_cpf | VMask("##.###.###/####-##") }}</span>
    </template>

    <template #[`item.status`]="{ item }">
      <v-switch
        inset
        color="secondary"
        v-model="item.status"
        :readonly="!authorization.canEdit"
        @change="changeStatus(item)"
        :true-value="1"
        :false-value="0"
      />
    </template>

    <template #[`item.edit`]="{ item }">
      <default-edit-button
        :onlyShow="!authorization.canEdit"
        @click="edit(item)"
      />
    </template>

    <template #[`item.control`]="{ item }">
      <send-invitation-btn :payer="item" />
    </template>
  </v-data-table>
</template>

<script>
import SendInvitationBtn from "./SendInvitationBtn.vue";

import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";
import DefaultEditButton from "@/components/layout/DefaultEditButton.vue";

export default {
  name: "PayerTable",
  created() {
    this.loadPermissions();
  },
  components: {
    SendInvitationBtn,
    DefaultEditButton
  },
  props: {
    items: Array,
    value: Array,
    pagination: Object
  },
  data() {
    return {
      selected: this.value,
      authorization: {
        route: "/payer"
      },
      headers: [
        { text: "Razão social", value: "name" },
        {
          text: "CNPJ",
          align: "left",
          value: "cnpj"
        },
        {
          text: "Status",
          value: "status",
          align: "center",
          width: "5%",
          sortable: false
        },
        {
          text: "Editar",
          value: "edit",
          align: "center",
          width: "5%",
          sortable: false
        }
      ],
      send_invitation_header: {
        text: "Envio Convite",
        value: "control",
        align: "center",
        width: "5%",
        sortable: false
      }
    };
  },
  watch: {
    selected() {
      this.$emit("input", this.selected);
    },
    value() {
      this.selected = this.value;
    }
  },
  mixins: [AuthorizationMixin],
  methods: {
    edit(item) {
      this.$emit("edit", item);
    },
    changeStatus(item) {
      this.$emit("changeStatus", item.id, item.status);
    },
    async loadPermissions() {
      const sendInvitation = await this.canAccess({
        route: "/send-invitation",
        type: "exec"
      });

      if (sendInvitation) {
        this.headers.push(this.send_invitation_header);
      }

      this.$forceUpdate();
    }
  }
};
</script>

<style></style>
