<template>
  <v-col class="w-100">
    <v-img
      src="@/assets/qrcode-error-background.jpg"
      class="qrcode-error mx-auto d-flex"
      :max-width="qrcodeDefaultWidth"
      :max-height="qrcodeDefaultHeight"
    >
      <div
        class="qrcode-error-info flex-item d-flex flex-column flex-wrap mx-auto my-auto text-center py-2 px-3"
      >
        <slot name="content"></slot>
      </div>
    </v-img>
  </v-col>
</template>

<script>
export default {
  name: "PixQrCodeError",
  data() {
    return {
      qrcodeDefaultWidth: 280,
      qrcodeDefaultHeight: 280
    };
  }
};
</script>

<style lang="scss">
.qrcode-error {
  z-index: 0;

  & > .v-responsive__content {
    display: flex !important;
  }
}

.qrcode-error-info {
  z-index: 1;
  background-color: white;
  max-width: 75%;
  border: 1px solid #a5a5a5;
}
</style>
