<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" v-show="loading">
        <v-progress-circular
          indeterminate
          color="primary"
          class="d-flex flex-item mx-auto"
        />
      </v-col>
    </v-row>
    <!-- PAGINAS -->
    <v-row v-show="access.pages.length">
      <v-col cols="12">
        <access-page-table :accesses="access.pages" />
      </v-col>
    </v-row>

    <v-row v-show="access.functionalities.length">
      <v-col cols="12">
        <access-functionality-table :accesses="access.functionalities" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AccessFunctionalityTable from "./AccessFunctionalityTable.vue";
import AccessPageTable from "./AccessPageTable.vue";

export default {
  name: "AccessList",
  components: { AccessPageTable, AccessFunctionalityTable },
  props: {
    accessType: Object,
    permissions: Array,
    loading: Boolean
  },
  data() {
    return {
      access: {
        pages: [],
        functionalities: []
      }
    };
  },
  watch: {
    permissions() {
      this.serializePermissions();
    }
  },
  async created() {
    this.serializePermissions();
  },
  methods: {
    serializePermissions() {
      const accessType = this.getAccessType();

      const onlyOfThisGroupPermissions = this.permissions.filter(
        permission => permission.group_type == accessType
      );

      onlyOfThisGroupPermissions.forEach(permission => {
        if (permission.type == "page") {
          this.addPageOrAddOperation(permission);
        }

        if (permission.type == "func") {
          this.access.functionalities.push(permission);
        }
      });

      this.$forceUpdate();
    },
    addPageOrAddOperation(page) {
      const alreadyHasPage = this.access.pages.some(
        item => item.route == page.route
      );

      page[page.operation] = page.id;

      if (alreadyHasPage) {
        this.updatePage(page);
      } else {
        this.access.pages.push(page);
      }
    },

    updatePage(page) {
      let pageIndex = 0;

      this.access.pages.forEach((item, index) => {
        if (item.route == page.route) {
          page = { ...item, ...page };

          pageIndex = index;
        }
      });

      this.access.pages.splice(pageIndex, 1);

      this.access.pages.push(page);
    },
    getAccessType() {
      return this.accessType.type;
    }
  }
};
</script>

<style></style>
