<template>
  <v-container fluid class="w-100">
    <v-stepper class="d-flex w-100">
      <v-stepper-header
        class="d-flex flex-row w-100 px-12 invoice-extra-info-stepper"
      >
        <div
          v-for="(step, index) in timelineSteps"
          :key="step.id"
          class="d-flex flex-row"
          :class="index + 1 != timelineSteps.length ? 'flex-2' : 'flex-item'"
        >
          <v-stepper-step
            complete
            color="primary"
            :complete-icon="getTimelineIcon(step)"
            :step="index"
            :class="step.disabled ? 'disabled--text' : 'primary_300--text'"
            class="elevation-0 d-flex flex-column invoice-extra-info-stepper__step"
          >
            <span
              class="mt-2 text-center"
              :class="step.disabled ? 'disabled--text' : 'primary_300--text'"
            >
              <b>{{ getTimelineMessage(step) }}</b>
              <br />
              {{ getTime(step) }}
            </span>
          </v-stepper-step>

          <v-divider v-if="index + 1 != timelineSteps.length" />
        </div>
      </v-stepper-header>
    </v-stepper>

    <v-row v-if="canViewInvoiceHistory">
      <v-col class="mt-n4">
        <invoice-history-btn @click="showInvoiceHistory" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import InvoiceVerify from "@/pages/Invoice/domain/InvoiceVerify";
import StepsStatus from "./StepsStatus";
import InvoiceHistoryBtn from "./InvoiceHistoryBtn.vue";
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";

export default {
  components: { InvoiceHistoryBtn },
  name: "ExtraInfoSteps",
  props: {
    invoice: Object
  },
  data() {
    return {
      canViewInvoiceHistory: false
    };
  },
  mixins: [AuthorizationMixin],
  created() {
    this.loadPermissions();
  },
  computed: {
    timelineSteps() {
      try {
        if (!this.invoice || !this.invoice.histories_timeline) {
          return [];
        }

        const result = Object.values(this.invoice.histories_timeline);

        const alreadyPayed = new InvoiceVerify().alreadyPayed(this.invoice);

        if (!alreadyPayed) {
          result.push({
            status_id: 4,
            disabled: true
          });
        }

        return result;
      } catch (error) {
        console.error(error);

        return [];
      }
    }
  },
  methods: {
    getTime(step) {
      const timestamp = this.formatISODate(step.created_at, {
        withHour: true
      });

      if (!timestamp) {
        return "";
      }

      return `${timestamp}`.replace("00:00", "");
    },
    async loadPermissions() {
      this.canViewInvoiceHistory = await this.canAccess({
        route: "/invoice/history",
        type: "exec"
      });
    },
    showInvoiceHistory() {
      this.$emit("showInvoiceHistory");
    },
    getTimelineMessage(timeline) {
      if (!timeline) {
        return "";
      }

      if (timeline.message) {
        const serializedMessage = timeline.message.replace("!", "");

        return serializedMessage;
      }

      const result = StepsStatus.getMessageByStatusID(timeline.status_id);

      return result;
    },
    getTimelineIcon(timeline) {
      if (!timeline) {
        return "mdi-help";
      }

      const result = StepsStatus.getIconByStatusID(timeline.status_id);

      return result;
    }
  }
};
</script>

<style lang="scss">
.invoice-extra-info {
  &-stepper {
    min-height: 150px;

    &__step {
      & > .v-stepper__step__step.primary {
        border: solid 1px !important;
        background-color: white !important;
      }

      & > .v-stepper__step__step.disabled {
        border: solid 1px !important;
        background-color: white !important;
      }
    }
  }
}

.theme--light.v-stepper .v-stepper__step__step .v-icon {
  color: inherit !important;
}

.theme--light.v-stepper .v-stepper__step__step {
  color: inherit !important;
}
</style>
