<template>
  <v-container class="pa-0" v-if="!errors.length">
    <v-row no-gutters>
      <v-col>
        <span class="text-caption font-weight-bold text--disabled">
          Status do Processo
        </span>

        <v-list flat class="bg-transparent">
          <template v-for="(arrivalResponse, index) in errors">
            <v-list-item :key="index">
              <v-list-item-subtitle>
                Navio: {{ arrivalResponse.ship }} - Número da Viagem:
                {{ arrivalResponse.travel }}
              </v-list-item-subtitle>
              <v-list-item-title>
                {{ arrivalResponse.error }}
              </v-list-item-title>
            </v-list-item>

            <v-divider :key="index" />
          </template>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ArrivalResponseState from "../states/ArrivalResponseState";

export default {
  name: "ErrorList",
  computed: {
    errors() {
      const responses = ArrivalResponseState().getters.errors;

      return responses;
    }
  },
  watch: {
    errors() {
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    }
  }
};
</script>

<style></style>
