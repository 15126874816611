<template>
  <v-form ref="form" v-model="valid">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <file-dialog-contract
            v-model="model.files"
            file-add-button-message="Enviar Arquivo"
            file-upload-button-message="Clique aqui para adicionar as procurações"
            :accepted-formats="FileHandle.acceptedFormats"
            @change="changeFiles"
            @download="download"
            can-manipulate
            :can-manipulate-due-date="canEditDateContract"
          />
        </v-col>

        <v-col md="6" xs="12">
          <payer-select
            v-model="payer"
            field="name_or_cnpj_cpf"
            label="Nome do Pagador"
            return-object
          />
        </v-col>
        <v-col md="2" xs="2">
          <v-btn @click="addPayer" small :loading="registering">
            Adicionar
          </v-btn>
        </v-col>
        <v-col md="12" xs="12">
          <v-data-table
            :headers="headers"
            :items="model.payerGroup"
            @update:options="sortRequest"
            loading-text="Aguarde...carregando"
            :hide-default-footer="true"
            class="elevation-0"
            :header-props="{ sortIcon: null }"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
          >
            <template #[`item.payer_status`]="{ item }">
              <v-switch
                inset
                color="secondary"
                v-model="item.payer_status"
                @change="save"
              />
            </template>

            <template #[`item.cnpj_cpf`]="{ item }">
              {{ maskCNPJ(item.cnpj_cpf) }}
            </template>

            <template #[`item.contract_id`]="{ item }">
              <contract-select
                :user="model"
                v-model="item.contract_id"
                @change="saveContractByUser(item, $event)"
              />
            </template>

            <template #[`item.updated_at`]="{ item }">
              <v-btn
                v-if="canEditDateContract"
                icon
                @click="remove(item)"
                class="ma-2"
              >
                <v-icon> mdi-delete </v-icon>
              </v-btn>
            </template>
          </v-data-table>

          <div class="text-center pt-2">
            <v-pagination v-model="page" :length="pageCount"></v-pagination>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";
import FormMixin from "@/components/misc/mixins/FormMixin";
import FileDialogContract from "@/components/FileUploadContract/FileDialogContract.vue";
import FileHandle from "./Domain/FileHandle";
import DownloadFile from "./Domain/DownloadFile";
import UserService from "@/app/Services/UserService";
import PayerSelect from "@/components/payer_select/PayerSelect.vue";
import ContractSelect from "@/components/FileUpload/ContractSelect.vue";

import { bus } from "@/main";

export default {
  components: { FileDialogContract, PayerSelect, ContractSelect },
  name: "UserContracts",
  mixins: [FormMixin, ModelManagementMixin, AuthorizationMixin, DownloadFile],
  props: {
    value: Object,
    loading: Boolean,
    userTypePermissions: {}
  },
  data() {
    return {
      valid: false,
      FileHandle,
      responsivity: {
        maxWidth: "256px",
        maxHeight: "256px"
      },
      payers: [],
      payer: "",
      payerGroup: {
        payers: []
      },
      canEditDateContract: false,
      headers: [
        {
          text: "Id",
          align: "start",
          value: "id",
          width: "40"
        },
        {
          text: "Nome",
          align: "start",
          value: "name"
        },
        {
          text: "CNPJ/CPF",
          align: "start",
          value: "cnpj_cpf"
        },
        {
          text: "Contrato",
          align: "start",
          value: "contract_id"
        },
        {
          text: "Status",
          value: "payer_status",
          align: "center",
          width: "30"
        },
        { text: "#", value: "updated_at", align: "center", width: "30" }
      ],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10
    };
  },
  watch: {
    valid() {
      this.$emit("valid", this.valid);
    },
    "model.files": {
      handler() {
        this.valid = true;
      }
    }
  },
  async created() {
    this.canEditDateContract = await this.canAccess({
      route: "/user/contract",
      type: "write"
    });
  },
  methods: {
    getUserId() {
      return this.model.id;
    },
    saveContractByUser(item, $event) {
      item.contract_id = $event;

      this.save();
    },
    remove(item) {
      bus.$emit("showNotificationDialog", {
        status: "error",
        title: "Deseja remover esse pagador?",
        continue: () => {
          const idx = this.model.payerGroup.findIndex(con => con.id == item.id);

          this.model.payerGroup.splice(idx, 1);

          this.save();
        }
      });
    },
    async addPayer() {
      try {
        const payers = Object.assign([], this.model.payerGroup);

        payers.unshift({
          created: true,
          cnpj_cpf: this.payer.cnpj_cpf
        });

        const form = {
          id: this.value.id,
          group_id: this.model.group_id,
          payerGroup: payers
        };

        this.payer = "";

        this.registering = true;

        const userService = UserService.build();

        await userService.update(form);

        this.registering = false;

        this.$forceUpdate();

        this.$emit("loadData");
      } catch (e) {
        this.registering = false;

        this.$forceUpdate();

        console.log(e);
        await this.defaultCatchError(e);
      } finally {
        this.registering = false;

        this.$forceUpdate();
      }
    },
    upload() {
      const upload = this.FileHandle.upload.bind(this);

      upload();
    },
    validate() {
      this.valid = this.$refs.form.validate();

      return this.valid;
    },
    changeFiles() {
      this.valid = true;

      this.$emit("valid", this.valid);
    },
    async save() {
      try {
        this.loading = true;

        const form = {
          id: this.model.id,
          group_id: this.model.group_id,
          payerGroup: this.model.payerGroup
        };

        const userService = UserService.build();

        await userService.update(form);
      } catch (error) {
        this.loading = false;

        this.$emit("loadData");

        console.error(error);

        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
