<template>
  <v-hover v-slot="{ hover }">
    <v-col
      class="file-preview-item cursor-pointer ma-2 px-3 pt-1 pb-3 rounded"
      :class="hover ? 'bg-primary-400' : 'bg-primary-300'"
      @click="addNewFile(file)"
    >
      <v-row no-gutters class="file-preview">
        <v-col
          v-if="canManipulate || (file && file.deleted_at == null)"
          cols="12"
          class="d-flex"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-on="on"
                v-bind="attrs"
                icon
                @click="click"
                class="white--text ml-left"
              >
                <v-icon large> mdi-download </v-icon>
              </v-btn>
            </template>

            <span>Baixar Arquivo</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="file"
                v-on="on"
                v-bind="attrs"
                icon
                @click="removeFile"
                class="white--text ml-auto"
              >
                <v-icon large> mdi-close </v-icon>
              </v-btn>
            </template>

            <span>Remover Arquivo</span>
          </v-tooltip>
        </v-col>

        <v-col v-if="file && file.deleted_at != null" cols="12" class="d-flex">
          <v-tooltip bottom>
            <template v-slot:activator="{}">
              <span
                v-if="file && file.deleted_at != null"
                class="captionDeletedAt"
              >
                Deletado <br />
                {{ file.deleted_at | formatDateHour }}</span
              >
              <span
                v-if="file && file.deleted_at == null"
                class="captionCreatedAt"
              >
                <br />
                {{ file.created_at | formatDateHour }}
              </span>
            </template>
          </v-tooltip>
        </v-col>

        <v-col cols="12" class="d-flex py-6">
          <v-icon x-large class="white--text mx-auto">
            {{ icon }}
          </v-icon>
        </v-col>

        <v-col
          cols="12"
          class="white--text text-subtitle-2 file-preview-truncate text-center"
        >
          {{ message }}
        </v-col>

        <v-col
          cols="12"
          class="white--text text-subtitle-2 file-preview-truncate text-center"
        >
          <v-menu
            v-if="file"
            v-model="datePicker1"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="file.validation_date"
                :readonly="
                  (!canManipulateDueDate && file && file.deleted_at == null) ||
                    (file && file.deleted_at != null)
                "
                mask="##/##/####"
                label="Data de validade"
                v-on="on"
              />
            </template>
            <v-date-picker
              :disabled="
                (!canManipulateDueDate && file && file.deleted_at == null) ||
                  (file && file.deleted_at != null)
              "
              v-model="dtIniPickerModel"
              no-title
              locale="pt-br"
              @input="datePicker1 = false"
            />
          </v-menu>
        </v-col>

        <v-col cols="12" v-if="loading">
          <v-progress-linear indeterminate />
        </v-col>
      </v-row>
    </v-col>
  </v-hover>
</template>

<script>
import { bus } from "@/main";

export default {
  name: "FileChip",
  props: {
    message: String,
    icon: String,
    loading: Boolean,
    canManipulateDueDate: Boolean,
    canManipulate: Boolean,
    file: Object || { validation_date: "" }
  },
  watch: {
    dtIniPickerModel: {
      handler() {
        this.file.validation_date = this.formDate(this.dtIniPickerModel);

        this.$emit("updateDateValidate", this.file.validation_date);
      }
    }
  },
  data() {
    return {
      datePicker1: false,
      datePicker2: false,
      dtIniPickerModel: ""
    };
  },
  methods: {
    formDate(date) {
      if (!date) {
        return null;
      }

      const [year, month, day] = date.split("-");

      return `${day}/${month}/${year}`;
    },
    addNewFile(file) {
      if (!file) {
        this.$emit("click");
      }
    },
    click() {
      this.$emit("click");
    },
    removeFile() {
      bus.$emit("showNotificationDialog", {
        status: "error",
        title: "Deseja mesmo remover esse contrato?",
        continue: () => {
          this.$emit("removeFile");
        }
      });
    }
  }
};
</script>

<style>
.file-preview {
  overflow: hidden;
}

.file-preview-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.captionDeletedAt {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #a10035 !important;
  text-align: center;
  width: 100%;
}
.captionCreatedAt {
  color: white;
  font-size: 12px;
  text-align: center;
  width: 100%;
}
</style>
