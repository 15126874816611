var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-6 mb-6 background"},[(_vm.arrMessages.length > 0)?_c('v-timeline',{attrs:{"align-top":"","dense":""}},_vm._l((_vm.arrMessages),function(item,index){return _c('v-timeline-item',{key:index,attrs:{"color":item.status.color != null && item.status.color != ''
            ? item.status.color
            : 'purple',"small":""}},[_c('v-row',{staticClass:"pt-1"},[_c('strong',{staticClass:"text-h6 font-weight-regular mr-6"},[_vm._v(" "+_vm._s(item.user.name)+" ")]),_c('strong',{staticClass:"font-weight-regular mr-6"},[_c('v-chip',{staticClass:"white--text ml-0",attrs:{"color":item.status.color != null && item.status.color != ''
                  ? item.status.color
                  : 'purple',"label":"","small":""}},[_vm._v(" "+_vm._s(item.status.name)+" ")])],1)]),(
            item.status.internal != 1 ||
              _vm.userTypePermissions.canEditInternalUsers == true
          )?_c('v-row',[_vm._v(" "+_vm._s(item.message)+" ")]):_vm._e(),_c('v-row',[_c('v-icon',{attrs:{"left":true,"small":""}},[_vm._v(" mdi-calendar-clock-outline ")]),_vm._v(" "+_vm._s(_vm._f("formatDateHour")(item.created_at))+" "),_c('v-icon',{attrs:{"left":true,"right":true,"small":""}},[_vm._v(" mdi-email-arrow-right-outline ")]),_vm._v(" "+_vm._s(item.user.email)+" ")],1),_c('v-row',_vm._l((item.files),function(file){return _c('file-chip',{key:file.id,attrs:{"file":file,"message":_vm.getFileName(file),"icon":"mdi-paperclip","canManipulate":_vm.userTypePermissions.canEditInternalUsers == true},on:{"click":function($event){return _vm.download(file)},"removeFile":function($event){return _vm.removeFile(file)}}})}),1)],1)}),1):_c('v-col',{staticClass:"text-center pt-12 pb-12",attrs:{"cols":"12"}},[(_vm.isOpenChat())?_c('span',[_vm._v("Envie uma mensagem")]):_c('span',[_vm._v("Atendimento encerrado")])])],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(
      (_vm.userTypePermissions.canEditInternalUsers || _vm.editStatus) && _vm.isOpenChat()
    ),expression:"\n      (userTypePermissions.canEditInternalUsers || editStatus) && isOpenChat()\n    "}],staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"3"}},[_c('RefundSelectChatStatus',{attrs:{"registering":_vm.registering,"canManipulate":_vm.canManipulate},model:{value:(_vm.refund_modify.status_id),callback:function ($$v) {_vm.$set(_vm.refund_modify, "status_id", $$v)},expression:"refund_modify.status_id"}})],1)],1),_c('v-row',{staticClass:"mt-12"},[(_vm.isOpenChat() && _vm.canManipulate)?_c('v-textarea',{attrs:{"filled":"","auto-grow":"","rows":"4","row-height":"30","outlined":"","name":"input-7-4","label":"Mensagem","value":"","counter":500,"loading":_vm.registering,"append-icon":"mdi-paperclip"},on:{"click:append":_vm.addFiles},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}},[_c('file-dialog',{on:{"change":_vm.changeFiles,"download":_vm.download},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1):_vm._e()],1),(_vm.isOpenChat())?_c('v-row',{staticClass:"mt-1",attrs:{"justify":"end"}},[_c('file-dialog-chat',{ref:"upload",attrs:{"file-add-button-message":'Enviar Comprovante'},on:{"change":_vm.changeFiles,"download":_vm.download},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1):_vm._e(),(_vm.isOpenChat())?_c('v-row',{staticClass:"mt-4",attrs:{"justify":"end"}},[(_vm.canManipulate)?_c('v-btn',{attrs:{"small":"","loading":_vm.registering},on:{"click":_vm.addMessage}},[_vm._v(" Enviar ")]):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }