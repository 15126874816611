<template>
  <div
    v-html="response"
    class="pix-frame"
    :height="height"
    id="payment-info"
  ></div>
</template>

<script>
import InvoiceService from "@/app/Services/InvoiceService";
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import { bus } from "@/main";

export default {
  name: "PixPaymentInfo",
  data() {
    return {
      response: null,
      loading: false,
      error: null
    };
  },
  created() {
    bus.$on("render-pix", this.load);
  },
  computed: {
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "1024px";
        case "sm":
          return "1024px";
        case "md":
          return "1024px";
        default:
          return "100%";
      }
    }
  },
  mixins: [ModelManagementMixin],
  methods: {
    async load() {
      try {
        const response = await InvoiceService.build().viewPixInformation(
          this.model.id,
          "html"
        );

        this.error = false;

        const enc = new TextDecoder("utf-8");

        this.response = enc.decode(response);
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="scss">
.pix-frame {
  width: 100%;
  border: none;
  overflow: hidden;
}

.b-info-table--secondary {
  display: flex;

  & > tbody {
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end;

    & > tr {
      display: flex;
      flex: 1;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 4px 0px;

      & > td {
        flex: 1;
        display: flex;
        flex-direction: column;
        min-height: 36px;
        max-width: 33%;
        min-width: 33%;

        & > * {
          flex: 1;
          max-height: 24px;
        }
      }
    }
  }
}
</style>
