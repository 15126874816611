<template>
  <default-layout
    route-name="Pagadores"
    @search="search"
    :loading="loading"
    :instructions="instructions"
    has-filters
    header-actions-class-name="mt-8"
  >
    <template #header-actions>
      <v-tooltip v-if="accessExtraFilter" bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            @click="handleOpenExtraFilterModal"
            class="text--normal ml-auto mr-2"
            color="primary"
          >
            <v-icon>
              mdi-filter-variant
            </v-icon>
          </v-btn>
        </template>
        <span>Filtros Extras</span>
      </v-tooltip>
      <v-spacer />
      <!-- GENERATE REPORT -->
      <authorization-gate
        auth-route="/reports"
        auth-type="exec"
        class="ml-auto"
      >
        <generate-reports-button
          table="payers"
          label="Gerar Relatório"
          :filename="filedescription"
          :filter="filter"
        />
      </authorization-gate>

      <!-- SEND INVITATION -->
      <authorization-gate
        auth-route="/user/send-invitation"
        auth-type="exec"
        class="mx-6"
      >
        <v-btn
          @click="sendInvitation"
          class="text--secondary text-normal px-6 pa-2"
          :disabled="payers.length < 1"
        >
          Enviar Convite
        </v-btn>
      </authorization-gate>

      <!-- CREATE -->
      <authorization-gate auth-route="/payer" auth-type="write" class="mx-6">
        <v-btn class="text-normal ml-auto" color="primary" @click="add">
          Adicionar
        </v-btn>
      </authorization-gate>
    </template>

    <template #content>
      <send-invitation-dialog @loading="changeLoading" />
      <payer-table
        v-model="payers"
        :items="items"
        :pagination="pagination"
        @edit="edit"
        @changeStatus="changeStatus"
      />

      <Dialog v-model="openExtraFilterDialog" :loading="loading" :error="error">
        <template #header-title>
          Filtros Extras
        </template>

        <template #content>
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="4">
                <v-container fluid>
                  <p>Juros e Multa</p>
                  <v-checkbox
                    v-model="extraFilters.interest_fine"
                    label="Padrão"
                    value="default"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="extraFilters.interest_fine"
                    label="Diferenciados"
                    value="custom"
                    hide-details
                  ></v-checkbox>
                </v-container>
              </v-col>

              <v-col cols="12" md="4">
                <v-container fluid>
                  <p>Prazo Dias</p>
                  <v-checkbox
                    v-model="extraFilters.deadline_days"
                    label="Padrão"
                    value="default"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="extraFilters.deadline_days"
                    label="Diferenciados"
                    value="custom"
                    hide-details
                  ></v-checkbox>
                </v-container>
              </v-col>

              <v-col cols="12" md="4">
                <v-container fluid>
                  <p>Roe Programada</p>
                  <v-checkbox
                    v-model="extraFilters.scheduled_roe"
                    label="Ativo"
                    value="active"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="extraFilters.scheduled_roe"
                    label="Inativo"
                    value="inactive"
                    hide-details
                  ></v-checkbox>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </template>

        <template #actions>
          <v-spacer />
          <default-cancel-button text custom-function @cancel="closeDialog" />

          <default-save-button text @click="alterArrivalDateDialog">
            Aplicar
          </default-save-button>
        </template>
      </Dialog>
    </template>

    <template #footer>
      <pagination v-model="pagination" @change="loadData" :loading="loading" />
    </template>
  </default-layout>
</template>

<script>
import PayerService from "@/app/Services/PayerService";
import AuthorizationGate from "@/components/Authorization/AuthorizationGate.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import SendInvitationDialog from "./components/SendInvitationDialog.vue";
import PayerTable from "./components/PayerTable.vue";
import { bus } from "@/main";
import FilterMixin from "@/components/Filters/FilterMixin";
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";

import GenerateReportsButton from "@/components/Reports/GenerateReportsButton.vue";
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import Dialog from "@/components/Dialog/Dialog.vue";
import DefaultCancelButton from "@/components/layout/DefaultCancelButton.vue";
import DefaultSaveButton from "@/components/layout/DefaultSaveButton.vue";

const payerService = PayerService.build();

export default {
  name: "Payer",
  components: {
    AuthorizationGate,
    Pagination,
    SendInvitationDialog,
    PayerTable,
    GenerateReportsButton,
    DefaultLayout,
    Dialog,
    DefaultCancelButton,
    DefaultSaveButton
  },
  data() {
    return {
      instructions: {
        search_for: [
          { text: "Razão Social", field: "name" },
          { text: "CNPJ", field: "cnpj_cpf", mask: "##.###.###/####-##" }
        ],
        filter_for: [
          {
            text: "Ativos",
            field: "status",
            value: 1
          },
          {
            text: "Inativos",
            field: "status",
            value: 0
          },
          {
            text: "Sem usuários cadastrados",
            field: "without_users",
            value: true
          }
        ]
      },
      payers: [],
      loading: false,
      items: [],
      itemsSendInvitation: [],
      accessExtraFilter: false,
      openExtraFilterDialog: false,
      error: null,
      extraFilters: {
        interest_fine: [],
        deadline_days: [],
        scheduled_roe: []
      }
    };
  },
  async created() {
    await this.getAccessExtraFilter();
    await this.loadData();
  },
  mixins: [PaginationMixin, FilterMixin, AuthorizationMixin],
  computed: {
    filedescription() {
      const withoutUsersFilter = this.filter.without_users;

      if (withoutUsersFilter) {
        return "relatorio_pagadores_sem_usuarios";
      }

      return "relatorio_pagadores";
    }
  },
  methods: {
    async loadData() {
      this.loading = true;

      try {
        if (this.accessExtraFilter) {
          if (this.extraFilters.scheduled_roe != null) {
            this.filter = {
              ...this.filter,
              exchange_roe: this.extraFilters.scheduled_roe
            };
          }

          if (this.extraFilters.interest_fine != null) {
            this.filter = {
              ...this.filter,
              interest_fine: this.extraFilters.interest_fine
            };
          }

          if (this.extraFilters.deadline_days != null) {
            this.filter = {
              ...this.filter,
              deadline_days: this.extraFilters.deadline_days
            };
          }
        }

        const query = {
          params: {
            ...this.pagination,
            filter: this.filter
          }
        };

        const response = await payerService.index({ query });

        this.items = response.data;

        this.paginate(response);

        this.$forceUpdate();
      } catch (e) {
        console.log(e);
        this.defaultCatchError(e);
      } finally {
        this.loading = false;
      }
    },
    sendInvitation() {
      bus.$emit("payer.openInvitationDialog", this.payers);
    },
    async changeStatus(item_id, item) {
      this.loading = true;

      try {
        await payerService.update({ id: item_id, status: item });
      } catch (httpError) {
        this.defaultCatchError(httpError);
      } finally {
        this.loading = false;
      }
    },
    add() {
      this.$router.push({ name: "PayerRecord", params: { id: "new" } });
    },
    edit(item) {
      this.$router.push({ name: "PayerRecord", params: { id: item.id } });
    },
    changeLoading(value) {
      this.loading = value;
    },
    async getAccessExtraFilter() {
      this.accessExtraFilter = await this.canAccess({
        route: "/invoice/extra-filter",
        type: "exec"
      });
    },
    handleOpenExtraFilterModal() {
      this.openExtraFilterDialog = true;
    },
    closeDialog() {
      this.openExtraFilterDialog = false;
    },
    async alterArrivalDateDialog() {
      this.closeDialog();
      await this.loadData();
    }
  }
};
</script>
