<template>
  <v-col cols="12" class="mx-0 px-0" @click="downloadPdf">
    <v-btn class="w-100" outlined color="grey lighten-1" :disabled="disabled">
      <span class="d-flex mr-auto black--text">
        Salvar Código em PDF
      </span>

      <v-icon color="grey darken-4">
        mdi-file-pdf-box
      </v-icon>
    </v-btn>
  </v-col>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import { bus } from "@/main";

export default {
  name: "PixActionPrintButton",
  mixins: [ModelManagementMixin],
  props: {
    disabled: Boolean
  },
  methods: {
    downloadPdf() {
      bus.$emit("download-pix-pdf");
    }
  }
};
</script>

<style></style>
