<template>
  <div :class="authorizated ? '' : 'd-none'">
    <slot v-if="authorizated"> </slot>
  </div>
</template>

<script>
import AuthorizationMixin from "./AuthorizationMixin";

export default {
  name: "AuthorizationGate",
  props: {
    /* authRoute: Rota cadastrada para acesso*/
    authRoute: [String, Array],
    /* Tipo de accesso: execute,write ou read */
    authType: String
  },
  data() {
    return {
      authorizated: false
    };
  },
  mixins: [AuthorizationMixin],
  created() {
    if (!this.authRoute) {
      throw new Error(
        "AuthorizationGate::created -  Parâmetro authRoute não definido"
      );
    }

    if (!this.authType) {
      throw new Error(
        "AuthorizationGate::created -  Parâmetro authType não definido"
      );
    }

    this.authenticate();
  },
  methods: {
    async authenticate() {
      this.authorizated = await this.canAccess({
        route: this.authRoute,
        type: this.authType
      });
    }
  }
};
</script>

<style></style>
