<template>
  <v-container fluid>
    <!-- Button -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon @click="sendInvitation" v-bind="attrs" v-on="on">
          <v-icon>
            mdi-email-send
          </v-icon>
        </v-btn>
      </template>

      <span>Enviar Convite</span>
    </v-tooltip>
  </v-container>
</template>

<script>
import { bus } from "@/main";
export default {
  name: "SendInvitation",
  props: {
    payer: Object
  },
  methods: {
    sendInvitation() {
      bus.$emit("payer.openInvitationDialog", [this.payer]);
    }
  }
};
</script>

<style></style>
