<template>
  <v-container fluid class="px-4 mb-4">
    <breadcrumb
      :route-name="routeName"
      v-if="!hideBreadcrumb"
      :custom-function="customFunction"
      @returnToPreviousPage="returnToPreviousPageOrCustom"
    />

    <span v-else class="my-4" />

    <v-card :loading="loading">
      <v-row>
        <v-col
          :xl="hasFilters ? '12' : '6'"
          :lg="hasFilters ? '12' : '6'"
          :md="hasFilters ? '12' : '8'"
          sm="12"
          xs="12"
          class="mt-3 ml-n7 mr-7"
          :class="!hasFilters ? 'mb-n12' : ''"
          v-if="!hasFilters || hasTabs"
        >
          <slot name="tabs"></slot>
        </v-col>

        <v-col cols="12" :class="!hasFilter && hasTabs ? 'mt-n6' : ''">
          <div fluid class="w-100 d-flex flex-column w-100">
            <div
              class="flex-item d-flex flex-row"
              :class="headerFilterClassName"
            >
              <div class="d-flex flex-2 flex-row" v-if="hasFilters">
                <slot name="header-filter">
                  <default-filter
                    :routeName="routeName"
                    :filtersIsVisible="showHeaderFilter"
                    :instructions="instructions"
                    @search="search"
                    :unique-filter="uniqueFilter"
                    @changeFilter="changeFilter"
                  />
                </slot>
              </div>

              <div
                class="d-flex flex-item flex-row"
                v-if="showHeaderActions"
                :class="headerActionsClassName"
              >
                <slot name="header-actions"></slot>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="pa-0 ma-0 mt-n8">
          <slot name="filter"></slot>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" :class="hasFilters || hasTabs ? 'mt-n12' : ''">
          <slot name="content"></slot>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <slot name="footer"></slot>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import DefaultFilter from "../Filters/DefaultFilter.vue";

export default {
  components: {
    Breadcrumb,
    DefaultFilter
  },
  name: "DefaultLayout",
  props: {
    showHeaderActions: { type: Boolean, default: true },
    showHeaderFilter: { type: Boolean, default: true },
    loading: Boolean,
    hasTabs: Boolean,
    routeName: String,
    searchParams: Array,
    hasFilters: Boolean,
    hideBreadcrumb: Boolean,
    instructions: Object,
    uniqueFilter: Boolean,
    customFunction: Boolean,
    headerActionsClassName: String,
    headerFilterClassName: String,
    saveLocalStorage: Boolean
  },
  methods: {
    search(toSearch) {
      this.$emit("search", toSearch);
    },
    returnToPreviousPageOrCustom() {
      if (this.customFunction) {
        this.$emit("returnToPreviousPage");

        return;
      }

      this.$router.go(-1);
    },
    changeFilter(filters) {
      this.$emit("changeFilters", filters);
    }
  }
};
</script>

<style></style>
