import Rest from "../Base/Rest";

/**
 * @typedef {InvoiceService}
 */
export default class InvoiceService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/invoice";

  indexMain(query = {}) {
    return this.post(this.prepareRoute(""), query, {});
  }

  indexMainFilter({ query, url } = {}) {
    return this.post(this.prepareRoute(`/${url || ""}`), query, {});
  }

  /**
   *
   * RELATIONSHIPS
   *
   */
  status() {
    this.relationship = "status";

    return this;
  }

  generatePix() {
    this.relationship = "generate-pix";

    return this;
  }

  viewPixInformation(invoice_id, type) {
    return this.send({ invoice_id, type }, `generate-pix/${invoice_id}/view/`, {
      responseType: "arraybuffer"
    });
  }

  discount() {
    this.relationship = "discount";

    return this;
  }

  /**
   *
   * EXECUTION METHODS
   *
   */
  generateBillet(id) {
    return this.send({ id }, `generate-billet`, {
      responseType: "arraybuffer"
    });
  }

  generateSimulate(id) {
    return this.send({ id }, `generate-simulate`);
  }

  download(file) {
    const query = {
      params: {
        file
      }
    };

    query.responseType = "arraybuffer";

    return this.show(`/download`, query, {
      responseType: "arraybuffer"
    });
  }

  changeStatus(id, status_id) {
    return this.post("api/invoice/change-status", {
      id,
      status_id
    });
  }

  report(query = {}) {
    return this.send(query, "/report");
  }
}
