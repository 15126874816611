export default [
  { text: "Usuários", value: "users" },
  { text: "Pagadores", value: "payers" },
  { text: "Pagadores - Informações Extras", value: "payers_extra_info" },
  { text: "Câmbio", value: "exchange_rates" },
  { text: "Feriados", value: "holidays" },
  { text: "Núcleo de Pagadores", value: "groups" },
  { text: "Núcleo de Pagadores - Pagadores", value: "groups_payers" },
  { text: "Faturas", value: "invoices" },
  { text: "Descontos e Entradas de Faturas", value: "invoices_entries" },
  { text: "Histórico da fatura", value: "invoices_histories" },
  { text: "Juros e Multa", value: "invoices_charges_by_banks_transactions" },
  { text: "Reembolso", value: "invoices_refunds" },
  { text: "Razões de descontos", value: "invoices_discounts_reasons" },
  { text: "Razões de reembolsos", value: "invoices_refunds_reasons" },
  { text: "Tipo de usuário", value: "users_roles" },
  { text: "Permissões de usuários", value: "users_roles_permissions" },
  { text: "Arquivos enviados", value: "upload_files" }
];
