<template>
  <v-data-table
    :headers="headers"
    :items="accesses"
    hide-default-footer
    class="w-100"
    :header-props="{ sortIcon: null }"
  >
    <template v-slot:item.write="{ item }">
      <v-switch
        small
        inset
        color="secondary"
        :input-value="isToWrite(item)"
        @click="changePermission(item, 'write')"
      />
    </template>

    <template v-slot:item.read="{ item }">
      <v-switch
        small
        inset
        color="secondary"
        :input-value="isToWrite(item) || isToRead(item)"
        @click="changePermission(item, 'read')"
      />
    </template>
  </v-data-table>
</template>

<script>
import UserTypePermissionsMixin from "../domain/UserTypePermissionsMixin";

export default {
  name: "AccessPageTable",
  props: {
    accesses: Array
  },
  mixins: [UserTypePermissionsMixin],
  data() {
    return {
      headers: [
        { value: "name", text: "Páginas", width: "80%" },
        { value: "read", text: "Visualização", width: "10%" },
        { value: "write", text: "Edição", width: "10%" }
      ]
    };
  }
};
</script>

<style></style>
