<template>
  <default-layout
    :loading="loading"
    route-name="Núcleo de Pagadores"
    has-filters
  >
    <template #header-filter>
      <default-filter :instructions="instructions" @search="search" />
    </template>

    <template #header-actions>
      <div class="d-flex flex-row ml-auto my-auto">
        <v-spacer />

        <authorization-gate auth-route="/group" auth-type="write">
          <default-save-button @click="add">
            Adicionar
          </default-save-button>
        </authorization-gate>
      </div>
    </template>

    <template #content>
      <v-container fluid>
        <payer-group-table
          @edit="edit"
          :pagination="pagination"
          :items="items"
          @changeStatus="changeStatus"
        />
      </v-container>
    </template>

    <template #footer>
      <pagination v-model="pagination" @change="loadData" :loading="loading" />
    </template>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import AuthorizationGate from "@/components/Authorization/AuthorizationGate.vue";
import DefaultSaveButton from "@/components/layout/DefaultSaveButton.vue";
import Pagination from "@/components/pagination/Pagination.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";

import PayerGroupService from "@/app/Services/PayerGroupService";
import PayerGroupTable from "./components/PayerGroupTable.vue";
import DefaultFilter from "@/components/Filters/DefaultFilter.vue";
import FilterMixin from "@/components/Filters/FilterMixin";

export default {
  components: {
    DefaultLayout,
    AuthorizationGate,
    DefaultSaveButton,
    Pagination,
    PayerGroupTable,
    DefaultFilter
  },
  name: "PayerGroup",
  mixins: [PaginationMixin, AuthorizationMixin, FilterMixin],
  data() {
    return {
      items: [],
      loading: false,
      filter: [],
      authorization: {
        route: "/group"
      },
      instructions: {
        search_for: [
          { text: "Nome do Grupo", field: "name" },
          { text: "Nome do Pagador", field: "payer_name" }
        ],
        filter_for: [
          { text: "Ativos", field: "status", value: 1 },
          { text: "Inativos", field: "status", value: 0 }
        ]
      }
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;

        const query = this.getApiQuery();

        const response = await PayerGroupService.build().index({ query });

        this.items = response.data;

        this.paginate(response);
      } catch (error) {
        console.error(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    getApiQuery() {
      return {
        params: {
          filter: this.filter,
          ...this.pagination
        }
      };
    },
    add() {
      this.$router.push({
        name: "PayerGroupSave",
        params: { id: "new" }
      });
    },
    edit(item) {
      this.$router.push({
        name: "PayerGroupSave",
        params: { id: item.id }
      });
    },
    async changeStatus(item) {
      try {
        this.loading = true;

        const itemTmp = Object.assign({}, item);
        delete itemTmp.payers;

        const response = await PayerGroupService.build().update(itemTmp);

        this.toHandle = null;

        return response.data;
      } catch (error) {
        console.log(error);
        item.status = !item.status;
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
