import ReadElectronicInvoice from "@/pages/ReadElectronicInvoice/ReadElectronicInvoice.vue";

const routes = [
  {
    description: "Nota Fiscal Eletrônica",
    path: "/read-electronic-invoice",
    name: "ReadElectronicInvoice",
    group_type: "",
    icon: "mdi-receipt-text-arrow-left-outline",
    component: ReadElectronicInvoice
  }
];

export default routes;
