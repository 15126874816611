import Rest from "@/app/Base/Rest";

/**
 * @typedef {ReadFileChangeShipService}
 */
export default class ReadFileChangeShipService extends Rest {
  /**
   * @type {String}
   */
  static resource = "invoice/read-file-change-ship-date";
}
