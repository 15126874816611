<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        @click="click"
        class="invoice-history__btn"
      >
        <v-icon>
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </template>

    <span>
      Ver Extrato da Fatura
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: "invoiceHistoryBtn",
  methods: {
    click() {
      this.$emit("click");
    }
  }
};
</script>

<style lang="scss">
.invoice-history__btn {
  top: 8%;
  right: 2%;
  position: absolute;
}
</style>
