export default {
  data() {
    return {
      rules: {
        required: v => !!v || "Campo Necessário",
        exactRequired: v => !!v || v == 0 || "Campo Necessário",
        requireMultipleField: v => v.length > 0 || "Campo Necessário",
        email: v => /.+@.+\..+/.test(v) || "Email inválido",
        greaterThanZero: v => v >= 0 || "Campo não pode ser negativo",
        decimal: v =>
          !v || /^\d{1,8}(\.\d{1,2})?$/.test(v) || "Campo inválido (0.00)"
      },
      valid: false
    };
  },
  watch: {
    valid() {
      this.$emit("changeValidation", this.valid);
    },
    validate() {
      return this.$refs.form.validate();
    }
  }
};
