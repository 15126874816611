<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <user-profile-info-component
        :on="on"
        :attrs="attrs"
        v-model="user"
        v-if="!render"
      />
    </template>

    <v-list>
      <user-profile-header-link />

      <user-profile-header-system-exit-button />
    </v-list>
  </v-menu>
</template>

<script>
import UserProfileInfoComponent from "./components/UserProfileInfo.vue";
import UserProfileHeaderSystemExitButton from "./components/UserProfileHeaderSystemExitButton.vue";
import UserProfileHeaderLink from "./components/UserProfileHeaderLink.vue";

import UserProfileInfo from "./domain/UserProfileInfo";

export default {
  name: "UserProfileHeader",
  components: {
    UserProfileInfoComponent,
    UserProfileHeaderSystemExitButton,
    UserProfileHeaderLink
  },
  data() {
    return {
      user: {},
      render: false
    };
  },
  created() {
    const userProfileInfo = new UserProfileInfo();

    this.user = userProfileInfo.getUserInfo();
  },
  methods: {}
};
</script>

<style lang="scss">
.user-profile-container {
  max-width: 25%;
  min-width: 15%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
  }
}
</style>
