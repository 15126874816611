<template>
  <v-autocomplete
    label="Status"
    color="primary"
    :return-object="returnObject"
    :items="items"
    item-text="name"
    item-value="id"
    :search-input.sync="searchInput"
    :loading="loading"
    :clearable="canManipulate"
    :outlined="outlined"
    :multiple="multiple"
    :small-chips="multiple"
    :rules="rules != 'undefined' ? rules : []"
    no-data-text="Digite para pesquisar..."
    v-model="selected"
    :readonly="!canManipulate"
  >
    <template v-slot:selection="{ item }">
      <v-chip
        small
        close
        @click="removeSelection(item, index)"
        @click:close="removeSelection(item, index)"
        v-if="multiple"
      >
        {{ getItemText(item) }}
      </v-chip>

      <span v-else> {{ getItemText(item) }} </span>
    </template>
  </v-autocomplete>
</template>

<script>
import RefundMessageService from "@/app/Services/RefundMessageService";

export default {
  name: "RefundSelectChatStatus",
  props: {
    returnObject: Boolean,
    value: Object,
    field: String,
    fieldFilterArray: Array,
    label: String,
    outlined: Boolean,
    multiple: Boolean,
    canManipulate: Boolean,
    registering: Boolean,
    rules: Array
  },
  mounted() {
    this.search(true);
  },
  data() {
    return {
      loading: this.registering,
      searchInput: null,
      items: [],
      selected: null,
      timeoutVar: null,
      debounceSearch: this.$utils.registerDebounce(
        this.search,
        1000,
        this.timeoutVar
      )
    };
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        const haveValuesForSelectAtFirstTime = this.value && !this.items.length;

        if (haveValuesForSelectAtFirstTime) {
          const isArray = this.value.length >= 0 || this.value == [];

          if (isArray) {
            this.items = [...this.value];
          } else {
            this.items = [{ ...this.value }];
          }
        }

        this.selected = this.value;
      }
    },
    selected() {
      this.$emit("input", this.selected);
    },
    loading() {
      this.$emit("loading", this.loading);
    },
    items() {
      this.$emit("changeList", this.items);
    },
    searchInput() {
      this.loading = true;

      this.debounceSearch(this.searchInput);
    }
  },
  methods: {
    removeSelection(item, index) {
      const toChange = [...this.selected];

      toChange.splice(index, 1);

      this.selected = toChange;
    },
    getItemText(item) {
      return item.name;
    },
    async search(vload = false) {
      try {
        const response = await RefundMessageService.build().loadChatStatus({});

        if (response.data.length < 1) {
          return;
        }

        this.items = response.data;

        if (vload && this.value == 0) {
          const selectedTmp = this.items.filter(
            con => con.refs == "solicitation"
          );

          if (selectedTmp.length > 0) {
            this.selected = selectedTmp[0].id;
          }
        }
      } catch (error) {
        this.error = this.getExceptionResponseMessage(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
