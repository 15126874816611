export default class UserProfileInfo {
  getUserInfo() {
    const unserializedToken = localStorage.getItem("user");

    const token = JSON.parse(unserializedToken);

    return token.user;
  }

  getLocalStorageData() {
    const unserializedData = localStorage.getItem("user");

    const data = JSON.parse(unserializedData);

    return data;
  }

  updateUserImage(image) {
    const data = this.getLocalStorageData();

    data.user.image = image;

    localStorage.setItem("user", JSON.stringify(data));
  }
}
