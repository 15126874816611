<template>
  <v-container fluid>
    <v-row>
      <exchange-table
        :pagination="pagination"
        :items="items"
        @edit="edit"
        @remove="openRemoveDialog"
      />
    </v-row>

    <v-row>
      <pagination v-model="pagination" @change="loadData" />
    </v-row>
  </v-container>
</template>

<script>
import PaginationMixin from "@/components/pagination/PaginationMixin";
import Pagination from "@/components/pagination/Pagination.vue";
import ExchangeTable from "./ExchangeTable.vue";
import ExchangeRateService from "@/app/Services/ExchangeRateService";

import { bus } from "@/main";

export default {
  components: { Pagination, ExchangeTable },
  name: "ExchangeTabs",
  props: {
    currency: String
  },
  data() {
    return {
      items: [],
      loading: false
    };
  },
  mixins: [PaginationMixin],
  created() {
    this.loadData();
  },
  watch: {
    loading() {
      this.$emit("load", this.loading);
    }
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    edit(id) {
      this.$router.push({
        name: "ExchangeSave",
        params: { id }
      });
    },
    openRemoveDialog(id) {
      bus.$emit("showNotificationDialog", {
        status: "error",
        title: "Deseja Remover esse registro de Câmbio?",
        continue: () => this.remove(id)
      });
    },
    async remove(id) {
      try {
        this.loading = true;

        await ExchangeRateService.build().destroy(id);

        this.loadData();
      } catch (error) {
        console.error(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    getApiQuery() {
      return {
        params: {
          ...this.pagination,
          filter: { currency: this.currency }
        }
      };
    },
    async loadData() {
      try {
        const query = this.getApiQuery();

        this.loading = true;

        const response = await ExchangeRateService.build().index({ query });

        this.items = [];
        this.items = response.data;

        this.paginate(response);
      } catch (error) {
        console.log(error);
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
        this.$forceUpdate();
      }
    }
  }
};
</script>

<style></style>
