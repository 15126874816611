<template>
  <v-data-table
    :items="items"
    :headers="headers"
    :items-per-page="-1"
    hide-default-footer
    sort-by="date"
  >
    <template #item.date="{ item }">
      {{ item.date | formatDate }}
    </template>

    <template #item.action="{ item }">
      <holiday-actions :id="item" @edit="edit" @remove="remove" />
    </template>
  </v-data-table>
</template>

<script>
import HolidayActions from "./HolidayActions.vue";

export default {
  name: "HolidayTable",
  components: {
    HolidayActions
  },
  props: {
    items: Array
  },
  data() {
    return {
      headers: [
        { text: "Nome", value: "name" },
        { text: "Data", value: "date" },
        { text: "", value: "action", width: 80 }
      ]
    };
  },
  methods: {
    edit(id) {
      this.$emit("edit", id);
    },
    remove(id) {
      this.$emit("remove", id);
    }
  }
};
</script>

<style></style>
