<template>
  <file-chip
    :message="fileAddButtonMessage"
    icon="mdi-plus"
    @click="addFiles"
    hideRemove
  />
</template>

<script>
import FileChip from "./FileChip.vue";
export default {
  components: { FileChip },
  name: "FileChangeButton",
  props: {
    fileAddButtonMessage: Boolean
  },
  methods: {
    addFiles() {
      this.$emit("addFiles");
    }
  }
};
</script>

<style></style>
