<template>
  <v-container fluid>
    <v-row>
      <v-col md="3" class="d-xs-none d-md-flex" />
      <v-col md="6" sm="12" xs="12">
        <v-btn icon x-large @click="backToLogin">
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
      </v-col>

      <v-col lg="3" md="2" class="d-xs-none d-md-flex" />
      <v-col lg="3" md="2" class="d-xs-none d-md-flex" />

      <v-col lg="6" md="8" sm="12" xs="12" class="mx-auto">
        <div class="d-flex flex-column text-center">
          <v-img
            contain
            :src="require('@/assets/logo_horizontal.svg')"
            max-width="560"
            class="mx-auto flex-item"
          />

          <span class="text-h6 mt-3">
            Pré-cadastro
          </span>
        </div>
      </v-col>

      <v-col lg="3" md="2" class="d-xs-none d-md-flex" />
      <v-col lg="4" md="3" class="d-xs-none d-md-flex" />

      <v-col lg="4" md="6" sm="12" xs="12" class="mx-auto">
        <pre-register-form />
      </v-col>

      <v-col lg="4" md="3" class="d-xs-none d-md-flex" />

      <v-col cols="12"></v-col>
    </v-row>
  </v-container>
</template>

<script>
import PreRegisterForm from "./components/PreRegisterForm.vue";

export default {
  components: { PreRegisterForm },
  name: "PreRegister",
  methods: {
    backToLogin() {
      this.$router.push({ name: "Login" });
    }
  }
};
</script>

<style></style>
