<template>
  <v-dialog v-model="dialog" width="60%">
    <v-card>
      <v-container fluid>
        <v-row v-if="this.model && fieldsHeader.length == 0">
          <v-col>
            <span class="text-h5"> Usuário </span>
          </v-col>
        </v-row>

        <v-row v-if="this.model && fieldsHeader.length == 0">
          <v-col>
            <v-data-table
              :headers="headerInfo"
              hide-default-footer
              :items="user"
            >
            </v-data-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <span class="text-h5"> Auditoria de {{ getTable() }} </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-data-table
              :headers="headerInfo"
              hide-default-footer
              :items="fieldsHeader"
            >
            </v-data-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <span class="text-h5"> Campo(s) alterado(s)</span>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-data-table
              :headers="headers"
              hide-default-footer
              :items="fields"
            >
            </v-data-table>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="d-flex">
            <v-spacer />

            <v-btn class="font-weight-bold" @click="cancel" text color="text">
              Fechar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import AuditHandle from "../domain/AuditHandle";

export default {
  name: "AuditFieldsModel",
  data() {
    return {
      dialog: false,
      headerInfo: [
        { text: "Informações", value: "name", width: "20%" },
        { text: "", value: "value", width: "80%" }
      ],
      headers: [
        { text: "Campo", value: "field" },
        { text: "Valor Original", value: "original_value" },
        { text: "Valor Modificado", value: "updated_value" }
      ]
    };
  },
  mixins: [ModelManagementMixin],
  computed: {
    fieldsHeader() {
      if (!this.model) {
        return [];
      }

      if (!this.model.from_to) {
        return [];
      }

      return [...this.model.from_to];
    },
    user() {
      if (!this.model) {
        return [];
      }

      if (!this.model.user) {
        return [];
      }

      const fields = this.model.user;

      const user = [
        { name: "Nome", value: fields.name },
        {
          name: "Data",
          value: this.formatISODate(this.model.updated_at, { withHour: true })
        }
      ];

      return user;
    },
    fields() {
      if (!this.model) {
        return [];
      }

      const auditHandle = new AuditHandle();

      const fields = auditHandle.getFields(this.model);

      for (const item of fields) {
        item["original_value"] =
          item["original_value_formatted"] != undefined
            ? item["original_value_formatted"]
            : item["original_value"] != null
            ? item["original_value"]
            : "";
        item["updated_value"] =
          item["updated_value_formatted"] != undefined
            ? item["updated_value_formatted"]
            : item["updated_value"] != null
            ? item["updated_value"]
            : "";
      }

      return fields;
    }
  },
  methods: {
    search(model) {
      this.model = model;

      this.dialog = true;
    },
    cancel() {
      // this.model = {};

      this.dialog = false;
    },
    getTable() {
      if (!this.model) {
        return;
      }

      const auditHandle = new AuditHandle();

      return auditHandle.getTable(this.model);
    }
  }
};
</script>

<style></style>
