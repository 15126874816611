<template>
  <v-form v-model="valid" ref="form">
    <v-container v-model="valid" fluid>
      <v-row>
        <v-col xs="12" lg="4" md="6">
          <v-text-field label="Razão Social" id="name" v-model="model.name" />
        </v-col>
        <v-col xs="12" lg="4" md="6">
          <v-text-field
            id="cnpj"
            label="CNPJ"
            v-mask="$cnpjMask"
            v-model="model.cnpj"
            :rules="[validateCnpj]"
          />
        </v-col>

        <v-col xs="12" lg="4" md="6">
          <v-text-field
            id="street"
            label="Endereço"
            v-model="model.street"
            :rules="[rules.required]"
          />
        </v-col>

        <v-col xs="12" lg="4" md="6">
          <v-text-field
            id="number"
            label="Número"
            v-model="model.number"
            :rules="[rules.required]"
          />
        </v-col>

        <v-col xs="12" lg="4" md="6">
          <v-text-field
            id="complement"
            label="Complemento"
            v-model="model.complement"
          />
        </v-col>

        <v-col xs="12" lg="4" md="6">
          <v-text-field
            id="neighborhood"
            label="Bairro"
            v-model="model.neighborhood"
          />
        </v-col>

        <v-col xs="12" lg="4" md="6">
          <v-text-field id="cep" label="CEP" v-model="model.zip_code" />
        </v-col>

        <v-col xs="12" lg="4" md="6">
          <state-select id="state" v-model="model.uf" />
        </v-col>

        <v-col xs="12" lg="4" md="6">
          <city-select id="city" v-model="model.city_id" :uf="model.uf" />
        </v-col>

        <v-col xs="12" lg="4" md="6">
          <v-text-field id="email" label="E-mail" v-model="model.email" />
        </v-col>

        <v-col xs="12" lg="4" md="6">
          <v-text-field
            id="phone"
            v-mask="'(##) ####-####'"
            label="Telefone"
            v-model="model.phone"
          />
        </v-col>

        <v-col xs="12" lg="4" md="6">
          <bank-select
            v-model="model.bank_id"
            cy-data="bank_account_select"
            item-value="id"
            :return-object="false"
            :rules="[rules.required]"
          />
        </v-col>

        <v-col xs="12" lg="4" md="6">
          <v-select
            id="status"
            label="Status"
            v-model="model.status"
            :items="status_select_items"
            data-cy="status_select"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import FormMixin from "@/components/misc/mixins/FormMixin";

import StateSelect from "@/components/StateSelect/StateSelect.vue";
import CitySelect from "@/components/CitySelect/CitySelect.vue";
import BankSelect from "@/components/BankSelect/BankSelect.vue";

export default {
  name: "CompanySaveForm",
  mixins: [ModelManagementMixin, FormMixin],
  components: {
    StateSelect,
    CitySelect,
    BankSelect
  },
  data() {
    return {
      status_select_items: [
        { text: "Ativo", value: 1 },
        { text: "Inativo", value: 0 }
      ],
      valid: false
    };
  },
  watch: {
    valid() {
      this.$emit("valid", this.valid);
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    }
  }
};
</script>

<style></style>
