<template>
  <default-layout
    :loading="loading"
    route-name="Contas Bancárias"
    @returnToPreviousPage="returnToList"
    custom-function
  >
    <template #tabs>
      <v-tabs v-model="tab" color="secondary">
        <v-tab
          href="#account"
          class="font-weight-bold"
          id="account"
          cy-data="tab_account"
        >
          Informações
        </v-tab>

        <v-tab
          href="#config"
          class="font-weight-bold"
          id="config"
          cy-data="tab_config"
        >
          Configurações da API
        </v-tab>

        <v-tab
          href="#extra"
          class="font-weight-bold"
          id="extra"
          cy-data="tab_extra"
        >
          Configurações Extras
        </v-tab>
      </v-tabs>
    </template>

    <template #header-actions>
      <v-spacer />

      <default-cancel-button @click="returnToCompanyManagement" />

      <authorization-gate auth-route="/bank" auth-type="write" class="d-flex ">
        <default-save-button
          class="my-auto"
          :disabled="!valid"
          :loading="saving"
          @click="save"
        />
      </authorization-gate>
    </template>

    <template #content>
      <v-container class="mt-n6" fluid>
        <v-tabs-items v-model="tab">
          <v-tab-item value="account">
            <bank-account-save-form
              v-model="account"
              :old-data="oldAccount"
              @valid="changeValidation"
            />
          </v-tab-item>

          <v-tab-item value="config">
            <bank-account-config-form
              v-model="accountConfig"
              :bank="account.bank"
            />
          </v-tab-item>

          <v-tab-item value="extra" :disabled="doesntHaveAConfiguration">
            <bank-account-config-extra-form v-model="accountConfigExtra" />
          </v-tab-item>
        </v-tabs-items>
      </v-container>
    </template>
  </default-layout>
</template>

<script>
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import DefaultCancelButton from "@/components/layout/DefaultCancelButton.vue";
import DefaultSaveButton from "@/components/layout/DefaultSaveButton.vue";
import AuthorizationGate from "@/components/Authorization/AuthorizationGate.vue";
import BankAccountSaveForm from "./components/BankAccountSaveForm.vue";
import BankAccountConfigForm from "./components/BankAccountConfigForm.vue";
import BankAccountConfigExtraForm from "./components/BankAccountConfigExtraForm.vue";

import BankAccountService from "@/app/Services/BankAccountService";
import CompanyState from "@/components/Company/State";

import { v1 as uuid } from "uuid";

export default {
  components: {
    DefaultLayout,
    DefaultCancelButton,
    DefaultSaveButton,
    AuthorizationGate,
    BankAccountSaveForm,
    BankAccountConfigForm,
    BankAccountConfigExtraForm
  },
  name: "BankAccountSave",
  data() {
    return {
      tab: "",
      loading: false,
      saving: false,
      valid: false,
      account: {},
      oldAccount: {},
      accountConfig: {},
      accountConfigExtra: []
    };
  },
  computed: {
    doesntHaveAConfiguration() {
      return (
        !this.accountConfig ||
        !this.accountConfig.api_version ||
        !this.accountConfig.client_id
      );
    }
  },
  created() {
    if (this.isToEdit()) {
      this.loadData();
    }
  },
  methods: {
    isToEdit() {
      const id = this.$route.params.id;

      return id && id != "new";
    },
    isToCreate() {
      const id = this.$route.params.id;

      return id || id == "new";
    },
    changeValidation(value) {
      this.valid = value;
    },
    async loadData() {
      try {
        this.loading = true;

        const id = this.$route.params.id;

        const stateAccount = this.findAccountInStateManagement(id);

        if (stateAccount) {
          this.account = stateAccount;
          this.accountConfig = this.account.config || {};
          this.accountConfigExtra = this.account.config
            ? this.account.config.extra
            : [];

          return;
        }

        const response = await BankAccountService.build().show(id);

        this.account = response.data;
        this.accountConfig = response.data.accounts_config || {};
        this.accountConfigExtra = response.data.accounts_config
          ? response.data.accounts_config.accounts_config_extra
          : [];

        this.oldAccount = { ...this.account };
      } catch (error) {
        console.error(error);

        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    async save() {
      try {
        this.saving = true;

        let accounts = CompanyState().state.accounts;

        if (this.isToEdit()) {
          accounts = this.edit();
        } else {
          accounts = this.create();
        }

        accounts = this.changeStatusForAllAccountsIfNecessary({ accounts });

        CompanyState().dispatch("updateAccounts", accounts);

        this.showSuccessSaveMessage();

        this.returnToCompanyManagement();
      } catch (error) {
        console.error(error);
      } finally {
        this.saving = false;
      }
    },
    findAccountInStateManagement() {
      const accounts = CompanyState().state.accounts;

      const id = this.$route.params.id;

      const account = accounts.find(account => account.id == id);

      return account;
    },
    returnToCompanyManagement() {
      const companyId = this.$route.params.company_id;

      this.$router.push({
        name: "CompanySave",
        params: { id: companyId }
      });
    },
    edit() {
      let accounts = CompanyState().state.accounts;

      accounts = accounts.map(account => {
        if (account.id == this.account.id) {
          account = { ...account, ...this.account };
          account.config = this.accountConfig;
          account.config.edited = true;
          account.config.extra = this.accountConfigExtra;

          account.edited = true;
        }

        return account;
      });

      return accounts;
    },
    create() {
      const accounts = CompanyState().state.accounts;

      this.account.id = uuid();
      this.account.inserted = true;
      this.account.config = this.accountConfig;
      this.account.config.inserted = true;
      this.account.config.extra = this.accountConfigExtra;

      accounts.push(this.account);

      return accounts;
    },
    changeStatusForAllAccountsIfNecessary({ accounts }) {
      if (!this.account.status) {
        return accounts;
      }

      return this.changeAllAccountStatusToOffOnThisBank({
        accounts,
        handle: this.account
      });
    },
    changeAllAccountStatusToOffOnThisBank({ accounts, handle }) {
      const serializedAccounts = accounts.map(account => {
        if (account.id != handle.id && account.bank.id == handle.bank.id) {
          account.edited = true;
          account.status = 0;
        }

        return account;
      });

      return serializedAccounts;
    },
    showSuccessSaveMessage() {
      this.showToast({ message: "Banco Salvo com sucesso" });
    },
    async returnToList() {
      await CompanyState().dispatch("updateAccountEditingStatus", false);

      this.$router.go(-1);
    }
  }
};
</script>

<style></style>
