<template>
  <v-col class="pa-12 my-12 text-center text--secondary d-flex flex-column">
    <span class="text-h4 flex-item text-center w-100">
      <slot name="message"></slot>
    </span>
    <span class="text-subtitle-2">
      <slot name="complemention"></slot>
    </span>
  </v-col>
</template>

<script>
export default {
  name: "CentralizedMessage"
};
</script>

<style></style>
