import UserTypeAccessState from "./UserTypeAccessState";

const userTypeAccessState = UserTypeAccessState();

export default {
  methods: {
    /**
     * verifica se o tipo de usuario "pode" realizar alguma acao
     * faz a analise atraves do id da permissao e retorna o resultado em bool
     *
     * @param {Vuex} context
     * @param {int} permission_id
     * @returns {boolean}
     */
    permissionIsEnable(permission, operation) {
      const can = this.find(permission, operation);

      return can != -1;
    },

    findPermissionByRoute(permission) {
      const enabledPermissions = userTypeAccessState.state.permissions;

      const index = enabledPermissions.findIndex(
        statePermission => statePermission.route == permission.route
      );

      if (index == -1) {
        return null;
      }

      const found = enabledPermissions[index];

      found.index = index;

      return found;
    },

    find(permission) {
      try {
        const found = this.findPermissionByRoute(permission);

        return found;
      } catch (error) {
        return null;
      }
    },

    async changePermission(permission, operation) {
      const foundPermission = this.find(permission, operation);

      if (foundPermission && operation != "write") {
        userTypeAccessState.dispatch("disable", foundPermission.index);

        return;
      }

      if (foundPermission) {
        this.verifyWritePermissions(foundPermission);

        return;
      }

      permission.operation = operation;

      userTypeAccessState.dispatch("enable", permission);
    },

    verifyWritePermissions(permission) {
      if (permission.write) {
        permission.operation = "read";
        permission.read = true;
        permission.write = false;

        userTypeAccessState.dispatch("update", permission);
      } else {
        permission.operation = "write";
        permission.read = true;
        permission.write = true;

        userTypeAccessState.dispatch("update", permission);
      }
    },

    isToRead(permission) {
      const found = this.find(permission);

      if (!found) {
        return false;
      }

      const result = found.operation == "read";

      return result;
    },

    isToWrite(permission) {
      const found = this.find(permission);

      if (!found) {
        return false;
      }

      const result = found.operation == "write";

      return result;
    },

    isToExecute(permission) {
      const found = this.find(permission);

      if (!found) {
        return false;
      }

      return found.operation == "exec";
    }
  }
};
