<template>
  <v-container
    v-on="on"
    v-bind="attrs"
    class="user-profile-container pa-2 ma-0 mt-n1 cursor-pointer"
  >
    <v-row no-gutters>
      <v-col class="my-auto" cols="2">
        <v-avatar>
          <v-img
            v-if="hasImage"
            class="mx-auto"
            :v-bind="attrs"
            :v-on="on"
            :src="getUrlWithToken(this.model.image)"
            max-width="48px"
          />

          <v-img
            v-else
            :src="getUserImageUrl()"
            :v-bind="attrs"
            :v-on="on"
            max-width="48px"
            class="mx-auto"
          />
        </v-avatar>
      </v-col>

      <v-col class="flex-column d-flex text-left px-4" cols="9">
        <span class="text-subtitle-1 my-auto w-100 flex-item text-truncate">
          {{ model ? model.name : "" }}
        </span>

        <span class="text-subtitle-2 text-truncate">
          {{ payerOrCompany() }}
        </span>
      </v-col>

      <v-col cols="1">
        <v-btn icon disabled>
          <v-icon>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import UserService from "@/app/Services/UserService";

export default {
  name: "UserProfileHeader",
  props: {
    attrs: Object,
    on: Boolean
  },
  computed: {
    hasImage() {
      return this.model.image;
    }
  },
  mixins: [ModelManagementMixin],
  methods: {
    getUserImageUrl() {
      const url = UserService.getUserImage(this.model);

      return url;
    },
    payerOrCompany() {
      if (this.model.payer) {
        return this.model.payer.name;
      }

      if (this.model.companies && this.model.companies[0]) {
        return this.model.companies[0].name;
      }

      return "Hapag-Lloyd | Sistema de Cobrança";
    }
  }
};
</script>

<style></style>
