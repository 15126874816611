var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-100"},[_c('image-upload-input',{ref:"img_upload_input",model:{value:(_vm.selectedFile),callback:function ($$v) {_vm.selectedFile=$$v},expression:"selectedFile"}}),(_vm.doesntHaveFileAndPreview)?_c('image-upload-button',{ref:"img_upload_button",on:{"click":_vm.selectFile},model:{value:(_vm.selectedFile),callback:function ($$v) {_vm.selectedFile=$$v},expression:"selectedFile"}}):(_vm.haveFileButNotMakePreviewYet)?_c('image-cropper',{attrs:{"image":_vm.selectedFile,"aspectRatio":_vm.aspectRatio,"aspect-ratio":_vm.responsivity.aspectRatio,"responsivity":{
      mdWidth: _vm.responsivity.mdWidth,
      mdHeight: _vm.responsivity.mdHeight,
      smWidth: _vm.responsivity.smWidth,
      smHeight: _vm.responsivity.smHeight
    }},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}}):_c('image-preview',{attrs:{"responsivity":{
      maxWidth: _vm.responsivity.maxWidth,
      maxHeight: _vm.responsivity.maxHeight
    }},on:{"change":_vm.selectFile},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }