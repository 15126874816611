<template>
  <v-dialog v-model="dialog" width="60%">
    <v-card :loading="loading">
      <v-row class="py-2">
        <v-col cols="12" xs="12">
          <span class="text-h5 flex-item"> {{ getText }} PIX </span>
        </v-col>
      </v-row>

      <v-row v-if="loading">
        <centralized-message>
          <template #message>
            Carregando...
          </template>
        </centralized-message>
      </v-row>

      <v-row v-else-if="error">
        <centralized-message>
          <template #message>
            {{ error.message }}
          </template>

          <template #complemention>
            {{ error.complemention }}
          </template>
        </centralized-message>
      </v-row>

      <v-row v-else class="mt-n6">
        <v-col cols="12" xs="12" class="d-flex flex-row">
          <preview-pix-dialog-form v-model="preview" />
        </v-col>
      </v-row>

      <v-col sm="12" class="pb-6 d-flex flex-row">
        <!-- disclaimer -->
        <v-container>
          <v-row no-gutters>
            <v-col cols="12" class="d-flex flex-row">
              <v-spacer />

              <v-btn
                class="font-weight-bold"
                @click="closePreviewPix"
                text
                color="text"
              >
                Fechar
              </v-btn>

              <v-btn
                @click="goToGeneratedPix"
                class="font-weight-bold"
                color="primary"
                text
                :loading="generating"
                :disabled="loading || error || !preview"
              >
                {{ getText }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import { bus } from "@/main";
import { DateTime } from "luxon";

import InvoiceService from "@/app/Services/InvoiceService";
import PreviewPixDialogForm from "./components/PreviewPIXDialogForm.vue";
import CentralizedMessage from "../misc/CentralizedMessage.vue";

export default {
  components: { PreviewPixDialogForm, CentralizedMessage },
  name: "PreviewPixDialog",
  data() {
    return {
      preview: null,
      invoice: null,
      error: null,
      loading: false,
      generating: false,
      dialog: false
    };
  },
  created() {
    bus.$on("preview_pix_dialog_show", this.showDialog);
  },
  computed: {
    getText() {
      if (this.invoice && this.invoice.status_id == 1) {
        return "Gerar";
      }

      return "Visualizar";
    }
  },
  methods: {
    async goToGeneratedPix() {
      const route = await this.$router.resolve({
        name: "PixGenerated",
        params: {
          invoice: this.invoice.id
        }
      });

      try {
        this.generating = true;

        await InvoiceService.build()
          .generatePix()
          .send({ id: this.invoice.id });

        window.open(route.href);
        this.closePreviewPix();
      } catch (error) {
        this.defaultCatchError(error);

        this.generating = false;

        this.error = this.getExceptionResponseMessage(error);
      } finally {
        this.generating = false;
      }
    },
    getTime() {
      const now = DateTime.now();

      return now.setLocale("pt-BR").toFormat("D");
    },
    showDialog(invoice) {
      this.invoice = invoice;
      this.dialog = true;

      this.loadPreview(invoice);
    },
    closePreviewPix() {
      this.dialog = false;
      this.$emit("altered");
    },
    async loadPreview(invoice) {
      try {
        this.loading = true;

        const response = await InvoiceService.build().generateSimulate(
          invoice.id
        );

        this.preview = await response.data;
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style></style>
