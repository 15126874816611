<template>
  <v-form v-model="valid" ref="form">
    <v-container fluid>
      <v-row>
        <v-col cols="12" v-show="loading">
          <v-progress-linear
            indeterminate
            color="primary"
            class="d-flex flex-item mx-auto"
          />
        </v-col>

        <v-col xl="6" lg="6" md="6" sm="12" xs="12">
          <v-text-field
            label="Nome"
            v-model="model.name"
            placeholder="Digite..."
            :rules="[rules.required]"
          />
        </v-col>

        <v-col xl="6" lg="6" md="6" sm="12" xs="12">
          <date-picker v-model="model.date" label="Data do Feriado" />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import DatePicker from "@/components/date_picker/DatePicker.vue";

export default {
  components: { DatePicker },
  name: "HolidaySaveForm",
  props: {
    value: Object,
    loading: Boolean
  },
  mixins: [ModelManagementMixin],
  data() {
    return {
      rules: {
        required: v =>
          (v !== "undefined" && v !== null && v !== "") || "Campo Necessário"
      },
      valid: {},
      holiday: this.value
    };
  },
  methods: {
    validate() {
      const valid = this.$refs.form.validate();

      return valid;
    }
  }
};
</script>
