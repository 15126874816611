<template>
  <default-layout :loading="loading" route-name="Motivo de Descontos">
    <template v-slot:header-actions>
      <div class="d-flex flex-row ml-auto">
        <v-spacer />

        <default-cancel-button @click="cancel" />

        <default-save-button :loading="saving" @click="save" />
      </div>
    </template>

    <template v-slot:content>
      <v-container fluid class="mt-n6">
        <discount-reason-save-form v-model="discount" />
      </v-container>
    </template>
  </default-layout>
</template>

<script>
import DiscountReasonSaveForm from "./components/DiscountReasonSaveForm.vue";
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import DefaultCancelButton from "@/components/layout/DefaultCancelButton.vue";
import DefaultSaveButton from "@/components/layout/DefaultSaveButton.vue";

import DiscountReasonService from "@/app/Services/DiscountReasonService";

export default {
  components: {
    DiscountReasonSaveForm,
    DefaultLayout,
    DefaultCancelButton,
    DefaultSaveButton
  },
  name: "DiscountReasonSave",
  data() {
    return {
      loading: false,
      saving: false,
      discount: {}
    };
  },
  created() {
    if (this.isToEdit()) {
      this.loadData();
    }
  },
  methods: {
    async save() {
      try {
        this.saving = true;

        const service = DiscountReasonService.build();
        const id = this.$route.params.id;

        if (id != "new") {
          await service.update(this.discount);
        } else {
          delete this.discount.id;

          await service.store(this.discount);
        }

        this.discount = null;

        this.$router.push({ name: "DiscountReason" });
      } catch (error) {
        console.log(error);
      } finally {
        this.saving = false;
        this.$forceUpdate();
      }
    },
    async loadData() {
      try {
        const id = this.$route.params.id;

        this.loading = true;
        this.discount = {};

        const response = await DiscountReasonService.build().show(id);

        this.discount = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    isToEdit() {
      const id = this.$route.params.id;

      return id && id != "new";
    },
    validate() {
      const valid = this.$refs.DiscountReasonSaveForm.validate();

      return valid;
    }
  }
};
</script>

<style></style>
