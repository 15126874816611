<template>
  <default-layout
    route-name="Alterar Data do Navio"
    @search="search"
    :loading="loading"
  >
    <template #header-actions>
      <v-spacer />

      <default-save-button
        @click="upload()"
        :disabled="files.length == 0"
        :loading="uploading"
      >
        Enviar
      </default-save-button>
    </template>

    <template #content>
      <invoice-search-dialog :data="itensDialog" :showDialog="showDialog" />

      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <file-dialog
              v-if="!rerenderFileDialog"
              v-model="files"
              file-add-button-message="Enviar Arquivo"
              file-upload-button-message="Clique Aqui para Adicionar Arquivos e Atualizar a Nova Data de Chegada"
              :accepted-formats="FileHandle.acceptedFormats"
              can-manipulate
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <keep-alive>
              <read-electronic-invoice-table
                :items="items"
                :pagination="pagination"
                @download="download"
                @search_invoices="search_invoices"
              />
            </keep-alive>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #footer>
      <pagination v-model="pagination" @change="loadData" :loading="loading" />
    </template>
  </default-layout>
</template>

<script>
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";
import DefaultLayout from "@/components/layout/DefaultLayout.vue";
import ReadElectronicInvoiceTable from "./../ReadElectronicInvoice/components/ReadElectronicInvoiceTable.vue";
import Http from "./../ReadFileChangeShipDate/domain/Http";
import FileDialog from "@/components/FileUpload/FileDialog.vue";
import DefaultSaveButton from "@/components/layout/DefaultSaveButton.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import Pagination from "@/components/pagination/Pagination.vue";

import FileHandle from "./../ReadFileChangeShipDate/domain/FileHandle";
import InvoiceSearch from "./../WriteOffPayment/domain/InvoiceSearch";
import InvoiceSearchDialog from "./components/InvoiceSearchDialog/InvoiceSearchDialog.vue";

export default {
  components: {
    DefaultLayout,
    ReadElectronicInvoiceTable,
    FileDialog,
    DefaultSaveButton,
    InvoiceSearchDialog,
    Pagination
  },
  name: "ReadElectronicInvoice",
  data() {
    return {
      items: [],
      files: [],
      uploading: false,
      loading: false,
      FileHandle,
      InvoiceSearch,
      Http,
      rerenderFileDialog: false,
      dialogOpt: false,
      payType: "W",
      canInvoiceChangeStatus: false,
      itensDialog: [],
      showDialog: false
    };
  },
  mixins: [PaginationMixin, AuthorizationMixin],
  async created() {
    this.loadData();
    // this.canInvoiceChangeStatus = await this.canAccess({
    //   route: "/invoice/change-status",
    //   type: "exec"
    // });
  },
  methods: {
    loadData() {
      const loadData = this.Http.loadData.bind(this);

      loadData();
    },
    async upload() {
      this.showDialog = false;
      const count = this.files.filter(file => !file.removed);

      if (count.length > 0) {
        this.dialogOpt = false;
        this.files.map(file => (file.payType = this.payType));
        const upload = await this.FileHandle.upload.bind(this);

        await upload();

        this.files = [];

        await this.loadData();
      } else {
        this.dialogOpt = false;
        this.showToast({
          message: "Selecione um arquivo!",
          status: "error"
        });
      }
    },
    async download(item) {
      this.showDialog = false;
      const download = await this.FileHandle.download.bind(this);

      await download(item);
    },
    async search_invoices(item) {
      this.loading = true;
      this.showDialog = false;
      const res = await this.Http.getReadElectronicInvoices(item.id);
      this.itensDialog = res.read_electronic_invoices;
      this.showDialog = true;
      this.loading = false;
    }
  }
};
</script>

<style></style>
