export default class FileValidator {
  static validate(file) {
    const twoMegabytes = 1024 * 1024 * 2;

    const fileSizeIsValid = file.size <= twoMegabytes;

    if (!fileSizeIsValid) {
      throw new Error({ message: "Tamanho do arquivo inválido" });
    }

    return fileSizeIsValid;
  }
}
