export default class Reponsivity {
  static props() {
    return {
      responsivity: {
        aspectRatio: String,
        mdHeight: String,
        mdWidth: String,
        smHeight: String,
        smWidth: String,
        xsHeight: String,
        xsWidth: String,
        lgHeight: String,
        lgWidth: String,
        xlHeight: String,
        xlWidth: String,
        maxWidth: String,
        maxHeight: String
      }
    };
  }

  static aspectRatio() {
    return {
      widescreen: "21 / 6",
      horizontal: "16 / 9",
      box: "3 / 4"
    };
  }
}
