export default {
  data() {
    return {
      headers: {
        all: [
          {
            text: "Tipo",
            sortable: false,
            value: "type",
            width: 140,
            order: 1
          },
          {
            text: "Nosso Número",
            value: "transaction",
            width: 125,
            order: 2
          },
          {
            text: "Fatura",
            value: "invoice",
            width: 150,
            order: 2
          },
          {
            text: "Pagador",
            value: "payer.name",
            width: 400,
            order: 3
          },
          {
            text: "CNPJ/CPF",
            value: "payer.cnpj_cpf",
            width: 180,
            order: 4
          },
          {
            text: "Data Ent. Portal",
            value: "created_at",
            width: 140,
            sortable: false,
            order: 5
          },
          {
            text: "Data Emissão",
            value: "invoice_date",
            width: 140,
            sortable: false,
            order: 5
          },
          {
            text: "Data Vencimento",
            value: "due_date",
            align: "center",
            width: 135,
            sortable: false,
            order: 6
          },
          {
            text: "Descontos",
            value: "computed_discount",
            width: 180,
            order: 8
          },
          {
            text: "Moeda Fatura",
            value: "currency",
            class: "bg-white text-center",
            align: "center",
            width: 180,
            order: 8
          },
          {
            text: "Valor da Fatura",
            value: "total",
            width: 180,
            order: 9
          },
          {
            text: "Valor do Boleto",
            value: "result_total",
            width: 200,
            class: "bg-white",
            order: 10
          },
          {
            text: "Câmbio Emissão",
            value: "currency_value",
            width: 130,
            sortable: false,
            class: "bg-white text-center",
            align: "center",
            order: 10
          },
          {
            text: "Status",
            sortable: false,
            value: "status",
            align: "center",
            class: "bg-white",
            order: 10
          },
          {
            text: "",
            sortable: false,
            width: 150,
            value: "actions",
            order: 20
          },
          {
            text: "Data Remessa",
            value: "emited_date",
            class: "bg-white",
            order: 11
          },
          {
            text: "Operações",
            value: "identification",
            align: "center",
            sortable: false,
            order: 12
          },
          {
            text: "BL",
            value: "bl",
            class: "bg-white",
            order: 13
          },
          {
            text: "Booking",
            value: "booking",
            class: "bg-white",
            order: 14
          },
          {
            text: "Código do Navio",
            value: "ship",
            class: "bg-white",
            order: 15
          },
          {
            text: "Código da Viagem",
            value: "travel",
            class: "bg-white",
            order: 16
          },
          {
            text: "Porto de Embarque",
            value: "boarding_point_name",
            class: "bg-white",
            order: 17
          },
          {
            text: "Porto de Desembarque",
            value: "landing_point_name",
            class: "bg-white",
            order: 18
          },
          {
            text: "Identif. do Título no Sistema",
            value: "id",
            align: "center",
            sortable: false,
            order: 1
          }
        ],
        selected: [
          "select",
          "type",
          "invoice",
          "payer.name",
          "payer.cnpj_cpf",
          "invoice_date",
          "due_date",
          "computed_total",
          "status",
          "actions"
        ]
      }
    };
  },
  computed: {
    getSelectedHeaders() {
      const selectedHeaders = this.headers.all.filter(header =>
        this.headers.selected.some(select => header.value == select)
      );

      return selectedHeaders.sort(
        (compare, comparison) => compare.order - comparison.order
      );
    }
  }
};
