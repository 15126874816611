<template>
  <v-col cols="4" xs="4" xl="4" class="flex-column d-flex">
    <span class="text-caption font-weight-bold flex-item">
      <slot name="title"> </slot>
    </span>
    <span class="text-body-1 flex-item">
      <slot name="message"> </slot>
    </span>

    <span class="text-subtitle-2 flex-item text--disabled">
      <slot name="extra_info"> </slot>
    </span>
  </v-col>
</template>

<script>
export default {
  name: "ExtraInfoTile"
};
</script>

<style lang="scss" scoped>
.flex-item__info {
  flex: 1 0 33%;
}
</style>
