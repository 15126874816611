<template>
  <v-container class="bordered-info rounded-sm" fluid>
    <v-row>
      <v-col cols="12">
        <h5 class="text-h5 text--secondary">
          QRCODE
        </h5>

        <span class="text-caption text--secondary">
          Aponte a camêra para usá-lo
        </span>
      </v-col>
      <v-col cols="12" class="pa-0 my-n3">
        <pix-qr-code-error v-if="loading">
          <template #icon>
            <v-progress-circular indeterminate color="grey" size="46" />
          </template>

          <template #content>
            Carregando...
          </template>
        </pix-qr-code-error>

        <pix-qr-code-image
          v-else-if="!error && !loading"
          v-model="model.image"
        />

        <pix-qr-code-error v-else>
          <template #content>
            <v-icon large class="my-1">
              mdi-close
            </v-icon>

            OCORREU UM ERRO AO GERAR O QRCODE
          </template>
        </pix-qr-code-error>

        <v-col cols="12" class="mx-0 px-0">
          <h5 class="text-h5 text--secondary">
            Ações
          </h5>
        </v-col>

        <pix-action-copy-code-button v-model="model" :disabled="disabled" />

        <pix-action-save-pdf-button v-model="model" :disabled="disabled" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PixQrCodeError from "./PixQrCodeError.vue";
import PixQrCodeImage from "./PixQrCodeImage.vue";
import PixActionCopyCodeButton from "./PixActionCopyCodeButton.vue";
import PixActionSavePdfButton from "./PixActionSavePdfButton.vue";
import { bus } from "@/main";

export default {
  components: {
    PixQrCodeError,
    PixQrCodeImage,
    PixActionCopyCodeButton,
    PixActionSavePdfButton
  },
  name: "PixQrCode",
  data() {
    return {
      model: {
        image: "",
        qrcode: ""
      },
      invoice: {},
      loading: true,
      error: false
    };
  },
  created() {
    bus.$on("render-qrcode", this.render);
  },
  methods: {
    render(data) {
      this.model = data.model;
      this.invoice = data.invoice;
      this.loading = false;

      if (this.model.image) {
        this.error = false;
      } else {
        this.error = true;
      }
    }
  }
};
</script>

<style></style>
