import Rest from "../Base/Rest";

/**
 * @typedef {InvoicesDiscountsTypesService}
 */
export default class InvoicesDiscountsTypesService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/invoice/discount-types";

  storeApprovalGroupType(type) {
    const resource = InvoicesDiscountsTypesService.resource;

    return this.post(`api${resource}/type`, type);
  }

  getApprovalGroupType(id_group) {
    const resource = InvoicesDiscountsTypesService.resource;

    return this.get(`api${resource}/group/${id_group}`);
  }

  deleteApprovalGroupType(id) {
    const resource = InvoicesDiscountsTypesService.resource;

    return this.delete(`api${resource}/type/${id}`);
  }

  getApprovalGroupUser(id_group) {
    const resource = InvoicesDiscountsTypesService.resource;

    return this.get(`api${resource}/user/${id_group}`);
  }

  storeApprovalGroupUser(user) {
    const resource = InvoicesDiscountsTypesService.resource;

    return this.post(`api${resource}/user`, user);
  }

  deleteApprovalGroupUser(id) {
    const resource = InvoicesDiscountsTypesService.resource;

    return this.delete(`api${resource}/user/${id}`);
  }
}
