var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.pagination.itemsPerPage,"hide-default-footer":true,"header-props":{ sortIcon: null }},scopedSlots:_vm._u([{key:"item.invoice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getInvoice(item))+" ")]}},{key:"item.invoice_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.invoice_date))+" ")]}},{key:"item.nominal_value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.toCurrency(item.net_total - item.total_discount))+" ")]}},{key:"item.amount_paid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.credit_date != null ? _vm.toCurrency(item.net_total - item.total_discount) : "0,00")+" ")]}},{key:"item.credit_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCreditDate(item))+" ")]}},{key:"item.bank_our_number",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBankOurNumber(item))+" ")]}},{key:"item.transaction_created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getRecordingDate(item))+" ")]}},{key:"item.bank",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBank(item))+" ")]}},{key:"item.status_desc",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStatus(item))+" ")]}},{key:"item.invoice_status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status_name)+" ")]}},{key:"item.days_overdue",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDaysOverdue(item))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","loading":item.loading,"disabled":_vm.doesntHaveBankReturnFileToDownload(item)},on:{"click":function($event){return _vm.download(item)}}},[_c('v-icon',[_vm._v(" mdi-download ")])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }