<template>
  <v-container fluid>
    <v-row align-content="stretch">
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        class="text-center backgroundImageMobile"
        style="background: white"
      >
        <v-form ref="form" v-model="valid">
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12" lg="4" md="6" xs="12">
                <v-text-field
                  ref="name"
                  label="Nome"
                  v-model="model.name"
                  :rules="[rules.required]"
                  color="primary"
                  @keydown.enter="submit"
                />
              </v-col>
              <v-col cols="12" sm="12" lg="4" md="6" xs="12">
                <v-text-field
                  label="E-mail"
                  v-model="model.email"
                  :rules="[rules.required, rules.email]"
                  color="primary"
                  @keydown.enter="submit"
                />
              </v-col>
              <v-col class="d-flex" cols="12" sm="12" lg="4" md="6" xs="12">
                <v-select
                  :items="userRoles"
                  v-model="model.user_role_id"
                  label="Permissões do usuário"
                  :rules="[rules.required]"
                  :loading="loadingUserRoles"
                  item-text="name"
                  item-value="id"
                  color="primary"
                  clearable
                />
              </v-col>

              <v-col xs="12" sm="12" lg="4" md="6">
                <v-select
                  v-model="model.user_type"
                  label="Tipo de Usuário"
                  :items="user_type_items"
                  :rules="[rules.required]"
                  @change="onUserTypeChange"
                  clearable
                />
              </v-col>

              <v-col xs="12" lg="4" md="6" v-show="isExternal()">
                <payer-group-select
                  v-model="model.group"
                  :rules="[requirePayerGroupSelect]"
                />
              </v-col>

              <v-col xs="12" lg="4" md="6" v-show="isInternal()">
                <company-select
                  v-model="model.companies"
                  :rules="[requireCompanySelect]"
                  multiple
                  return-object
                />
              </v-col>

              <v-col class="d-flex" cols="12" sm="12" lg="4" md="6" xs="12">
                <v-select
                  v-model="model.status"
                  :items="items_status"
                  :rules="[rules.exactRequired]"
                  label="Status"
                  color="primary"
                  clearable
                />
              </v-col>
              <v-col cols="12" sm="12" lg="4" md="6" xs="12">
                <v-text-field
                  ref="last_login"
                  label="Último acesso"
                  :value="formattedDate"
                  color="primary"
                  disabled
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

import FormMixin from "@/components/misc/mixins/FormMixin";

import UserRoleService from "@/app/Services/UserRoleService";

const userRoleService = UserRoleService.build();

import CompanySelect from "@/components/CompanySelect/CompanySelect.vue";
import PayerGroupSelect from "@/components/PayerGroupSelect/PayerGroupSelect.vue";
import ModelManagementMixin from "@/components/misc/mixins/ModelManagementMixin";
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";
import UserTypeAccessMethods from "@/pages/User/Domain/UserTypeAccessMethods";

export default Vue.extend({
  name: "UsersRecordInfo",
  props: {
    user: Object,
    listTypeUser: Array,
    userTypePermissions: Array
  },
  components: {
    CompanySelect,
    PayerGroupSelect
  },
  mixins: [FormMixin, ModelManagementMixin, AuthorizationMixin],
  computed: {
    formattedDate: {
      get() {
        if (!this.model.last_login) {
          return null;
        }

        const result = this.formatMysqlDate(this.model.last_login, {
          withHour: true
        });

        return result;
      }
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      valid: false,
      loadingUserRoles: false,
      userRoles: [],
      items_status: [
        { value: 1, text: "Ativo" },
        { value: 0, text: "Desativado" }
      ],
      user_type_items: []
    };
  },
  created() {
    this.loadUserRoles();

    this.loadUserTypeByPermission();
  },
  watch: {
    valid() {
      this.$emit("valid", this.valid);
    }
  },
  methods: {
    async loadUserTypeByPermission() {
      this.user_type_items = await new UserTypeAccessMethods()
        .setScope(this)
        .loadUserTypeByPermission();

      this.$forceUpdate();
    },
    isInternal() {
      const result = this.model.user_type == "internal";

      return result;
    },
    isExternal() {
      const result = this.model.user_type == "external";

      return result;
    },
    onUserTypeChange() {
      this.model.payer_id = null;
      this.model.group_id = null;
      this.model.group = null;
      this.model.companies = null;

      this.$emit("verifyUserTypePermission");

      this.$forceUpdate();
    },
    requireCompanySelect(value) {
      if (this.isInternal() && (!value || !value.length)) {
        return "Companhia Não Selecionado";
      }

      return null;
    },
    requirePayerGroupSelect(value) {
      if (!value || !this.isExternal()) {
        return null;
      }

      if (!value.payers || !value.payers.length) {
        return null;
      }
    },
    validate() {
      return this.$refs.form.validate();
    },
    async loadUserRoles() {
      try {
        this.loadingUserRoles = true;

        const response = await userRoleService.search();

        this.userRoles = response.data;
      } catch (error) {
        console.error(error);
        this.defaultCatchError(error);
      } finally {
        this.loadingUserRoles = false;
      }
    }
  }
});
</script>
