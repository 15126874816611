import BankReturnService from "@/app/Services/BankReturnService";
import FileSaver from "file-saver";

export default {
  data() {
    return {
      files: []
    };
  },
  methods: {
    async download(file) {
      try {
        this.loading = true;

        if (file.created) {
          return;
        }

        const params = {};

        const options = {
          responseType: "blob"
        };

        const response = await BankReturnService.build().request(
          `/download/${file.audit_bank_return_file_id}`,
          params,
          options
        );

        FileSaver.saveAs(response.data, file.file_name);
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
