var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-layout',{attrs:{"loading":_vm.loading,"route-name":"Reembolso","custom-function":""},on:{"returnToPreviousPage":_vm.returnToList},scopedSlots:_vm._u([{key:"header-actions",fn:function(){return [_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-spacer'),_c('default-cancel-button',{attrs:{"loading":_vm.saving,"custom-function":""},on:{"click":_vm.clearHandleOrReturnToList}}),(
            (_vm.refund && _vm.refund.status && _vm.refund.status.refs == 'solicitation'
              ? true
              : false) &&
              _vm.verifyAuthentication.accessHandle &&
              !_vm.handleDisapproveFinancialReason &&
              _vm.refund.id
          )?_c('attendance-button',{attrs:{"loading":_vm.approving || _vm.saving,"disabled":_vm.alreadyConfirmed,"handle":_vm.handleApproveFinancialReason},on:{"click":_vm.startAttendance}}):_vm._e(),(
            _vm.verifyAuthentication.accessSave &&
              !_vm.handleDisapproveFinancialReason &&
              !_vm.handleApproveFinancialReason
          )?_c('default-save-button',{attrs:{"loading":_vm.saving,"disabled":_vm.alreadyConfirmed},on:{"click":_vm.onSave}}):_vm._e()],1),_c('v-row',[_c('span',{staticClass:"ml-auto mr-6 mt-n4 text-caption error--text"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")])])],1)]},proxy:true},{key:"content",fn:function(){return [_c('v-tabs',{staticClass:"containerInner",attrs:{"color":"secondary","flat":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{staticClass:"font-weight-bold",attrs:{"href":"#info"}},[_vm._v(" INFORMAÇÕES GERAIS ")]),(_vm.isOpenChat())?_c('v-tab',{staticClass:"font-weight-bold",attrs:{"href":"#chat"}},[_vm._v(" ATENDIMENTO ")]):_vm._e(),_c('v-tab-item',{attrs:{"value":"info"}},[_c('refund-save-form',{ref:"form",attrs:{"user-type-permissions":_vm.user_type_permissions,"handle-financial-message":_vm.handleApproveFinancialReason || _vm.handleDisapproveFinancialReason,"can-manipulate":_vm.verifyAuthentication.accessSave && !_vm.alreadyConfirmed,"can-manipulate-upload":_vm.verifyAuthentication.accessSave && !_vm.alreadyConfirmed},on:{"valid":_vm.changeValidation,"download":_vm.download},model:{value:(_vm.refund),callback:function ($$v) {_vm.refund=$$v},expression:"refund"}})],1),_c('v-tab-item',{attrs:{"value":"chat"}},[(_vm.isOpenChat())?_c('refund-chat',{ref:"chat",attrs:{"user-type-permissions":_vm.user_type_permissions,"can-manipulate":_vm.verifyAuthentication.accessSave && !_vm.alreadyConfirmed},on:{"loadData":_vm.loadData},model:{value:(_vm.refund),callback:function ($$v) {_vm.refund=$$v},expression:"refund"}}):_vm._e()],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }