<template>
  <div></div>
</template>

<script>
import { getAuthentication } from "@/config/service/standard";
import Report from "./domain/Report";

export default {
  name: "ReportPushNotificationManager",
  created() {
    this.defineAuth();

    this.joinToReportChannel();
  },
  methods: {
    defineAuth() {
      const authenticate = getAuthentication();

      const authorization = window.Echo.connector.pusher.config.auth;

      authorization.headers["Authorization"] = authenticate;
      authorization.headers["Access-Control-Allow-Origin"] = "*";
    },
    getUser() {
      const storage = localStorage.getItem("user");

      const parsedStorage = JSON.parse(storage);

      return parsedStorage.user;
    },
    getChannelToSubscribe() {
      const user = this.getUser();

      let channel = "";

      // eslint-disable-next-line no-undef
      if (process.env.NODE_ENV === "production") {
        channel = "messages.";
      } else {
        channel = "messages_dev.";
      }

      channel = channel + user.id;

      return channel;
    },
    async joinToReportChannel() {
      const channel = this.getChannelToSubscribe();

      const broadcast = await window.Echo.channel(channel);

      broadcast.listen(".message", response => {
        const isTheSubscrivedUser =
          response.message.socket_id == window.Echo.socketId();

        if (isTheSubscrivedUser) {
          const handleReport = Report.handleReport.bind(this);

          handleReport(response.message);
        }
      });
    }
  }
};
</script>

<style></style>
