<template>
  <v-select
    v-model="selected"
    :items="status"
    :loading="loading"
    :readonly="loading"
    label="Status bancário"
    item-text="text"
    clearable
    multiple
    return-object
    small-chips
  />
</template>

<script>
export default {
  name: "StatusByBankSelectFilter",
  props: {
    value: Array,
    status: Array
  },
  data() {
    return {
      selected: this.value,
      loading: false
    };
  },
  watch: {
    selected() {
      this.$emit("input", this.selected);
    },
    value() {
      this.selected = this.value;
    }
  }
};
</script>

<style></style>
