import DiscountTypes from "@/pages/DiscountTypes/DiscountTypes.vue";
import DiscountApprovalGroups from "@/pages/DiscountApprovalGroups/DiscountApprovalGroups.vue";
import DiscountApprovalGroupsSave from "@/pages/DiscountApprovalGroups/DiscountApprovalGroupsSave.vue";
import DiscountTypesSave from "@/pages/DiscountTypesSave/DiscountTypesSave.vue";
import DiscountApprovals from "@/pages/DiscountApprovals/DiscountApprovals.vue";

const routes = [
  {
    description: "Tipos de Desconto",
    permission: "/discount/types",
    path: "/discount/types/",
    name: "DiscountTypes",
    group_type: "discount",
    component: DiscountTypes
  },
  {
    path: "/discount/types/:id",
    name: "DiscountTypesSave",
    component: DiscountTypesSave
  },
  {
    description: "Grupos Aprovação de Desconto",
    permission: "/discount/approval-groups",
    path: "/discount/approval-groups/",
    name: "DiscountApprovalGroups",
    group_type: "discount",
    component: DiscountApprovalGroups
  },
  {
    path: "/discount/approval-groups/:id",
    name: "DiscountApprovalGroupsSave",
    component: DiscountApprovalGroupsSave
  },
  {
    description: "Aprovações",
    permission: "/discount/approvals",
    path: "/discount/approvals/",
    name: "DiscountApprovals",
    group_type: "discount",
    component: DiscountApprovals
  }
];

export default routes;
