export default {
  data() {
    return {
      status: {
        solicitation: 1,
        attendance: 2,
        waiting_customer: 3,
        waiting_customer_documentation: 4,
        under_registration: 5,
        under_approval: 6,
        approved: 7,
        repproved: 8,
        cancel: 9
      }
    };
  }
};
