var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('breadcrumb',{attrs:{"route-name":"Usuários"}}),_c('v-card',{attrs:{"depressed":""}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"xl":"6","lg":"6","md":"6","sm":"12","xs":"12"}},[_c('default-filter',{attrs:{"instructions":_vm.instructions},on:{"search":_vm.search}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"xl":"6","lg":"6","md":"6","sm":"12","xs":"12"}},[_c('v-spacer'),_c('authorization-gate',{staticClass:"d-flex flex-item",attrs:{"auth-route":['/user/external', '/user/internal'],"auth-type":"write"}},[_c('generate-reports-button',{attrs:{"table":"powers_of_attorney","label":"Gerar Relatório de Procurações","filename":"relatorio_procuracoes","filter":_vm.filter}}),_c('v-btn',{staticClass:"ml-auto text-normal my-auto mr-6",attrs:{"color":"primary"},on:{"click":function($event){return _vm.add()}}},[_vm._v(" Adicionar ")])],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.pagination.itemsPerPage,"loading":_vm.loading,"loading-text":"Aguarde...carregando","hide-default-footer":true,"header-props":{ sortIcon: null }},on:{"update:options":_vm.sortRequest},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"inset":"","color":"secondary","readonly":!_vm.verifyIfCanEditByUserAccess(item)},on:{"change":function($event){return _vm.changeStatus(item)}},model:{value:(item.custom_status),callback:function ($$v) {_vm.$set(item, "custom_status", $$v)},expression:"item.custom_status"}})]}},{key:"item.resend",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"medium":"","icon":""},on:{"click":function($event){return _vm.reesendPassword(item)}}},[_c('v-icon',[_vm._v(" mdi-lock ")])],1)]}},{key:"item.last_login",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatMysqlDate(item.last_login, { withHour: true }))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"medium":""},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v(" "+_vm._s(_vm.verifyIfCanEditByUserAccess(item) ? "mdi-pencil" : "mdi-eye")+" ")])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-container',{staticClass:"max-width",attrs:{"fluid":""}},[_c('pagination',{on:{"change":_vm.loadData},model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }