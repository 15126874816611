<template>
  <v-list-item-group color="primary">
    <v-list-item @click="changeProfile">
      <v-list-item-title>
        <v-icon medium class="mr-2"> mdi-account </v-icon>

        Editar Perfil
      </v-list-item-title>
    </v-list-item>
  </v-list-item-group>
</template>

<script>
export default {
  name: "UserProfileHeaderLink",
  methods: {
    changeProfile() {
      this.$router.push({
        name: "UserProfile"
      });
    }
  }
};
</script>

<style></style>
