import Rest from "../Base/Rest";

/**
 * @typedef {CityService}
 */
export default class CityService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/city";
}
