<template>
  <v-col cols="12" xs="12">
    <v-row no-gutters>
      <extra-info-tile>
        <template #title>
          Navio
        </template>

        <template #message>
          {{ invoice.ship }}
        </template>
      </extra-info-tile>

      <extra-info-tile>
        <template #title>
          Porto de Embarque
        </template>

        <template #message>
          {{ invoice.boarding_point_name }}
        </template>
      </extra-info-tile>

      <extra-info-tile>
        <template #title>
          Porto de Desembarque
        </template>

        <template #message>
          {{ invoice.landing_point_name }}
        </template>
      </extra-info-tile>

      <extra-info-tile>
        <template #title>
          Número da Viagem
        </template>

        <template #message>
          {{ invoice.travel }}
        </template>
      </extra-info-tile>

      <extra-info-tile>
        <template #title>
          BL
        </template>

        <template #message>
          {{ invoice.bl }}
        </template>
      </extra-info-tile>

      <extra-info-tile>
        <template #title>
          Data de Chegada do Navio
        </template>

        <template #message>
          {{ invoice.arrival_date | formatDate }}
        </template>
      </extra-info-tile>
    </v-row>

    <v-divider class="mt-3" />
  </v-col>
</template>

<script>
import ExtraInfoTile from "@/pages/Invoice/components/extra_info_dialog/ExtraInfoTile.vue";

export default {
  components: { ExtraInfoTile },
  name: "ArrivalInfo",
  props: {
    invoice: Object
  }
};
</script>

<style></style>
