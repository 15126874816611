import Rest from "../Base/Rest";

/**
 * @typedef {RecoverPasswordService}
 */
export default class RecoverPasswordService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/auth/recover-password";
}
