<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="text-start">
        <v-btn icon x-large>
          <v-icon @click="back()" color="grey darken-2">
            mdi-keyboard-backspace
          </v-icon>
        </v-btn>

        <h1 class="display-2 font-weight-bold mt-3 mb-3 logo row"></h1>

        <p class="text-center mb-4">Alterar Senha</p>
        <p v-if="msg_error != ''" class="text-center mb-4 red--text">
          {{ msg_error }}
        </p>

        <p v-if="msg_sucess != ''" class="text-center mb-4 green--text">
          {{ msg_success }}
        </p>
      </v-col>

      <v-col cols="12">
        <v-form ref="form" class="w-100">
          <v-row>
            <v-col cols="8" class="mx-auto">
              <v-text-field
                outlined
                label="Nova senha"
                v-model="user.password"
                :append-icon="!e1 ? 'visibility' : 'visibility_off'"
                @click:append="() => (e1 = !e1)"
                :type="e1 ? 'password' : 'text'"
                counter
                required
                color="primary"
                @keydown.enter="submit"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8" class="mx-auto">
              <v-text-field
                outlined
                label="Confirmar a senha"
                v-model="passwordConfirm"
                :append-icon="!e2 ? 'visibility' : 'visibility_off'"
                @click:append="() => (e2 = !e2)"
                :type="e2 ? 'password' : 'text'"
                counter
                required
                color="primary"
                @keydown.enter="submit"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="8" class="mx-auto">
              <v-btn
                v-if="msg_success == ''"
                block
                class="buttonEnter"
                color="primary"
                @click="save()"
                :loading="saving"
              >
                Salvar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>

      <v-col cols="12">
        <div class="pre-register-spacer" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import LoginRecoverService from "@/app/Services/LoginRecoverService";
import UserService from "@/app/Services/UserService";

const loginRecoverService = LoginRecoverService.build();

export default Vue.extend({
  name: "AlterPassword",
  data: () => ({
    loading: false,
    e1: true,
    e2: true,
    msg_error: "",
    msg_success: "",
    passwordConfirm: "",
    saving: false,
    data: {
      id: 0
    },
    user: {
      name: "",
      password: ""
    }
  }),
  created() {
    if (this.isToRecoverPassword()) {
      this.verifyRecover();
    } else {
      this.verifyChange();
    }
  },
  methods: {
    back() {
      this.$router.push({ path: "/login" });
    },
    async verifyRecover() {
      if (this.$router.history.current.params.hash == undefined) {
        this.emitAlterPasswordError(
          "Código de Alteração de Senha não definido"
        );

        return;
      }

      try {
        this.loading = true;

        const response = await loginRecoverService.send({
          hash: String(this.$router.history.current.params.hash).replace(
            /[.]/g,
            "/"
          )
        });

        const user = response;

        this.data.id = user.id;
      } catch (e) {
        this.emitAlterPasswordError("Alteração expirada ou senha já alterada!");
      } finally {
        this.loading = false;
      }
    },
    verifyChange() {
      this.data.id = this.$route.params.id;
    },
    emitAlterPasswordError(message) {
      this.msg_success = message;

      setTimeout(() => {
        localStorage.clear();
        this.back();
      }, 7000);
    },
    isToRecoverPassword() {
      const result = this.$route.path.includes("recover");

      return result;
    },
    isToChangePassword() {
      const result = this.$route.path.includes("change-password");

      return result;
    },
    submit() {
      this.save();
    },
    async save() {
      if (!this.valid()) {
        return;
      }

      try {
        this.saving = true;

        await UserService.build().update(
          {
            id: this.data.id,
            password: this.user.password,
            password_prox_login: false
          },
          `/${this.data.id}/login`
        );

        this.msg_success = "Alteração efetuada com sucesso!";
        setTimeout(() => {
          localStorage.clear();
          this.$router.push({ path: "/login" });
        }, 2000);
      } catch (e) {
        console.error(e);
      } finally {
        this.saving = false;
      }
    },
    valid() {
      if (this.user.password == "") {
        this.msg_error = "Favor digitar a senha corretamente!";

        return false;
      }

      if (
        this.user.password != "" &&
        this.user.password != this.passwordConfirm
      ) {
        this.msg_error = "As senhas não conferem!";

        return false;
      }

      return true;
    }
  }
});
</script>
<style lang="scss" scoped></style>
