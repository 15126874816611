<template>
  <v-app id="app">
    <drawer :items="items" v-model="drawer" />

    <app-navigation-top />

    <v-main class="main--fixes">
      <router-view />

      <dialog-handler />

      <app-navigation-footer />

      <report-push-notification-manager />
    </v-main>
  </v-app>
</template>

<script>
import DialogHandler from "@/components/NotificationDialog/DialogHandler.vue";

import Drawer from "./navigation/drawer/Drawer.vue";
import AppNavigationTop from "./AppNavigationTop.vue";

import AuthService from "@/app/Services/AuthService";
import AppNavigationFooter from "./AppNavigationFooter.vue";

import ReportPushNotificationManager from "@/components/Reports/ReportPushNotificationManager";

export default {
  name: "AppView",
  data: () => ({
    loading: false
  }),
  components: {
    Drawer,
    AppNavigationTop,
    DialogHandler,
    ReportPushNotificationManager,
    AppNavigationFooter
  },
  created() {
    this.addRefreshTokenJob();
  },
  mounted() {
    this.$root.$SnackBar = this.$refs.SnackBar;
    this.loading = true;
  },
  methods: {
    addRefreshTokenJob() {
      const everyThirthyMinutes = 1000 * 60 * 15;

      setInterval(this.refreshToken, everyThirthyMinutes);
    },
    async refreshToken() {
      const response = await AuthService.refreshToken();

      localStorage.user = JSON.stringify(response);
    }
  }
};
</script>

<style lang="scss">
body {
  font-family: "Helvetica Neue" !important;
  font-size: 1rem;
}
.v-application {
  font-family: "Helvetica Neue" !important;
}

#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.main--fixes {
  & > .v-main__wrap > .container {
    min-height: 85vh;
  }
}
</style>
