import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

const companyState = new Vuex.Store({
  state: {
    company: {
      id: "new"
    },
    accounts: [],
    editingAccount: false
  },
  mutations: {
    updateCompany(state, company) {
      state.company = company;
    },
    updateAccounts(state, accounts) {
      state.accounts = accounts;
    },
    updateAccountEditingStatus(state, value) {
      state.editingAccount = value;
    },
    clearHandle(state) {
      state.company = {
        id: "new"
      };

      state.accounts = [];
    }
  },
  actions: {
    changeHandleCompany(context, company) {
      context.commit("updateCompany", company);

      context.commit("updateAccounts", company.accounts);
    },
    updateAccountEditingStatus(context, value) {
      context.commit("updateAccountEditingStatus", value);
    },
    clearHandle(context) {
      context.commit("clearHandle");
    },
    updateCompany(context, company) {
      context.commit("updateCompany", company);
    },
    updateAccounts(context, accounts) {
      context.commit("updateAccounts", accounts);
    }
  }
});

const secret = () => companyState;

export default secret;
