<template>
  <v-btn
    class="white--text ml-auto mr-6 my-auto"
    :color="handle ? 'primary' : 'success'"
    @click="attendance"
    :disabled="disabled"
    :loading="loading"
    data-cy="attendance"
  >
    <slot>Iniciar atendimento</slot>
  </v-btn>
</template>

<script>
export default {
  name: "AttendanceButton",
  props: {
    disabled: Boolean,
    loading: Boolean,
    handle: Boolean
  },
  methods: {
    attendance() {
      this.$emit("click");
    }
  }
};
</script>

<style></style>
