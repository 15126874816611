import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

const ArrivalResponseState = new Vuex.Store({
  state: {
    data: []
  },
  mutations: {
    updateData(state, data) {
      state.data = data;
    },
    updateArrivalResponse(state, payload) {
      state.data[payload.index] = payload.arrivalResponse;

      state.data = [...state.data];
    },
    clearHandle(state) {
      state.data = [];
    }
  },
  getters: {
    errors(state) {
      const responses = state.data;

      const serializedErrorList = responses.map(arrivalResponse => {
        if (arrivalResponse.loading) {
          arrivalResponse.error = "Carregando...";
        }

        return arrivalResponse;
      });

      return serializedErrorList.filter(
        arrivalResponse => arrivalResponse.error
      );
    },
    realizedItems(state) {
      const responses = state.data;

      const items = responses.filter(
        arrivalResponse => arrivalResponse.realized
      );

      return items;
    },
    realizedPercentage(state) {
      try {
        const responses = state.data;

        const items = responses.filter(
          arrivalResponse => arrivalResponse.realized
        );

        const percentage = Number(items.length / responses.length);

        const serializedPercentage = Number.isNaN(percentage)
          ? 0
          : percentage * 100;

        return serializedPercentage;
      } catch (error) {
        return 0;
      }
    }
  },
  actions: {
    changeData(context, payload) {
      context.commit("updateData", payload);
    },
    changeArrivalResponse(context, payload) {
      context.commit("updateArrivalResponse", payload);
    },
    clearHandle(context, payload) {
      context.commit("clearHandle", payload);
    }
  }
});

const secret = () => ArrivalResponseState;

export default secret;
