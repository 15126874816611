<template>
  <v-container fluid>
    <breadcrumb route-name="Tipos de Usuário" />

    <v-card :loading="loading">
      <v-row>
        <v-col xl="6" lg="6" md="8" sm="12" xs="12" cols="12">
          <default-filter :instructions="instructions" @search="search" />
        </v-col>

        <v-col
          xl="6"
          lg="6"
          md="4"
          sm="12"
          xs="12"
          cols="12"
          class="d-flex flex-row"
        >
          <authorization-gate
            auth-route="/user-role-permission"
            auth-type="write"
            class="d-flex flex-item ml-auto my-auto"
          >
            <default-save-button @click="add">
              Adicionar
            </default-save-button>
          </authorization-gate>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page="pagination.itemsPerPage"
            loading-text="Aguarde...carregando"
            :hide-default-footer="true"
            class="elevation-0"
          >
            <template #item.actions="{ item }">
              <v-icon @click="edit(item)" medium class="mr-2">
                {{ authorization.canEdit ? "mdi-pencil" : "mdi-eye" }}
              </v-icon>
            </template>

            <template #item.status="{ item }">
              <v-switch
                v-model="item.status"
                inset
                :readonly="!authorization.canEdit"
                color="secondary"
                class="mx-auto d-flex flex-item mt-1"
                @change="changeStatus(item)"
              />
            </template>
          </v-data-table>
        </v-col>

        <v-col cols="12">
          <v-pagination
            v-if="items.last_page > 0"
            color="orange"
            light="true"
            v-model="items.current_page"
            class="my-4"
            :length="items.last_page"
            @input="loadData"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import Vue from "vue";
import UserRoleService from "@/app/Services/UserRoleService";
import AuthorizationMixin from "@/components/Authorization/AuthorizationMixin";
import AuthorizationGate from "@/components/Authorization/AuthorizationGate.vue";
import PaginationMixin from "@/components/pagination/PaginationMixin";
import FilterMixin from "@/components/Filters/FilterMixin";
import DefaultSaveButton from "@/components/layout/DefaultSaveButton.vue";
import DefaultFilter from "@/components/Filters/DefaultFilter.vue";

const userRoleService = UserRoleService.build();

export default Vue.extend({
  name: "UsersAccess",
  components: {
    Breadcrumb,
    AuthorizationGate,
    DefaultSaveButton,
    DefaultFilter
  },
  data: () => ({
    loading: false,

    headers: [
      {
        text: "Tipo de usuário",
        align: "start",
        sortable: false,
        value: "name"
      },
      {
        text: "Status",
        sortable: false,
        value: "status",
        width: "100"
      },
      {
        text: "Ações",
        value: "actions",
        width: "100",
        align: "center"
      }
    ],
    instructions: {
      search_for: [{ text: "Tipo de Usuário", field: "name" }],
      filter_for: [
        { text: "Ativos", field: "status", value: 1 },
        { text: "Inativos", field: "status", value: 0 }
      ]
    },
    authorization: {
      route: "/user-role-permission"
    },
    items: [],
    configTable: {
      per_page: 10,
      page: 1,
      filter: []
    }
  }),
  created() {
    this.loadAccesses();
  },
  mounted() {
    this.loadData();
  },
  mixins: [AuthorizationMixin, FilterMixin, PaginationMixin],
  methods: {
    async loadData() {
      try {
        this.loading = true;

        const query = { params: { ...this.pagination, filter: this.filter } };

        const response = await userRoleService.index({ query });

        this.items = response.data;
      } catch (error) {
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    async changeStatus(item) {
      try {
        this.loading = true;

        const service = UserRoleService.build();

        const response = await service.update(item);

        this.toHandle = null;

        return response.data;
      } catch (error) {
        console.log(error);
        item.status = !item.status;
        this.defaultCatchError(error);
      } finally {
        this.loading = false;
      }
    },
    add() {
      this.$router.push({ name: "UserTypeAccessSave", params: { id: "new" } });
    },
    edit(row) {
      this.$router.push({ name: "UserTypeAccessSave", params: { id: row.id } });
    }
  }
});
</script>

<style lang="css" scoped>
.v-subheader {
  height: 57px;
  padding: 0;
}
.elevation-1 {
  padding: 10px 37px;
}
.lblUser {
  font-size: 18px;
}
.custom-btn {
  font-size: 14px;
  font-family: "Helvetica Neue" !important;
  text-transform: none;
  margin: 10px;
  color: white;
}
.alignButtons {
  width: 230px;
  margin: 0;
  margin-left: auto;
  padding: 23px 0;
}
.btnAdd {
  font-size: 11px;
  font-family: "Helvetica Neue" !important;
  text-transform: none;
  position: absolute;
  right: 20px;
  margin: 10px;
}
.spaceL {
  margin-left: 10px;
}
.btnHome {
  height: 27px;
}
</style>
