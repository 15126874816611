import Rest from "../Base/Rest";

/**
 * @typedef {PayerGroupService}
 */
export default class PayerGroupService extends Rest {
  /**
   * @type {String}
   */
  static resource = "/group";

  all(filters = {}) {
    filters["all"] = true;

    const query = {
      ...filters,
      per_page: 100
    };

    return this.search(query);
  }
}
